
import _ from 'Helpers/_/_';
const DevHostname = [
    'localhost',
    '128.199.27.56',
    '172.20.10.4',
    'isocial.vinfotechprojects.com',
    'Isocial.vinfotechprojects.com'
]

const StagingHostname = [
    'Isocial.vinfotechprojects.com'
]

const regex = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm
const hostname = window.location.hostname;
const protocol = window.location.protocol;
const origin = window.location.origin;

const development = {
    s3: {
        UPLOAD: "https://isocial-stg.s3.ap-south-1.amazonaws.com/upload/",
        BUCKET: "https://isocial-stg.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "isocial-stg",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: protocol + "//isocial.vinfotechprojects.com",
    Socket: {
        URL: "https://isocial.vinfotechprojects.com:4000",
        //  URL: protocol + "//Isocial.vinfotechprojects.com:4000",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "AIzaSyASOa7RM_dUylr1y-FaV14Jfx20hK_B4pg"
    },
    REDUX_LOGGER: false,
    CASINO_LINK_ENABLE: true,
    CASINO_URL: 'http://206.189.128.89/',
    is_live: false
};

const staging = {
    s3: {
        UPLOAD: "https://isocial-stg.s3.ap-south-1.amazonaws.com/upload/",
        BUCKET: "https://isocial-stg.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "isocial-stg",
        //BUCKET_DATA_PREFIX: "stg",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: protocol + "//Isocial.vinfotechprojects.com",
    Socket: {
        URL: "https://Isocial.vinfotechprojects.com:4000",
        //  URL: protocol + "//Isocial.vinfotechprojects.com:4000",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "AIzaSyASOa7RM_dUylr1y-FaV14Jfx20hK_B4pg"
    },
    REDUX_LOGGER: false,
    CASINO_LINK_ENABLE: true,
    CASINO_URL: 'https://casinoapi.in/',
    is_live: false
};


const prod = {
    s3: {
        UPLOAD: "https://Isocial-master.s3.amazonaws.com/upload/",
        BUCKET: "https://Isocial-master.s3.ap-south-1.amazonaws.com/appstatic/",
        //BUCKET_DATA_PREFIX: "live",
        BUCKET_DATA_PREFIX: "live_app_master_data",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: origin,
    Socket: {
        //  URL: "http://ec2-65-0-190-172.ap-south-1.compute.amazonaws.com:4000",
        URL: window.location.protocol !== 'https:' ? "http://bet.vinfotech.org:4000" : "http://bet.vinfotech.org:4000",
      //  URL: window.location.protocol !== 'https:' ? "http://ec2-65-0-190-172.ap-south-1.compute.amazonaws.com:4000" : "https://node.Isocial.com:4000",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "AIzaSyASOa7RM_dUylr1y-FaV14Jfx20hK_B4pg"
    },
    REDUX_LOGGER: false,
    CASINO_LINK_ENABLE: true,
    CASINO_URL: 'https://casinoapi.in/',
    is_live: true
};

const config = _.includes(DevHostname, hostname) || hostname.match(regex)
    ? (_.includes(StagingHostname, hostname) ? staging : development)
    : prod;

export default {
    ...config
};