import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
//import { SiteContainer } from 'Components';
import { _, Utils } from 'Helpers';
import router from "../../Router";

const { UserSportsbookRoute } = router;
class SportsbookLayout extends Component {
  render() {
    const { match } = this.props; 
    return (
      // <SiteContainer {...this.props} header>
        <>
        <Switch>
          {
            _.map(UserSportsbookRoute, (route, idx) => {
              return route.component ?
                <Route key={idx} path={match.url + route.path} exact={route.exact} name={route.name} component={(props) => <route.component {...props} page_id={route.page_id || Utils.CreateUUID()}/>} />
                :
                null
            })
          }
          <Redirect from="*" to={match.url} />
        </Switch>
        </>
      // {/* </SiteContainer> */}
    );
  }
}

export default SportsbookLayout;
