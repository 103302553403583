const CONSTANT = {
  DEFAULT_LNG: 'en',
  FALLBACK_LNG: 'en',
  DEVICE_TYPE: 3,
  DEVICE_ID: "",
  EXCHANGE_BETTING: 'exchange_betting',
  SPORTBOOK_BETTING: 'sportbook_betting',
  FANCY_BETTING: 'fancy_betting',
  CASINO_BETTING: 'casino_betting',
  MATCHED: 'Matched',
  UNMATCHED: 'Unmatched',
  FANCY: 'Fancy',
  CASINO: 'casino',
  ITEMS_PERPAGE: 20,
  ITEMS_PERPAGE_SM: 10,
  ITEMS_PERPAGE_XS: 5,
  CURRENT_OFFSET: 0,
  DATE_FORMAT: "HH:mm, DD/MM/YYYY",
  TIMEZONE: "",
  LABEL_DRAW: "Draw",
  BOOK_MAKER_ID: {
    exchange_betting: 41,
    sportbook_betting: 8
  },
  BETSLIP: {
    exchange_betting: 'exchange_betting',
    sportbook_betting: 'sportbook_betting',
    fancy_betting: 'fancy_betting',
    casino_betting:'casino_betting'
  },
  BOOKMAKER_TYPE: {
    sportbook_betting: '1',
    exchange_betting: '2',
    fancy_betting: '3',
    casino_betting:'4',
    live_casino:'5',
  },
  GET_BOOKMAKER_TYPE: {
    1: 'Sportsbook',
    2: 'Exchange',
    3: 'Fancy',
    4: 'Live Cards',
    5: 'Live Casino',
    6: 'Bookmaker'
  },
  GET_BETTING_TYPE: {
    1: 'Back',
    2: 'Lay',
  },
  MarketBox2Set: ['2', '21', '45'],
  MarketBox3Set: ['3'],

  CASINO_GAMES: [
    { streamingKey: 'Liveteenpatti', path: 'teenpatti', league_abbr:'ltp' },
  ]
}

const MSZ = {
  SIDEBAR_NO_LEAGUE: "No League available",
  FIXTURE_BLANK_HEADING: "No record found",
  FIXTURE_BLANK_PARAGRAPH: "Please try with different sports",
  MARKET_BLANK_PARAGRAPH: "No market available for this match",
  // BETSLIP_BLANK_HEADING: "No record found",
  BETSLIP_BLANK_PARAGRAPH: "Bet slip is empty. ",
  DUPLICATE_BETITEM: "Markets from same match cannot be combined in the same multiple bet",
  LAY_BETITEM: "You cannot place lay bet in multibet, please remove lay bet(s)",
  LOCK_BETTING: "You do not have sufficient permissions to perform this operation, Contact your administrator",
  BETSLIP_RETURN_MSZ: "Odds or Lines are changed and updated for the picks. Please submit if you confirm this.",
  BETSLIP_INVALID_BET_MSZ: "The game is already started, you cannot place this pick",
  PARTIALLY_SUBMIT_BETSLIP: "Some of your picks were submitted, but there was an error with at least one pick. See Bet Slip",
  BLANK_STAKE: "Please enter stake",
  BLANK_HISTORY: "No bet(s) are placed as of now",
  COMING_SOON: "Coming soon...",
  LIVEGAME_BLANK_HEADING: " has No live games at the moment",
  LIVEGAME_BLANK_PARAGRAPH: "Please check again later."
}

const permissions = {
  IS_MULTIBET_ALLOW: false,
  ONLY_SINGLE_BET: true,
}
export default { ...CONSTANT, ...MSZ, ...permissions }


