import React, { Component, Fragment } from 'react';
import { MessageBox, SportsBookBetItem } from 'Components';
import { Utils, _, Http, API } from 'Helpers';
import { CONSTANT } from 'Constants';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { Collapse } from 'reactstrap';
// import TinderCard from "react-tinder-card";

import { withTranslation } from 'react-i18next';
import Cards, { Card } from "react-swipe-card";

class SportsBookFixtureListComponent extends Component {
  state = {
    CollapseItem: [],
    myIdentifier: '',
    status: '',
    Callone: false,
    isFavourite: 0,
    isFavouriteLive: 0
  }
  componentDidMount() {
    this.props.sockeListener()
    this.props.InitListToggle(true)
    setTimeout(() => {
      _.forEach(this.props.NavigationList, item => {
        this.setState({
          CollapseItem: [...this.state.CollapseItem, item.sports_id]
        })
      })
    }, 100)
  }
  componentWillUnmount() {
    this.props.removeSockeListener()
    this.props.InitListToggle(false)

  }


  toggleCollapse = (sports_id) => {
    const { CollapseItem } = this.state
    if (_.includes(CollapseItem, sports_id)) {
      _.remove(CollapseItem, (o) => o == sports_id)
    } else {
      CollapseItem.push(sports_id)
    }
    this.setState({ CollapseItem });
  }

  // onSwipe = (direction,event) => {
  //   // event.stopPropagation();
  //   console.log('You swiped: ' + direction)
  // }

  onCardLeftScreen = (myIdentifier, item) => {
    const { Callone } = this.state
    this.setState({ myIdentifier: item, status: myIdentifier == "right" ? "1" : "0", Callone: true }, () => {
      if (Callone) {
        // this.getFavouriteList()
      }
    })
  }


  getFavouriteList = (msg) => {
    const { myIdentifier, status, isFavourite, isFavouriteLive } = this.state;
    let param = {
      "ls_event_id": myIdentifier ? myIdentifier.id : '',
      "sports_id": myIdentifier ? myIdentifier.sports_id : '',
      "choice_type": status,
      "status": "1"
    }
    this.setState({ posting: true })
    Http.post(API.FIXTURE_FAVOURITE_CHOICE, param).then(response => {
      this.setState({
        Callone: false
      }, () => {
        Utils.notify(response.message)
      })
      if (response != '' && response.response_code == 200) {
        if(status == '1'){
          if(msg == 'live'){
            this.setState({ isFavouriteLive: isFavouriteLive + 1 })
          } else {
            this.setState({ isFavourite: isFavourite + 1})
          }
        }
       
        
      }

    });

  }
  allMatchesView = (BetItemProps) => {
    const { NavigationList, SelectedSports, is_inplay } = this.props;
    console.log({ NavigationList: NavigationList, SelectedSports: SelectedSports, is_inplay: is_inplay }, "PPPPPPP");
    this.props.history.push({ pathname: '/all-matches', state: { NavigationList: NavigationList, SelectedSports: SelectedSports, is_inplay: is_inplay } });
  }

  setAnswer = (item, status,msg) => {
    // const { Callone } = this.state
    this.setState({ myIdentifier: item, status: status == "Yes" ? "1" : "0", Callone: true }, () => {
      // if (Callone) {
      this.getFavouriteList(msg)

      // }
    })
  }

  handleEnd = () => {
    // console.log(this.props.items);
    console.log("End");
    this.props.updateList()
  };


  render() {
    // console.log("first",this.state.rightDataView.event_id);
    // console.log("second",this.state.leftDataView.event_id);
    const {
      // status,

      // scope
      NavigationList,
      SelectedSports,
      // hasMore,
      FixtureList,
      FixtureListLive,
      FixtureListUpcoming,
      FixturePosting,
     // FixtureListBlank,
      page_id,
      InitList,
      is_inplay,

      // Methods
      handleSelectSport,
      // fetchMoreData,
      _oddsActiveClass,
      handleOptionChange,
      fixtureSelectHandler,
      SocketHighlight,
      t,
      reverseMatch
    } = this.props
    const { CollapseItem } = this.state
    const BetItemProps = {
      page_id,
      t,
      SocketHighlight,
      _oddsActiveClass: _oddsActiveClass,
      handleOptionChange: handleOptionChange,
      fixtureSelectHandler: fixtureSelectHandler,
      SelectedSports: SelectedSports,
    }




    return (
      <Fragment>
        {
          !is_inplay &&
          <div className="odds-padding-row">
            <div className="odds-filter-pills">
              {
                _.map(NavigationList, (item, key) => <span onClick={() => handleSelectSport({ Sport: item, League: {}, unbind: true })} {...{ key, className: item.sports_id == SelectedSports.sports_id ? 'active' : '' }}> {item.sports_id != '' ? <i className={`icon-${item.sports_id}`} /> : null} {item.sports_name}</span>)
              }
            </div>
          </div>
        }

        {
          InitList ?
            <Fragment>
              {
                is_inplay ?
                  <div className='game-event-list m-t-20'>
                    {
                      (FixturePosting && _.isEmpty(FixtureList)) ?
                        <Utils.BoxLoader />
                        :
                        <Fragment>
                          {
                            _.map(NavigationList, (sport) => {
                              return (
                                <Fragment key={sport.sports_id}>
                                  <h4 className='sport-title' onClick={() => this.toggleCollapse(sport.sports_id)}>
                                    {sport.sports_name}
                                    {
                                      _.includes(CollapseItem, sport.sports_id) ?
                                        <i className="icon-minus-border" />
                                        :
                                        <i className="icon-plus-border" />
                                    }
                                  </h4>
                                  <Collapse className='sport-title-collapsing' isOpen={_.includes(CollapseItem, sport.sports_id)}>
                                    {
                                      _.filter(FixtureList, function (o) { return o.sports_id == sport.sports_id; }).length > 0 &&
                                      <div className="game-event-item heading">
                                        <div className="event-details">{t('Match')}</div>
                                        <div className="cell">
                                          <div className="row-cell">

                                            {
                                              sport.sports_id == 8 ?
                                                <React.Fragment>
                                                  <div {...{ className: `market-label` }}>{t('Spread')}</div>
                                                  <div {...{ className: `market-label` }}>{t('Total')}</div>
                                                  <div {...{ className: `market-label` }}>{t('Money Line')}</div>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                  <div {...{ className: `market-label` }}>1</div>
                                                  {
                                                    SelectedSports.sports_id != 3 &&
                                                    <div {...{ className: `market-label` }}>X</div>
                                                  }
                                                  <div {...{ className: `market-label` }}>2</div>
                                                </React.Fragment>
                                            }
                                            {/* <div {...{ className: `market-label` }}>1</div>
                                            {
                                              SelectedSports.sports_id != 3 &&
                                              <div {...{ className: `market-label` }}>X</div>
                                            }
                                            <div {...{ className: `market-label` }}>2</div> */}
                                          </div>
                                        </div>
                                        <div className="last-cell p-0" />
                                      </div>
                                    }
                                    {
                                      _.map(_.filter(FixtureList, function (o) { return o.sports_id == sport.sports_id; }), (item, key) => {
                                        return (
                                          <SportsBookBetItem {...{ key, item, ...BetItemProps, isAll: true }} />
                                        )
                                      })
                                    }
                                    {
                                      _.filter(FixtureList, function (o) { return o.sports_id == sport.sports_id; }).length == 0 &&
                                      <MessageBox size='md' heading={t(sport.sports_name) + ' ' + t(CONSTANT.LIVEGAME_BLANK_HEADING)} paragraph={t(CONSTANT.LIVEGAME_BLANK_PARAGRAPH)} />
                                    }
                                  </Collapse>
                                </Fragment>
                              )
                            })
                          }
                        </Fragment>
                    }
                  </div>
                  :
                  // <InfiniteScroll
                  //   dataLength={FixtureList.length}
                  //   next={fetchMoreData}
                  //   hasMore={hasMore}
                  //   loader={<Utils.FlexboxLoader className='show m-2 no-margin-h' />}
                  //   className='game-event-list'
                  // >
                  <>
                    {
                      !_.isEmpty(FixtureList) &&
                      <div className="game-event-item heading">
                        <div className="event-details">{t('Match')}</div>
                        <div className="cell">
                          <div className="row-cell">

                            {
                              SelectedSports.sports_id == 8 ?
                                <React.Fragment>
                                  <div {...{ className: `market-label` }}>{t('Spread')}</div>
                                  <div {...{ className: `market-label` }}>{t('Total')}</div>
                                  <div {...{ className: `market-label` }}>{t('Money Line')}</div>
                                </React.Fragment> :
                                <React.Fragment>
                                  <div {...{ className: `market-label` }}>1</div>
                                  {
                                    SelectedSports.sports_id != 3 &&
                                    <div {...{ className: `market-label` }}>X</div>
                                  }
                                  <div {...{ className: `market-label` }}>2</div>
                                </React.Fragment>
                            }
                          </div>
                        </div>
                        <div className="last-cell p-0" />
                      </div>
                    }

                    {
                      (FixturePosting && _.isEmpty(FixtureList)) &&
                      <Utils.BoxLoader />
                    }

                    <div className='matches-view-container'>
                      <div className="left-side-view">
                        <div className="matches-text">Live Matches</div>
                        <i className="icon-arrow-ic" onClick={() => reverseMatch('live')} />
                      </div>
                      <div className="right-side-view">
                        <div className="favorites-text">
                          Favorites {this.state.isFavouriteLive > 0 && <span>{this.state.isFavouriteLive}</span>}
                        </div>
                        <div className='view-all-text'
                          onClick={() => this.allMatchesView(BetItemProps)}
                        >View All</div>
                      </div>
                    </div>
                    {FixtureListLive.length > 0 ?
                      <>


                        {/* {this.FilterLiveData(FixtureList)}
                   { console.log("this.FilterLiveData",this.FilterLiveData(FixtureList))} */}
                        {/* {
                      _.map(FixtureList, (item, key) => {
                        return (
                          <SportsBookBetItem {...{ key, item, ...BetItemProps }} />
                        )
                      })
                    } */}

                        <Cards onEnd={this.handleEnd} className='swipable-cards-view'>
                          {
                            _.map(FixtureListLive, (item, key) => {

                              return (
                                <Card
                                  key={key}
                                  onSwipeLeft={(e) => this.setAnswer(item, "No",'live')}
                                  onSwipeRight={(e) => this.setAnswer(item, "Yes",'live')}
                                >
                                  <SportsBookBetItem {...{ key, item, ...BetItemProps, isAll: true }} />
                                </Card>
                              )
                            })
                          }


                        </Cards>
                      </>
                      :
                      <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} paragraph={t(CONSTANT.FIXTURE_BLANK_PARAGRAPH)} />
                      
                    }

                    <div className='matches-view-container'>
                      <div className="left-side-view">
                        <div className="matches-text">Upcoming Matches</div>
                        <i className="icon-arrow-ic" onClick={() => reverseMatch('upcoming')} />
                      </div>
                      <div className="right-side-view">
                        <div className="favorites-text">
                          Favorites {this.state.isFavourite > 0 && <span>{this.state.isFavourite}</span>}
                        </div>

                        <div className='view-all-text'
                          onClick={() => this.allMatchesView(BetItemProps)}
                        >View All</div>
                      </div>
                    </div>

                    {
                      FixtureListUpcoming.length > 0 ?
                      <>



                        {/* {
                      _.map(FixtureList, (item, key) => {
                        return (
                          <SportsBookBetItem {...{ key, item, ...BetItemProps }} />
                        )
                      })
                    } */}

                        <Cards onEnd={this.handleEnd} className='swipable-cards-view'>
                          {
                            _.map(FixtureListUpcoming, (item, key) => {

                              return (
                                <Card
                                  key={key}
                                  onSwipeLeft={(e) => this.setAnswer(item, "No",'up')}
                                  onSwipeRight={(e) => this.setAnswer(item, "Yes",'up')}
                                >
                                  <SportsBookBetItem {...{ key, item, ...BetItemProps, isAll: true }} />
                                </Card>
                              )
                            })
                          }


                        </Cards>
                      </>
                      :
                      
                        <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} paragraph={t(CONSTANT.FIXTURE_BLANK_PARAGRAPH)} />
                      
                    }


                    {/* <div className="thider-card-styles">

                      {
                        _.map(FixtureList, (item, key) => {
                          return (
                            <TinderCard
                              key={item.id}
                              // key={key}
                              // flickOnSwipe={false}
                              // onSwipe={(dir) => this.swiped(dir, item.event_id)}
                              preventSwipe={["up", "down"]}
                              // onCardLeftScreen={() => this.outOfFrame(item.event_id)}
                              onCardLeftScreen={(e) => this.onCardLeftScreen(e, item)}
                              className="tinder-cards"
                            >
                              <SportsBookBetItem {...{ key, item, ...BetItemProps, isAll:true }} />
                            </TinderCard>
                          )
                        })
                      }
                    </div> */}
                    {/* {
                      FixtureListBlank &&
                      <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} paragraph={t(CONSTANT.FIXTURE_BLANK_PARAGRAPH)} />
                    } */}
                    {/* </InfiniteScroll> */}
                  </>
              }
            </Fragment>
            :
            <Utils.BoxLoader />
        }
      </Fragment>

    )
  }
}
SportsBookFixtureListComponent.defaultProps = {
  status: '0' // 0 = Upcoming, 1 = Live
}
export default withTranslation()(SportsBookFixtureListComponent);