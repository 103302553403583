import React, { Component } from 'react';
import { Container, Row} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { Images } from 'Constants';


class Unauth extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
    

  }



  componentDidMount() {
  }




  render() {
   


    return (
      <Container>
        <Row>
          <img className='noauth' src={Images.UNAUTH} alt="" />
        </Row>
      </Container>
    )
  }
}

export default withTranslation()(Unauth);
