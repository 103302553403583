// Config
import config from "../../config";
const SUCCESS_CODE = 200;

const API = {
    // AUTH
    LOGIN: `${config.apiGateway}/user/auth/login`,
    LOGOUT: `${config.apiGateway}/user/auth/logout`,
    UPDATE_PASSWORD: `${config.apiGateway}/user/auth/update_password`,
    
    // MASTER 
    GET_APP_MASTER_LIST           : `${config.apiGateway}/user/auth/get_app_master_list`,
    S3_GET_APP_MASTER_LIST        : `app_master_data`,                                                     // S3
    S3_GET_APP_MASTER_LIST_AGENT  : `agent_app_master_data`,                                            // S3 AGENT
    GET_SPORT_LEAGUES             : `${config.apiGateway}/user/league/get_sport_leagues`,
    S3_GET_SPORT_LEAGUES          : `all_leagues_by_sport`,                                                // S3
    
    
    // Fixture: Exchange
    GET_ACTIVE_LEAGUES_BY_SPORT_EXCHANGE_BETTING: `${config.apiGateway}/user/exfixture/get_active_leagues_by_sport`,
    GET_UPCOMING_FIXTURES                       : `${config.apiGateway}/user/exfixture/get_upcoming_fixtures`,
    GET_FIXTURE_MARKETS                         : `${config.apiGateway}/user/exfixture/get_fixture_markets`,
    GET_INPLAY_GAMES                            : `${config.apiGateway}/user/exfixture/get_inplay_games`,
    
    // Fixture: SPORTSBOOK
    GET_ACTIVE_LEAGUES_BY_SPORT_SPORTBOOK_BETTING: `${config.apiGateway}/user/spfixture/get_active_leagues_by_sport`,
    GET_UPCOMING_FIXTURES_SP                     : `${config.apiGateway}/user/spfixture/get_upcoming_fixtures`,
    GET_FIXTURE_MARKETS_SP                       : `${config.apiGateway}/user/spfixture/get_fixture_markets`,
    GET_INPLAY_GAMES_SP                          : `${config.apiGateway}/user/spfixture/get_inplay_games`,

    
    
    GET_FIXTURE_MARKET_DETAIL: `${config.apiGateway}/user/fixture/get_fixture_market_detail`,
    FANCY_EVENT              : `${config.apiGateway}/user/fancy/event`,
    BET365_EVENT             : `${config.apiGateway}/user/fancy/bet365_event`,
    
    // USER BET
    PLACE_BET     : `${config.apiGateway}/user/betslip/place_bet`,
    OPEN_BETS     : `${config.apiGateway}/user/betslip/open_bets`,
    UPDATE_STAKE     : `${config.apiGateway}/user/auth/update_stake`,
    
    
    // BET HISTORY
    HISTORY     : `${config.apiGateway}/user/betslip/history`,
    CANCEL_UM_BET     : `${config.apiGateway}/user/betslip/cancel_um_bet`,
    
    //ACCOUNTS STATEMENT
    ACCOUNTS_STATEMENT     : `${config.apiGateway}/user/accounts/statement`,
    //PROFIT LOSS
    PROFIT_LOSS     : `${config.apiGateway}/user/accounts/profit_loss`,
    // CHANGE PASSWORD
    CHANGE_PASSWORD     : `${config.apiGateway}/user/profile/change_password`,
    //NOTIFICATION
    GET_NOTIFICATION     : `${config.apiGateway}/user/notification/get_notifications`,
    GET_UNREAD_NOTIFICATION     : `${config.apiGateway}/user/notification/get_unread_notification`,
    GET_BAL     : `${config.apiGateway}/user/profile/get_bal`,
    //EXPOSURE
    EXPOSURE_DATA     : `${config.apiGateway}/user/betslip/exposure_data`,
    


    

    SIGNUP_USER : `${config.apiGateway}/user/agentauth/signup`,
    GET_COUNTRY_LIST:`${config.apiGateway}/user/agentauth/get_country_list`,
    SEND_OTP:`${config.apiGateway}/user/agentauth/send_otp`,
    FORGOT_PASSWORD:`${config.apiGateway}/user/agentauth/forgot_password`,
    RESET_PASSWORD:`${config.apiGateway}/user/agentauth/reset_password`,
    AGENT_REQUEST:`${config.apiGateway}/user/agentauth/agent_request`,
    
    GET_DEPOSIT_REQUEST_MASTER: `${config.apiGateway}/user/agentfinance/get_deposit_req_master_data`,
    DO_UPLOAD_RECEIPT:`${config.apiGateway}/user/agentfinance/do_upload_receipt`,
    MAKE_DEPOSIT_REQUEST:`${config.apiGateway}/user/agentfinance/make_deposit_request`,

    GET_WITHDRAW_REQUEST_MASTER: `${config.apiGateway}/user/agentfinance/get_withdraw_req_master_data`,
    MAKE_WITHDRAW_REQUEST:`${config.apiGateway}/user/agentfinance/make_withdraw_request`,
    // Casino
    GET_ACTIVE_LEAGUES_BY_SPORT_CASINO_BETTING: `${config.apiGateway}/user/csfixture/get_active_leagues_by_sport`,
    GET_EVENT_MARKET : `${config.apiGateway}/user/csfixture/get_event_market`,
    
    GET_OFFERS:`${config.apiGateway}/user/common/get_offers`,

    GET_PROFILE_DETAIL:`${config.apiGateway}/user/agentprofile/get_profile_detail`,
    UPDATE_PROFILE:`${config.apiGateway}/user/agentprofile/update_profile`,


    LIVE_SCORE : `${config.apiGateway}/user/sport/livescore`,


    // Live Casino
    GAME_LIST:`${config.apiGateway}/user/wallet/game_list`,
    GAME_URL:`${config.apiGateway}/user/wallet/game_url`,

    //favourite or remove API

    FIXTURE_FAVOURITE_CHOICE: `${config.apiGateway}/user/spfixture/mark_user_fixture_choice`,
    FIXTURE_REMOVE_CHOICE: `${config.apiGateway}/user/spfixture/remove_from_not_intrested`,
    GET_USER_BALANCE: `${config.apiGateway}/user/get_user_balance`,
    

    
}

export default {
    SUCCESS_CODE,
    ...API
};
