import React from 'react';
import { NavLink } from 'react-router-dom';
import { Auth } from 'Service';
import { Role } from 'Helpers'

const AgentLinkNav = (props) => {
    const { t } = props;
    const CurrentUser = Auth.currentUserValue;
    return (
        <React.Fragment>
            <div className="left-navigation">
                <NavLink className="left-nav-item" to={'/bet-history'}>{t('Bets History')}</NavLink>
                <NavLink className="left-nav-item" to={'/profit-loss'}>{t('Profit & Loss')}</NavLink>
                <NavLink className="left-nav-item" to={'/account-statement'}>{t('Account statement')}</NavLink>
                {
                    CurrentUser != null && CurrentUser.role == Role.AgentPunter ?
                        <React.Fragment>
                            <NavLink className="left-nav-item blinkie" to={'/deposit'}>{t('Deposit')}</NavLink>
                            <NavLink className="left-nav-item blinkie" to={'/withdraw'}>{t('Withdraw')}</NavLink>
                            <NavLink className="left-nav-item" to={'/account-information'}>{t('Account Information')}</NavLink>
                        </React.Fragment> : ''
                }

                <NavLink className="left-nav-item" to={'/change-password'}>{t('Change Password')}</NavLink>
                <NavLink className="left-nav-item" to={'/notification'}>{t('Notification')}</NavLink>
            </div>
        </React.Fragment>
    )
}

export default AgentLinkNav;