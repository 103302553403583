import React, { Component, Fragment } from 'react';
import { _ } from 'Helpers';
import { Auth } from "Service";

class TemplateHandicapSB extends Component {
    render() {
        const {
            SelectedSports,
            item,
            // Mehtods
            handleOptionChange,
            _oddsActiveClass,
            SocketHighlight
        } = this.props;

        const isDisabled = () => {
            return item.bet_status != 1
            // ||  item.admin_status != 1
        }
        const currentUser = Auth.currentUserValue;
        return (
            <div {...{ className: `odd-market-collapse handicap-market active` }} >


                <div className="heading handi-head">
                    <div className={'name-hd'}>
                        {item.market_name}
                        {/* {
                            Utils.isDev() &&
                            <b>{" --- "}{_.toUpper(item.template_name)}</b>
                        } */}
                    </div>
                    <div className={'od-ls-head'}>
                        <span>Spread</span>
                        <span>Total</span>
                        <span>Money Line</span>
                    </div>
                </div>

                <div className={'odd-market-body'}>
                    <div className={'handicap-odd'}>
                        <div className={'m-team-b'}>
                            <div className={'name'}>
                                {JSON.parse(item.runners).home.name}
                            </div>
                            <div className={'odd_ls'}>
                                {
                                    _.map(JSON.parse(item.runners_odds), (obj, key) => {
                                        if (obj.header == 1) {
                                            return <div className={'odd'} key={key}>
                                                <div
                                                    {...{ className: `odd-market-btn ${isDisabled() ? 'disabled' : ''} ${_oddsActiveClass(obj.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: obj.selection_id })}` }}
                                                    onClick={
                                                        isDisabled() ?
                                                            null :
                                                            () => handleOptionChange({
                                                                fixture: { ...item, sports_id: SelectedSports.sports_id },
                                                                odd: { ...obj, price: obj.odds, market_bet_id: obj.selection_id, selectionId: obj.selection_id },
                                                                betting_type: 1,
                                                                selected_odd: { ...obj, team_name: JSON.parse(item.runners).home.name }
                                                            })}

                                                // className={'odd'}
                                                >
                                                    {`${obj.handicap ? `${obj.handicap}` : ''} (${obj.odds})`}
                                                </div>
                                                {currentUser && !_.isUndefined(obj.winloss) && (
                                                        <Fragment>
                                                            {_.isUndefined(obj.temp_winloss) ? (
                                                                <span
                                                                    {...{
                                                                        className: `winloss-${Math.sign(
                                                                            obj.winloss
                                                                        )}`,
                                                                    }}
                                                                >
                                                                    {_.round(obj.winloss, 2).toFixed(2)}
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    {...{
                                                                        className: `winloss-${Math.sign(
                                                                            obj.temp_winloss
                                                                        )}`,
                                                                    }}
                                                                >
                                                                    {_.round(obj.temp_winloss, 2).toFixed(
                                                                        2
                                                                    )}
                                                                </span>
                                                            )}
                                                        </Fragment>
                                                    )}
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className={'m-team-b'}>
                            <div className={'name'}>{JSON.parse(item.runners).away.name}</div>
                            <div className={'odd_ls'}>
                                {
                                    _.map(JSON.parse(item.runners_odds), (obj, key) => {
                                        if (obj.header == 2) {
                                            return <div className={'odd'} key={key}>
                                                <div
                                                    {...{ className: `odd-market-btn ${isDisabled() ? 'disabled' : ''} ${_oddsActiveClass(obj.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: obj.selection_id })}` }}
                                                    onClick={
                                                        isDisabled() ?
                                                            null :
                                                            () => handleOptionChange({
                                                                fixture: { ...item, sports_id: SelectedSports.sports_id },
                                                                odd: { ...obj, price: obj.odds, market_bet_id: obj.selection_id, selectionId: obj.selection_id },
                                                                betting_type: 1,
                                                                selected_odd: { ...obj, team_name: JSON.parse(item.runners).away.name }
                                                            })}

                                                // className={'odd'}
                                                >
                                                    {`${obj.handicap ? `${obj.handicap}` : ''} (${obj.odds})`}
                                                </div>
                                                {currentUser && !_.isUndefined(obj.winloss) && (
                                                        <Fragment>
                                                            {_.isUndefined(obj.temp_winloss) ? (
                                                                <span
                                                                    {...{
                                                                        className: `winloss-${Math.sign(
                                                                            obj.winloss
                                                                        )}`,
                                                                    }}
                                                                >
                                                                    {_.round(obj.winloss, 2).toFixed(2)}
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    {...{
                                                                        className: `winloss-${Math.sign(
                                                                            obj.temp_winloss
                                                                        )}`,
                                                                    }}
                                                                >
                                                                    {_.round(obj.temp_winloss, 2).toFixed(
                                                                        2
                                                                    )}
                                                                </span>
                                                            )}
                                                        </Fragment>
                                                    )}
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="odd-market-body">

                    {
                        _.isEmpty(JSON.parse(item.runners_odds)) &&
                        <div className="odd-market-row">
                            <div className="odd-market-line text-center">Odds Not available</div>
                        </div>
                    }
                </div>

            </div>
        );
    }
}
export default TemplateHandicapSB;
