import React, { Component, Fragment } from 'react';
import { _, Utils } from 'Helpers';
import { CONSTANT } from 'Constants';

class BetItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      oddShowType : localStorage.getItem('OddSelectiontype')
    }
  }
  render() {
    const {oddShowType} = this.state
    const { 
      page_id,
      item,
      SelectedSports,
      _oddsActiveClass,
      handleOptionChange,
      fixtureSelectHandler,
      SocketHighlight
    } = this.props;

    const isDisabled = (agent_status) => {
      return agent_status != 1
      // return obj.bet_status != 1 || obj.agent_status != 1
    }


    // console.log(SelectedSports, 'betitem')

    const OddItem = ({ odd, item }) => {
      return (
        <div {...{ className: `odd-trigger` }}>
          
          {
            _.map(odd.ex.availableToBack, (back, key) => {
              return (
                key == 0 &&
                <Fragment {...{key}}>
                  {
                    isDisabled(item.agent_status ? item.agent_status : item.admin_status) ?
                    <div style={{ order: key }}
                      {...{
                        key,
                        className:
                          `odd-trigger-inner back disabled ${_oddsActiveClass(odd.selectionId, item.market_event_id,  1) ? 'yellow' : ''} ${page_id == CONSTANT.EXCHANGE_BETTING ? '' : 'vslign'} ${SocketHighlight({id: odd.selectionId})}`
                      }}>

                      <div className='price'>

                      { back.price ?
                      oddShowType == 'decimal' ? Utils.DecimalOdds(back.price) : oddShowType == 'fractional' ? Utils.FractionalOdds(back.price) || '--' : oddShowType == 'american' ? Utils.AmericanOdds(back.price) : '--' : '--' }
                        </div>
                      {
                        page_id == CONSTANT.EXCHANGE_BETTING &&
                        <div className='price_volume'>{back.size || '--'}</div>
                      }
                    </div>
                    :
                    <div style={{ order: key }}
                      onClick={(back.price) ? () => handleOptionChange({ fixture: {...item, sports_id: SelectedSports.sports_id}, odd: { ...odd, market_bet_id: odd.selectionId }, betting_type: 1, selected_odd: back }) : null}
                      {...{
                        key,
                        className:
                          `odd-trigger-inner back ${_oddsActiveClass(odd.selectionId, item.market_event_id, 1) ? 'yellow' : ''} ${page_id == CONSTANT.EXCHANGE_BETTING ? '' : 'vslign'} ${SocketHighlight({id: odd.selectionId})}`
                      }}>

                      <div className='price'>
                      { back.price ? 
                      oddShowType == 'decimal' ? Utils.DecimalOdds(back.price) : oddShowType == 'fractional' ? Utils.FractionalOdds(back.price) || '--' : oddShowType == 'american' ? Utils.AmericanOdds(back.price) : '--'  : '--' }
                        </div>
                      {
                        page_id == CONSTANT.EXCHANGE_BETTING &&
                        <div className='price_volume'>{back.size || '--'}</div>
                      }
                    </div>
                  }
                </Fragment>
              )
            })
          }
          {
            _.map(odd.ex.availableToLay, (lay, key) => {
              return (
                key == 0 &&
                <Fragment {...{key}}>
                  {
                    isDisabled(item.agent_status ? item.agent_status : item.admin_status) ?
                    <div style={{ order: key }}
                      {...{
                        key,
                        className:
                          `odd-trigger-inner disabled lay ${_oddsActiveClass(odd.selectionId, item.market_event_id, 2) ? 'yellow' : ''} ${SocketHighlight({id: `${odd.selectionId}_lay_price`})}`
                      }}>
                      <div className='price'>
                        {lay.price ? 
                        oddShowType == 'decimal' ? Utils.DecimalOdds(lay.price) : oddShowType == 'fractional' ? Utils.FractionalOdds(lay.price) || '--' : oddShowType == 'american' ? Utils.AmericanOdds(lay.price) : '--'  :'--'
                        }
                      </div>
                      <div className='price_volume'>{lay.size || '--'}</div>
                    </div>
                    :
                    <div style={{ order: key }}
                      onClick={(lay.price) ? () => handleOptionChange({ 
                        fixture: {...item, sports_id: SelectedSports.sports_id}, 
                        odd: { ...odd, market_bet_id: odd.selectionId }, 
                        betting_type: 2, 
                        selected_odd: lay 
                      }) : null}
                      {...{
                        key,
                        className:
                          `odd-trigger-inner lay ${_oddsActiveClass(odd.selectionId, item.market_event_id, 2) ? 'yellow' : ''} ${SocketHighlight({id: `${odd.selectionId}_lay_price`})}`
                      }}>
                      <div className='price'>

                      {lay.price ?
                      oddShowType == 'decimal' ? Utils.DecimalOdds(lay.price) : oddShowType == 'fractional' ? Utils.FractionalOdds(lay.price) || '--' : oddShowType == 'american' ? Utils.AmericanOdds(lay.price) : '--' :'--' 
                      }
                        </div>
                      <div className='price_volume'>{lay.size || '--'}</div>
                    </div>
                  }
                </Fragment>
              )
            })
          }
        </div>
      )
    }
    const OddItemBlank = () => {
      return (
        <div className="odd-trigger">
          <div className="odd-trigger-inner back disabled">
            <div className='price'>--</div>
            <div className="price_volume">--</div>
          </div>
          <div className="odd-trigger-inner lay disabled">
            <div className='price'>--</div>
            <div className="price_volume">--</div>
          </div>
        </div>
      )
    }
    return (
      <div className="game-event-item">
        <div className="event-details" onClick={() => fixtureSelectHandler(item)}>
          <div className="small">{item.league_name}</div>
          <div className="title">
              {
                _.map(_.orderBy(JSON.parse(item.runners), [function (o) { return Number(o.sortPriority) }], ['asc']), (participant, key) => {
                  return (
                    participant.sortPriority != 3 &&
                    <span {...{ key }} title={participant.runnerName}>{`${key == 0 ? '' : ' - '} ${participant.runnerName}`}</span>
                  )
                })
              }
          </div>
          <div className="timestamp">
            {
              item.is_live == 1 ? 
              <span className="is-live-lbl">In-Play</span>
              :
              <Fragment>
                <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
              </Fragment>
            }
          </div>
        </div>
        <div className="cell">
          {
            item.status == 'SUSPENDED'?
            <Fragment>
              <div className="game-status-suspended" onClick={() => fixtureSelectHandler(item)}><span>{item.status}</span></div>
              {/* <div className="row-cell">
                <OddItemBlank />
              </div> */}
            </Fragment>
            :
              item.lock_betting == '1'?
              <Fragment>
                 <div className="game-status-suspended" onClick={() => fixtureSelectHandler(item)}><span>SUSPENDED</span></div>
              </Fragment>
            :
            <Fragment>
              <div className="row-cell heading">
                <div {...{ className: `market-label`}}>1</div>
                {
                  SelectedSports.sports_id != 3 &&
                  <div {...{ className: `market-label`}}>X</div>
                }
                <div {...{ className: `market-label`}}>2</div>
              </div>
              <div className="row-cell">
                <OddItem {...{ odd: JSON.parse(item.runners_odds)[0], item}}/>
                {
                  SelectedSports.sports_id != 3 &&
                  <Fragment>
                    {
                      item.number_of_runners == 3 ?
                      <OddItem {...{ odd: JSON.parse(item.runners_odds)[2], item}}/>
                      :
                      <OddItemBlank />
                    }
                  </Fragment>
                }
                <OddItem {...{ odd: JSON.parse(item.runners_odds)[1], item}}/>
              </div>
            </Fragment>
          }
        </div>
        <div className="last-cell" onClick={() => fixtureSelectHandler(item)}>
          <a>+{item.total_markets} <span className='mrkt-lbl'>Markets</span></a>
        </div>
      </div>
    );
  }
}
BetItem.defaultProps = {
  item: {},
  SocketHighlight: () => {}
}
export default BetItem;
