import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Images } from 'Constants';
import { Auth } from 'Service';
import { Role } from 'Helpers'

class DefaultFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Init: false
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        Init: true
      });
    }, 500)

  }
  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  render() {
    const { t } = this.props;
    const { Init } = this.state;
    const CurrentUser = Auth.currentUserValue;
    return (
      Init &&
      <div className="app-footer" id='AppFooter'>
        <Fragment>
          <Container fluid>
            <Row>
              <Col sm={12} lg={3}>
                <img src={Images.Logo} alt="" className="logo" />
                <div className="web-description">{t('DefaultFooterTxt')}</div>
              </Col>
              <Col sm={3} lg={2}>
                <div className="footer-heading"></div>
                <Row className='logo-list'>
                  <Col sm={12} xs={7}><img src={Images.MGA} width='146' alt="" /></Col>
                  <Col sm={12} xs={5}><img src={Images.GAMBLING_COMMISSION} width='92' alt="" /></Col>
                </Row>
              </Col>
              <Col sm={5} lg={3}>
                <div className="footer-heading">{t('payment methods')}</div>
                <Row className='logo-list'>
                  <Col xs={6}><img src={Images.UPI} width='80' alt="" /></Col>
                  <Col xs={6}><img src={Images.NET_BANKING} width='138' alt="" /></Col>
                  <Col xs={6}><img src={Images.PAYTM} width='85' alt="" /></Col>
                  <Col xs={6}><img src={Images.GOOGLE_PAY} width='80' alt="" /></Col>
                  <Col xs={6}><img src={Images.PHONE_PE} width='86' alt="" /></Col>
                </Row>
              </Col>
              <Col sm={4} lg={4}>
                <div className="footer-heading">{t('support')}</div>
                <ul className="support-list">
                  <li>
                    <i className="icon-envelope" />
                    <div className="overflow-hidden">
                      support@Isocial.com
                    </div>
                  </li>
                  <li>
                    <i className="icon-phone" />
                    <div className="overflow-hidden">
                      <div className='phone'>+44 7537121156</div>
                    </div>
                  </li>
                  <li className={'whatsapp-li'}>
                    <i className="icon-whatsapp" />
                    <div className="overflow-hidden">
                      <a href={'https://wa.me/+447863910533'} rel="noopener noreferrer" target={'_blank'} style={{ color: 'inherit' }}>
                        <div className='phone'>+44 7863910533</div>
                      </a>
                    </div>
                  </li>
                </ul>
                <nav className="social-list">
                  <a className="icon-facebook" rel="noopener noreferrer" href={'https://www.facebook.com/Isocial-Ltd-108242341482605'} target={'_blank'} />
                  <a className="icon-whatsapp" href={'https://api.whatsapp.com/send/?phone=%2B447863910533&text&app_absent=0'} rel="noopener noreferrer" target={'_blank'} />
                  <a className="icon-email" href={'https://t.me/IsocialLtd'} rel="noopener noreferrer" target={'_blank'} />
                  <a className="icon-youtube" rel="noopener noreferrer" href={'https://www.youtube.com/channel/UCxMJaVwpPkqo8LkZ69bSL0w/videos'} target={'_blank'} />
                  <a className="icon-instagram" rel="noopener noreferrer" href={'https://www.instagram.com/Isocial_limited/'} target={'_blank'} />
                  <a className="icon-twitter" rel="noopener noreferrer" href={'https://twitter.com/IsocialL'} target={'_blank'} />
                </nav>
              </Col>
            </Row>
            {
              CurrentUser == null || (CurrentUser != null && CurrentUser.role == Role.AgentPunter) ?
                <Row className={'justify-content-center py-4 w-100'}>
                  <Col className={'text-center'}>
                    <Link to={'/agent-request'}>
                      <Button className={'btn btn-sm request_agent_btn'}>Request for Agent Account</Button>
                    </Link>
                  </Col>
                </Row>
                : ''
                }
          </Container>
          <div className="footer-copy-right">{process.env.REACT_APP_WEBSITE_COPYRIGHT}</div>
        </Fragment>
      </div>
    )
  }
}

export default withTranslation()(DefaultFooter);
