
import React, { Component } from 'react';
import { _, Utils } from 'Helpers';
import { NavLink } from 'react-router-dom';
import { Fragment } from 'react';

class CasinoLinkNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gamesList: Utils.getMasterData().sports_list.casino_betting,
    }
  }
  GoToDetail = (item) =>{
    this.props.history.push({
      pathname: `/casino-detail/`+item.video_abbr,
      state: {
       item:item
      }
    })


  }
  render() {
    const { gamesList } = this.state
    return (
      <Fragment>
        <div className="left-navigation">
        {
              _.map(gamesList, (item, index) => {
                return (
                  
                    //<div  onClick={()=>this.GoToDetail(item)}>
                        <NavLink  key={index}  
                        className="left-nav-item" 
                        to={{
                          pathname:`/casino-detail/`+item.video_abbr,
                          state: {item:item}  
                        }}>
                        {item.league_name}</NavLink>
                        
                    //</div>
                      
                    
                  
                )
              })
            }
        </div>
      </Fragment>
       
          

         
    );
  }
}

export default CasinoLinkNav;



