/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { SiteContainer, AgentLinkNav } from 'Components';
import { Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import { Utils, Http, API } from 'Helpers';
// import DatePicker from 'react-date-picker';
// import Select from 'react-select';
// import { CONSTANT } from 'Constants';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { notify } from 'react-notify-toast';

class AccountInformation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      Username: '',
      dob: '',
      Address: '',
      SAddress: '',
      City: '',
      ZipCode: '',
      FilValue: [{ id: '0', name: 'India' }],
      MobileNumber: '',
      Name: '',
      email: ''
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, this.validateForm);
  };

  onDateChange = (date) => {
    this.setState({ dob: date }, this.validateForm)
  }


  validateForm = () => {
    let { Name, email, dob } = this.state;
    this.setState({
      formValid: Utils.isValid("required", Name) &&
        email != '' &&
        dob != '' &&
        dob != null &&
        Utils.isValid("email", email) &&
        Utils.isValid("dob", dob)

    })
  }

  // handleFilterChange = (FilValue) => {
  //   this.setState({ FilValue: FilValue, Offset: CONSTANT.CURRENT_OFFSET }, () => {
  //   });
  // }


  getProfileDetail = () => {
    Http.post(API.GET_PROFILE_DETAIL, {})
      .then(res => {
        let data = res.data;
        this.setState({
          MobileNumber: data.mobile,
          Username: data.username,
          Referral: data.referral_code,
          Name: data.name,
          email: data.email,
          dob: moment(data.dob, 'YYYY-MM-DD').toDate()
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  componentDidMount() {
    this.getProfileDetail()
  }


  submitHandler = (e) => {
    e.preventDefault();
    let param = {
      name: this.state.Name,
      email: this.state.email,
      dob: moment(this.state.dob).format('DD-MM-YYYY')
    }
    this.setState({ posting: true })
    Http.post(API.UPDATE_PROFILE, param)
      .then(res => {
        notify.show(res.message, "success", 2500)
        setTimeout(() => window.location.reload(), 2500)
        // this.setState({})
      })
      .catch(err => {
        this.setState({ posting: false })
      })
  }


  render() {
    // const { t } = this.props
    const { formValid, posting, dob, Name, email, MobileNumber, Username, Referral } = this.state

    return (
      <SiteContainer {...this.props}>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <AgentLinkNav {...this.props} />

            </Col>
            <Col>
              <div className="white-box">
                <div className="title">Account Information</div>
                <div className="sub- mt-2 mb-5">Please provide full personal information to confirm your identity. Owing to regulation, your access to some products, markets and commision discount may be restricted until you confirm your identity. You can provide personal document using our secure email service.</div>


                <Form className="deposit-form" onSubmit={this.submitHandler}>
                  <Row>
                    <Col sm={4} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label pl-0'>Mobile Number</span>
                        <div className={'pt-2'}><strong>{MobileNumber || '-'}</strong></div>
                      </FormGroup>
                    </Col>
                    <Col sm={4} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label  pl-0'>Username</span>
                        <div className={'pt-2'}><strong>{Username || '-'}</strong></div>
                      </FormGroup>
                    </Col>
                    <Col sm={4} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label  pl-0 '>Referral Code</span>
                        <div className={'pt-2'}><strong>{Referral || '-'}</strong></div>
                      </FormGroup>
                    </Col>

                    <Col sm={4} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>Name</span>
                        <Input
                          type="text"
                          id='Name'
                          name='Name'
                          required
                          value={Name}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", Name)}
                          valid={Utils.isValid("reqiured", Name)} />
                      </FormGroup>
                    </Col>
                    <Col sm={4} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>Email</span>
                        <div className={'pt-2'}><strong>{email || '-'}</strong></div>
                        {/* <Input
                          type="email"
                          id='email'
                          name='email'
                          required
                          value={email}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("email", email)}
                          valid={Utils.isValid("email", email)} /> */}
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={4}>
                      <FormGroup className='input-border-bottom'>
                        <span className='label'>Date of Birth</span>
                        <div className={'pt-2'}><strong> {dob? moment(dob).format('DD-MM-YYYY') : '-'}</strong></div>
                        {/* <DatePicker
                          onChange={this.onDateChange}
                          value={dob}
                          calendarIcon={<i className='icon-calendar' />}
                          className={`form-control datepicker_control ${Utils.isValid('dob', dob) ? 'valid' : 'invalid'}`}
                          clearIcon={null}
                          format="d/M/yyyy"
                        /> */}
                      </FormGroup>
                    </Col>


                    {/* <Col sm={4} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>Zip Code</span>
                        <Input type="ZipCode"
                          id='ZipCode'
                          name='ZipCode'
                          required
                          value={ZipCode}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", ZipCode)}
                          valid={Utils.isValid("reqiured", ZipCode)} />
                      </FormGroup>
                    </Col>
                    <Col sm={4} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>City</span>
                        <Input type="City"
                          id='City'
                          name='City'
                          required
                          value={City}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", City)}
                          valid={Utils.isValid("reqiured", City)} />
                      </FormGroup>
                    </Col>
                 
                    <Col sm={4} xs={12}>
                      <FormGroup  className='input-border-bottom'>
                        <span className='label'>Country</span>
                        <Select
                          className='react-select'
                          classNamePrefix="react-select"
                          isSearchable={false}
                          options={options}
                          value={FilValue}
                          onChange={this.handleFilterChange}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </FormGroup>
                    </Col> */}

                  </Row>
                  <FormGroup className='m-0 text-center pb-4'>
                    <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round btn-xlg'>Confirm Changes</Button>
                  </FormGroup>
                </Form>
              </div>
            </Col>
          </Row>

        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(AccountInformation);


