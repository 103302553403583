import { AuthHelp, Utils } from "Helpers";
import { notify } from 'react-notify-toast';
import config from "config";
import { CONSTANT } from "Constants";
import { Auth } from "Service";
// import i18next from 'i18next';

function status(res) {
  if (res.status >= 200 && res.status < 400) {
    return Promise.resolve(res);
  } else if (res.status === 401) {
    // unauthorized
    return Promise.reject(res);
  }
  return Promise.reject(res);
}

function json(res) {
  if (
    res &&
    res.headers &&
    res.headers.get("content-type").startsWith("application/json")
  ) 
    return res.json().then(response=>{
      if (response.response_code >= 200 && response.response_code < 400 && response.response_code != 401) {
        return response;
      } else if(response.response_code == 401) {
        notify.show(response.global_error, "error", 2500);
      } else {
        // Notify
        notify.show(response.message, "error", 2500);
        throw response;
      }
    });

  return {};
}

function parseErrorMessage(response) {
  var errorMsg = response.message != "" ? response.message : response.global_error;
  if (errorMsg == "") {
    for (var key in response.error) {
      errorMsg = response.error[key];
      if (errorMsg != "") {
        break;
      }
    }
  }
  return errorMsg
}

function error(res, errorNotifyHide) {
  if (
    res &&
    res.headers &&
    res.headers.get("content-type").startsWith("application/json")
  ) {
    return res.json().then(err => {
      // Notify
      if(err.response_code == 401) {
        notify.show(err.global_error, "error", 2500);
        Auth.logout()
        // History.push('/');
        // window.location.reload()
      } else {
        if(!errorNotifyHide) {
          notify.show(parseErrorMessage(err), "error", 2500);
        }
      }
      throw err;
    });
  }

  if (res) {
    throw res;
  }

  return {};
}

export function get(url) {
  const auth = AuthHelp.getAuth();
  const token = (!!auth && auth) || null;
  const settings =
    token !== null
      ? {
          headers: {
            Sessionkey: token,
            'Accept-Language': Utils.geti18nextLng('Isocial_lang')
          }
        }
      : {
          headers: {
            'Accept-Language': Utils.geti18nextLng('Isocial_lang')
          }
      };

  return fetch(url, settings)
    .then(status)
    .then(json)
    .catch(error);
}

export function post(url, body = {}, errorNotifyHide = false) {
  const auth = AuthHelp.getAuth();
  const token = (!!auth && auth) || null;
  const settings =
    token !== null
      ? {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            'Accept-Language': Utils.geti18nextLng('Isocial_lang'),
            Sessionkey: token
          }
        }
      : {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            'Accept-Language': Utils.geti18nextLng('Isocial_lang')
          }
        };

  return fetch(url, settings)
    .then(status)
    .then(json)
    .catch((res) => error(res, errorNotifyHide));
}

export function s3(s3url, url, body = {}, errorNotifyHide = false) {
  const auth = AuthHelp.getAuth();
  const token = (!!auth && auth) || null;
  const settings =
    token !== null
      ? {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            'Accept-Language': Utils.geti18nextLng('Isocial_lang'),
            Sessionkey: token
          }
        }
      : {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            'Accept-Language': Utils.geti18nextLng('Isocial_lang')
          }
        };

  const s3Setting = {
    mode   : 'cors',
    method : 'GET',
    headers: {
      'Accept'                      : 'application/json, text/plain, */*',
      'Content-Type'                : 'text/plain',
      'Access-Control-Allow-Methods': 'PUT, POST, DELETE, GET',
      'Access-Control-Allow-Origin' : '*'
    }
  }
  const RestAPI = function(){
    return fetch(url, settings)
      .then(status)
      .then(json)
      .catch((res) => error(res, errorNotifyHide));
  }

  if(config.s3.BUCKET_STATIC_DATA_ALLOWED == 1) {
    return fetch(s3url, s3Setting)
      .then((response) => {
        return response.json()
      })
      .then(response => {
        return { data: response, response_code: CONSTANT.SUCCESS_CODE };
      })
      .catch((error) => {
        return RestAPI()
      });
  } else {
    return RestAPI()
  }
}


const getFatch = function (url) {
  const auth = AuthHelp.getAuth();
  const token = (!!auth && auth) || null;
  const settings =
    token !== null
      ? {
        headers: {
          Sessionkey: token,
          'Accept-Language': Utils.geti18nextLng('Isocial_lang')
        }
      }
      : {
        headers: {
          'Accept-Language': Utils.geti18nextLng('Isocial_lang')
        }
      };

  return fetch(url, settings)
    .then(res => {
      return Promise.resolve(res)
    })
    .then(res => {
      if (
        res &&
        res.headers &&
        res.headers.get("content-type").startsWith("application/json")
      )
        return res.json().then(response => {
          return response
        });

      return {};
    })
    .catch(error);
}



export default { get, post, s3, getFatch };
//Loginsessionkey