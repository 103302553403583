/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, Fragment, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";
import Notification from 'react-notify-toast';
import { withTranslation } from 'react-i18next';
import ScrollMemory from 'react-router-scroll-memory';
import { SocketEvent } from 'Constants';
import { BehaviorSubject } from 'rxjs';
import './App.scss';
import { UserLayout } from "Layouts";
import { API, Http, Utils, Socket, _, Role, AuthHelp } from "Helpers";
import { LoadingView } from "Views";
//import { LanguageSwitchModal } from "Modals";
import { TitleComponent, CacheBuster } from "Components";
import { Auth } from 'Service';
import { CONSTANT } from 'Constants';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const location = history.location;
const queryString = require('query-string');
const parsed = queryString.parse(location.search);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Init: false,
      MaintenanceShow: true,
      CurrentUser: Auth.currentUserValue
    }
  }

  GetAppMasterList = () => {
    let s3_param = [
      !_.isNull(this.state.CurrentUser) ?
        this.state.CurrentUser.role == Role.AgentPunter ? API.S3_GET_APP_MASTER_LIST_AGENT : API.S3_GET_APP_MASTER_LIST : API.S3_GET_APP_MASTER_LIST
    ]

    Http.s3(Utils.s3UrlConvert(s3_param) + '?timestamp=' + Date.now(), API.GET_APP_MASTER_LIST).then(response => {

      if (!_.isEmpty(response.data)) {
        if(response.data.sports_list.sportbook_betting[0]){
          localStorage.setItem("selectedSports", JSON.stringify(response.data.sports_list.sportbook_betting[0]));
        }
        Utils.setMasterData(response.data)
      } else {
        console.log("out")
      }

      this.setState({
        Init: true
      });
    }).catch(error => {
    });
  }

  sockeListener = () => {
    const _this = this
    Socket.on(SocketEvent.SPORTSSTATUSUPDATED, function (res) {
      console.log(res,'radhe');
      _this.GetAppMasterList()
    })
  }

  removeSockeListener = () => {
    Socket.off(SocketEvent.SPORTSSTATUSUPDATED)
  }

  componentDidMount() {
    this.sockeListener()
    this.GetAppMasterList()
    const { i18n } = this.props;
    document.documentElement.setAttribute("lang", i18n.language);
  }


  componentWillUnmount() {
    this.removeSockeListener()
    this.setState = () => {
      return;
    };
  }


  render() {
    const { Init } = this.state;
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return (
            !Init
              ?
              <LoadingView />
              :
              <Fragment>
                <Notification options={{ zIndex: 99999 }} />
                <BrowserRouter>
                  <ScrollMemory />
                  <AppInit {...this.props}/>
                  <TitleComponent />
                  <Switch>
                    {/* <OnlyPublicRoute path='/' exact={true} component={Home} /> */}
                    {/* <Route path='/' exact={true} component={Home} /> */}
                    <Route exact component={UserLayout} />
                    <Redirect from="*" to='/exchange' />
                  </Switch>
                </BrowserRouter>
                {/* <LanguageSwitchModal /> */}
              </Fragment>
          )
        }}
      </CacheBuster>


    )
  }
}
export default withTranslation()(App);


const AppInit = withRouter((props) => {

  const authenticate = (token,pageid) => {
    if(!token) return;
    let param = {
      "token":token,
      "device_type": CONSTANT.DEVICE_TYPE,
    }
    Http.post(API.LOGIN, param).then(response => {
      const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
       AuthHelp.setAuth(response.data.Sessionkey);
       Utils.setProfile(response.data.user_profile);
       localStorage.setItem("OddSelectiontype", "decimal");
       localStorage.setItem('firstlogin', 1);
       localStorage.setItem('currentUser', JSON.stringify(response.data.user_profile));
       currentUserSubject.next(response.data.user_profile);
       if(pageid){
        switch (true) {
          case (pageid == 1):
            props.history.push('/bet-history');
          break;
          case (pageid == 2):
            props.history.push('/account-statement');
          break;
          case (pageid == 3):
            props.history.push('/profit-loss');
          break;
          default:
            props.history.push('/all-matches');
            break;
        }
       } else {
        props.history.push('/all-matches');
       }
       
       setTimeout(() => {
        window.location.reload(false);
      }, 100)
    }).catch(error => {
      if(error.data.responsecode == 500){
        localStorage.removeItem('currentUser');
        localStorage.removeItem('auth')
        localStorage.removeItem('profile')
        props.history.push('/500');
        
      }
      
      console.log(error,"error")
    });
  }

  const intialDataCall = (intialData) => {
    let _token = '';
    let _pageid = ''
    if (intialData) {
        if (intialData.token) {
            _token = intialData.token;
            _pageid = intialData.page_id
        } else {
            let jsonOBj = JSON.parse(intialData);
            _token = jsonOBj.token;
            _pageid = intialData.page_id
        }
    }
    authenticate(_token,_pageid)
}

 const getSessionKey = () => {
    if (!_.isUndefined(parsed) && !_.isEmpty(parsed) && !_.isUndefined(parsed.token)) {
      //console.log(parsed,"parsedparsedparsed");
      //let _token = parsed.token.replace("\"", "")
      const currentUrl = new URL(window.location.href);
      let _token = currentUrl.searchParams.get('token');
      let pageId = currentUrl.searchParams.get('page_id');

        
        switch (true) {
            case (typeof window.AndroidInterface === 'object' && window.AndroidInterface !== null):
              
                if (_token != AuthHelp.getAuth()) {
                    let intialData = window.AndroidInterface.setNewToken();
                    intialDataCall(intialData)
                } else {
                    let intialData = window.AndroidInterface.onFantasyInit();
                    intialDataCall(intialData)
                }
                break;

                case (typeof window.iOSInterface === 'object' && window.iOSInterface !== null):
              
                if (_token != AuthHelp.getAuth()) {
                    let intialData = window.iOSInterface.setNewToken();
                    intialDataCall(intialData)
                } else {
                    let intialData = window.iOSInterface.onFantasyInit();
                    intialDataCall(intialData)
                }
                break;

                case (typeof window.webkit !== 'undefined' &&  window.webkit.messageHandlers.iOSInterface === 'object' && window.webkit.messageHandlers.iOSInterface !== null):
              
                if (_token != AuthHelp.getAuth()) {
                    let intialData = window.webkit.messageHandlers.iOSInterface.setNewToken();
                    intialDataCall(intialData)
                } else {
                    let intialData = window.webkit.messageHandlers.iOSInterface.onFantasyInit();
                    intialDataCall(intialData)
                }
                break;

            default:
                authenticate(_token,pageId)
                break;
        }
    }
}

  useEffect(() => {
    getSessionKey()
  }, [])

  return (
    <></>
  ) 
})