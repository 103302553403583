import React, { Component, Fragment } from 'react';
import { Collapse, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Pagination from "react-js-pagination";

import { Auth } from 'Service';
import { SlipToggle } from '../../Redux/Reducers'
import { CONSTANT } from 'Constants';
import { MessageBox } from 'Components';
import { Http, API, _, Utils, Role } from 'Helpers';


class BetslipOpenBet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BetslipType:CONSTANT.MATCHED,
      filter:1,
      OpenBetList:[],
      Offset: CONSTANT.CURRENT_OFFSET,
      current_page: 1,
      totalPage: 0,
      itemsCountPerPage:CONSTANT.ITEMS_PERPAGE_XS,
      pageRangeDisplayed: 5,
    }
  }

// Handlers
  switchBetting = (BetslipType,filter) => {
    this.setState({
      BetslipType,
      filter,
      OpenBetList: [],
      totalPage: 0,
      current_page: 1,
    }, () => {
      if(Auth.currentUserValue){
        this.getOpenBet()
      }
    })
  }

  getOpenBet = () =>{
    const { filter, current_page } = this.state
    let param = {
      "items_perpage": CONSTANT.ITEMS_PERPAGE_XS,
      "current_page": current_page,
      "filter": filter
    }

    this.setState({ posting: true })
    Http.post(API.OPEN_BETS, param).then(response => {
      this.setState({
        posting: false,
        OpenBetList:response.data.open_bets,
        totalPage: response.data.total_records,
      }, () => {
      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  handlePageChange = (current_page) => {
    this.setState({current_page}, () => this.getOpenBet())
  }

  getIndex(index){
    if(this.state.current_page==1){
     return (index+1)
   }else{
     return ((this.state.current_page-1)*CONSTANT.ITEMS_PERPAGE_XS)+(index+1)
   }
 }

 deleteUnmatchBet = (betslip_uid,index)=>{
    
  this.setState({ posting: true })
  let param = {
    "betslip_uid": betslip_uid,
  }
  Http.post(API.CANCEL_UM_BET, param).then(response => {
    let { current_page, OpenBetList } = this.state
      let PastListSize = _.size(OpenBetList)
      let tempArr = OpenBetList
      if(PastListSize > 1) {
        tempArr.splice(index, 1);
      } else {
        current_page = current_page != 1 ? (current_page - 1) : current_page
      }
      this.setState({
        posting: false,
        ...(PastListSize > 1 && {"OpenBetList": tempArr})
        }, () => {
        Utils.notify(response.message);
        this.setState({current_page}, () => this.getOpenBet())
      })
    // let tempArr = this.state.OpenBetList
    // tempArr.splice(index, 1);
    // this.setState({
    //   posting: false,
    //   OpenBetList: tempArr,
    // })
    // Utils.notify(response.message)
  }).catch(error => {
    this.setState({ posting: false })
  });
}

  componentDidMount (){
    if(Auth.currentUserValue){
      this.getOpenBet()
    }
  }


  // Life cycle
  render() {
    const { openCollapse, SlipToggle, is_betslip_drawer, t } = this.props
    const {BetslipType, OpenBetList,totalPage ,current_page,itemsCountPerPage,pageRangeDisplayed} = this.state
    const currentUser = Auth.currentUserValue
    const PaginationProps = {
      activePage: current_page,
      itemsCountPerPage: itemsCountPerPage,
      pageRangeDisplayed: pageRangeDisplayed,
      totalItemsCount: totalPage,
      onChange: this.handlePageChange,
    }
    
    return (
      currentUser ?
        <div {...{ className: `betslip-wrap betslip-open-bet${CONSTANT.ONLY_SINGLE_BET ? ' only-single-bet' : ''}` }}>
          <div className="betslip-container">
            <div className="betslip-header" onClick={is_betslip_drawer ? () => [SlipToggle(2),this.getOpenBet()] : null}>
              {t('Open Bets')}
              {
                is_betslip_drawer &&
                <Fragment>
                  {
                    (openCollapse == 2) ?
                      <i className="icon-up" />
                      :
                      <i className="icon-down" />
                  }
                </Fragment>
              }
            </div>
            <Collapse isOpen={is_betslip_drawer == false ? true : openCollapse == 2}>
              <div className="betslip-tabs">
                <span onClick={() => this.switchBetting(CONSTANT.MATCHED,1)}  {...{ className: `${BetslipType == CONSTANT.MATCHED ? 'active' : ''}` }}>{t('Matched')}</span>
                
                {
                   currentUser != null && currentUser.role != Role.AgentPunter ?<span onClick={() => this.switchBetting(CONSTANT.UNMATCHED,2)} {...{ className: `${BetslipType == CONSTANT.UNMATCHED ? 'active' : ''}` }}>{t('Unmatched')}</span>
                   :''
                 }
                
                <span onClick={() => this.switchBetting(CONSTANT.FANCY,3)} {...{ className: `${BetslipType == CONSTANT.FANCY ? 'active' : ''}` }}>{t('Fancy')}</span>
                <span onClick={() => this.switchBetting(CONSTANT.CASINO,4)} {...{ className: `${BetslipType == CONSTANT.CASINO ? 'active' : ''}` }}>{t('Live Cards')}</span>
                <span onClick={() => this.switchBetting(CONSTANT.CASINO_BETTING,5)} {...{ className: `${BetslipType == CONSTANT.CASINO_BETTING ? 'active' : ''}` }}>{t('Live Casino')}</span>
              </div>
              <div className="betslip-body">

                  {
                    !_.isEmpty(OpenBetList) ? 
                    <Table className="table-bets m-0">
                    <thead>
                      <tr>
                        <th>{t('S.no')}</th>
                        <th>{t('Runner')}</th>
                        <th>{t('Odds')}</th>
                        <th>{t('Stack')}</th>
                        <th style={{width:`18%`}}>{t('P&I')}</th>
                        {BetslipType == CONSTANT.UNMATCHED && 
                        <th className="text-center">{t('Action')}</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      
                      {_.map(OpenBetList,(item,idx)=>{
                        return(
                        <tr key={idx} className={item.betting_type == 1 ? "back" : "lay"}>
                          <td>{this.getIndex(idx)}</td>
                          <td>
                            <span className="block">{item.event_name}</span>
                            <span className="block">{item.market_name}
                            {Number(item.bookmaker_type) == 5 ? '' : ' - '}
                                 { _.map(item.market_runners,(ritm,rid)=>{
                                    return(
                                      Number(item.bookmaker_type) == 4 ?
                                       item.selection_id == ritm.id ? ritm.name : ''
                                       :
                                       item.selection_id == ritm.selectionId ? ritm.runnerName : ''
                                    )
                                  }) }
                            </span>
                            <span className="block">{Utils.dateTimeFormat(item.created_date, 'DD/MM/YYYY, h:mm A')}</span>
                          </td>
                          <td>
                          {Number(item.bookmaker_type) == 5 ? '-':
                                Number(item.bookmaker_type) !== 3 ?
                                  item.price : 
                                  item.line}
                          </td>
                          <td>{item.stake}</td>
                          <td>{item.pp}</td>
                          {
                            BetslipType == CONSTANT.UNMATCHED && 
                            <td className="text-center"><a onClick={() => {if(window.confirm(t('Are you sure you want to delete this bet?'))){this.deleteUnmatchBet(item.betslip_uid,idx)}}} className="delete-unmatch-row sm"><i className="icon-trash"></i></a></td>
                          }
                        </tr>

                        )
                      }) 
                      }
                      
                    </tbody>
                  </Table>
                   : 
                  <MessageBox paragraph={t(CONSTANT.FIXTURE_BLANK_HEADING)} />
                  }
                  {totalPage >= 5 &&
                  <div className="text-center mt20 pagination-sm">
                    <Pagination {...PaginationProps}/>

                  </div>
              }

              </div>
              
            </Collapse>
          </div>
        </div>
        :
        null
    )
  }
}

BetslipOpenBet.defaultProps = {

}

const BetslipOpenBetTrans = withTranslation()(BetslipOpenBet)

function mapStateToProps(state) {
  const { app } = state;
  return {
    openCollapse: app.openCollapse
  };
}

const mapDispatchToProps = dispatch => ({
  SlipToggle: (type) => dispatch(SlipToggle(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetslipOpenBetTrans);
