const Role = {
  Admin: 'Admin',
  User: 'User',
  Punter:"6",
  SuperAdminAgent:'7',
  Agent:'8',
  SubAgent :'9',
  AgentPunter:'10',
  AgentStaff :'11',    
}

export default Role;