import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, InputGroup } from 'reactstrap';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { Utils, Http, API } from 'Helpers';
import { notify } from 'react-notify-toast';
import { Auth } from 'Service';
import { Link } from 'react-router-dom';
import { VideoModal } from 'Modals';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      email: "",
      Name: "",
      Username: "",
      password: "",
      ConfirmPassword: "",
      ReferralCode: "",
      MobileNumber: "",
      Dob: moment().toDate(),
      phone_code: { value: "91", label: "91" },
      otp: '',
      countries_options: [],
      otp_sent: false,
      agree: false,
      SignupVideoModalOpen: false
    }
    if (Auth.currentUserValue) {
      this.props.history.push('/');
    }

  }
  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    if (name === 'MobileNumber') {
      this.setState({ otp_sent: false })
    }
    this.setState({ [name]: e.target.type == 'checkbox' ? !this.state[name] : value },
      this.validateForm
    );
  };

  selectChange = (val) => {
    this.setState({
      phone_code: val
    }, this.validateForm)
  }

  onFromDateChange = (date) => {
    this.setState({ Dob: date }, this.validateForm)
  }

  validateForm() {
    const { Name, Username, password, ConfirmPassword, email, Dob, MobileNumber, phone_code, otp, agree } = this.state
    this.setState({
      formValid:
        Name !== '' &&
        Username !== '' &&
        password !== '' &&
        ConfirmPassword !== '' &&
        email !== '' &&
        Dob !== '' &&
        MobileNumber !== '' &&
        phone_code !== null &&
        otp != '' &&
        agree == true &&
        Utils.isValid("name", Name) &&
        Utils.isValid("username", Username) &&
        Utils.isFormValid(['password', 'email'], this.state) &&
        Utils.isValid("mobile", MobileNumber) &&
        password == ConfirmPassword
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true })

    let obj = { ...this.state };
    let param = {
      name: obj.Name,
      username: obj.Username,
      email: obj.email,
      password: obj.password,
      dob: moment(obj.Dob).format('DD-MM-yyy'),
      phone_code: obj.phone_code.value,
      mobile: obj.MobileNumber,
      otp: obj.otp,
      referral_code: obj.ReferralCode
    }
    Http.post(API.SIGNUP_USER, param).then(response => {
      notify.show(response.message, "success", 2500)
      setTimeout(() => this.props.history.push('/'), 2500)
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  countries_list = () => {
    Http.post(API.GET_COUNTRY_LIST)
      .then(res => {
        let countries_options = [];
        res.data.forEach((country) => {
          let obj = { value: country.phonecode, label: country.phonecode };
          countries_options.push(obj);
          // countries_label[country.abbr] = `${country.abbr} (${country.phonecode})`
        })
        this.setState({ countries_options })
        // console.log(countries_options)
      })
  }

  optSendHandler = () => {
    let param = {
      phone_code: this.state.phone_code.value,
      mobile: this.state.MobileNumber
    }
    Http.post(API.SEND_OTP, param)
      .then(res => {
        notify.show(res.message, "success", 2500)
        this.setState({
          otp_sent: true
        })
      })
      .catch(error => {
        console.log(error);
      });

  }

  componentDidMount() {
    this.countries_list();
  }




  render() {
    const { t } = this.props
    const { email, Name, Username, password, ConfirmPassword, Dob, ReferralCode, MobileNumber, phone_code, posting, formValid, otp, agree, SignupVideoModalOpen } = this.state

    const SignupVideoModalProps = {
      isOpen: SignupVideoModalOpen,
      toggle: () => this.setState({ SignupVideoModalOpen: false }),
      videoId:"hXpf5zAHQJE"
    }


    return (
      <Container>
        <Row>
          <Col>
            <div className="onboarding-wrap">
              <div className="title text-center mb-2">{t('Sign up')}</div>
              <div
                className={'text-center dp-head-note c-pointer'}
                onClick={() => this.setState({ SignupVideoModalOpen: true })}
              >{t('Any questions? Watch video to learn how to signup.')}</div>
              <Form onSubmit={this.onSubmit} className="">
                <Row>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Name')}</span>
                      <Input type="Name"
                        id='Name'
                        name='Name'
                        required
                        value={Name}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("name", Name)}
                        valid={Utils.isValid("name", Name)} />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Username')}</span>
                      <Input type="text"
                        id='Username'
                        name='Username'
                        required
                        value={Username}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("username", Username)}
                        valid={Utils.isValid("username", Username)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Password')}</span>
                      <Input
                        id='password'
                        name='password'
                        required
                        type='password'
                        maxLength={50}
                        value={password}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("password", password)}
                        valid={Utils.isValid("password", password)} />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Confirm Password')}</span>
                      <Input
                        id='ConfirmPassword'
                        name='ConfirmPassword'
                        required
                        type='password'
                        maxLength={50}
                        value={ConfirmPassword}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("password", ConfirmPassword) || (ConfirmPassword != '' && password != ConfirmPassword)}
                        valid={Utils.isValid("password", ConfirmPassword)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('E-mail')}</span>
                      <Input type="email"
                        id='email'
                        name='email'
                        required
                        value={email}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("email", email)}
                        valid={Utils.isValid("email", email)} />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom'>
                      <span className='label'>{t('Date of Birth')}</span>
                      <DatePicker
                        onChange={this.onFromDateChange}
                        onCalendarOpen={this.onCalendarOpen}
                        value={Dob}
                        calendarIcon={<i className='icon-calendar' />}
                        className='form-control'
                        clearIcon={null}
                        format="d/M/yyyy"
                      />
                      <div><span><small style={{ fontSize: '12px' }}>Below 18 Years cannot register upon Sign Up & Profile</small></span></div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Mobile Number')}</span>
                      <InputGroup className={'otp_inp_grp'}>
                        <Select
                          options={this.state.countries_options}
                          placeholder={''}
                          className={'country_sel'}
                          onChange={(val) => this.selectChange(val)}
                          value={phone_code}
                        />
                        <Input type="text"
                          id='MobileNumber'
                          name='MobileNumber'
                          required
                          value={MobileNumber}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("mobile", MobileNumber)}
                          valid={Utils.isValid("mobile", MobileNumber)}
                        />
                        {
                          Utils.isValid("mobile", MobileNumber) && !this.state.otp_sent ?
                            <span className={'sd_otp_sp'} onClick={this.optSendHandler}>Send OTP</span>
                            : ''
                        }

                      </InputGroup>
                    </FormGroup>

                  </Col>
                  <Col>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>OTP</span>
                      <Input
                        id='otp'
                        type="text"
                        name="otp"
                        value={otp}
                        onChange={this.handleChange}
                        autoComplete="off"
                        invalid={Utils.isInvalid("required", otp)}
                      />
                      {
                        this.state.otp_sent ? <div className={'resend_otp'}>OTP has been set on your number…. <span onClick={this.optSendHandler}>Resend</span></div> : ''
                      }

                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup >
                      <div style={{ fontSize: 11, marginTop: '-10px' }}>
                        By clicking "Send OTP", you agree to Isocial Terms of Use and Privacy Policy.
                        You consent to receive SMS messages from Isocial for number verification and login purposes.
                        Message frequency depends on your activity. You may opt-out by texting "STOP" to 86753.
                        Message and data rates may apply.
                        </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>Referral Code</span>
                      <Input type="ReferralCode"
                        id='ReferralCode'
                        name='ReferralCode'
                        // required
                        value={ReferralCode}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("ReferralCode", ReferralCode)}
                        valid={Utils.isValid("ReferralCode", ReferralCode)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup check className='check check-primary'>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={agree ? true : false}
                          onChange={this.handleChange}
                          name={'agree'}

                        />
                        {t('I agree to all')} <Link target={'_blank'} to={'/terms-and-conditions'}>{t('terms & conditions')} </Link>  {t('of Isocial Ltd.')}
                        <span />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <FormGroup className='m-0'>
                      <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round'>{t('Signup')}</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </Form>
            </div>
          </Col>
        </Row>

        {SignupVideoModalOpen && <VideoModal {...SignupVideoModalProps} />}
      </Container>
    )
  }
}

export default withTranslation()(Signup);
