import React from 'react';
import { Row, Col } from 'reactstrap';
import { CONSTANT, SocketEvent } from 'Constants';
import { Auth } from 'Service';

// import Countdown from 'react-countdown';
import { Http, API, _, Socket } from 'Helpers';
import config from 'config';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
// import Images from 'Constants/Images/Images';

class CasinoSingleGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            games: CONSTANT.CASINO_GAMES,
            streamingKey: '',
            getCurrentCasinoGame: '',
            league_list: [],
            casinoMarkets: [],
            timer: parseInt('1000'),
            countdownStart: false,
            newTimer: 0,
            roundId: null,
            stackData: [],
            openCards: [],
            SocketEventName: '',
            numberOfCards: ''
        }

    }



    getEventMarket = () => {

        const { getLeagueId } = this.state;

        let param = {
            league_uid: getLeagueId
        }
        Http.post(API.GET_EVENT_MARKET, param)
            .then(res => {
                let timer = res.data.markets.find(itm => itm.market_type == 'main');

                this.setState({
                    casinoMarkets: res.data.markets,
                }, () => {
                    if (res.data.markets.length > 0) {
                        this.setState({
                            timer: timer ? parseInt(timer.timer + '000') : 0,
                            countdownStart: true,
                            newTimer: timer ? timer.timer : 0,
                            roundId: res.data.markets.length > 0 ? res.data.markets[0].event_uid : null
                        })
                    }
                    else {
                        this.setState({ roundId: 'Waiting for next round' })

                    }
                })
            })
    }



    SaveLatestMarketData = (data) => {

        // _.map(JSON.parse(data.runners), (item, idx) => {
        //     if(item.cards.length > 0){
        //         this.setState({firstCardOpen:true})
        //     }
        // })


        const { casinoMarkets, openCards, numberOfCards } = this.state
        let tempData = casinoMarkets
        // console.log(this.state.RoundRunning, 'RoundRunning',)
        _.map(casinoMarkets, (item, idx) => {

            if (data.mm_id == item.mm_id) {
                let currentRunners = JSON.parse(tempData[idx].runners);
                // console.log(tempData, 'tempData');
                let comingRunners = JSON.parse(data.runners);
                let latestRunners = comingRunners;

                _.map(comingRunners, (run, myidx) => {
                    _.map(currentRunners, (r, i) => {
                        if (r.id == run.id) {
                            // console.log(r, 'r')
                            latestRunners[myidx]['winloss'] = r.winloss
                            latestRunners[myidx]['temp_winloss'] = r.temp_winloss
                        }
                    })
                })

                // console.log('latestRunners', latestRunners);
                // console.log('SaveLatestMarketData', JSON.parse(tempData[idx].runners) , JSON.parse(data.runners))
                // tempData[idx] = data;
                tempData[idx] = { ...data, runners: JSON.stringify(latestRunners) }

            }
        })
        let tempCard = openCards


        if (data.market_name == "Match odds") {

            _.map(JSON.parse(data.runners), (runner, idx) => {
                //if (runner.cards.length > 0 && (runner.name == 'Player A' || runner.name == 'Player B')) {
                if (runner.cards.length > 0) {


                    if (tempCard.length == numberOfCards) {
                        _.map(openCards, (itm, indx) => {
                            if ((itm.id === runner.id)) {
                                tempCard[indx].cards = runner.cards
                            }

                        })
                    } else {
                        if (_.isEmpty(tempCard)) {
                            tempCard.push(runner)
                        } else {
                            _.map(openCards, (itm, indx) => {
                                //   console.log(indx,"indxindx")
                                if ((itm.id !== runner.id)) {
                                    tempCard.push(runner)
                                }

                            })
                        }


                    }


                }
            })


            this.setState({
                openCards: tempCard
            }, () => {
            })


            //  console.log(tempCard,"tempCardtempCardtempCardtempCardtempCardtempCard")


        }


        this.setState({
            casinoMarkets: tempData,
            newTimer: data.timer,
        }, () => {

            if ((Number(this.state.roundId) !== Number(data.event_uid) && data.market_status !== 'CLOSED')) {

                this.setState({ roundId: data.event_uid, openCards: [] }, () => {
                    this.ResetWinloss(this.props.WinlossObject)
                    this.getEventMarket()

                })

            }
        })
        // if (data.market_type == 'main') {
        //     this.setState({
        //         newTimer: data.timer,
        //     })
        // }




        // console.log(data)
    }


    sockeListener = (name) => {
        //console.log(name,"SocketEvent.nameSocketEvent.nameSocketEvent.name")
        const _this = this

        Socket.on(name, function (res) {

            _this.SaveLatestMarketData(res);
            //    console.log('resrrrrrrrrrrrrrrrrrrrrrrrrrr', res);
        })
    }
    removeSockeListener = (name) => {
        Socket.off(name)
    }


    componentWillReceiveProps(nextProps) {
        const { WinlossObject } = this.props

        if (nextProps.WinlossObject.lastupdate !== WinlossObject.lastupdate) {
            if (!nextProps.WinlossObject.is_place_bet) {
                switch (nextProps.WinlossObject.type) {
                    case 0:
                        this.UpdateWinloss(nextProps.WinlossObject)
                        break;
                    case 1:
                        this.RunTimeUpdateWinloss(nextProps.WinlossObject)
                        break;
                    case 2:
                        this.ResetWinloss(nextProps.WinlossObject)
                        break;
                    default:
                        break;
                }
            }
        }
    }

    UpdateWinloss = (obj) => {
        const { casinoMarkets } = this.state
        let newcasinoMarkets = _.map(casinoMarkets, (item) => {
            if (item.market_event_id == obj.market_event_id) {
                let new_runners = _.map(JSON.parse(item.runners), (_obj) => {
                    return {
                        ..._obj,
                        winloss: obj.win_loss[_obj.selection_id]
                    }
                })
                return { ...item, runners: JSON.stringify(new_runners) }
            }
            return item
        })
        this.setState({
            casinoMarkets: newcasinoMarkets
        })

    }


    // RunTimeUpdateWinloss_old = (data) => {
    //     console.log('RunTimeUpdateWinloss', data)
    // }


    RunTimeUpdateWinloss = ({
        market_event_id,
        selectionId,
        stack,
        betting_type,
        pp,
        liability,
        bookmaker_type,
        session_id,
        selection_id
    }) => {

        // console.log(
        //     market_event_id,
        //     selectionId,
        //     stack,
        //     betting_type,
        //     pp,
        //     liability,
        //     bookmaker_type,
        //     session_id,
        //     selection_id
        // );
        const { casinoMarkets } = this.state
        if (bookmaker_type == CONSTANT.BOOKMAKER_TYPE['casino_betting']) {
            let newCasinoMarkets = _.map(casinoMarkets, (item) => {
                if (item.market_event_id == market_event_id) {
                    let new_runners = _.map(JSON.parse(item.runners), (_object) => {
                        let _obj = _.cloneDeep(_object)
                        if (betting_type == 1) {
                            if (_obj.id == selection_id) {
                                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) + Number(pp) }
                            } else {
                                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) - Number(liability) }
                            }
                        } else if (betting_type == 2) {
                            if (_obj.id == selection_id) {
                                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) - Number(liability) }
                            } else {
                                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) + Number(stack) }
                            }
                        }
                        return _obj
                    })
                    return { ...item, runners: JSON.stringify(new_runners) }
                }
                return item
            })
            this.setState({
                casinoMarkets: newCasinoMarkets
            })
        }
    }



    ResetWinloss = (obj) => {
        // console.log('ResetWinloss', obj)
        const { casinoMarkets } = this.state
        let newCasinoMarkets = _.map(casinoMarkets, (item) => {
            let new_runners = _.map(JSON.parse(item.runners), (_obj) => {
                return {
                    ..._obj,
                    temp_winloss: undefined
                }
            })
            return { ...item, runners: JSON.stringify(new_runners) }
        })
        this.setState({
            casinoMarkets: newCasinoMarkets
        })
    }


    componentDidMount() {

        const _this = this;
        if (Auth.currentUserValue) {
            _.forEach(Auth.currentUserValue.sport_settings, item => {
                if (item.sports_id == 5) {
                    this.setState({
                        stackData: item
                    })

                }
            })
        }


        if (this.props.match.params == undefined || this.props.location.state == undefined) {
            console.log('check no params')
            this.props.history.push('/casino');
            return;
        }


        this.setState({
            getCurrentCasinoGame: this.props.location.state.item,
            streamingKey: config.is_live ? this.props.location.state.item.video_port : this.props.location.state.item.video_abbr,
            getLeagueId: this.props.location.state.item.league_uid,
            SocketEventName: this.props.location.state.item.node_event,
            numberOfCards: Number(this.props.location.state.item.number_of_cards),

        }, () => {
            // console.log(this.state.numberOfCards,"numberOfCardsnumberOfCards")

            this.getEventMarket()
            this.sockeListener(this.state.SocketEventName)

            Socket.on(SocketEvent.STATUS_UPDATED_MK, function (res) {
                console.log('STATUS_UPDATED_MK', res)
                let casinoMarkets = [..._this.state.casinoMarkets];
                let getIdx = casinoMarkets.findIndex(itm => itm.event_uid == res.event_id);
                if (getIdx > -1) {
                    casinoMarkets[getIdx]['lock_betting'] = res.lock_betting;
                    _this.setState({ casinoMarkets })
                }

            })
        })



    }
    componentWillUnmount() {
        this.removeSockeListener(this.state.SocketEventName)

    }

    countdownTickHandler = (e) => {
        // console.log(e)
    }

    render() {

        const {
            _oddsActiveClass,
        } = this.props
        // console.log(_oddsActiveClass,"_oddsActiveClass_oddsActiveClass")


        const { casinoMarkets, getCurrentCasinoGame, openCards, streamingKey } = this.state;
        //openCards
        console.log('openCards', openCards);
        const currentUser = Auth.currentUserValue

        return (
            <React.Fragment>

                <section className={'single_casino_sec'}>
                    <Row className={'mark-row1 mb-4 align-items-center'}>
                        <Col sm={6} xs={6}>
                            <h4 className="casino-game-name">{getCurrentCasinoGame.league_name}</h4>
                            <div className={'round-d'}>
                                <span>Round Id:</span>
                                <b>{this.state.roundId || '-'}</b>
                            </div>
                            {/* <div className={'stake-limit'}>Stake Limit : <span>{stackData.min_odd_stack}  -  {stackData.max_odd_stack}</span></div> */}
                        </Col>
                        <Col sm={6} xs={6}>
                            <span className={'timer'}>
                                {/* <img src={Images.STOP_WATCH} alt={'stopwatch'} /> */}
                                {/* <Countdown
                                    stop={!this.state.countdownStart}
                                    start={this.state.countdownStart}
                                    date={Date.now() + this.state.timer}
                                    renderer={timer}
                                    precision={2}
                                    onTick={this.countdownTickHandler}
                                ><span>0</span></Countdown>  */}

                                {/* {this.state.newTimer} */}
                            </span>


                        </Col>
                    </Row>




                    <div className={'video_panel'}>

                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                className="embed-responsive-item"
                                // src={`${config.CASINO_URL}${streamingKey}`} 
                                src={`${config.CASINO_URL}${config.is_live ? `?id=${streamingKey}` : `${streamingKey}`}`}
                                title={'casino'}
                                scrolling="no"
                            ></iframe>
                        </div>

                        {/* {
                            !_.isEmpty(openCards) &&
                            <div className="results-card">
                                {
                                    _.map(openCards, (cardItem, cardIdx) => {
                                        return (
                                            <div style={{ width: (100 / (openCards.length)) + '%' }} key={cardIdx}>
                                                <span>{cardItem.name}</span>
                                                <div className="results-card-img">
                                                    {_.map(cardItem.cards, (citm, cidx) => {
                                                        return (

                                                            <img key={cidx} src={require(`../../Assets/img/cards/SVG/${citm}.svg`)} alt="" />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            !_.isEmpty(openCards) &&
                            <div className="game-results">
                                <span className="game-results-text-color">Game Result</span>
                            </div>
                        } */}



                    </div>






                    <Row>
                        <Col xs={12}>
                            <div className={'casino-game-group'}>

                                {
                                    _.map(casinoMarkets, (market, key) => {
                                        //console.log(casinoMarkets,"casinoMarketscasinoMarketscasinoMarkets")
                                        // if (market.market_type !== "main") {
                                        //     return;
                                        // }
                                        // console.log('f')
                                        // if(this.state.getLeagueId == '98789'){
                                        //     console.log(this.state.getLeagueId)

                                        // }

                                        return (
                                            <div className={`casino-game-panel`} key={key}>
                                                <div className={'cg-heading'}>
                                                    <span className={'mn-hd'}>{market.market_name || '--'}</span>
                                                    <span className={'back-bt'}>Back</span>
                                                </div>
                                                <div className={'cg-body'}>

                                                    {
                                                        _.map(JSON.parse(market.runners), (runner, idx) => {
                                                            return (
                                                                runner.id !== '66779' &&
                                                                <div className={`pl-items`} key={idx}>
                                                                    <div className={'l-side'}>
                                                                        <div className={'plyr_dv'}>
                                                                            {runner.name}
                                                                            {
                                                                                (currentUser && !_.isUndefined(runner.winloss)) &&
                                                                                <React.Fragment>
                                                                                    {
                                                                                        _.isUndefined(runner.temp_winloss) ?
                                                                                            <span {...{ className: `winloss winloss-${Math.sign(runner.winloss)}` }} >
                                                                                                {_.round(runner.winloss, 2).toFixed(2)}
                                                                                            </span>
                                                                                            :
                                                                                            <span {...{ className: `winloss winloss-${Math.sign(runner.temp_winloss)}` }} >
                                                                                                {_.round(runner.temp_winloss, 2).toFixed(2)}
                                                                                            </span>
                                                                                    }

                                                                                </React.Fragment>

                                                                            }
                                                                            {/* {
                                                                                (!_.isUndefined(runner.winloss)) &&
                                                                                <span {...{ className: `winloss wl-${Math.sign(runner.winloss)}` }} >
                                                                                    {_.round(runner.winloss, 2).toFixed(2)}
                                                                                </span>
                                                                            } */}
                                                                        </div>
                                                                        {/* <div className={'clr-red pts'}>0.00</div> */}

                                                                    </div>
                                                                    <div className={'r-side'}>

                                                                        {/* {
                                                                    console.log(
                                                                        'runner.back.length-', runner.back.length,
                                                                        'market.lock_betting-',market.lock_betting, 
                                                                        'market.lock_agent_betting-', market.lock_agent_betting,
                                                                        'market.market_status-', market.market_status
                                                                        )
                                                                        } */}


                                                                        {

                                                                            (runner.back.length > 0 && (market.lock_betting == '0' && market.lock_agent_betting == '0') && market.market_status == "OPEN") ?

                                                                                //&& runner.cards.length == 0 

                                                                                // <div
                                                                                //     className={'bet-st'}
                                                                                //     onClick={() => this.props.selectBet({
                                                                                //         fixture: { ...market },
                                                                                //         player: runner,
                                                                                //         selected_odd: runner.back[0],
                                                                                //         betting_type: 1
                                                                                //     })}
                                                                                // >{runner.back.price}</div>

                                                                                (this.state.getLeagueId == '56767' && this.state.openCards.length > 0) || (this.state.getLeagueId == "98790" && this.state.openCards.length > 0) ? //Teen Patti One Day + Dragon Tiger - Inplay odds we don’t have to run

                                                                                    <div className={'suspended'}>Suspended</div>
                                                                                    :
                                                                                    _.map(runner.back, (bck, i) => {
                                                                                        if (this.state.getLeagueId != '98790' && i > 0) {
                                                                                            return;
                                                                                        }
                                                                                        return <div
                                                                                            key={i}
                                                                                            className={`bet-st ${_oddsActiveClass(runner.id, market.market_uid, 1, i) ? 'yellow' : ''}`}
                                                                                            onClick={() => [
                                                                                                this.props.selectBet({
                                                                                                    fixture: { ...market },
                                                                                                    player: runner,
                                                                                                    selected_odd: bck,
                                                                                                    betting_type: 1,
                                                                                                    idx: i
                                                                                                }),
                                                                                                this.ResetWinloss(market)
                                                                                            ]}
                                                                                        >{bck.price}</div>
                                                                                    })
                                                                                :
                                                                                <div className={'suspended'}>Suspended</div>
                                                                        }


                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }



                            </div>
                        </Col>
                    </Row>


                </section>
            </React.Fragment>
        )
    }
}


const FixtureDetailListTrans = withTranslation()(CasinoSingleGame)

function mapStateToProps(state) {
    const { app } = state;
    return {
        WinlossObject: app.WinlossObject
    };
}

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FixtureDetailListTrans);