import React, { Component } from 'react';
import { Modal, Row, Col } from 'reactstrap';

class LiveCasinoInfoModal extends Component {
  render() {
    const { isOpen, toggle, SelectedItem} = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'xs',
      className: 'language-dialog live-casino-info',
      //backdrop:  'static' ,
    }
    
 
    return (
      <Modal {...ModalProps}>
        
          
              <h2 className='title'>LIVE CASINO INFO</h2>
            <Row>
              <Col xs={12}>
                <div className="live-casino-info-body">
                  <p>लाइव कैसीनो में 1 अंक 100 रुपये के बराबर है।</p>
                  <p>In Live Casino, 1 point is equal to 100 Rupees.</p>
                  <p>লাইভ ক্যাসিনো - এর মধ্যে ১ পয়েন্ট এর সমান ১০০ টাকা। উদাহরণ : ১০ পয়েন্ট = ১০০০ টাকা</p>
                  <p>Ex: 10 points = 1000 Rupees</p>
                </div>
                
              </Col>
              
            </Row>
            
          
          <div className="lang-modal-footer">
            <button className="btn btn-primary" onClick={()=>this.props.GoToDetail(SelectedItem)}>Continue</button>
        </div>
          
        
      </Modal>
    );
  
  }
}

export default LiveCasinoInfoModal;
