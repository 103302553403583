import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { SlipToggle } from '../../Redux/Reducers'
import { Collapse } from 'reactstrap';
import { SocketEvent } from 'Constants';
import { Socket, API, Http,  _ } from 'Helpers';
import ReactHtmlParser from 'react-html-parser';


class ScoresAndTvAccordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            scoreData: "",
            currentMatchId: "",
        }
    }


    componentDidMount() {
        const _this = this;
        Socket.on(SocketEvent.SCORELIVE, function (res) {
            console.log('SCORELIVE', res)
            _this.scoreSocketHandler(res)
        })


        console.log('this.props.currentMatchId', this.props.currentMatchId, 'this.state.currentMatchId', this.state.currentMatchId)

    }

    // OpenNewWin = ()=>{
    //     var win = window.open();
    // win.document.write(`${<iframe title={'livematch'} className={'livematch-frame'} width={'100%'} src={`https://live.dreamipl.com/GetAPI.html?MatchID=${this.state.currentMatchId}`}></iframe>}`)
    // }

    scoreSocketHandler = (res) => {
        console.log(res,"RRRRRRRRR")

        if (!_.isEmpty(res) && res.data.length > 0 && this.props.currentMatchId) {

            let scoreData = ""
            // eslint-disable-next-line
            res.data.map((itm, i) => {

                if (itm.event_id == this.props.currentMatchId) {
                    scoreData = itm.data;

                }
            })

            this.setState({ scoreData })
        }
    }


    getScore = () => {
        Http.post(API.LIVE_SCORE, { event_id: this.props.currentMatchId }).then(response => {

            if (response.response_code == 200) {

                let json = JSON.parse(response.data)

                let scoreData = null;
                if (json) {
                    if (json.data && json.data.length > 0) {
                        scoreData = json.data[0].data
                    }
                }
                this.setState({
                    scoreData: scoreData
                })
            }
        })
    }



    componentWillUnmount() {
        this.setState({ currentMatchId: "" })
        Socket.off(SocketEvent.SCORELIVE);
        if(this.props.openCollapse === 3){
            this.props.SlipToggle('')
        }
    }


    componentWillReceiveProps() {
       
        if (this.props.currentMatchId && this.state.currentMatchId != this.props.currentMatchId) {
            this.setState({
                currentMatchId: this.props.currentMatchId
            }, this.getScore)

        }
    }

    render() {


        const { activeTab, scoreData, } = this.state;

        const { openCollapse, SlipToggle, t, currentMatchId, } = this.props


        return (
            <React.Fragment>
                <div {...{ className: `betslip-wrap ` }}>
                    <div className="betslip-container">
                        <div className="betslip-header" onClick={() => [SlipToggle(3)]}>
                            {t('Live Scores & TV')}

                            <Fragment>
                                {
                                    (openCollapse == 3) ?
                                        <i className="icon-up" />
                                        :
                                        <i className="icon-down" />
                                }
                            </Fragment>

                        </div>
                        <Collapse isOpen={openCollapse == 3}>
                            <div>

                                <div>
                                    <div className="betslip-tabs">
                                        <span onClick={() => this.setState({ activeTab: 1 })} className={`${activeTab == 1 ? 'active' : ''}`}>{t('Live Score')}</span>
                                        <span onClick={() => this.setState({ activeTab: 2 })} className={`${activeTab == 2 ? 'active' : ''}`}>{t('Live TV')}</span>
                                    </div>
                                </div>

                                <div className="betslip-body">
                                    {
                                        activeTab == 1 &&
                                        <div>
                                            {/* <iframe title={'livescore'} width={'100%'} src={`https://live.dreamipl.com/liveMatches.html?MatchID=${currentMatchId}`}></iframe> */}

                                            {
                                                scoreData ?
                                                    ReactHtmlParser(scoreData) :
                                                    <div className={'py-5 text-center'}>No Data Found</div>
                                            }
                                        </div>
                                    }

                                    {
                                        activeTab == 2 &&
                                        <div >
                                            <div className={'tv-modal-box'}>
                                                {/* <a href={`https://live.dreamipl.com/GetAPI.html?MatchID=${currentMatchId}`} target="_blank"><i className="icon-logout"></i></a> */}
                                                <iframe title={'livematch'} className={'livematch-frame'} width={'100%'} src={`https://live.dreamipl.com/GetAPI.html?MatchID=${currentMatchId}`}></iframe>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </Collapse>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const ScoresAndTvAccordionTrans = withTranslation()(ScoresAndTvAccordion)

function mapStateToProps(state) {
    const { app } = state;
    return {
        openCollapse: app.openCollapse,
        currentMatchId: app.currentMatchId,
    };
}

const mapDispatchToProps = dispatch => ({
    SlipToggle: (type) => dispatch(SlipToggle(type)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScoresAndTvAccordionTrans);
