const EVENT = {
  "MARKET"                : "marketUpdated",
  "SPORTSSTATUSUPDATED"   : "sportsStatusUpdated",
  "MARKET_SP"             : "marketUpdatedSP",
  "FANCY"                 : "fancySessionUpdated",
  "FANCY_REPLACED"        : "fancySessionReplaced",
  "SCORE"                 : "scoreUpdated",
  "AMOUNT_DW"             : "AmountDW",
  "BALANCE_UPDATED"       : "balanceUpdated",
  "SPORTS_SETTING_UPDATED": "sportsSettingUpdated",
  "MARKETUPDATEDCS"     : "marketUpdatedCS",
  "MARKETUPDATEDCSLTP"     : "marketUpdatedCSltp",
  "STATUS_UPDATED_MK"     : "StatusUpdatedMk",
  "MARKET_UPDATED_CS"     : "marketUpdatedCS",
  "PROVIDER_UPDATED"      : "providerUpdated",
  "SCORELIVE"             : "scoreLive",
  "BOOKMAKER_MARKET"       : "bookmakerUpdated",
  "STATUS_UPDATED_BK"       : "StatusUpdatedBK",
  "SCORE_UPDATED_SP"       : "scoreUpdated"

}

export default { ...EVENT }
