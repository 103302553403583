import { Utils } from 'Helpers';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Trans } from 'react-i18next';

class GlobalLoader extends Component {
  render() {
    const { isLoaderShow } = this.props;
    return (
      isLoaderShow &&
      <div className="global-loader">
        <div className='page-loading'>
          <Utils.Spinner />
          <div className='page-loading-title'><Trans>Loading...</Trans></div>
        </div>
      </div>
    );
  }
}

GlobalLoader.defaultProps = {
  isLoaderShow: false
}
function mapStateToProps(state) {
  const { app } = state;
  return {
    isLoaderShow: app.isLoaderShow
  };
}
export default connect(
  mapStateToProps
)(GlobalLoader);