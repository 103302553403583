import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { SiteContainer } from 'Components';

class CommonOffers extends Component {
  render() {
    const { t } = this.props
    return (
      <SiteContainer {...this.props} header staticPage>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col>
              <div className="odds-padding-row heading">
                <h4 className="title">{t('Offers')}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="static-container">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.  </p>
              </div>
            </Col>
          </Row>
        </Container>
      </SiteContainer>
    );
  }
}

export default withTranslation()(CommonOffers);
