import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import {
  SportsBookFixtureListComponent,
  SportsBookFixtureDetailList,
  BetslipDrawer,
} from "Components";
//Sidebar,
import { Http, API, Utils, _, Socket } from "Helpers";
import { CONSTANT, SocketEvent } from "Constants";

import { connect } from "react-redux";
import { SlipToggle } from "../../Redux/Reducers";

class SportsbookHome extends Component {
  constructor(props) {
    super(props);
    const { page_id } = props;
    this.state = {
      is_inplay: false,
      FixturePosting: false,
      FixtureListBlank: false,
      hasMore: false,
      isChanged: false,
      AppMasterData: [],
      NavigationList: [],
      SelectedSports: {},
      SelectedLeague: {},
      SelectedFixture: {},
      FixtureList: [],
      Offset: CONSTANT.CURRENT_OFFSET,
      SelectedOdds: Utils.getBetSlip(page_id),
      SelectedOddsFancy: Utils.getBetSlip(CONSTANT.FANCY_BETTING),
      betslip_lastupdate: new Date().valueOf(),
      fixture_lastupdate: new Date().valueOf(),
      recently_changed: [],
      fancy_betslip: "",
      InitList: false,
      FixtureListLive: [],
      FixtureListUpcoming: [],
    };
  }
  // Fetch Request(s)

  GetFixtures = () => {
    const {
      InitList,
      SelectedSports,
      SelectedLeague,
      Offset,
      FixtureListUpcoming,
      is_inplay,
    } = this.state;
    if (!InitList) return;
    let param = {
      sports_id: is_inplay ? "" : SelectedSports.sports_id,
      league_uid: _.isEmpty(SelectedLeague) ? "" : SelectedLeague.league_uid,
      limit: is_inplay ? 99999 : CONSTANT.ITEMS_PERPAGE_SM,
      offset: Offset,
      game_type: "0",
    };

    this.setState({ FixturePosting: true });
    Http.post(
      is_inplay ? API.GET_INPLAY_GAMES_SP : API.GET_UPCOMING_FIXTURES_SP,
      param
    )
      .then((response) => {
        let matches = response.data.matches.filter(
          (item) => item.is_favourite === "0"
        );
        this.setState(
          {
            FixturePosting: false,
            FixtureListUpcoming:
              Offset == CONSTANT.CURRENT_OFFSET
                ? matches
                : [...FixtureListUpcoming, ...matches],
            hasMore:
              !_.isEmpty(matches) ||
              _.size(matches) === CONSTANT.ITEMS_PERPAGE_SM,
            fixture_lastupdate: new Date().valueOf(),
          },
          () => {
            //this.filterLivedata()
            //this.filterUpcomingData()
            this.setState({
              FixtureListBlank:
                _.isEmpty(matches) && _.isEmpty(this.state.FixtureListUpcoming),
            });
          }
        );
      })
      .catch((error) => {
        this.setState({ FixturePosting: false });
      });
  };

  GetFixturesLive = () => {
    const {
      InitList,
      SelectedSports,
      SelectedLeague,
      Offset,
      FixtureListLive,
      is_inplay,
    } = this.state;
    if (!InitList) return;
    let param = {
      sports_id: is_inplay ? "" : SelectedSports.sports_id,
      league_uid: _.isEmpty(SelectedLeague) ? "" : SelectedLeague.league_uid,
      limit: is_inplay ? 99999 : CONSTANT.ITEMS_PERPAGE_SM,
      offset: Offset,
      game_type: "1",
    };

    this.setState({ FixturePosting: true });
    Http.post(
      is_inplay ? API.GET_INPLAY_GAMES_SP : API.GET_UPCOMING_FIXTURES_SP,
      param
    )
      .then((response) => {
        let matches = response.data.matches.filter(
          (item) => item.is_favourite === "0"
        );
        this.setState(
          {
            FixturePosting: false,
            FixtureListLive:
              Offset == CONSTANT.CURRENT_OFFSET
                ? matches
                : [...FixtureListLive, ...matches],
            hasMore:
              !_.isEmpty(matches) ||
              _.size(matches) === CONSTANT.ITEMS_PERPAGE_SM,
            fixture_lastupdate: new Date().valueOf(),
          },
          () => {
            //this.filterLivedata()
            //this.filterUpcomingData()
            this.setState({
              FixtureListBlank:
                _.isEmpty(matches) && _.isEmpty(this.state.FixtureListLive),
            });
          }
        );
      })
      .catch((error) => {
        this.setState({ FixturePosting: false });
      });
  };

  filterLivedata = () => {
    const { FixtureList } = this.state;
    const data = FixtureList.filter((item) => item.is_live == "1");
    this.setState({ FixtureListLive: data });
  };

  filterUpcomingData = () => {
    const { FixtureList } = this.state;
    const data = FixtureList.filter((item) => item.is_live == "0");
    this.setState({ FixtureListUpcoming: data });
  };

  // Handlers
  InitListToggle = (bool) => {
    this.setState({
      InitList: bool,
    });
  };
  sockeListener = () => {
    const _this = this;
    Socket.on(SocketEvent.MARKET_SP, function (res) {
      if (!_.isEmpty(res)) {
        //console.log(res[0],"res[0]");
        _this.SocketMarkethandler(res[0]);
        _this.SocketMarkethandlerUp(res[0]);
      }
    });
  };

  sockeListenerScore = () => {
    const _this = this;
    Socket.on(SocketEvent.SCORE_UPDATED_SP, function (res) {
      if (!_.isEmpty(res)) {
        _this.UpdateMarketScore(res);
      }
    });
  };

  removeSockeListener = () => {
    Socket.off(SocketEvent.MARKET);
  };

  removesockeListenerScore = () => {
    Socket.off(SocketEvent.SCORE_UPDATED_SP);
  };

  UpdateMarketScore = (res) => {
    const { FixtureList } = this.state;
    const updatedMatches = FixtureList.map((match) => {
      if (match.event_id == res.event_id) {
        match.scores = res.scores;
        match.ss = res.ss;
      }

      return match;
    });

    this.setState({ FixtureList: updatedMatches }, () => {});
  };

  SocketMarkethandler = (res = {}) => {
    const { FixtureListLive, recently_changed } = this.state;
    //  console.log('radhe');
    let newFixtureList = _.map(_.cloneDeep(FixtureListLive), (item) => {
      //console.log(item.market_event_id,"item.market_event_id");
      // console.log(res.market_event_id,"res.market_event_id");
      if (item.market_event_id == res.market_event_id) {
        //console.log(res,"yha aaya");
        let ex_obj = {};
        let new_recently_changed = recently_changed;
        let item_runners_odds = item.runners_odds
          ? JSON.parse(item.runners_odds)
          : {};

        _.forEach(JSON.parse(res.runners_odds), (obj, idx) => {
          // odd change k liye == ka condtion change kiya hai
          if (
            item_runners_odds[idx] != undefined &&
            item_runners_odds[idx].selection_id == obj.selection_id
          ) {
            new_recently_changed = [
              ...new_recently_changed,
              JSON.stringify(obj.selection_id),
            ];
            ex_obj = { ...res };
            this.setState({
              isChanged: true,
              recently_changed: new_recently_changed,
            });
          }
        });
        return {
          ...item,
          ...ex_obj,
        };
      }
      return item;
    });

    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ FixtureListLive: newFixtureList }, () => {
        this.setState({ isChanged: false });
      });
    }
  };

  SocketMarkethandlerUp = (res = {}) => {
    const { FixtureListUpcoming, recently_changed } = this.state;
    //console.log('radhe');
    let newFixtureList = _.map(_.cloneDeep(FixtureListUpcoming), (item) => {
      //console.log(item.market_event_id,"item.market_event_id");
      //console.log(res.market_event_id,"res.market_event_id");
      if (item.market_event_id == res.market_event_id) {
        //  console.log(res,"yha aaya");
        let ex_obj = {};
        let new_recently_changed = recently_changed;
        let item_runners_odds = item.runners_odds
          ? JSON.parse(item.runners_odds)
          : {};

        _.forEach(JSON.parse(res.runners_odds), (obj, idx) => {
          // odd change k liye == ka condtion change kiya hai
          if (
            item_runners_odds[idx] != undefined &&
            item_runners_odds[idx].selection_id == obj.selection_id
          ) {
            new_recently_changed = [
              ...new_recently_changed,
              JSON.stringify(obj.selection_id),
            ];
            ex_obj = { ...res };
            this.setState({
              isChanged: true,
              recently_changed: new_recently_changed,
            });
          }
        });
        return {
          ...item,
          ...ex_obj,
        };
      }
      return item;
    });

    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ FixtureListUpcoming: newFixtureList }, () => {
        this.setState({ isChanged: false });
      });
    }
  };

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state;
    const formattedArr = recently_changed.map((str) => str.replace(/"/g, ""));
    let _id = _.isString(id) ? id : JSON.stringify(id);
    if (_.includes(formattedArr, _id)) {
      this.cleanSocketHighlight();
      return " blink ";
    } else return "";
  };

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] });
      }
    }, timer);
  };

  fetchMoreData = () => {
    if (!this.state.FixturePosting) {
      let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
      this.setState({ Offset: offset }, () => {
        this.GetFixtures();
      });
    }
  };

  handleSelectSport = (option) => {
    this.setState(
      {
        FixtureList: [],
        FixtureListBlank: false,
        Offset: CONSTANT.CURRENT_OFFSET,
        SelectedSports: option.Sport,
        SelectedLeague: option.League,
      },
      () => {
        const { match } = this.props;
        const { SelectedSports, SelectedLeague, is_inplay } = this.state;
        this.props.history.push({
          pathname: is_inplay ? `${match.url}/in-play` : `${match.url}`,
          ...(SelectedSports.sports_id != "" && {
            search: `?sports=${SelectedSports.sports_id}`,
          }),
          state: {
            SelectedSports,
            SelectedLeague,
            unbind: option.unbind,
          },
        });
        // this.GetFixtures()
      }
    );
  };

  // for Exchange/Sportsbook betslip
  // *******************************
  _oddsActiveClass = (market_bet_id, market_event_id, betting_type) => {
    let { SelectedOdds } = this.state;
    return _.some(SelectedOdds, (odd) => {
      return (
        odd.picked_odd_id ==
        market_bet_id + "_" + market_event_id + "_" + betting_type
      );
    });
  };

  handleOptionChange = (e, option) => {
    e.stopPropagation();
    //console.log(option, "optionoption");
    const { page_id } = this.props;
    let { SelectedOdds } = this.state;
    const { fixture, odd, betting_type, selected_odd } = option;

    if (fixture.sports_id == "8") {
      selected_odd["name"] =
        selected_odd.name != "Spread" && selected_odd.name != "Total"
          ? "Money Line"
          : selected_odd.name;
    }
    //console.log(option.multiBet,"option.multiBet");
    if (option.multiBet) {
      let selected_odd_name = CONSTANT.ONLY_SINGLE_BET
        ? "0"
        : [fixture.event_id, odd.market_bet_id, betting_type].join("_");

      //SelectedOdds.bookmakerHandle = bookmakerHandle

      delete odd.ex;
      SelectedOdds[selected_odd_name] = {
        picked_odd_id: odd.market_bet_id + "_" + betting_type,
        market_bet_id: odd.market_bet_id,
        picked_odd_price: betting_type == 1 ? odd.b1 : odd.lay_price,
        picked_odd: { ...odd, betting_type, ...selected_odd },
        stack_value: "",
        ...fixture,
      };

      this.setState(
        {
          SelectedOdds,
          fancy_betslip: "",
          betslip_lastupdate: new Date().valueOf(),
        },
        () => {
          Utils.setBetSlip(CONSTANT.BETSLIP[page_id], this.state.SelectedOdds);
        }
      );

      const { openCollapse, SlipToggle } = this.props;

      if (openCollapse != 1) {
        SlipToggle();
      } else {
        SlipToggle(1);
      }

      //console.log({...SelectedOdds},"SelectedOddsSelectedOddsSelectedOdds");
    } else {
      let selected_odd_name = CONSTANT.ONLY_SINGLE_BET
        ? "0"
        : [
            fixture.event_id,
            fixture.market_event_id,
            odd.market_bet_id,
            betting_type,
          ].join("_");

      if (
        this._oddsActiveClass(
          odd.market_bet_id,
          fixture.market_event_id,
          betting_type
        )
      ) {
        SelectedOdds = _.omit(SelectedOdds, [selected_odd_name]);
        this.setState(
          {
            SelectedOdds,
            betslip_lastupdate: new Date().valueOf(),
          },
          () => {
            Utils.setBetSlip(
              CONSTANT.BETSLIP[page_id],
              this.state.SelectedOdds
            );
          }
        );
      } else {
        delete odd.ex;
        SelectedOdds[selected_odd_name] = {
          picked_odd_id:
            odd.market_bet_id +
            "_" +
            fixture.market_event_id +
            "_" +
            betting_type,
          market_bet_id: odd.market_bet_id,
          picked_odd_price: betting_type == 1 ? odd.price : odd.lay_price,
          picked_odd: { ...odd, betting_type, ...selected_odd },
          stack_value: "",
          ...fixture,
        };
        this.setState(
          {
            SelectedOdds,
            fancy_betslip: "",
            betslip_lastupdate: new Date().valueOf(),
          },
          () => {
            Utils.setBetSlip(
              CONSTANT.BETSLIP[page_id],
              this.state.SelectedOdds
            );
          }
        );
      }
    }

    const { openCollapse, SlipToggle } = this.props;
    if (openCollapse != 1) {
      SlipToggle(1);
    }
  };

  handleRemoveSlip = (key) => {
    const { page_id } = this.props;
    let SelectedOdds = Utils.getBetSlip(page_id);
    let newSelectedOdds = _.omit(SelectedOdds, key);
    this.setState(
      {
        SelectedOdds: newSelectedOdds,
        betslip_lastupdate: new Date().valueOf(),
      },
      () => {
        Utils.setBetSlip(CONSTANT.BETSLIP[page_id], this.state.SelectedOdds);
      }
    );
  };

  clearAllBet = (BetslipType) => {
    const { page_id } = this.props;
    this.setState(
      {
        ...(page_id == BetslipType && { SelectedOdds: {} }),
        ...(CONSTANT.FANCY_BETTING == BetslipType && { SelectedOddsFancy: {} }),
        betslip_lastupdate: new Date().valueOf(),
      },
      () => {
        Utils.removeBetSlip(CONSTANT.BETSLIP[BetslipType]);
        Utils.removeMultiBetSlip(CONSTANT.BETSLIP[BetslipType]);
      }
    );
  };

  // for Fancy betslip
  // *******************************
  _fancyOddsActiveClass = (market_bet_id, selection_id, betting_type) => {
    let { SelectedOddsFancy } = this.state;
    return _.some(SelectedOddsFancy, (odd) => {
      return (
        odd.picked_odd_id ==
        market_bet_id + "_" + selection_id + "_" + betting_type
      );
    });
  };

  _fancyHandleOptionChange = (option) => {
    let { SelectedOddsFancy } = this.state;
    const { fixture, odd, betting_type, selected_odd } = option;
    let selected_odd_name = CONSTANT.ONLY_SINGLE_BET
      ? "0"
      : [
          fixture.event_id,
          fixture.market_event_id,
          odd.market_bet_id,
          betting_type,
        ].join("_");

    if (
      this._fancyOddsActiveClass(
        odd.market_bet_id,
        odd.selection_id,
        betting_type
      )
    ) {
      SelectedOddsFancy = _.omit(SelectedOddsFancy, [selected_odd_name]);
      this.setState(
        {
          SelectedOddsFancy,
          betslip_lastupdate: new Date().valueOf(),
        },
        () => {
          Utils.setBetSlip(
            CONSTANT.BETSLIP[CONSTANT.FANCY_BETTING],
            this.state.SelectedOddsFancy
          );
        }
      );
    } else {
      SelectedOddsFancy[selected_odd_name] = {
        picked_odd_id:
          odd.market_bet_id + "_" + odd.selection_id + "_" + betting_type,
        market_bet_id: odd.market_bet_id,
        picked_odd_price: betting_type == 1 ? odd.price : odd.lay_price,
        picked_odd: {
          ...odd,
          betting_type,
          name: odd.runner_name,
          ...selected_odd,
        },
        stack_value: "",
        ...fixture,
      };
      this.setState(
        {
          SelectedOddsFancy,
          fancy_betslip: CONSTANT.FANCY_BETTING,
          betslip_lastupdate: new Date().valueOf(),
        },
        () => {
          Utils.setBetSlip(
            CONSTANT.BETSLIP[CONSTANT.FANCY_BETTING],
            this.state.SelectedOddsFancy
          );
        }
      );
    }

    const { openCollapse, SlipToggle } = this.props;
    if (openCollapse != 1) {
      SlipToggle(1);
    }
  };

  _fancyHandleRemoveSlip = (key) => {
    let SelectedOddsFancy = Utils.getBetSlip(CONSTANT.FANCY_BETTING);
    let newSelectedOddsFancy = _.omit(SelectedOddsFancy, key);
    this.setState(
      {
        SelectedOddsFancy: newSelectedOddsFancy,
        betslip_lastupdate: new Date().valueOf(),
      },
      () => {
        Utils.setBetSlip(CONSTANT.FANCY_BETTING, this.state.SelectedOddsFancy);
      }
    );
  };

  fixtureSelectHandler = (item = {}) => {
    const { match } = this.props;
    const { is_inplay } = this.state;
    this.props.history.push({
      pathname: is_inplay
        ? `${match.url}/in-play/details/${item.event_id}`
        : `${match.url}/details/${item.event_id}`,
      search: `?sports=${item.sports_id}&market=${item.market_event_id}`,
    });
  };

  reinitiateComponent = (sports_id = "", is_bypass = false) => {
    const { match } = this.props;
    if (is_bypass) {
      this.props.history.push({
        pathname: `${match.url}/in-play`,
      });
    } else {
      this.props.history.push({
        pathname: `${match.url}`,
        ...(sports_id != "" && { search: `?sports=${sports_id}` }),
      });
    }
    this.setState({
      SelectedFixture: {},
    });
  };

  pageInit = ({ SelectedLeague = {}, SelectedSports = {}, unbind }) => {
    const { page_id } = this.props;
    const { is_inplay } = this.state;
    this.setState(
      {
        AppMasterData: Utils.getMasterData(),
        NavigationList: is_inplay
          ? [...Utils.getMasterData().sports_list[page_id]]
          : Utils.getMasterData().sports_list[page_id],
      },
      () => {
        const { NavigationList, AppMasterData } = this.state;
        const { location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        if (unbind) {
          this.setState(
            {
              SelectedSports: SelectedSports,
              SelectedLeague: SelectedLeague,
            },
            () => [this.GetFixtures(), this.GetFixturesLive()]
          );
        } else {
          if (_.isEmpty(SelectedLeague) && _.isEmpty(SelectedSports)) {
            _.forEach(NavigationList, (item) => {
              if (
                item.sports_id ==
                (searchParams.get("sports")
                  ? searchParams.get("sports")
                  : AppMasterData.default_sport)
              ) {
                this.setState(
                  {
                    SelectedSports: item,
                  },
                  () => [this.GetFixtures(), this.GetFixturesLive()]
                );
              }
            });
          }
        }
      }
    );
  };

  reverseMatch = (msg) => {
    const { SelectedSports } = this.state;

    let param = {
      sports_id: SelectedSports.sports_id,
    };

    Http.post(API.FIXTURE_REMOVE_CHOICE, param).then((response) => {
      Utils.notify(response.message);
      if (msg == "upcoming") {
        this.GetFixtures();
      } else {
        this.GetFixturesLive();
      }
    });
  };

  // Life cycle
  componentDidMount() {
    const { location } = this.props;
    this.setState({
      is_inplay: _.includes(location.pathname, "in-play"),
    });
    this.pageInit(
      _.isUndefined(location.state) || _.isNull(location.state)
        ? {}
        : location.state
    );
  }

  componentWillUnmount() {
    this.removeSockeListener();
    this.removesockeListenerScore();
    this.setState = () => {
      return;
    };
  }

  render() {
    const { page_id, match } = this.props;
    const {
      NavigationList,
      SelectedSports,
      FixtureList,
      FixtureListLive,
      FixtureListUpcoming,
      hasMore,
      FixturePosting,
      FixtureListBlank,
      SelectedOdds,
      SelectedOddsFancy,
      betslip_lastupdate,
      fixture_lastupdate,
      SelectedFixture,
      fancy_betslip,
      InitList,
      is_inplay,
    } = this.state;

    const BetslipProps = {
      SelectedOdds,
      SelectedOddsFancy,
      betslip_lastupdate,
      page_id,
      fancy_betslip,
      // Methods
      handleRemoveSlip: this.handleRemoveSlip,
      clearAllBet: this.clearAllBet,
      // Fancy Methods
      _fancyHandleRemoveSlip: this._fancyHandleRemoveSlip,
    };

    const FixtureListProps = {
      ...this.props,
      NavigationList,
      SelectedSports,
      hasMore,
      FixtureList,
      FixturePosting,
      FixtureListBlank,
      SelectedFixture,
      fixture_lastupdate,
      InitList,
      is_inplay,
      FixtureListLive,
      FixtureListUpcoming,

      // Methods
      InitListToggle: this.InitListToggle,
      handleSelectSport: this.handleSelectSport,
      fetchMoreData: this.fetchMoreData,
      _oddsActiveClass: this._oddsActiveClass,
      handleOptionChange: this.handleOptionChange,
      fixtureSelectHandler: this.fixtureSelectHandler,
      reinitiateComponent: this.reinitiateComponent,
      SocketHighlight: this.SocketHighlight,

      // Fancy Methods
      _fancyOddsActiveClass: this._fancyOddsActiveClass,
      _fancyHandleOptionChange: this._fancyHandleOptionChange,

      // Socket
      sockeListener: this.sockeListener,
      removeSockeListener: this.removeSockeListener,
      removesockeListenerScore: this.removesockeListenerScore,
      sockeListenerScore: this.sockeListenerScore,
      //for reverse
      reverseMatch: this.reverseMatch,
      updateList: this.GetFixtures,
    };
    const BetslipDrawerProps = {
      ...this.props,
      BetslipProps,
    };
    return (
      <Container
        fluid
        className="gutters-container-20px"
        style={{ marginBottom: "170px" }}
      >
        <Row>
          {
            // !is_inplay &&
            // <Col className='leftnav-col'>
            //   <Sidebar menu={NavigationList} page_id={page_id} callback={this.handleSelectSport} />
            // </Col>
          }
          <Col>
            <Switch>
              <Route
                path={[
                  `${match.url}/details/:event_id`,
                  `${match.url}/in-play/details/:event_id`,
                ]}
                exact
                render={(props) => (
                  <SportsBookFixtureDetailList
                    {...FixtureListProps}
                    {...props}
                  />
                )}
              />

              <Route
                path={[`${match.url}/`, `${match.url}/in-play`]}
                exact
                render={(props) => (
                  <SportsBookFixtureListComponent
                    {...FixtureListProps}
                    {...props}
                  />
                )}
              />

              <Redirect from="*" exact to={match.url} />
            </Switch>
          </Col>
          {!_.isEmpty(SelectedOdds) && (
            <BetslipDrawer {...BetslipDrawerProps} />
          )}
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;
  return {
    openCollapse: app.openCollapse,
  };
}
const mapDispatchToProps = (dispatch) => ({
  SlipToggle: (type) => dispatch(SlipToggle(type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SportsbookHome);
