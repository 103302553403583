import React, { Component, Fragment } from 'react';
import { _, Utils } from 'Helpers';
//import { Images } from 'Constants';

class SportsBookBetItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      oddShowType: localStorage.getItem('OddSelectiontype')
    }
  }
  componentDidMount(){
    this.props.sockeListener()
    this.props.sockeListenerScore()
    
  }
  componentWillUnmount() {
    this.props.removeSockeListener()
    this.props.removesockeListenerScore()
   
  }
  render() {
    // const {oddShowType} = this.state

    const {
      item,
      SelectedSports,
      _oddsActiveClass,
      handleOptionChange,
      fixtureSelectHandler,
      SocketHighlight,
      t,
     // isFav,
      toggleFav,
      isAll
    } = this.props;
    //console.log(SelectedSports,"SelectedSports.sports_nameSelectedSports.sports_nameSelectedSports.sports_name");
    const isDisabled = (obj) => {
      return obj.bet_status != 1 || (obj.agent_status ? obj.agent_status : obj.admin_status) != 1
    }

    // const BetTitle = ({ home, away }) => {
      
    //   return (
    //     <div className="title">
    //       <span title={home.name}>{`${home.name}`}</span>
    //       <div className="timestamp">
    //               {
    //                 item.is_live == 1 ?
    //                 <>
    //                 {item.scores !== '' && <>
    //                 <span className='score-value'>{JSON.parse(item.scores).home}</span>
    //                 <span className='score-value'> - </span>
    //                 <span className='score-value'>{JSON.parse(item.scores).away}</span>
    //                 </>}
    //                 </>
                      
    //                   // <span className="is-live-lbl">In-Play</span>
    //                   :
    //                   <Fragment>
    //                     <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
    //                   </Fragment>
    //               }
    //             </div>
    //       <span title={away.name}>{`${away.name}`}</span>
    //     </div>
    //   )
    // }

    const getTeamName = (odd) => {
      return item.sports_id == '8' ? odd.header == '1' ? JSON.parse(item.runners).home.name : JSON.parse(item.runners).away.name : odd.name
    }

    const BetTitleNew = ({ home, away }) => {
      return (
        <div className="team-details-view">
        <div className="right-view">
        <i className={`img-view icon-${SelectedSports.sports_id}`}></i>
          {/* <img className='img-view' alt="" src={Images.ENGLAND_LOGO}/> */}
          <div className="team-name">{home.name}</div>
          {/* <div className="fisrt-line-text">2’ David Alaba
            28’ Kingsley Coman</div> */}
        </div>
        <div className="middle-view">
        {
                    item.is_live == 1 ?
                    <>
                    {item.scores !== '' && <>
                    <span className='score-value'>{JSON.parse(item.scores).home}</span>
                    <span className='score-value'> - </span>
                    <span className='score-value'>{JSON.parse(item.scores).away}</span>
                    </>}
                    </>
                      
                      // <span className="is-live-lbl">In-Play</span>
                      :
                      <Fragment>
                       <span className="time-view-container"> <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}</span>
                      </Fragment>
                  }
          {/* {
              item.is_live == 1 ?
                <span className="is-live-lbl">In-Play</span>
                :
                <Fragment>
                  <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
                </Fragment>
            } */}
        </div>
        <div className="left-view">
        <i className={`img-view icon-${SelectedSports.sports_id}`}></i>
          {/* <img alt=""  className='img-view' src={Images.ENGLAND_LOGO}/> */}
          <div className="team-name">{away.name}</div>
          {/* <div className="fisrt-line-text">19’ Adrien Rabiot</div> */}
        </div>
      </div>
      )
    }

    return (
      <div className={`game-event-item`}>

        {
          item.sports_id == '8' ?
            <div className={'basket-ball-game'}>
              <div className={'l-part'}>
                <div className={'league_title'}>
                  <div className="small" >{item.league_name}</div>
                  {/* onClick={() => fixtureSelectHandler(item)} */}
                  <div className={'hdng_prt'}>
                    <div className={'hdngs'}>
                      <div {...{ className: `market-label` }}>{t('Spread')}</div>
                      <div {...{ className: `market-label` }}>{t('Total')}</div>
                      <div {...{ className: `market-label` }}>{t('Money Line')}</div>
                    </div>
                  </div>


                </div>
                <div className="timestamp">
                  {
                    item.is_live == 1 ?
                      <span className="is-live-lbl">In-Play</span>
                      :
                      <Fragment>
                        <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
                      </Fragment>
                  }
                </div>

                <div className={'odds-body'}>
                  <div className={'bs-odd-itm'}>
                    <div className={'l-p'} >
                      {/* onClick={() => fixtureSelectHandler(item)} */}
                      <span title={`${JSON.parse(item.runners).home.name}`}>{`${JSON.parse(item.runners).home.name}`}</span>
                    </div>
                    <div className={'r-p'}>
                      {
                        _.map(JSON.parse(item.runners_odds), (odd, key) => {
                          if (odd.header == 1) {
                            return <div
                              onClick={
                                isDisabled(item) ?
                                  null :
                                  (e) => [handleOptionChange(e,{
                                    fixture: { ...item, sports_id: SelectedSports.sports_id },
                                    odd: { ...odd, price: odd.odds, market_bet_id: odd.selection_id, selectionId: odd.selection_id },
                                    betting_type: 1,
                                    selected_odd: {
                                      ...odd,
                                      team_name: getTeamName(odd)
                                    }
                                  })]}
                              {...{
                                key,
                                className:
                                  `odd-trigger-inner back vslign ${isDisabled(item) ? 'disabled' : ''} ${_oddsActiveClass(odd.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: odd.selection_id })}`
                              }}>
                              <div className='price'>{`${odd.handicap ? `${odd.handicap}` : ''} (${odd.odds})`} </div>
                            </div>
                          }
                        })
                      }
                    </div>
                  </div>
                  <div className={'bs-odd-itm'} >
                    <div className={'l-p'}>
                      {/* onClick={() => fixtureSelectHandler(item)} */}
                      <span title={`${JSON.parse(item.runners).away.name}`}>{`${JSON.parse(item.runners).away.name}`}</span>
                    </div>
                    <div className={'r-p'}>
                      {
                        _.map(JSON.parse(item.runners_odds), (odd, key) => {
                          if (odd.header == 2) {
                            return <div
                              onClick={
                                isDisabled(item) ?
                                  null :
                                  (e) => [handleOptionChange(e,{
                                    fixture: { ...item, sports_id: SelectedSports.sports_id },
                                    odd: { ...odd, price: odd.odds, market_bet_id: odd.selection_id, selectionId: odd.selection_id },
                                    betting_type: 1,
                                    selected_odd: {
                                      ...odd,
                                      team_name: getTeamName(odd)
                                    }
                                  })]
                                }
                              {...{
                                key,
                                className:
                                  `odd-trigger-inner back vslign ${isDisabled(item) ? 'disabled' : ''} ${_oddsActiveClass(odd.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: odd.selection_id })}`
                              }}>
                              <div className='price'>{`${odd.handicap ? `${odd.handicap}` : ''} (${odd.odds})`} </div>
                            </div>
                          }
                        })
                      }
                    </div>
                  </div>
                </div>

              </div>
              <div className="last-cell" >
                <a onClick={() => fixtureSelectHandler(item)}>+{item.total_markets} <span className='mrkt-lbl'>Markets</span></a>
              </div>

            </div>
            :
            // <React.Fragment>
            //   <div className="event-details" onClick={() => fixtureSelectHandler(item)}>
            //     <div className="small">{item.league_name}</div>
            //     <BetTitle {...JSON.parse(item.runners)} />
            //     <div className="timestamp">
            //       {
            //         item.is_live == 1 ?
            //         <>
            //         {/* {item.scores !== '' && <>
            //         <span className='score-value'>{JSON.parse(item.scores).home}</span>
            //         <span className='score-value'> - </span>
            //         <span className='score-value'>{JSON.parse(item.scores).away}</span>
            //         </>} */
            //         // <span className="is-live-lbl">In-Play</span>
            //         }
            //         </>
                       
            //           :
            //           <Fragment>
            //             <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
            //           </Fragment>
            //       }
            //     </div>
            //   </div>
            //   <div className="cell">
            //     {
            //       item.is_suspended == '1' ?
            //         <Fragment>
            //           <div className="game-status-suspended" onClick={() => fixtureSelectHandler(item)}><span>{t('SUSPENDED')}</span></div>
            //         </Fragment>
            //         :
            //         <Fragment>

            //           <div className="row-cell heading">

            //             <div {...{ className: `market-label` }}>1</div>
            //             {
            //               SelectedSports.sports_id != 3 &&
            //               <div {...{ className: `market-label` }}>X</div>
            //             }
            //             <div {...{ className: `market-label` }}>2</div>


            //           </div>
            //           <div className="row-cell">
            //             <div {...{ className: `odd-trigger ${SelectedSports.sports_id == 8 ? 'basketball_odds' : ''}` }}>
            //               {
            //                 _.map(JSON.parse(item.runners_odds), (odd, key) => {

            //                   return (
            //                     <Fragment {...{ key }}>
            //                       {
            //                         (key == 1 && SelectedSports.sports_id != 3 && JSON.parse(item.runners_odds).length == 2) &&
            //                         <div className="odd-trigger-inner back vslign disabled">
            //                           <div className='price'>--</div>
            //                         </div>
            //                       }
            //                       <div
            //                         onClick={
            //                           isDisabled(item) ?
            //                             null :
            //                             () => handleOptionChange({
            //                               fixture: { ...item, sports_id: SelectedSports.sports_id },
            //                               odd: { ...odd, price: odd.odds, market_bet_id: odd.selection_id, selectionId: odd.selection_id },
            //                               betting_type: 1,
            //                               selected_odd: {
            //                                 ...odd,
            //                                 team_name: getTeamName(odd)
            //                               },
            //                               //multiBet:true
            //                             })}
            //                         {...{
            //                           key,
            //                           className:
            //                             `odd-trigger-inner back vslign ${isDisabled(item) ? 'disabled' : ''} ${_oddsActiveClass(odd.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: odd.selection_id })}`
            //                         }}>
            //                         <div className='price'>{odd.odds || '--'}</div>
            //                       </div>
            //                     </Fragment>

            //                   )
            //                 })
            //               }
            //             </div>
            //           </div>
            //         </Fragment>
            //     }
            //   </div>
            //   <div className="last-cell" onClick={() => fixtureSelectHandler(item)}>
            //     <a>+{item.total_markets} <span className='mrkt-lbl'>Markets</span></a>
            //   </div>
            // </React.Fragment>
            // <React.Fragment>
            //   <div className="event-details" onClick={() => fixtureSelectHandler(item)}>
            //     <div className="small">{item.league_name}</div>
            //     <BetTitle {...JSON.parse(item.runners)} />
            //     <div className="timestamp">
            //       {
            //         item.is_live == 1 ?
            //         <>
            //          {item.scores !== '' && <>
            //         <span className='score-value'>{JSON.parse(item.scores).home}</span>
            //         <span className='score-value'> - </span>
            //         <span className='score-value'>{JSON.parse(item.scores).away}</span>
            //         </>
            //         // <span className="is-live-lbl">In-Play</span>
            //         }
            //         </>
                       
            //           :
            //           <Fragment>
            //             <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
            //           </Fragment>
            //       }
            //     </div>
            //   </div>
            //   <div className="cell">
            //     {
            //       item.is_suspended == '1' ?
            //         <Fragment>
            //           <div className="game-status-suspended" onClick={() => fixtureSelectHandler(item)}><span>{t('SUSPENDED')}</span></div>
            //         </Fragment>
            //         :
            //         <Fragment>

            //           <div className="row-cell heading">

            //             <div {...{ className: `market-label` }}>1</div>
            //             {
            //               SelectedSports.sports_id != 3 &&
            //               <div {...{ className: `market-label` }}>X</div>
            //             }
            //             <div {...{ className: `market-label` }}>2</div>


            //           </div>
            //           <div className="row-cell">
            //             <div {...{ className: `odd-trigger ${SelectedSports.sports_id == 8 ? 'basketball_odds' : ''}` }}>
            //               {
            //                 _.map(JSON.parse(item.runners_odds), (odd, key) => {

            //                   return (
            //                     <Fragment {...{ key }}>
            //                       {
            //                         (key == 1 && SelectedSports.sports_id != 3 && JSON.parse(item.runners_odds).length == 2) &&
            //                         <div className="odd-trigger-inner back vslign disabled">
            //                           <div className='price'>--</div>
            //                         </div>
            //                       }
            //                       <div
            //                         onClick={
            //                           isDisabled(item) ?
            //                             null :
            //                             () => handleOptionChange({
            //                               fixture: { ...item, sports_id: SelectedSports.sports_id },
            //                               odd: { ...odd, price: odd.odds, market_bet_id: odd.selection_id, selectionId: odd.selection_id },
            //                               betting_type: 1,
            //                               selected_odd: {
            //                                 ...odd,
            //                                 team_name: getTeamName(odd)
            //                               }
            //                             })}
            //                         {...{
            //                           key,
            //                           className:
            //                             `odd-trigger-inner back vslign ${isDisabled(item) ? 'disabled' : ''} ${_oddsActiveClass(odd.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: odd.selection_id })}`
            //                         }}>
            //                         <div className='price'>{odd.odds || '--'}</div>
            //                       </div>
            //                     </Fragment>

            //                   )
            //                 })
            //               }
            //             </div>
            //           </div>
            //         </Fragment>
            //     }
            //   </div>
            //   <div className="last-cell" onClick={() => fixtureSelectHandler(item)}>
            //     <a>+{item.total_markets} <span className='mrkt-lbl'>Markets</span></a>
            //   </div>
            // </React.Fragment>
            <React.Fragment>
              <div className="matches-container" onClick={() => fixtureSelectHandler(item)}
              //onClick={() => fixtureSelectHandler(item)}
              >
                {isAll && <>
                {item.is_live == 1 ?  <div className="live-text-container"><span>Live</span></div> : <div className="upcoming-text-container"><span>Upcoming</span></div>}
                  
                  <div className="game-container">{SelectedSports.sports_name}</div>
                </>}
                <div className={`league-view-text ${!isAll ? 'head-view' : ''}`}>
                  <div>
                    <i className={`icon-${SelectedSports.sports_id}`}></i>
                    
                    
                  {/* <img alt="" src={Images.ENGLAND_LOGO} /> */}
                  <span> {item.league_name}</span>
                  </div>
                  
                  {
                  //isFav && 
                  <i className={`heart-img ${item.is_favourite == 1 ? 'icon-heart active' : 'icon-heart' }`} onClick={(e)=>toggleFav(e,item)}/> 
                }
                </div>
                <BetTitleNew {...JSON.parse(item.runners)} />
               
                <div className="cell">
              {
                item.is_suspended == '1' ?
                  <Fragment>
                    <div className="game-status-suspended" onClick={() => fixtureSelectHandler(item)}><span>{t('SUSPENDED')}</span></div>
                  </Fragment>
                  :
                  <Fragment>

                    <div className="row-cell heading">
                      {
                         _.map(JSON.parse(item.runners_odds), (obj, key) => {
                            return(
                              <div key={obj.selection_id} {...{ className: `market-label` }}>{obj.name}</div>
                            )
                         })
                      }

                      {/* <div {...{ className: `market-label` }}>1</div>
                      {
                        SelectedSports.sports_id != 3 &&
                        <div {...{ className: `market-label` }}>X</div>
                      }
                      <div {...{ className: `market-label` }}>2</div> */}


                    </div>
                    <div className="row-cell">
                      <div {...{ className: `odd-trigger ${SelectedSports.sports_id == 8 ? 'basketball_odds' : ''}` }}>
                        {
                          _.map(JSON.parse(item.runners_odds), (odd, key) => {
                            return (
                              <Fragment key={odd.selection_id}>
                                {
                                  (key == 1 && SelectedSports.sports_id != 3 && JSON.parse(item.runners_odds).length == 2) &&
                                  <div className="odd-trigger-inner back vslign disabled">
                                    <div className='price'>--</div>
                                  </div>
                                }

                                {
                                  odd.lock_betting == '1' ? 
                                  <div className="odd-trigger-inner back vslign disabled">
                                    <div className='price'>SUSPENDED</div>
                                  </div>
                                  :

                                <div
                                  onClick={
                                    isDisabled(item) ?
                                      null :
                                      (e) => [handleOptionChange(e,{
                                        fixture: { ...item, sports_id: SelectedSports.sports_id },
                                        odd: { ...odd, price: odd.odds, market_bet_id: odd.selection_id, selectionId: odd.selection_id },
                                        betting_type: 1,
                                        selected_odd: {
                                          ...odd,
                                          team_name: getTeamName(odd)
                                        },
                                        //multiBet:true
                                      })]}
                                      onTouchStart={(e) => {
                                        handleOptionChange(e,{
                                          fixture: { ...item, sports_id: SelectedSports.sports_id },
                                          odd: { ...odd, price: odd.odds, market_bet_id: odd.selection_id, selectionId: odd.selection_id },
                                          betting_type: 1,
                                          selected_odd: {
                                            ...odd,
                                            team_name: getTeamName(odd)
                                          },
                                          //multiBet:true
                                        })
                                      }}
                                  {...{
                                    key,
                                    className:
                                      `odd-trigger-inner back vslign ${isDisabled(item) ? 'disabled' : ''} ${_oddsActiveClass(odd.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: odd.selection_id })}`
                                  }}>
                                  <div className='price'>{odd.odds || '--'}</div>
                                </div>
                          }
                              </Fragment>

                            )
                          })
                        }
                      </div>
                    </div>
                  </Fragment>
              }
            </div>
              </div>
              <div className="last-cell">
                <a onTouchStart={(e) => {fixtureSelectHandler(item,e)}}
              onClick={(e) => fixtureSelectHandler(item,e)}>+{item.total_markets} <span className='mrkt-lbl'>Markets</span></a>
              </div>
            </React.Fragment>
        }




      </div>
    );
  }
}
SportsBookBetItem.defaultProps = {
  item: {},
  SocketHighlight: () => { }
}
export default SportsBookBetItem;
