import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withTranslation } from 'react-i18next';

import { Http, API, Utils, _ } from 'Helpers';
import { CONSTANT } from 'Constants';
import { MessageBox } from 'Components';

var globalThis = null;

const NotificationList = ({ notificationItem }) => {
  
  return <div className={"list-card"}>
      <p dangerouslySetInnerHTML={{ __html: globalThis.renderTagMessage(notificationItem) || '--' }}></p>
  </div>
}


class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NotificationListData: [],
      hasMore: false,
      posting: false,
      Offset: 1,
    }
  }

  
  // LIST API
  getNotificationList = () => {
    const { Offset, NotificationListData } = this.state
    let param = {
      "page_size": CONSTANT.ITEMS_PERPAGE,
      "page_no": Offset,
    }

    this.setState({ posting: true })
    Http.post(API.GET_NOTIFICATION, param).then(response => {
      this.setState({
        posting: false,
        NotificationListData: (Offset === CONSTANT.CURRENT_OFFSET ? response.data : [...NotificationListData, ...response.data]),
        hasMore: !_.isEmpty(response.data)
      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

   getNotificationCount = () => {
    
    let param = {
      
    }

    this.setState({ posting: true })
    Http.post(API.GET_UNREAD_NOTIFICATION, param).then(response => {
      this.setState({
        posting: false,
        unreadCount:response.data
        
      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }


 //Handlers

 renderTagMessage = rowData => {
  //var msg = rowData.message || '';
  var content = rowData.content;
  _.map(Object.keys(content), (key, idx) => {
    //  msg = msg.replace("{{" + key + "}}", '<span class="highlighted-text">' + content[key] + '</span>');
  });
  // msg
  return content.message;
};

  fetchMoreData = () => {
    let offset = this.state.Offset + 1;
    this.setState({ Offset: offset }, () => {
      this.getNotificationList()
    });
  }

  //LIFE CYLCE METHODS
  componentDidMount() {
    globalThis = this;
    this.getNotificationList()
    this.getNotificationCount()
  }

  render() {
    const {t} = this.props
    const { NotificationListData, hasMore } = this.state
    return (
      <Container>
        <Row>
          <Col>
            <div className="odds-padding-row heading">
              <h4 className="title">{t('Notification')}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="notification-container">
            {!_.isEmpty(NotificationListData) ? <InfiniteScroll
                dataLength={NotificationListData.length}
                next={this.fetchMoreData}
                hasMore={hasMore}
                hasChildren={false}
                className=''
              >
                {_.map(NotificationListData, (item, index) => {
                  return (
                    <div className="notification-row" key={index}>
                      <div className="notification-item p-r">
                        <p>{Utils.dateTimeFormat(item.added_date,  'DD/MM/YYYY,')}</p>
                        <p>{Utils.dateTimeFormat(item.added_date, ' h:mm A')}</p>
  
                      </div>
                      <div className="notification-item">
                          <NotificationList notificationItem={item}  notificationKey={index}/>
                      </div>
                    </div>
                  )
                })}

              </InfiniteScroll> :
              <MessageBox paragraph={CONSTANT.BLANK_HISTORY} />
                }
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withTranslation()(Notification);
