import React, { Component } from "react";
import { withTranslation } from "react-i18next";
//import { Images } from 'Constants';
import { Utils, _, Http, API, Socket } from "Helpers";
import { CONSTANT, SocketEvent } from "Constants";

import { connect } from "react-redux";
import { SlipToggle } from "../../Redux/Reducers";

import { TabPane, Nav, NavItem, NavLink, TabContent } from "reactstrap";
//TabContent
import { SportsBookBetItem, BetslipDrawer } from "Components";
import InfiniteScroll from "react-infinite-scroll-component";
import SwipeableViews from "react-swipeable-views";
// Game type = 1 => Live
// Game type = 0 => Upcoming
// Game type = 3 => Fav

class AllMatches extends Component {
  constructor(props) {
    super(props);
    const { page_id } = props;
    this.state = {
      NavigationList: [],
      SelectedSports: localStorage.getItem("selectedSports")
        ? JSON.parse(localStorage.getItem("selectedSports"))
        : "",
      is_inplay: false,
      currentActiveTab: "1",
      Offset: CONSTANT.CURRENT_OFFSET,
      FixturePosting: false,
      //fixture_lastupdate: new Date().valueOf(),
      hasMore: false,
      hasMoreUp: false,
      hasMoreFav: false,
      FixtureList: [],
      recently_changed: [],
      SelectedOdds: Utils.getBetSlip(page_id),
      SelectedOddsFancy: Utils.getBetSlip(CONSTANT.FANCY_BETTING),
      betslip_lastupdate: new Date().valueOf(),
      fancy_betslip: "",
      isScrolling: false,
      FixtureListLive: [],
      FixtureListUpcoming: [],
      FixtureListFav: [],
    };

    this.scrollTimeout = null;
  }
  onTabClick = (tab) => {
    this.setState(
      {
        currentActiveTab: tab,
        // FixtureList: [],

        Offset: CONSTANT.CURRENT_OFFSET,
      },
      () => {
        this.GetFixtures();
      }
    );
  };

  handleSwipeChangeIndex = (index) => {
    this.setState(
      {
        currentActiveTab: index.toString(),
        Offset: CONSTANT.CURRENT_OFFSET,
      },
      () => {
        this.GetFixtures();
      }
    );
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const { location } = this.props;
    this.setState({
      is_inplay: _.includes(location.pathname, "in-play"),
    });
    this.pageInit(
      _.isUndefined(location.state) || _.isNull(location.state)
        ? {}
        : location.state
    );
    if (this.props.location.search) {
      const { location } = this.props;
      const searchParams = new URLSearchParams(location.search);
      this.setState(
        {
          currentActiveTab: searchParams.get("tab"),
        },
        () => {
          this.GetFixtures();
        }
      );
    }
    //this.GetFixtures()
  }

  handleScroll = () => {
    // Clear the previous timeout to prevent premature class removal
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    // Set a new timeout for removing the class after 200ms (adjust this as needed)
    this.scrollTimeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 100);

    // Update the state to add the class
    this.setState({
      isScrolling: true,
    });
  };

  pageInit = ({ SelectedLeague = {}, SelectedSports = {}, unbind }) => {
    let { page_id } = this.props;
    page_id = "sportbook_betting";
    const { is_inplay } = this.state;
    this.setState(
      {
        AppMasterData: Utils.getMasterData(),
        NavigationList: is_inplay
          ? [...Utils.getMasterData().sports_list[page_id]]
          : Utils.getMasterData().sports_list[page_id],
      },
      () => {
        const { NavigationList, AppMasterData } = this.state;
        const { location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        if (unbind) {
          this.setState(
            {
              SelectedSports: SelectedSports,
              SelectedLeague: SelectedLeague,
            },
            () => [this.GetFixtures()]
          );
        } else {
          if (
            _.isEmpty(SelectedLeague) &&
            !localStorage.getItem("selectedSports") &&
            _.isEmpty(SelectedSports)
          ) {
            _.forEach(NavigationList, (item) => {
              if (
                item.sports_id ==
                (searchParams.get("sports")
                  ? searchParams.get("sports")
                  : AppMasterData.default_sport)
              ) {
                this.setState(
                  {
                    SelectedSports: item,
                  },
                  () => {
                    if (!localStorage.getItem("selectedSports")) {
                      localStorage.setItem(
                        "selectedSports",
                        JSON.stringify(item)
                      );
                    }
                  }
                );
              }
            });
          }
          this.GetFixtures();
        }
      }
    );
  };

  GetFixtures = () => {
    const {
      SelectedSports,
      Offset,
      FixtureList,
      currentActiveTab,
      FixtureListUpcoming,
      FixtureListFav,
    } = this.state;

    let param = {
      sports_id: SelectedSports.sports_id,
      // "league_uid":  SelectedLeague.league_uid,
      limit: CONSTANT.ITEMS_PERPAGE_SM,
      offset: Offset,
      league_uid: "",
      game_type:
        currentActiveTab == "1"
          ? "0"
          : currentActiveTab == "0"
          ? "1"
          : currentActiveTab == "2"
          ? "3"
          : "",
    };

    this.setState({ FixturePosting: true });
    Http.post(API.GET_UPCOMING_FIXTURES_SP, param)
      .then((response) => {
        this.setState(
          {
            FixturePosting: false,
          },
          () => {
            // console.log(!(!_.isEmpty(response.data.matches) ||
            // _.size(response.data.matches) === CONSTANT.ITEMS_PERPAGE_SM),"Radheeeeeeeeeeeeeeee");
            if (currentActiveTab == "0") {
              this.setState({
                FixtureList:
                  Offset == CONSTANT.CURRENT_OFFSET
                    ? response.data.matches
                    : [...FixtureList, ...response.data.matches],
                    hasMore:
              (!_.isEmpty(response.data.matches) ||
              _.size(response.data.matches) === CONSTANT.ITEMS_PERPAGE_SM),
              Offset:response.data.offset
              },()=>{
                setTimeout(() => {
                  this.setState({
                    hasMore:false
                  })
                }, 100);
              });
            } else if (currentActiveTab == "1") {
              this.setState({
                FixtureListUpcoming:
                  Offset == CONSTANT.CURRENT_OFFSET
                    ? response.data.matches
                    : [...FixtureListUpcoming, ...response.data.matches],
                    hasMoreUp:
              (!_.isEmpty(response.data.matches) ||
              _.size(response.data.matches) === CONSTANT.ITEMS_PERPAGE_SM),
              Offset:response.data.offset
              },()=>{
                setTimeout(() => {
                  this.setState({
                   hasMoreUp:false
                  })
                }, 100);
              });
            } else {
              this.setState({
                FixtureListFav:
                  Offset == CONSTANT.CURRENT_OFFSET
                    ? response.data.matches
                    : [...FixtureListFav, ...response.data.matches],
                    hasMoreFav:
              (!_.isEmpty(response.data.matches) ||
              _.size(response.data.matches) === CONSTANT.ITEMS_PERPAGE_SM),
              Offset:response.data.offset
              },()=>{
                setTimeout(() => {
                  this.setState({
                   hasMoreFav:false
                  })
                }, 100);
              });
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ FixturePosting: false });
      });
  };

  // Handlers
  InitListToggle = (bool) => {
    this.setState({
      InitList: bool,
    });
  };

  handleSelectSport = (option) => {
    this.setState(
      {
        FixtureList: [],
        FixtureListLive: [],
        FixtureListUpcoming: [],
        FixtureListFav: [],
        Offset: CONSTANT.CURRENT_OFFSET,
        SelectedSports: option.Sport,
        SelectedLeague: option.League,
      },
      () => {
        localStorage.setItem("selectedSports", JSON.stringify(option.Sport));
        this.GetFixtures();
      }
    );
  };

  fetchMoreData() {
    if (!this.state.FixturePosting) {
      let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
      this.setState({ Offset: offset }, () => {
        this.GetFixtures();
      });
    }
  }

  // for Exchange/Sportsbook betslip
  // *******************************
  _oddsActiveClass = (market_bet_id, market_event_id, betting_type) => {
    let { SelectedOdds } = this.state;
    return _.some(SelectedOdds, (odd) => {
      return (
        odd.picked_odd_id ==
        market_bet_id + "_" + market_event_id + "_" + betting_type
      );
    });
  };

  handleOptionChange = (e, option) => {
    if (this.state.isScrolling) {
      return; // Prevent triggering during scroll
    }  else {
    e.stopPropagation();
    const { page_id } = this.props;
    let { SelectedOdds } = this.state;
    const { fixture, odd, betting_type, selected_odd } = option;

    if (fixture.sports_id == "8") {
      selected_odd["name"] =
        selected_odd.name != "Spread" && selected_odd.name != "Total"
          ? "Money Line"
          : selected_odd.name;
    }
    if (option.multiBet) {
      let selected_odd_name = CONSTANT.ONLY_SINGLE_BET
        ? "0"
        : [fixture.event_id, odd.market_bet_id, betting_type].join("_");

      //SelectedOdds.bookmakerHandle = bookmakerHandle

      delete odd.ex;
      SelectedOdds[selected_odd_name] = {
        picked_odd_id: odd.market_bet_id + "_" + betting_type,
        market_bet_id: odd.market_bet_id,
        picked_odd_price: betting_type == 1 ? odd.b1 : odd.lay_price,
        picked_odd: { ...odd, betting_type, ...selected_odd },
        stack_value: "",
        ...fixture,
      };

      this.setState(
        {
          SelectedOdds,
          fancy_betslip: "",
          betslip_lastupdate: new Date().valueOf(),
        },
        () => {
          Utils.setBetSlip(CONSTANT.BETSLIP[page_id], this.state.SelectedOdds);
        }
      );

      const { openCollapse, SlipToggle } = this.props;

      if (openCollapse != 1) {
        SlipToggle();
      } else {
        SlipToggle(1);
      }
    } else {
      let selected_odd_name = CONSTANT.ONLY_SINGLE_BET
        ? "0"
        : [
            fixture.event_id,
            fixture.market_event_id,
            odd.market_bet_id,
            betting_type,
          ].join("_");

      if (
        this._oddsActiveClass(
          odd.market_bet_id,
          fixture.market_event_id,
          betting_type
        )
      ) {
        SelectedOdds = _.omit(SelectedOdds, [selected_odd_name]);
        this.setState(
          {
            SelectedOdds,
            betslip_lastupdate: new Date().valueOf(),
          },
          () => {
            Utils.setBetSlip(
              CONSTANT.BETSLIP[page_id],
              this.state.SelectedOdds
            );
          }
        );
      } else {
        delete odd.ex;
        SelectedOdds[selected_odd_name] = {
          picked_odd_id:
            odd.market_bet_id +
            "_" +
            fixture.market_event_id +
            "_" +
            betting_type,
          market_bet_id: odd.market_bet_id,
          picked_odd_price: betting_type == 1 ? odd.price : odd.lay_price,
          picked_odd: { ...odd, betting_type, ...selected_odd },
          stack_value: "",
          ...fixture,
        };
        this.setState(
          {
            SelectedOdds,
            fancy_betslip: "",
            betslip_lastupdate: new Date().valueOf(),
          },
          () => {
            Utils.setBetSlip(
              CONSTANT.BETSLIP[page_id],
              this.state.SelectedOdds
            );
          }
        );
      }
    }

    const { openCollapse, SlipToggle } = this.props;
    if (openCollapse != 1) {
      SlipToggle(1);
    }
  }
  };

  fixtureSelectHandler = (item = {}) => {
    if (this.state.isScrolling) {
      return; // Prevent triggering during scroll
    }  else{
    const { match } = this.props;
    const { is_inplay, currentActiveTab } = this.state;
    this.props.history.push({
      pathname: is_inplay
        ? `${match.url}/in-play/details/${item.event_id}`
        : `sportsbook/details/${item.event_id}`,
      search: `?sports=${item.sports_id}&market=${item.market_event_id}&tab=${currentActiveTab}`,
    });
  }
  };

  reinitiateComponent = (sports_id = "", is_bypass = false) => {
    const { match } = this.props;
    if (is_bypass) {
      this.props.history.push({
        pathname: `${match.url}/in-play`,
      });
    } else {
      this.props.history.push({
        pathname: `${match.url}`,
        ...(sports_id != "" && { search: `?sports=${sports_id}` }),
      });
    }
    this.setState({
      SelectedFixture: {},
    });
  };

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state;
    const formattedArr = recently_changed.map((str) => str.replace(/"/g, ""));
    let _id = _.isString(id) ? id : JSON.stringify(id);
    if (_.includes(formattedArr, _id)) {
      this.cleanSocketHighlight();
      return " blink ";
    } else return "";
  };

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] });
      }
    }, timer);
  };

  handleRemoveSlip = (key) => {
    const { page_id } = this.props;
    let SelectedOdds = Utils.getBetSlip(page_id);
    let newSelectedOdds = _.omit(SelectedOdds, key);
    this.setState(
      {
        SelectedOdds: newSelectedOdds,
        betslip_lastupdate: new Date().valueOf(),
      },
      () => {
        Utils.setBetSlip(CONSTANT.BETSLIP[page_id], this.state.SelectedOdds);
      }
    );
  };

  clearAllBet = (BetslipType) => {
    const { page_id } = this.props;
    this.setState(
      {
        ...(page_id == BetslipType && { SelectedOdds: {} }),
        ...(CONSTANT.FANCY_BETTING == BetslipType && { SelectedOddsFancy: {} }),
        betslip_lastupdate: new Date().valueOf(),
      },
      () => {
        Utils.removeBetSlip(CONSTANT.BETSLIP[BetslipType]);
        Utils.removeMultiBetSlip(CONSTANT.BETSLIP[BetslipType]);
      }
    );
  };

  toggleFav = (e, item) => {
    e.stopPropagation();
    const {
      FixtureList,
      currentActiveTab,
      FixtureListUpcoming,
      FixtureListFav,
    } = this.state;

    if (currentActiveTab == "1" || currentActiveTab == "0") {
      if (currentActiveTab == "1") {
        const itemIndex = FixtureListUpcoming.findIndex(
          (sitem) => sitem.id === item.id
        );
        FixtureListUpcoming[itemIndex].is_favourite =
          item.is_favourite == "0" ? "1" : "0";
        console.log("Item updated successfully!");
        this.setState({ FixtureListUpcoming: FixtureListUpcoming }, () => {
          this.UpdateFavouriteList(item);
        });
      } else {
        const itemIndex = FixtureList.findIndex(
          (sitem) => sitem.id === item.id
        );
        FixtureList[itemIndex].is_favourite =
          item.is_favourite == "0" ? "1" : "0";
        console.log("Item updated successfully!");
        this.setState({ FixtureList: FixtureList }, () => {
          this.UpdateFavouriteList(item);
        });
      }
    } else {
      const updatedMatches = FixtureListFav.filter(
        (match) => match.id !== item.id
      );
      //console.log(updatedMatches, "updatedMatches");
      this.UpdateFavouriteList(item);
      this.setState({ FixtureListFav: updatedMatches }, () => {
        //console.log(this.state.FixtureListFav, "FixtureListFav");
      });
    }
  };

  UpdateFavouriteList = (item) => {
    const { SelectedSports, currentActiveTab } = this.state;
    let param = {
      ls_event_id: item.id,
      sports_id: SelectedSports.sports_id,
      choice_type: "1",
      status:
        currentActiveTab == "1" || currentActiveTab == "0"
          ? item.is_favourite
          : item.is_favourite == "0"
          ? "1"
          : "0",
    };
    this.setState({ posting: true });
    Http.post(API.FIXTURE_FAVOURITE_CHOICE, param).then((response) => {
      Utils.notify(response.message);
      this.setState({});
    });
  };

  // Soket event handles
  sockeListener = () => {
    const _this = this;
    Socket.on(SocketEvent.MARKET_SP, function (res) {
      if (!_.isEmpty(res)) {
          //console.log(res[0], "radheeeeeeee");
        _this.SocketMarkethandlerLive(res[0]);
        _this.SocketMarkethandlerUpComing(res[0]);
        _this.SocketMarkethandlerFav(res[0]);
      }
    });
    Socket.on(SocketEvent.STATUS_UPDATED_MK, function (res) {
      if (!_.isEmpty(res)) {
        //  console.log(res, "radheeeeeeee STATUS_UPDATED_MK");
        if (_this.state.currentActiveTab == "0") {
          let newArray = [..._this.state.FixtureList];
          _.map(newArray, (it, ix) => {
            let newData = JSON.parse(it.runners_odds);
            _.map(newData, (obj, key) => {
              if (Number(obj.selection_id) == Number(res.selection_id)) {
                obj.lock_betting = res.lock_betting;
              }
            });
            newArray[ix].runners_odds = JSON.stringify(newData);
          });
          _this.setState({ FixtureList: newArray }, () => {});
        } else if (_this.state.currentActiveTab == "1") {
          let newArray = [..._this.state.FixtureListUpcoming];
          _.map(newArray, (it, ix) => {
            let newData = JSON.parse(it.runners_odds);
            _.map(newData, (obj, key) => {
              if (Number(obj.selection_id) == Number(res.selection_id)) {
                obj.lock_betting = res.lock_betting;
              }
            });
            newArray[ix].runners_odds = JSON.stringify(newData);
          });
          _this.setState({ FixtureListUpcoming: newArray }, () => {});
        } else {
          let newArray = [..._this.state.FixtureListFav];
          _.map(newArray, (it, ix) => {
            let newData = JSON.parse(it.runners_odds);
            _.map(newData, (obj, key) => {
              if (Number(obj.selection_id) == Number(res.selection_id)) {
                obj.lock_betting = res.lock_betting;
              }
            });
            newArray[ix].runners_odds = JSON.stringify(newData);
          });
          _this.setState({ FixtureListFav: newArray }, () => {});
        }
      }
    });
  };

  SocketMarkethandlerLive = (res = {}) => {
    const { FixtureList, recently_changed } = this.state;
    let newFixtureList = _.map(_.cloneDeep(FixtureList), (item) => {
      if (item.market_event_id == res.market_event_id) {
        let ex_obj = {};
        let new_recently_changed = recently_changed;
        let item_runners_odds = item.runners_odds
          ? JSON.parse(item.runners_odds)
          : {};

        _.forEach(JSON.parse(res.runners_odds), (obj, idx) => {
          if (
            item_runners_odds[idx] != undefined &&
            item_runners_odds[idx].selection_id == obj.selection_id
          ) {
            new_recently_changed = [
              ...new_recently_changed,
              JSON.stringify(obj.selection_id),
            ];
            ex_obj = { ...res };
            this.setState({
              isChanged: true,
              recently_changed: new_recently_changed,
            });
          }
        });
        return {
          ...item,
          ...ex_obj,
        };
      }
      return item;
    });

    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ FixtureList: newFixtureList }, () => {
        this.setState({ isChanged: false });
      });
    }
  };

  SocketMarkethandlerUpComing = (res = {}) => {
    const { FixtureListUpcoming, recently_changed } = this.state;
    let newFixtureListUpcoming = _.map(
      _.cloneDeep(FixtureListUpcoming),
      (item) => {
        if (item.market_event_id == res.market_event_id) {
          let ex_obj = {};
          let new_recently_changed = recently_changed;
          let item_runners_odds = item.runners_odds
            ? JSON.parse(item.runners_odds)
            : {};

          _.forEach(JSON.parse(res.runners_odds), (obj, idx) => {
            if (
              item_runners_odds[idx] != undefined &&
              item_runners_odds[idx].selection_id == obj.selection_id
            ) {
              new_recently_changed = [
                ...new_recently_changed,
                JSON.stringify(obj.selection_id),
              ];
              ex_obj = { ...res };
              this.setState({
                isChanged: true,
                recently_changed: new_recently_changed,
              });
            }
          });
          return {
            ...item,
            ...ex_obj,
          };
        }
        return item;
      }
    );

    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ FixtureListUpcoming: newFixtureListUpcoming }, () => {
        this.setState({ isChanged: false });
      });
    }
  };

  SocketMarkethandlerFav = (res = {}) => {
    const { FixtureListFav, recently_changed } = this.state;
    let newFixtureListFav = _.map(_.cloneDeep(FixtureListFav), (item) => {
      if (item.market_event_id == res.market_event_id) {
        let ex_obj = {};
        let new_recently_changed = recently_changed;
        let item_runners_odds = item.runners_odds
          ? JSON.parse(item.runners_odds)
          : {};

        _.forEach(JSON.parse(res.runners_odds), (obj, idx) => {
          if (
            item_runners_odds[idx] != undefined &&
            item_runners_odds[idx].selection_id == obj.selection_id
          ) {
            new_recently_changed = [
              ...new_recently_changed,
              JSON.stringify(obj.selection_id),
            ];
            ex_obj = { ...res };
            this.setState({
              isChanged: true,
              recently_changed: new_recently_changed,
            });
          }
        });
        return {
          ...item,
          ...ex_obj,
        };
      }
      return item;
    });

    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ FixtureListFav: newFixtureListFav }, () => {
        this.setState({ isChanged: false });
      });
    }
  };

  removeSockeListener = () => {
    Socket.off(SocketEvent.MARKET);
    Socket.off(SocketEvent.STATUS_UPDATED_MK);
    // this.setState = () => {
    //   return;
    // };
  };

  sockeListenerScore = () => {
    const _this = this;
    Socket.on(SocketEvent.SCORE_UPDATED_SP, function (res) {
      if (!_.isEmpty(res)) {
       // console.log(res,"resres");
         _this.UpdateMarketScore(res);
      }
    });
  };

  UpdateMarketScore = (res) => {
    const { FixtureList,currentActiveTab,FixtureListUpcoming,FixtureListFav } = this.state;
    

    if (currentActiveTab == "0") {
      const updatedMatches = FixtureList.map((match) => {
        if (match.event_id == res.event_id) {
          match.scores = res.scores;
          match.ss = res.ss;
        }
        return match;
      });
      this.setState({
        FixtureList:updatedMatches
      });
    } else if (currentActiveTab == "1") {
      const updatedMatches = FixtureListUpcoming.map((match) => {
        if (match.event_id == res.event_id) {
          match.scores = res.scores;
          match.ss = res.ss;
        }
        return match;
      });
      this.setState({
        FixtureListUpcoming:updatedMatches
      });
    } else {
      const updatedMatches = FixtureListFav.map((match) => {
        if (match.event_id == res.event_id) {
          match.scores = res.scores;
          match.ss = res.ss;
        }
        return match;
      });
      this.setState({
        FixtureListFav:updatedMatches
      });
    }
  };

  removesockeListenerScore = () => {
    Socket.off(SocketEvent.SCORE_UPDATED_SP);
  };

  render() {
    // const BetTitleNew = ({ home, away }, { item }) => {
    //   console.log("first", item)
    //   return (
    //     <div className="team-details-view">
    //       <div className="right-view">
    //         <img className='img-view' alt="" src={Images.ENGLAND_LOGO} />
    //         <div className="team-name">{home.name}</div>
    //         <div className="fisrt-line-text">2’ David Alaba
    //           28’ Kingsley Coman</div>
    //       </div>
    //       <div className="middle-view">
    //         2 - 1
    //         {/* {
    //           item.is_live == 1 ?
    //             <span className="is-live-lbl">In-Play</span>
    //             :
    //             <Fragment>
    //               <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
    //             </Fragment>
    //         } */}
    //       </div>
    //       <div className="left-view">
    //         <img alt="" className='img-view' src={Images.ENGLAND_LOGO} />
    //         <div className="team-name">{away.name}</div>
    //         <div className="fisrt-line-text">19’ Adrien Rabiot</div>
    //       </div>
    //     </div>
    //   )
    // }
    const {
      NavigationList,
      page_id,
      SelectedSports,
      is_inplay,
      currentActiveTab,
      FixtureList,
      SelectedOdds,
      SelectedOddsFancy,
      betslip_lastupdate,
      fancy_betslip,
      hasMore,
      FixturePosting,
      isScrolling,
      FixtureListUpcoming,
      FixtureListFav,
      hasMoreUp,
      hasMoreFav
    } = this.state;
    const BetItemProps = {
      page_id: this.props.page_id,
      t: this.props.t,
      SocketHighlight: this.SocketHighlight,
      _oddsActiveClass: this._oddsActiveClass,
      handleOptionChange: this.handleOptionChange,
      fixtureSelectHandler:  this.fixtureSelectHandler,
      SelectedSports: SelectedSports,
      toggleFav: this.toggleFav,
      sockeListener: this.sockeListener,
      removeSockeListener: this.removeSockeListener,
      removesockeListenerScore: this.removesockeListenerScore,
      sockeListenerScore: this.sockeListenerScore,
    };

    const BetslipProps = {
      SelectedOdds,
      SelectedOddsFancy,
      betslip_lastupdate,
      page_id,
      fancy_betslip,
      // Methods
      handleRemoveSlip: this.handleRemoveSlip,
      clearAllBet: this.clearAllBet,
      // Fancy Methods
      _fancyHandleRemoveSlip: this._fancyHandleRemoveSlip,
    };

    const BetslipDrawerProps = {
      ...this.props,
      BetslipProps,
    };

    return (
      <div className={`all-matches-container ${isScrolling ? "cscroll-none" : ""}`}>
        {/* <div className="bet-history-heading">
          <div className="bet-history-view" onClick={()=>this.props.history.goBack()}>
            <img src={Images.BACK_IMG} alt="" />
            <i className='icon-previous' />
            <span> All Matches</span>
          </div >

        </div> */}
        {!is_inplay && (
          <div className="odds-padding-row">
            <div className="odds-filter-pills">
              {_.map(NavigationList, (item, key) => (
                <span
                  onClick={() =>
                    this.handleSelectSport({
                      Sport: item,
                      League: {},
                      unbind: true,
                    })
                  }
                  {...{
                    key,
                    className:
                      item.sports_id == SelectedSports.sports_id
                        ? "active"
                        : "",
                  }}
                >
                  {" "}
                  {item.sports_id != "" ? (
                    <i className={`icon-${item.sports_id}`} />
                  ) : null}{" "}
                  {item.sports_name}
                </span>
              ))}
            </div>
          </div>
        )}
        <div className="tabs-view-container">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={currentActiveTab == "0" ? "active" : ""}
                onClick={() => {
                  this.onTabClick("0");
                }}
              >
                Live
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={currentActiveTab == "1" ? "active" : ""}
                onClick={() => {
                  this.onTabClick("1");
                }}
              >
                upcoming
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={currentActiveTab == "2" ? "active" : ""}
                onClick={() => {
                  this.onTabClick("2");
                }}
              >
                Favorites
              </NavLink>
            </NavItem>
          </Nav>
          <SwipeableViews
            index={parseInt(currentActiveTab)}
            onChangeIndex={this.handleSwipeChangeIndex}
            key="Radhe"

          >
            <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="0">
              <div className="league-container">
                <InfiniteScroll
                  instanceKey="Live"
                  dataLength={FixtureList.length}
                  next={() => this.fetchMoreData()}
                  hasMore={hasMore}
                  loader={<h1 className="list-load">Loading...</h1>}
                  className="game-event-list"
                >
                  {FixtureList && FixtureList.length > 0 ? (
                    <>
                      {FixtureList.map((item, idx) => {
                        return (
                          <SportsBookBetItem
                            {...{ idx, item, ...BetItemProps, isAll: false }}
                            key={item.id}
                          />
                          // <div className="league-container-view" key={key}>
                          //   <div className="league-name-view">
                          //     <img src={Images.ENGLAND_LOGO} alt="" />
                          //     <span>{item.league_name}</span>
                          //   </div>
                          //   <BetTitleNew {...JSON.parse(item.runners)}  />
                          //   <div className="cell-view">
                          //     <div className="first-row-cell">
                          //       <div>1x</div>
                          //       <div>Draw</div>
                          //       <div>2x</div>
                          //     </div>
                          //     <div className="second-row-cell">
                          //       <div className='first-value'>2.51</div>
                          //       <div className='first-value'>5.12</div>
                          //       <div className='third-value'>3.02</div>
                          //     </div>
                          //   </div>
                          // </div>
                        );
                      })}
                    </>
                  ) : (
                    <h4 className="text-center n-data">
                      {FixturePosting ? "" : "No data"}
                    </h4>
                  )}
                </InfiniteScroll>
              </div>
            </TabPane>
            </TabContent>
            <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <div className="league-container">
                <InfiniteScroll
                  instanceKey="Upcoming"
                  dataLength={FixtureListUpcoming.length}
                  next={() => this.fetchMoreData()}
                  hasMore={hasMoreUp}
                  loader={<h1 className="list-load">Loading...</h1>}
                  className="game-event-list"
                >
                  {FixtureListUpcoming && FixtureListUpcoming.length > 0 ? (
                    <>
                      {FixtureListUpcoming.map((item, idx) => {
                        return (
                          <SportsBookBetItem
                            {...{ idx, item, ...BetItemProps, isAll: false }}
                            key={item.id}
                          />
                          // <div className="league-container-view" key={idx}>
                          //   <div className="league-name-view">
                          //     <img src={Images.ENGLAND_LOGO} alt="" />
                          //     <span>{item.league_name}</span>
                          //   </div>
                          //   <div className="team-details-view">
                          //     <div className="right-view">
                          //       <img className='img-view' alt="" src={Images.ENGLAND_LOGO} />
                          //       <div className="team-name">home</div>
                          //       <div className="fisrt-line-text">2’ David Alaba
                          //         28’ Kingsley Coman</div>
                          //     </div>
                          //     <div className="middle-view">
                          //       2 - 1
                          //     </div>
                          //     <div className="left-view">
                          //       <img alt="" className='img-view' src={Images.ENGLAND_LOGO} />
                          //       <div className="team-name">name</div>
                          //       <div className="fisrt-line-text">19’ Adrien Rabiot</div>
                          //     </div>
                          //   </div>
                          //   <div className="cell-view">
                          //     <div className="first-row-cell">
                          //       <div>1x</div>
                          //       <div>Draw</div>
                          //       <div>2x</div>
                          //     </div>
                          //     <div className="second-row-cell">
                          //       <div className='first-value'>2.51</div>
                          //       <div className='first-value'>5.12</div>
                          //       <div className='third-value'>3.02</div>
                          //     </div>
                          //   </div>
                          // </div>
                        );
                      })}
                    </>
                  ) : (
                    <h4 className="text-center n-data">
                      {FixturePosting ? "" : "No data"}
                    </h4>
                  )}
                </InfiniteScroll>
              </div>
            </TabPane>
            </TabContent>
            <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="2">
              {/* <div className="league-container">
                <div className="league-container-view">
                  <div className="league-name-view">
                    <img src={Images.ENGLAND_LOGO} alt="" className='team-logo' />
                    <span>Engaland-Premier League</span>
                     <i className={`heart-img ${is_favourite == 1 ? 'icon-heart active' : 'icon-heart' }`}/> 
                    <i className='heart-img icon-heart active' />
                  </div>
                  <div className="team-details-view">
                    <div className="right-view">
                      <img className='img-view' alt="" src={Images.ENGLAND_LOGO} />
                      <div className="team-name">home</div>
                      <div className="fisrt-line-text">2’ David Alaba
                        28’ Kingsley Coman</div>
                    </div>
                    <div className="middle-view">
                      2 - 1
                    </div>
                    <div className="left-view">
                      <img alt="" className='img-view' src={Images.ENGLAND_LOGO} />
                      <div className="team-name">name</div>
                      <div className="fisrt-line-text">19’ Adrien Rabiot</div>
                    </div>
                  </div>
                  <div className="cell-view">
                    <div className="first-row-cell">
                      <div>1x</div>
                      <div>Draw</div>
                      <div>2x</div>
                    </div>
                    <div className="second-row-cell">
                      <div className='first-value'>2.51</div>
                      <div className='first-value'>5.12</div>
                      <div className='third-value'>3.02</div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="league-container">
                <InfiniteScroll
                  instanceKey="Fav"
                  dataLength={FixtureListFav.length}
                  next={() => this.fetchMoreData()}
                  hasMore={hasMoreFav}
                  loader={<h1 className="list-load">Loading...</h1>}
                  className="game-event-list"
                >
                  {FixtureListFav && FixtureListFav.length > 0 ? (
                    <>
                      {FixtureListFav.map((item, idx) => {
                        return (
                          <>
                            <SportsBookBetItem
                              {...{
                                idx,
                                item,
                                ...BetItemProps,
                                isFav: true,
                                isAll: false,
                              }}
                              key={item.id}
                            />
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <h4 className="text-center n-data">
                      {FixturePosting ? "" : "No data"}
                    </h4>
                  )}
                </InfiniteScroll>
              </div>
              {/* <div className="league-container">
                <div className="league-container-view">
                  <div className="league-name-view">
                    <img src={Images.ENGLAND_LOGO} alt="" className='team-logo' />
                    <span>Engaland-Premier League</span>
                     <i className={`heart-img ${is_favourite == 1 ? 'icon-heart active' : 'icon-heart' }`}/> 
                    <i className='heart-img icon-heart active' />
                  </div>
                  <div className="team-details-view">
                    <div className="right-view">
                      <img className='img-view' alt="" src={Images.ENGLAND_LOGO} />
                      <div className="team-name">home</div>
                      <div className="fisrt-line-text">2’ David Alaba
                        28’ Kingsley Coman</div>
                    </div>
                    <div className="middle-view">
                      2 - 1
                    </div>
                    <div className="left-view">
                      <img alt="" className='img-view' src={Images.ENGLAND_LOGO} />
                      <div className="team-name">name</div>
                      <div className="fisrt-line-text">19’ Adrien Rabiot</div>
                    </div>
                  </div>
                  <div className="cell-view">
                    <div className="first-row-cell">
                      <div>1x</div>
                      <div>Draw</div>
                      <div>2x</div>
                    </div>
                    <div className="second-row-cell">
                      <div className='first-value'>2.51</div>
                      <div className='first-value'>5.12</div>
                      <div className='third-value'>3.02</div>
                    </div>
                  </div>
                </div>
              </div> */}
            </TabPane>
            </TabContent>
          </SwipeableViews>
        </div>
        {!_.isEmpty(SelectedOdds) && <BetslipDrawer {...BetslipDrawerProps} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;
  return {
    openCollapse: app.openCollapse,
  };
}
const mapDispatchToProps = (dispatch) => ({
  SlipToggle: (type) => dispatch(SlipToggle(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AllMatches));
