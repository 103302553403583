import { Component } from 'react';
// import { Images } from 'Constants';
// import { Button, Row, Col } from 'reactstrap';
// import { DefaultFooter } from 'Components';

class Home extends Component {
  render() {
    return (
      ''
      // <Fragment>
      //   <div className="mob-header">
      //     <img src={Images.Logo} alt="" />
      //   </div>
      //   <div className="banner-home">
      //     <div className="right-section">
      //       <img src={Images.BANNER_RIGHT} className="max-wid" alt="" />
      //     </div>
      //     <div className="home-container">
      //       <div className="left-section">
      //         <figure className="hidden-xs">
      //           <img src={Images.LOGO_HOME} alt="" />
      //         </figure>
      //         <h1>
      //           Play Real Betting
      //       </h1>
      //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
      //         <Button color="primary" size={'lg'} onClick={()=>this.props.history.push('/exchange')}>Play Now</Button>

      //       </div>
      //     </div>

      //   </div>
      //   <div className="shape-wrap">
      //     <div className="home-container">

      //       <Row>
      //         <Col>
      //           <h4 className="head-with-shadow">
      //             Bet on Live games
      //             <span>Bet on Live games</span>
      //           </h4>
      //         </Col>
      //       </Row>
      //       <Row>
      //         <Col>
      //           <div className="sm-banner">
      //             <figure>
      //               <img className="max-wids" src={Images.BANNER_GAME} alt="" />
      //             </figure>
      //             <div className="sm-banner-txt">
      //               <i className="icon-tennis hidden-xs"></i>
      //               <div className="sm-banner-txt-desc">
      //                  <h4> <i className="icon-tennis view-xs"></i> Cricket</h4>
      //                 <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.  </p>
      //               </div>
      //             </div>
      //           </div>
      //         </Col>
      //       </Row>
      //       <Row>
      //         <Col sm={4}>
      //           <div className="sports-card">
      //             <i className="icon-football"></i>
      //             <h4>Soccer</h4>
      //             <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incidi. Lorem ipsum dolor sit amet, consec. </p>
      //           </div>
      //         </Col>
      //         <Col sm={4}>
      //           <div className="sports-card">
      //             <i className="icon-horse-racing"></i>
      //             <h4>Horse Racing</h4>
      //             <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incidi. Lorem ipsum dolor sit amet, consec. </p>
      //           </div>
      //         </Col>
      //         <Col sm={4}>
      //           <div className="sports-card">
      //             <i className="icon-tennis"></i>
      //             <h4>Tennis</h4>
      //             <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incidi. Lorem ipsum dolor sit amet, consec. </p>
      //           </div>
      //         </Col>
      //       </Row>
      //     </div>  

      //   </div>
      //   <div className="shape-wrap-right">

      //   <div className="home-container">
      //     <Row>
      //       <Col>
      //         <h4 className="head-with-shadow">
      //           BET ON POPULAR LEAGUE
      //           <span>BET ON POPULAR LEAGUE</span>
      //           </h4>
      //       </Col>
      //     </Row>
      //     <Row>
      //       <Col>
      //         <ul className="league-list">
      //           <li className="league-list-item">
      //             <img src={Images.IPL} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.BBL} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.ASIA} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.ICC} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.ASH} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.PREMIER_LEAGUE} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.UEFA} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.WIMBLEDON} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.WTA} alt="" />
      //           </li>
      //           <li className="league-list-item">
      //             <img src={Images.PASS} alt="" />
      //           </li>
      //         </ul>
      //       </Col>
      //     </Row>
      //     <Row>
      //       <Col>
      //         <a className="link-txt">VIEW MORE</a>
      //       </Col>
      //     </Row>
      //   </div>
      //   </div>
      //   <DefaultFooter/>
      // </Fragment>
    )
  }
}

export default Home;
