import React, { Component, Fragment } from 'react';
import { GlobalLoader } from 'Components';
//DefaultHeader, DefaultFooter, 
import { Route, Switch, Redirect } from "react-router-dom";
import { _, Utils } from 'Helpers';
import router from "../../Router";
import { LoginModal } from 'Modals';


const { UserRoute, DefaultUserRoute } = router;
class UserLayout extends Component {
  render() {
    const { match } = this.props;
    return (
      <Fragment>
        {/* <DefaultHeader {...this.props} /> */}
        <Switch>
          {
            _.map(UserRoute, (route, idx) => {
              return route.component ?
                <Route
                  key={idx}
                  path={match.url + route.path}
                  exact={route.exact}
                  name={route.name}
                  component={(props) => <route.component {...props} page_id={route.page_id || Utils.CreateUUID()} />}
                />
                :
                null
            })
          }
          <Redirect from="*" to={match.url + DefaultUserRoute.path} />
        </Switch>
        {/* <DefaultFooter /> */}
        <LoginModal />
        <GlobalLoader />
      </Fragment>
    );
  }
}

export default UserLayout;
