import React, { Component } from 'react';
import { SiteContainer } from 'Components';
import { _, Utils, Role } from 'Helpers';
import { Auth } from 'Service';
import { Row, Col, Container } from 'reactstrap';
import config from 'config';
import { Fragment } from 'react';
import {
  loginOpen,
} from "../../Redux/Reducers";

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";


class LiveCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gamesList:  Utils.getMasterData().sports_list.cards_betting,
      gameType: 1,
      loading: false
    }
    //config.is_live ? Utils.getMasterData().sports_list.casino_betting :
  }
  GoToDetail = (item) => {
    const CurrentUser = Auth.currentUserValue;
    if (CurrentUser != null) {
      // let url = `https://d2.fawk.app/#/splash-screen/${AuthHelp.getAuth()}/9295?opentable=${this.state.gameType == 1 ? item.league_uid : item.league_vid}`;
      // window.open(url, '_blank')
      // this.props.history.push(`casino-game/detail/${this.state.gameType == 1 ? item.league_uid : item.league_vid}`)

      let url = `live-cards/detail/${this.state.gameType == 1 ? item.league_uid : item.league_vid}`;
      window.open(url, '_blank')

    } else {
      this.props.loginOpen()
    }

    // this.props.history.push({
    //   pathname: `/casino-detail/` + (config.is_live ? item.video_port : item.video_abbr),
    //   state: {
    //     item: item
    //   }
    // })
  }

  selectType = (key) => {
    this.setState({
      gameType: key,
      loading: true
    }, () => {
      setTimeout(() => this.setState({ loading: false }), 500)
    })
  }

  render() {
    const { gamesList, gameType, loading } = this.state
    const CurrentUser = Auth.currentUserValue;
    return (
      <SiteContainer {...this.props} header>
        <Container fluid className='gutters-container-7px'>
          <div className="casino-game-list-wrapper">

          </div>
          {/* {(CurrentUser != null && Number(CurrentUser.role) === Number(Role.AgentPunter)) ? <Row>
            {
              _.map(gamesList, (item, index) => {
                return (
                  <Fragment key={index}>
                    {Number(item.agent_league_status) !== 0 && <Col sm={4}  >
                      <div className="casino-game-list" onClick={() => this.GoToDetail(item)}>
                        <img src={`${config.s3.UPLOAD}casino/${item.avatar}`} alt="" className="max-wid" />
                        <h4>{item.league_name}</h4>
                      </div>
                    </Col>}
                  </Fragment>
                )
              })
            }
          </Row> : */}
          <Row>
            <div className={'casino-tog-area'}>
              <div className={`casino-tog ${gameType == 1 ? 'active' : ""}`} onClick={() => this.selectType(1)}>Top Games</div>
              <div className={`casino-tog ${gameType == 2 ? 'active' : ""}`} onClick={() => this.selectType(2)}>Virtual Games</div>
            </div>
          </Row>


          {
            loading ?

              <div className="m-4"><Utils.BoxLoader /></div> :


              <Row>

                {
                  _.map(gamesList, (item, index) => {


                    let league_status = item.league_status;
                    let agent_league_status = item.agent_league_status;
                    if (gameType == 2) {
                      league_status = item.league_virtual_status;
                      agent_league_status = item.agent_league_virtual_status
                    }


                    if (CurrentUser != null) {
                      if (Number(CurrentUser.role) === Number(Role.Punter) && league_status == 0) {
                        return;
                      }
                      if (Number(CurrentUser.role) === Number(Role.AgentPunter) && agent_league_status == 0) {
                        return;
                      }

                    } else {
                      if (league_status == 0) {
                        return;
                      }
                    }

                    return (
                      <Fragment key={index}>
                        <Col sm={4}  >
                          <div
                            className="casino-game-list"
                            onClick={() => this.GoToDetail(item)}
                          >
                            <img src={`${config.s3.UPLOAD}cards/${item.avatar || "tp1day.png"}`} alt="" className="max-wid" />
                            <h4>{item.league_name}</h4>
                          </div>
                        </Col>
                      </Fragment>
                    )
                  })
                }
              </Row>

          }

        </Container>
      </SiteContainer>
    );
  }
}


const CasinoTrans = withTranslation()(LiveCards);

const mapDispatchToProps = (dispatch) => ({
  loginOpen: () => dispatch(loginOpen())
});

export default connect(null, mapDispatchToProps)(CasinoTrans);
