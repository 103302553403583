import React from 'react';
import { Http, Utils,  API } from 'Helpers';
import {  Form, FormGroup, Input, Button, InputGroup } from 'reactstrap';
import { notify } from 'react-notify-toast';
import Select from 'react-select';


class ForgotPasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            init: false,
            posting: false,
            formValid: false,
            phone_code: { value: "91", label: "91" },
            phone_number: '',
            otp: '',
            password: '',
            confirm_password: '',
            view: 1, //1:forgotpassword, 2 reset,
            countries_options: [],
            password_key: ''
        }

    }


    countries_list = () => {
        Http.post(API.GET_COUNTRY_LIST)
            .then(res => {
                let countries_options = [];
                res.data.forEach((country) => {
                    let obj = { value: country.phonecode, label: country.phonecode };
                    countries_options.push(obj);
                    // countries_label[country.abbr] = `${country.abbr} (${country.phonecode})`
                })
                this.setState({ countries_options })
                // console.log(countries_options)
            })
    }

    selectChange = (val) => {
        this.setState({
            phone_code: val
        }, this.validateForm)
    }

    handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'password') {
            value = value.trim()
        }
        if (name === 'phone_number') {
            this.setState({ otp_sent: false })
        }
        this.setState({ [name]: value },
            this.validateForm
        );
    };

    validateForm = () => {
        const { otp_sent, view, phone_number, phone_code, otp, password, confirm_password } = this.state;
        if (view == 1) {
            if (!otp_sent) {
                this.setState({
                    formValid: Utils.isValid("mobile", phone_number) && phone_code != null
                })
            } else {
                this.setState({
                    formValid: Utils.isValid("mobile", phone_number) &&
                        phone_code != null &&
                        otp !== null
                })
            }
        }
        else {
            this.setState({
                formValid: password !== '' &&
                    confirm_password !== '' &&
                    password == confirm_password &&
                    Utils.isValid("password", password)
            })
        }
    }

    comparePassword = () => {
        const { confirm_password, password } = this.state;
        return confirm_password != '' && confirm_password != password
    }

    componentDidMount() {
        this.countries_list();
    }


    sendOtpHandler = () => {
        let param = {
            phone_code: this.state.phone_code.value,
            mobile: this.state.phone_number
        }
        Http.post(API.SEND_OTP, param)
            .then(res => {
                notify.show(res.message, "success", 2500)
                this.setState({
                    otp_sent: true,
                    formValid: false
                })
            })
            .catch(error => {
                console.log(error);
            });

    }

    forgotPasswordHandler = () => {
        let param = {
            phone_code: this.state.phone_code.value,
            mobile: this.state.phone_number,
            otp: this.state.otp
        }
        Http.post(API.FORGOT_PASSWORD, param)
            .then(res => {
                this.setState({
                    view: 2,
                    formValid: false,
                    password_key: res.data.password_key
                })
            })
            .catch(error => {
                console.log(error)
                // this.setState({
                //     view: 2,
                //     formValid: false,
                //     password_key: 'ff'
                // })
            });

    }


    onForgotSubmit = (e) => {
        e.preventDefault();
        if (!this.state.otp_sent) {
            this.sendOtpHandler()
        } else {
            this.forgotPasswordHandler()
        }


    }

    onResetSubmit = e => {
        e.preventDefault();
        this.setState({ posting: true })
        let param = {
            password: this.state.password,
            password_key: this.state.password_key
        }

        Http.post(API.RESET_PASSWORD, param)
            .then(res => {
                // console.log(res)
                this.props.close()
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 100)
            })
            .catch(error => {
                this.setState({ view: 1 })
            })
    }

    render() {
        const { t } = this.props;
        const { formValid,  posting, otp_sent, phone_number, otp, phone_code, view, password, confirm_password } = this.state;

        return (
            <React.Fragment>

                {
                    view == 1 ?


                        <Form onSubmit={this.onForgotSubmit}>
                            <FormGroup className='input-border-bottom' >
                                <span className='label'>{t('Phone Number')}</span>
                                <InputGroup className={'otp_inp_grp'}>
                                    <Select
                                        options={this.state.countries_options}
                                        placeholder={''}
                                        className={'country_sel'}
                                        onChange={(val) => this.selectChange(val)}
                                        value={phone_code}
                                    />
                                    <Input
                                        type="text"
                                        id='phone_number'
                                        name='phone_number'
                                        required
                                        value={phone_number}
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        invalid={Utils.isInvalid("mobile", phone_number)}
                                        valid={Utils.isValid("mobile", phone_number)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {
                                otp_sent ?
                                    <FormGroup className='input-border-bottom' >
                                        <span className='label'>{t('OTP')}</span>
                                        <Input
                                            type="text"
                                            id='otp'
                                            name='otp'
                                            required
                                            value={otp}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                            invalid={Utils.isInvalid("required", otp)}
                                            valid={Utils.isValid("required", otp)}
                                        />
                                    </FormGroup> : ''
                            }

                            <FormGroup className='m-0'>
                                <Button
                                    disabled={!formValid}
                                    type='submit' size="lg" color="primary" className='round' block>
                                    {t(otp_sent ? 'Submit' : 'Send OTP')}
                                </Button>
                            </FormGroup>

                        </Form>

                        :
                        <Form onSubmit={this.onResetSubmit}>
                            <FormGroup className='input-border-bottom' >
                                <span className='label'>{t('Password')}</span>
                                <Input
                                    type="password"
                                    id='password'
                                    name='password'
                                    required
                                    value={password}
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                    invalid={Utils.isInvalid("password", password)}
                                    valid={Utils.isValid("password", password)}
                                />
                            </FormGroup>
                            <FormGroup className='input-border-bottom' >
                                <span className='label'>{t('Confirm Password')}</span>
                                <Input
                                    type="password"
                                    id='confirm_password'
                                    name='confirm_password'
                                    required
                                    value={confirm_password}
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                    invalid={Utils.isInvalid("password", confirm_password) || (this.comparePassword() && confirm_password != '') ? 'true' : undefined}
                                    valid={Utils.isValid("password", confirm_password) ? 'true' : undefined}
                                />
                            </FormGroup>
                            <FormGroup className='m-0'>
                                <Button
                                    disabled={!formValid || posting}
                                    type='submit' size="lg" color="primary" className='round' block>
                                    {t('Submit')}
                                </Button>
                            </FormGroup>
                        </Form>
                }

            </React.Fragment>
        )
    }
}

export default ForgotPasswordModal;