import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { Sidebar, FixtureListComponent, FixtureDetailList, BetslipDrawer } from 'Components';
import { Http, API, Utils, _, Socket } from 'Helpers';
import { CONSTANT, SocketEvent } from 'Constants';


import { connect } from "react-redux";
import { SlipToggle } from '../../Redux/Reducers'

class ExchangeHome extends Component {
  constructor(props) {
    super(props);
    const { page_id } = props
    this.state = {
      is_inplay: false,
      FixturePosting: false,
      FixtureListBlank: false,
      hasMore: false,
      isChanged: false,
      AppMasterData: [],
      NavigationList: [],
      SelectedSports: {},
      SelectedLeague: {},
      SelectedFixture: {},
      FixtureList: [],
      Offset: CONSTANT.CURRENT_OFFSET,
      SelectedOdds: Utils.getBetSlip(page_id),
      SelectedOddsFancy: Utils.getBetSlip(CONSTANT.FANCY_BETTING),
      betslip_lastupdate: new Date().valueOf(),
      fixture_lastupdate: new Date().valueOf(),
      recently_changed: [],
      fancy_betslip: '',
      InitList: false,
    };
  }
  // Fetch Request(s) 

  GetFixtures = () => {


    const { InitList, SelectedSports, SelectedLeague, Offset, FixtureList, is_inplay } = this.state;


    if (!InitList) return;
    let param = {
      "sports_id": is_inplay ? "" : SelectedSports.sports_id,
      "league_uid": _.isEmpty(SelectedLeague) ? "" : SelectedLeague.league_uid,
      "limit": is_inplay ? 99999 : CONSTANT.ITEMS_PERPAGE_SM,
      "offset": Offset
    }

    this.setState({ FixturePosting: true })
    Http.post(is_inplay ? API.GET_INPLAY_GAMES : API.GET_UPCOMING_FIXTURES, param).then(response => {
      this.setState({
        FixturePosting: false,
        FixtureList: Offset == CONSTANT.CURRENT_OFFSET ? response.data.matches : [...FixtureList, ...response.data.matches],
        hasMore: !_.isEmpty(response.data.matches) || (_.size(response.data.matches) === CONSTANT.ITEMS_PERPAGE_SM),
        fixture_lastupdate: new Date().valueOf()
      }, () => {
        this.setState({
          FixtureListBlank: _.isEmpty(response.data.matches) && _.isEmpty(this.state.FixtureList)
        })
      })
    }).catch(error => {
      this.setState({ FixturePosting: false })
    });

  }


  // Handlers
  InitListToggle = (bool) => {
    this.setState({
      InitList: bool
    });
  }
  sockeListener = () => {
    const _this = this
    Socket.on(SocketEvent.MARKET, function (res) {
      _this.SocketMarkethandler(res)
    })

    Socket.on(SocketEvent.STATUS_UPDATED_MK, function (res) {
      let FixtureList = [..._this.state.FixtureList];
      let getIdx = FixtureList.findIndex(itm => itm.event_id == res.event_id);
      if (getIdx > -1) {
        FixtureList[getIdx]['lock_betting'] = res.lock_betting;
        _this.setState({ FixtureList })
      }

    })

    Socket.on(SocketEvent.PROVIDER_UPDATED, function (res) {
      console.log(_this.state.SelectedOdds, res)
      _this.socketProviderUpdateHandler();
    })

  }

  socketProviderUpdateHandler = () => {
    this.removeExchangeBets();
    this.setState({
      Offset: CONSTANT.CURRENT_OFFSET
    }, () => {
      this.GetFixtures()
    })
  }

  removeExchangeBets = () => {
    localStorage.removeItem(CONSTANT.EXCHANGE_BETTING);
    this.setState({
      SelectedOdds: {},
      betslip_lastupdate: new Date().valueOf(),
    })
  }


  removeSockeListener = () => {
    Socket.off(SocketEvent.MARKET)
    Socket.off(SocketEvent.STATUS_UPDATED_MK)
    Socket.off(SocketEvent.PROVIDER_UPDATED)
  }

  SocketMarkethandler = (res = {}) => {
    const { FixtureList, recently_changed } = this.state;
    let newFixtureList = _.map(FixtureList, (item) => {
      if (item.market_id == res.market_id) {
        let new_recently_changed = recently_changed

        let item_runners_odds = JSON.parse(item.runners_odds)

        let new_runners_odds = _.map(JSON.parse(res.runners_odds), (odds, idx) => {

          if (item_runners_odds[idx].ex.selectionId == odds.ex.selectionId) {
            let new_availableToBack = _.map(odds.ex.availableToBack, (o, back_key) => {
              if (back_key == 0) {
                if (item_runners_odds[idx].ex.availableToBack[back_key].price != o.price || item_runners_odds[idx].ex.availableToBack[back_key].size != o.size) {
                  new_recently_changed = [...new_recently_changed, JSON.stringify(odds.selectionId)]
                  this.setState({
                    isChanged: true,
                    recently_changed: new_recently_changed
                  })
                  return o
                }
              }
              return o
            })

            let new_availableToLay = _.map(odds.ex.availableToLay, (o, lay_key) => {
              if (lay_key == 0) {
                if (item_runners_odds[idx].ex.availableToLay[lay_key].price != o.price || item_runners_odds[idx].ex.availableToLay[lay_key].size != o.size) {
                  new_recently_changed = [...new_recently_changed, JSON.stringify(odds.selectionId) + '_lay_price']
                  this.setState({
                    isChanged: true,
                    recently_changed: new_recently_changed
                  })
                  return o
                }
              }
              return o
            })

            return { ...odds, ex: { ...odds.ex, availableToBack: new_availableToBack, availableToLay: new_availableToLay } }
          }
          return odds
        })
        return { ...item, runners_odds: JSON.stringify(new_runners_odds) };
      }
      return item;
    })

    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ FixtureList: newFixtureList }, () => {
        this.setState({ isChanged: false });
      })
    }
  }

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state
    let _id = _.isString(id) ? id : JSON.stringify(id)
    if (_.includes(recently_changed, _id)) {
      this.cleanSocketHighlight()
      return ' blink '
    }
    else return '';
  }

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] })
      }
    }, timer)
  }

  fetchMoreData = () => {
    if (!this.state.FixturePosting) {
      let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
      this.setState({ Offset: offset }, () => {
        this.GetFixtures()
      });
    }
  }

  handleSelectSport = (option) => {
    // this.reinitiateComponent()
    this.setState({
      FixtureList: [],
      FixtureListBlank: false,
      Offset: CONSTANT.CURRENT_OFFSET,
      SelectedSports: option.Sport,
      SelectedLeague: option.League,
    }, () => {
      const { match } = this.props;
      const { SelectedSports, SelectedLeague, is_inplay } = this.state
      this.props.history.push({
        pathname: is_inplay ? `${match.url}/in-play` : `${match.url}`,
        ...(SelectedSports.sports_id != '' && { search: `?sports=${SelectedSports.sports_id}` }),
        state: {
          SelectedSports,
          SelectedLeague,
          unbind: option.unbind
        }
      })
      // this.GetFixtures()
    })
  }

  // for Exchange/Sportsbook betslip
  // *******************************
  _oddsActiveClass = (market_bet_id, market_event_id, betting_type) => {

    let { SelectedOdds } = this.state
    return _.some(SelectedOdds, (odd) => {
      return odd.picked_odd_id == market_bet_id + '_' + market_event_id + '_' + betting_type
    })
  }

  handleOptionChange = (option) => {
    const { page_id } = this.props;
    let { SelectedOdds } = this.state;
    const { fixture, odd, betting_type, selected_odd, bookmakerHandle } = option;
    
    if(bookmakerHandle){
      let selected_odd_name = CONSTANT.ONLY_SINGLE_BET ? '0' :
      [
        fixture.bookmakerList.event_id,
        fixture.bookmakerList.bookmaker_id,
        odd.market_bet_id,
        betting_type
      ].join('_')

      //SelectedOdds.bookmakerHandle = bookmakerHandle
   
      
      
      delete odd.ex;
      SelectedOdds[selected_odd_name] = {
        picked_odd_id: odd.market_bet_id + '_' + fixture.bookmakerList.bookmaker_id + '_' + betting_type,
        market_bet_id: odd.market_bet_id,
        picked_odd_price: betting_type == 1 ? odd.b1 : odd.lay_price,
        picked_odd: { ...odd, betting_type, ...selected_odd },
        stack_value: '',
        ...fixture,
      }
      
  

      this.setState({
       SelectedOdds,
        fancy_betslip: '',
       betslip_lastupdate: new Date().valueOf()
      }, () => {
        Utils.setBetSlip(CONSTANT.BETSLIP[page_id], this.state.SelectedOdds)
      })
    

    const { openCollapse, SlipToggle } = this.props
    if (openCollapse != 1) {
      SlipToggle(1)
    }
    } 
    else {
      
    let selected_odd_name = CONSTANT.ONLY_SINGLE_BET ? '0' :
      [
        fixture.event_id,
        fixture.market_event_id,
        odd.market_bet_id,
        betting_type
      ].join('_')

      
    if (this._oddsActiveClass(odd.market_bet_id, fixture.market_event_id, betting_type)) {
      SelectedOdds = _.omit(SelectedOdds, [selected_odd_name])
      this.setState({
        SelectedOdds,
        betslip_lastupdate: new Date().valueOf()
      }, () => {
        Utils.setBetSlip(CONSTANT.BETSLIP[page_id], this.state.SelectedOdds)
      })
    } else {
      
      delete odd.ex;
      SelectedOdds[selected_odd_name] = {
        picked_odd_id: odd.market_bet_id + '_' + fixture.market_event_id + '_' + betting_type,
        market_bet_id: odd.market_bet_id,
        picked_odd_price: betting_type == 1 ? odd.price : odd.lay_price,
        picked_odd: { ...odd, betting_type, ...selected_odd },
        stack_value: '',
        ...fixture,
      }
      
      
      this.setState({
        SelectedOdds,
        fancy_betslip: '',
        betslip_lastupdate: new Date().valueOf()
      }, () => {
        Utils.setBetSlip(CONSTANT.BETSLIP[page_id], this.state.SelectedOdds)
      })
    }

    const { openCollapse, SlipToggle } = this.props
    if (openCollapse != 1) {
      SlipToggle(1)
    }
  }

    // console.log('hello')
  }

  handleRemoveSlip = (key) => {
    const { page_id } = this.props
    let SelectedOdds = Utils.getBetSlip(page_id);
    let newSelectedOdds = _.omit(SelectedOdds, key)
    this.setState({
      SelectedOdds: newSelectedOdds,
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip(CONSTANT.BETSLIP[page_id], this.state.SelectedOdds)
    })
  }

  clearAllBet = (BetslipType) => {
    const { page_id } = this.props
    this.setState({
      ...(page_id == BetslipType && { "SelectedOdds": {} }),
      ...(CONSTANT.FANCY_BETTING == BetslipType && { "SelectedOddsFancy": {} }),
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.removeBetSlip(CONSTANT.BETSLIP[BetslipType])
      Utils.removeMultiBetSlip(CONSTANT.BETSLIP[BetslipType])
    })
  }

  // for Fancy betslip
  // *******************************
  _fancyOddsActiveClass = (market_bet_id, selection_id, betting_type) => {
    let { SelectedOddsFancy } = this.state
    return _.some(SelectedOddsFancy, (odd) => {
      return odd.picked_odd_id == market_bet_id + '_' + selection_id + '_' + betting_type
    })
  }

  _fancyHandleOptionChange = (option) => {
    // console.log('hello')
    let { SelectedOddsFancy } = this.state;
    const { fixture, odd, betting_type, selected_odd } = option;
    let selected_odd_name = CONSTANT.ONLY_SINGLE_BET ? '0' : [
      fixture.event_id,
      fixture.market_event_id,
      odd.market_bet_id,
      betting_type
    ].join('_')

    if (this._fancyOddsActiveClass(odd.market_bet_id, odd.selection_id, betting_type)) {
      SelectedOddsFancy = _.omit(SelectedOddsFancy, [selected_odd_name])
      this.setState({
        SelectedOddsFancy,
        betslip_lastupdate: new Date().valueOf()
      }, () => {
        Utils.setBetSlip(CONSTANT.BETSLIP[CONSTANT.FANCY_BETTING], this.state.SelectedOddsFancy)
      })
    } else {
      SelectedOddsFancy[selected_odd_name] = {
        picked_odd_id: odd.market_bet_id + '_' + odd.selection_id + '_' + betting_type,
        market_bet_id: odd.market_bet_id,
        picked_odd_price: betting_type == 1 ? odd.price : odd.lay_price,
        picked_odd: { ...odd, betting_type, name: odd.runner_name, ...selected_odd },
        stack_value: '',
        ...fixture,
      }
      this.setState({
        SelectedOddsFancy,
        fancy_betslip: CONSTANT.FANCY_BETTING,
        betslip_lastupdate: new Date().valueOf()
      }, () => {
        Utils.setBetSlip(CONSTANT.BETSLIP[CONSTANT.FANCY_BETTING], this.state.SelectedOddsFancy)
      })
    }

    const { openCollapse, SlipToggle } = this.props
    if (openCollapse != 1) {
      SlipToggle(1)
    }
  }

  _fancyHandleRemoveSlip = (key) => {
    let SelectedOddsFancy = Utils.getBetSlip(CONSTANT.FANCY_BETTING);
    let newSelectedOddsFancy = _.omit(SelectedOddsFancy, key)
    this.setState({
      SelectedOddsFancy: newSelectedOddsFancy,
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip(CONSTANT.FANCY_BETTING, this.state.SelectedOddsFancy)
    })
  }

  fixtureSelectHandler = (item = {}) => {
    const { match } = this.props
    const { is_inplay } = this.state;

    this.props.history.push({
      pathname: is_inplay ? `${match.url}/in-play/details/${item.event_id}` : `${match.url}/details/${item.event_id}`,
      search: `?sports=${item.sports_id}&market=${item.market_event_id}`,
    })
  }

  reinitiateComponent = (sports_id = '', is_bypass = false) => {
    const { match } = this.props
    if (is_bypass) {
      this.props.history.push({
        pathname: `${match.url}/in-play`
      })
    } else {
      this.props.history.push({
        pathname: `${match.url}`,
        ...((sports_id != '') && { search: `?sports=${sports_id}` })
      })
    }
    this.setState({
      SelectedFixture: {}
    })
  }

  pageInit = ({ SelectedLeague = {}, SelectedSports = {}, unbind }) => {
    const { page_id } = this.props;
    const { is_inplay } = this.state
    this.setState({
      AppMasterData: Utils.getMasterData(),
      NavigationList: is_inplay ? [
        ...Utils.getMasterData().sports_list[page_id]
      ] : Utils.getMasterData().sports_list[page_id],
    }, () => {



      const { NavigationList, AppMasterData } = this.state
      const { location } = this.props
      const searchParams = new URLSearchParams(location.search);


      if (unbind) {
        this.setState({
          SelectedSports: SelectedSports,
          SelectedLeague: SelectedLeague,
        }, this.GetFixtures)
      } else {

        if (_.isEmpty(SelectedLeague) && _.isEmpty(SelectedSports)) {

          let findSport = NavigationList.find(itm => itm.sports_id == (searchParams.get('sports') ? searchParams.get('sports') : AppMasterData.default_sport));
          let selectedSport = NavigationList[0];
          if (findSport) {
            selectedSport = findSport;
          }

          this.setState({ SelectedSports: selectedSport }, this.GetFixtures)

        }
      }
    })
  }

  changeSports = (sportsId) => {
    // this methods is mainly for scoreboard ui
    const { NavigationList } = this.state
    let findSport = NavigationList.find(itm => itm.sports_id == sportsId);
    let selectedSport = NavigationList[0];
    this.setState({
      SelectedSports: findSport ? findSport : selectedSport
    },
      this.GetFixtures
    )
  }

  // Life cycle
  componentDidMount() {
    const { location } = this.props;
    this.setState({
      is_inplay: _.includes(location.pathname, 'in-play')
    });
    this.pageInit(_.isUndefined(location.state) || _.isNull(location.state) ? {} : location.state)
  }

  componentWillUnmount() {
    this.removeSockeListener()
    this.setState = () => {
      return;
    };
  }

  render() {
    const { page_id, match } = this.props
    const {
      NavigationList,
      SelectedSports,
      FixtureList,
      hasMore,
      FixturePosting,
      FixtureListBlank,
      SelectedOdds,
      SelectedOddsFancy,
      betslip_lastupdate,
      fixture_lastupdate,
      SelectedFixture,
      fancy_betslip,
      InitList,
      is_inplay,
    } = this.state;

    const BetslipProps = {
      SelectedOdds,
      SelectedOddsFancy,
      betslip_lastupdate,
      page_id,
      fancy_betslip,
      // Methods
      handleRemoveSlip: this.handleRemoveSlip,
      clearAllBet: this.clearAllBet,
      // Fancy Methods
      _fancyHandleRemoveSlip: this._fancyHandleRemoveSlip,
    }

    const FixtureListProps = {
      ...this.props,
      NavigationList,
      SelectedSports,
      hasMore,
      FixtureList,
      FixturePosting,
      FixtureListBlank,
      SelectedFixture,
      fixture_lastupdate,
      InitList,
      is_inplay,

      // Methods
      InitListToggle: this.InitListToggle,
      handleSelectSport: this.handleSelectSport,
      fetchMoreData: this.fetchMoreData,
      _oddsActiveClass: this._oddsActiveClass,
      handleOptionChange: this.handleOptionChange,
      fixtureSelectHandler: this.fixtureSelectHandler,
      reinitiateComponent: this.reinitiateComponent,
      SocketHighlight: this.SocketHighlight,
      changeSports: this.changeSports,

      // Fancy Methods
      _fancyOddsActiveClass: this._fancyOddsActiveClass,
      _fancyHandleOptionChange: this._fancyHandleOptionChange,

      // Socket
      sockeListener: this.sockeListener,
      removeSockeListener: this.removeSockeListener,

      removeExchangeBets : this.removeExchangeBets
    }
    const BetslipDrawerProps = {
      ...this.props,
      BetslipProps
    };


    // console.log(FixtureListProps, 'exha')
    return (
      <Container fluid className='gutters-container-7px'>
        <Row>
          {
            !is_inplay &&
            <Col className='leftnav-col'>
              <Sidebar menu={NavigationList} page_id={page_id} callback={this.handleSelectSport} />
            </Col>
          }
          <Col>

            <Switch>
              <Route
                path={[`${match.url}/details/:event_id`, `${match.url}/in-play/details/:event_id`]}
                exact
                render={(props) => <FixtureDetailList {...FixtureListProps} {...props} />}
              />
              <Route
                path={[`${match.url}/`, `${match.url}/in-play`]}
                exact
                render={(props) => <FixtureListComponent {...FixtureListProps} {...props} />}
              />

              <Redirect from="*" exact to={match.url} />
            </Switch>

          </Col>
          <BetslipDrawer {...BetslipDrawerProps} />
        </Row>
      </Container>
    );
  }
}
function mapStateToProps(state) {
  const { app } = state;
  return {
    openCollapse: app.openCollapse
  };
}
const mapDispatchToProps = dispatch => ({
  SlipToggle: (type) => dispatch(SlipToggle(type)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeHome);
