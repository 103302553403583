import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { SiteContainer } from 'Components';
import { Utils } from 'Helpers';

class CommonResponsibleGambling extends Component {
  render() {
    return (
      <SiteContainer {...this.props} header staticPage>
        <Container fluid className='gutters-container-7px'>
          {Utils.geti18nextLng() == 'en' &&
            <div>
              <Row>
                <Col>
                  <div className="odds-padding-row heading">
                    <h4 className="title">Policy</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="static-container">
                    <h4 className="s-head mb30">Isocial is committed to doing everything possible to give its Customers an enjoyable gaming experience, whilst recognizing that gambling can cause problems for a minority of individuals. To ensure that you continue to enjoy safe and manageable play we fully support Responsible Gaming and have numerous measures in place to offer checks.</h4>
                    <h4 className="s-head mb30">1. Gambling Self-Assessment and Help Organizations</h4>
                    <ul className="unorderd-list">
                      <li>For assistance in identifying whether to modify or seek help for your gambling behavior, we suggest you complete the NODS Self-Assessment in order to more impartially assess and better understand your current levels of play.</li>
                      <li>If you or someone you know has a gambling problem we advise you consider assistance from the below recognized organization:</li>
                      <li>Gamblers Anonymous</li>
                      <li>Website:  <a>http://www.gamblersanonymous.org</a></li>
                    </ul>
                    <h4 className="s-head">2. Identity Checks</h4>
                    <ul className="unorderd-list">
                      <li>We take stringent steps to ensure that only people of legal age participate and enter Isocial. Such checks include requesting the Customer to submit a copy of a photographic identification documents (such as a driver's license, a passport or a government-issued identity card), in order to verify both age and identity.</li>
                      <li>The legal age for gaming online varies from country to country and so we advise that you check the rules of your jurisdiction before you play.</li>
                    </ul>

                  </div>
                </Col>
              </Row>
            </div>
          }
          {Utils.geti18nextLng() == 'ben' &&
            <div>
              <Row>
                <Col>
                  <div className="odds-padding-row heading">
                    <h4 className="title">নীতি</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="static-container">
                    <h4 className="s-head mb30">Isocial তার গ্রাহকদের একটি উপভোগযোগ্য গেমিং অভিজ্ঞতা দেওয়ার জন্য যথাসাধ্য চেষ্টা করার জন্য প্রতিশ্রুতিবদ্ধ, যখন জুয়েলিং ব্যক্তি সংখ্যালঘুদের জন্য সমস্যার কারণ হতে পারে তা স্বীকার করে। আপনি নিরাপদ এবং পরিচালনাযোগ্য খেলা উপভোগ করা অব্যাহত রাখার জন্য আমরা দায়বদ্ধ গেমিংকে পুরোপুরি সমর্থন করি এবং চেকগুলি অফার করার জন্য অসংখ্য ব্যবস্থা রয়েছে।</h4>
                    <h4 className="s-head mb30">1. জুয়া আত্ম-মূল্যায়ন এবং সহায়তা সংস্থা</h4>
                    <ul className="unorderd-list">
                      <li>আপনার জুয়ার আচরণের জন্য সংশোধন করতে হবে বা সহায়তা চাইতে হবে কিনা তা সনাক্ত করতে সহায়তার জন্য, আমরা আপনাকে এটি সম্পূর্ণ করার পরামর্শ দিইNODS স্ব-মূল্যায়নআরও নিরপেক্ষভাবে আপনার খেলার খেলার স্তরগুলি আরও নিরপেক্ষভাবে মূল্যায়ন করতে এবং বোঝার জন্য।</li>
                      <li>আপনার বা আপনার পরিচিত কারও জুয়ার সমস্যা হলে আমরা আপনাকে নীচের স্বীকৃত প্রতিষ্ঠানের সহায়তা বিবেচনা করার পরামর্শ দিই:</li>
                      <li>জুয়াড়রা নামবিহীন</li>
                      <li> ওয়েবসাইট:  <a>http://www.gamblersanonymous.org</a></li>
                    </ul>
                    <h4 className="s-head">2. পরিচয় চেক</h4>
                    <ul className="unorderd-list">
                      <li>আমরা কেবল বৈধ বয়সী লোকেরা যাতে বেটভিকে অংশ নিতে এবং প্রবেশ করতে পারে সেজন্য কঠোর পদক্ষেপ গ্রহণ করি। এই ধরনের চেকগুলির মধ্যে গ্রাহককে বয়স এবং পরিচয় উভয়ই যাচাই করার জন্য কোনও ফটোগ্রাফিক শনাক্তকরণ দলিলগুলির একটি অনুলিপি (যেমন চালকের লাইসেন্স, একটি পাসপোর্ট বা সরকার দ্বারা জারি করা পরিচয় পত্র) জমা দেওয়ার অনুরোধ করা অন্তর্ভুক্ত।</li>
                      <li>অনলাইনে গেমিংয়ের আইনি বয়স দেশ থেকে দেশে পরিবর্তিত হয় এবং তাই আমরা আপনাকে পরামর্শ দিই যে আপনি খেলার আগে আপনার এখতিয়ারের নিয়মগুলি পরীক্ষা করে দেখুন।</li>
                    </ul>

                  </div>
                </Col>
              </Row>
            </div>
          }
          {Utils.geti18nextLng() == 'hi' &&
            <div>
              <Row>
                <Col>
                  <div className="odds-padding-row heading">
                    <h4 className="title">नीति</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="static-container">
                    <h4 className="s-head mb30">बेटविक अपने ग्राहकों को एक सुखद गेमिंग अनुभव देने के लिए हर संभव प्रयास करने के लिए प्रतिबद्ध है, जबकि यह समझते हुए कि जुआ व्यक्तियों के अल्पसंख्यक के लिए समस्या पैदा कर सकता है। यह सुनिश्चित करने के लिए कि आप सुरक्षित और प्रबंधनीय खेलने का आनंद लेना जारी रखते हैं, हम जिम्मेदार गेमिंग का पूरा समर्थन करते हैं और चेक प्रदान करने के लिए कई उपाय करते हैं।</h4>
                    <h4 className="s-head mb30">1. जुआ स्व-मूल्यांकन और सहायता संगठन</h4>
                    <ul className="unorderd-list">
                      <li>यह पहचानने में सहायता के लिए कि आपके जुआ व्यवहार के लिए संशोधित या मदद लेनी है, हम आपको पूरा करने का सुझाव देते हैंएनओडीएस स्व-मूल्यांकनअधिक निष्पक्ष रूप से मूल्यांकन करने और अपने खेल के वर्तमान स्तरों को बेहतर ढंग से समझने के लिए।</li>
                      <li>यदि आप या आपके कोई परिचित जुआ समस्या है, तो हम आपको सलाह देते हैं कि आप नीचे दिए गए मान्यता प्राप्त संगठन से सहायता लेने पर विचार करें:</li>
                      <li>जुआरी बेनामी </li>
                      <li>वेबसाइट  <a>http://www.gamblersanonymous.org</a></li>
                    </ul>
                    <h4 className="s-head">2. पहचान की जाँच</h4>
                    <ul className="unorderd-list">
                      <li>हम यह सुनिश्चित करने के लिए कड़े कदम उठाते हैं कि केवल कानूनी उम्र के लोग ही भाग लें और बेटविक में प्रवेश करें। इस तरह के चेक में ग्राहक से अनुरोध है कि वह फोटो पहचान संबंधी दस्तावेजों (जैसे कि ड्राइविंग लाइसेंस, पासपोर्ट या सरकार द्वारा जारी पहचान पत्र) की एक प्रति प्रस्तुत करे, ताकि उम्र और पहचान दोनों को सत्यापित किया जा सके।</li>
                      <li>ऑनलाइन जुआ खेलने की कानूनी उम्र एक देश से दूसरे देश में भिन्न होती है और इसलिए हम सलाह देते हैं कि आप खेलने से पहले अपने अधिकार क्षेत्र के नियमों की जांच करें।</li>
                    </ul>

                  </div>
                </Col>
              </Row>
            </div>
          }
        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(CommonResponsibleGambling);
