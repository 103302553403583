import React from "react";
import Loadable from "react-loadable";
import LoadingView from "Views/LoadingView";

function Loading(props) {
    if (props.error) {
        return <div>Error! <button onClick = {props.retry}>Retry</button></div>;
    } else if (props.timedOut) {
        return <div>Taking a long time... <button onClick = {props.retry}>Retry</button></div>;
    } else if (props.pastDelay) {
        return <LoadingView />;
    } else {
        return null;
    }
}

/* 
  COMMON ROUTE 
****************/
const CommonRoute = [
    {
        path     : "/500",
        name     : "Unauth",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Unauth"),
            loading: Loading
        })
    },
    // {
    //     path     : "/offers",
    //     name     : "CommonOffers",
    //     exact    : true,
    //     component: Loadable({
    //         loader : () => import("./Views/CommonOffers"),
    //         loading: Loading
    //     })
    // },
    {
        path     : "/responsible-gambling",
        name     : "CommonResponsibleGambling",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/CommonResponsibleGambling"),
            loading: Loading
        })
    },
    {
        path     : "/terms-and-conditions",
        name     : "CommonTermsCondition",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/CommonTermsCondition"),
            loading: Loading
        })
    },
]

/* 
  USER ROUTE 
****************/
const UserRoute = [
    
    // {
    //     path     : "exchange",
    //     name     : "ExchangeLayout",
    //     exact    : false,
    //     component: Loadable({
    //         loader : () => import("./Views/ExchangeLayout"),
    //         loading: Loading
    //     })
    // },
    {
        path     : "500",
        name     : "Unauth",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Unauth"),
            loading: Loading
        })
    },
    {
        path     : "bet-detail",
        name     : "BetDetail",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/BetDetail"),
            loading: Loading
        })
    },
    {
        path     : "bet-detail",
        name     : "BetDetail",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/BetDetail"),
            loading: Loading
        })
    },
    {
        path     : "sportsbook",
        name     : "SportsbookLayout",
        exact    : false,
        component: Loadable({
            loader : () => import("./Views/SportsbookLayout"),
            loading: Loading
        })
    },
    {
        path     : "live-cards",
        name     : "live cards",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/LiveCards"),
            loading: Loading
        })
    },

    {
        path     : "live-cards/detail/:id",
        name     : "live cards details",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/LiveCardsDetail"),
            loading: Loading
        })
    },

    {
        path     : "live-casino",
        name     : "live casino",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/LiveCasino"),
            loading: Loading
        })
    },
    {
        path     : "live-casino/games/:id",
        name     : "live casino games",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/LiveCasinoGames"),
            loading: Loading
        })
    },

    {
        path     : "live-casino/detail/:id",
        name     : "live casino games",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/LiveCasinoSingleGame"),
            loading: Loading
        })
    },
    // {
    //     path     : "casino/game/:id",
    //     name     : "Casino",
    //     exact    : true,
    //     component: Loadable({
    //         loader : () => import("./Views/CasinoDetail"),
    //         loading: Loading
    //     })
    // },
    // {
    //     path     : "casino-detail/:game",
    //     name     : "Casino Game",
    //     page_id  : "casino_betting",
    //     exact    : true,
    //     component: Loadable({
    //         loader : () => import("./Views/CasinoGame"),
    //         loading: Loading
    //     })
    // },
    {
        path     : "guidelines",
        name     : "Guidelines",
        exact    : true,
        component: Loadable({
            loader : () => import("./Guidelines"),
            loading: Loading
        })
    },
    {
        path     : "bet-history",
        name     : "BetHistory",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/BetHistory"),
            loading: Loading
        })
    },
    {
        path     : "profit-loss",
        name     : "ProfitLoss",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/ProfitLoss"),
            loading: Loading
        })
    },
    {
        path     : "account-statement",
        name     : "AccountStatement",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/AccountStatement"),
            loading: Loading
        })
    },
    {
        path     : "change-password",
        name     : "ChangePassword",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/ChangePassword"),
            loading: Loading
        })
    },
    // {
    //     path     : "offers",
    //     name     : "CommonOffers",
    //     exact    : true,
    //     component: Loadable({
    //         loader : () => import("./Views/CommonOffers"),
    //         loading: Loading
    //     })
    // },
    {
        path     : "responsible-gambling",
        name     : "CommonResponsibleGambling",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/CommonResponsibleGambling"),
            loading: Loading
        })
    },
    {
        path     : "terms-and-conditions",
        name     : "CommonTermsCondition",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/CommonTermsCondition"),
            loading: Loading
        })
    },
    {
        path     : "notification",
        name     : "Notification",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Notification"),
            loading: Loading
        })
    },
    {
        path     : "signup",
        name     : "Signup",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Signup"),
            loading: Loading
        })
    },
    {
        path     : "agent-request",
        name     : "AgentRequest",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/AgentRequestForm"),
            loading: Loading
        })
    },
    {
        path     : "deposit",
        name     : "Deposit",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Deposit"),
            loading: Loading
        })
    },
    {
        path     : "withdraw",
        name     : "Withdraw",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Withdraw"),
            loading: Loading
        })
    },
    {
        path     : "account-information",
        name     : "AccountInformation",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/AccountInformation"),
            loading: Loading
        })
    },

    {
        path     : "poc/exchange/:event_id",
        name     : "Poc",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Poc"),
            loading: Loading
        })
    },
    {
        path     : "poc/sportsbook/:event_id",
        name     : "Bet365Poc",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Bet365Poc"),
            loading: Loading
        })
    },
    {
        path     : "offers",
        name     : "offers",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Offers"),
            loading: Loading
        })
    },
    {
        path     : "/",
        name     : "home",
        exact    : true,
        component: Loadable({
            loader : () => import("./Views/Home"),
            loading: Loading
        })
    },
    {
        path     : "all-matches",
        name     : "AllMatches",
        exact    : true,
        page_id  : "sportbook_betting",
        component: Loadable({
            loader : () => import("./Views/AllMatches"),
            loading: Loading
        })
    },

]
const DefaultUserRoute = {
    path: "/",
}
const UserExchangeRoute = [
    {
        path     : "/",
        name     : "ExchangeHome",
        exact    : false,
        page_id  : "exchange_betting",
        component: Loadable({
            loader : () => import("./Views/ExchangeHome"),
            loading: Loading
        })
    },
    ...CommonRoute
]
const UserSportsbookRoute = [
    {
        path     : "/",
        name     : "SportsbookHome",
        exact    : false,
        page_id  : "sportbook_betting",
        component: Loadable({
            loader : () => import("./Views/SportsbookHome"),
            loading: Loading
        })
    },
    ...CommonRoute
]


export default { 
    UserRoute, 
    DefaultUserRoute, 
    UserExchangeRoute, 
    UserSportsbookRoute
}