import React, { Component, Fragment } from 'react';
import { _ } from 'Helpers';
import { withTranslation } from 'react-i18next';
import { Images } from 'Constants';
import {
  // Row, 
  // Col, 
  Table
} from 'reactstrap';


class Scoreboard extends Component {
  render() {
    const {
      ScoresData,
      EventDetails,
      sports_id,
      // is_sportsbook,
      t
    } = this.props
    const BlankBanner = () => ''
    // const BlankBanner = () => <div className="odds-banner" />
    // function NameAbbr(str) {
    //   var matches = str.match(/\b(\w)/g);
    //   return matches.join('')
    // }
    const CricketCell = ({ data, type }) => {
      // if(_.isUndefined(data[type])){
      //   return <div></div>
      // }
      // data = JSON.parse(data)[type];
      data = JSON.parse(data);
      let wicket = !_.isUndefined(data['i'+type]) ? data['i'+type].wk != 'ALL_OUT' ? data['i'+type].wk : '10' : '';
      
      return (
        <div className="cell">
          <div className="event-name-abbr">
            {
              // type === 'home' ?
                <Fragment>
                  {`${data['t'+type].n}`}
                  {/* {`${NameAbbr(data.name)}`} */}
                  {!_.isUndefined(data['i'+type]) ? ` - ${data['i'+type].sc}/${wicket}` : ' - 0/0'}
                </Fragment>
                // :
                // <Fragment>
                //   {!_.isUndefined(data.i2) ? `${data.i2.sc}/${wicketA} - ` : ' 0/0 - '}
                //   {`${data.t2.n}`}
                //   {/* {`${NameAbbr(data.name)}`} */}
                // </Fragment>

            }
          </div>
         {
          // type === 'home' ?
          <div className="event-over">
            {!_.isUndefined(data['i'+type]) ? `(${data['i'+type].ov} Ov)` : '(0.0 Ov)'}
          </div>
        //   :
        //   <div className="event-over">
        //   {!_.isUndefined(data['i'+type]) ? `(${data['i'+type].ov} Ov)` : '(0.0 Ov)'}
        // </div>
          }
        </div>
      )
    }

    const SoccerCell = ({ data, type }) => {
      data = JSON.parse(data)[type]
      return (
        <div className="cell">
          <div className="event-name-abbr">
            {
              type === 'home' ?
                <Fragment>
                  {data.name}{" - "}{data.score}
                </Fragment>
                :
                <Fragment>
                  {data.score}{" - "}{data.name}
                </Fragment>
            }
          </div>
          {/* <div className="event-over">
            {!_.isUndefined(data.i1) ? `(${data.i1.ov} Ov)` : '(0.0 Ov)'}
          </div> */}
        </div>
      )
    }

    // const TennisCell = ({ data, type }) => {
    //   data = JSON.parse(data)[type]
    //   return (
    //     <Fragment>
    //       <div className="cell">
    //         <div className="event-name-abbr-sm">
    //           {data.name || 'Player Name'}
    //         </div>
    //       </div>
    //       <div className="cell gameSequence">
    //         {_.map(data.gameSequence, (item, key) => <span {...{ key }}>{item}</span>)}
    //       </div>
    //     </Fragment>
    //   )
    // }


    // const PlayingBatsmenTable = () => {
    //   return (
    //     <Table>
    //       <thead>
    //         <tr>
    //           <th className={'text-left'}>BATSMAN</th>
    //           <th>R</th>
    //           <th>B</th>
    //           <th>4s</th>
    //           <th>6s</th>
    //           <th>SR</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr>
    //           <td className={'text-left'}>Shubman Gill*</td>
    //           <td>6</td>
    //           <td>8</td>
    //           <td>6</td>
    //           <td>8</td>
    //           <td>5</td>
    //         </tr>
    //         <tr>
    //           <td className={'text-left'}>R Sharma</td>
    //           <td>5</td>
    //           <td>9</td>
    //           <td>9</td>
    //           <td>4</td>
    //           <td>5</td>
    //         </tr>
    //       </tbody>
    //     </Table>
    //   )
    // }


    // const BowlerStatsTable = () => {
    //   let data = JSON.parse(ScoresData.state_of_ball);
    //   return (
    //     <Table>
    //       <thead>
    //         <tr>
    //           <th className={'text-left'}>BOWLER</th>
    //           <th>O</th>
    //           <th>M</th>
    //           <th>R</th>
    //           <th>W</th>
    //           <th>ECO</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr>
    //           <td className={'text-left'}>{data.bowlerName}</td>
    //           <td>{data.overNumber || '0'}</td>
    //           <td>8</td>
    //           <td>6</td>
    //           <td>8</td>
    //           <td>5</td>
    //         </tr>
    //       </tbody>
    //       <tfoot>
    //         <tr>
    //           <td colSpan={6} className={'text-left'}>
    //             <div className={'over_st'}>
    //               <span>This over-</span>
    //               <ul>
    //                 <li>6</li>
    //                 <li>0</li>
    //                 <li>w</li>
    //                 <li>w</li>
    //               </ul>
    //             </div>

    //           </td>
    //         </tr>
    //       </tfoot>
    //     </Table>
    //   )
    // }

    const TennisPlayers = () => {

      let data = JSON.parse(ScoresData.score);

      return (
        <div className={'cur_odds_tbl_grp tennis_tbl'}>
          <Table>
            <thead>
              <tr>
                <th ></th>
                {
                  data.home.gameSequence.map((itm, i) => {
                    return (
                      <th width={'50px'} key={i}>{`Set-${i + 1}`}</th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={'text-left'}><b>{data.home.name || 'Player Name'}</b></td>
                {
                  data.home.gameSequence.map((itm, i) => {
                    return (
                      <td key={i}>{itm}</td>
                    )
                  })
                }
              </tr>
              <tr>
                <td className={'text-left'}><b>{data.away.name || 'Player Name'}</b></td>
                {
                  data.away.gameSequence.map((itm, i) => {
                    return (
                      <td key={i}>{itm}</td>
                    )
                  })
                }
              </tr>
            </tbody>
          </Table>
        </div>
      )
    }


    const Timer = (data) => {
      data = data.full_time_elapsed?  JSON.parse(data.full_time_elapsed): {hour:'N/A', min:'0', sec:'0'};
      return <div className={'soc-timer'}>
        <span>{data.hour}</span>:<span>{data.min}</span>:<span>{data.sec}</span>
      </div>
    }

    const Template = () => {
      // if (_.isEmpty(ScoresData)) return ''
      let score = JSON.parse(ScoresData.score);

      switch (sports_id) {
        case "2":
          return (
            <div>
              <div className="score-list">
                <CricketCell data={ScoresData.score} type='1' />
                <div className="cell vs">
                  {t('vs')}
                  <div>{EventDetails.is_live == '1' ? t('Live') : t('Upcoming')}</div>
                </div>
                <CricketCell data={ScoresData.score} type='2' />
              </div>

             {score.i3 && score.i4 &&
             <div className="score-list">
                <CricketCell data={ScoresData.score} type='3' />
                <div className="cell vs">
                  {t('vs')}
                  <div>{EventDetails.is_live == '1' ? t('Live') : t('Upcoming')}</div>
                </div>
                <CricketCell data={ScoresData.score} type='4' />
              </div>}
              
              {/* <div className={'cur_odds_tbl_grp'}>
                <Row>
                  <Col xs={12} md={6} className={'mb-2'}>
                    <PlayingBatsmenTable />
                  </Col>
                  <Col xs={12} md={6} className={'mb-2'}>
                    <BowlerStatsTable />
                  </Col>
                </Row>
              </div> */}
            </div>
          )
        case "1":
          return (
            <div>
              {Timer(ScoresData)}
              <div className="score-list">
                <SoccerCell data={ScoresData.score} type='home' />
                <div className="cell vs">
                  {t('vs')}
                  <div>{EventDetails.is_live == '1' ? t('Live') : t('Upcoming')}</div>
                </div>
                <SoccerCell data={ScoresData.score} type='away' />
              </div>
            </div>

          )
        case "3":
          return (
            <Fragment>
              {/* <div className="score-list">
                <TennisCell data={ScoresData.score} type='home' />
              </div>
              <div className="score-list">
                <TennisCell data={ScoresData.score} type='away' />
              </div> */}
              <TennisPlayers />

            </Fragment>
          )
        default:
          return '';
      }
    }
    return (
      !_.isEmpty(ScoresData) ?
        <div className="odds-banner scoreboard" style={{ backgroundImage: `url(${Images.ODDS_BANNER})` }}>
          <div className="inner-scoreboard">
            <div className='league-name'>{EventDetails.league_name}</div>
            <div className='event-name'>{EventDetails.event_name}</div>
            <Template />
          </div>
        </div>
        :
        <BlankBanner />
    )
  }
}
Scoreboard.defaultProps = {
  ScoresData: [],
  EventDetails: {},
  sports_id: '',
  is_sportsbook: false
}
export default withTranslation()(Scoreboard);
