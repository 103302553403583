import React, { Fragment } from 'react';
import { Modal, ModalHeader } from 'reactstrap'
import YouTube from 'react-youtube';

class VideoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        const { isOpen, toggle } = this.props;

        const YoutubeOpts = {
            width: "100%",
            playerVars: {
                autoplay: 1,
                controls: 0,
                disablekb: 1,
                modestbranding: 0
            }
        };

        return (
            <Fragment>
                <Modal isOpen={isOpen} toggle={toggle} className='signup-video-dialog' size={'md'} >
                    <ModalHeader>
                        <div className={'text-right'}>
                            <i className="close-btn icon-cancel" onClick={toggle} />
                        </div>
                    </ModalHeader>
                    {/* <h4 className='title text-center'>Select Language</h4> */}
                    <YouTube
                        videoId={this.props.videoId}
                        opts={YoutubeOpts}
                    // onReady={this._onReady}
                    />
                </Modal>
            </Fragment>
        )
    }
}


VideoModal.defaultProps = {
    isOpen: false
}

export default VideoModal;