import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { SiteContainer, AgentLinkNav } from 'Components';
import { Images } from 'Constants';
import { Utils, API, Http, Role, _ } from 'Helpers';

import { notify } from 'react-notify-toast';
import { Auth } from 'Service';
import { VideoModal } from 'Modals';


class Withdraw extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      Wallet: '',
      selectedPayment: '1',
      BankName: '',
      BankAccountHolder: '',
      BankAccountNumber: '',
      Ifsc: '',
      paytm: '',
      phonePe: '',
      googlePay: '',
      min_withdraw_amt: 0,
      max_withdraw_amt: 10,
      withdraw_amt_const: [],
      paymentList: [],
      VideoModalOpen:false
    }

    const CurrentUser = Auth.currentUserValue;
    if (CurrentUser != null && CurrentUser.role != Role.AgentPunter) {
      this.props.history.push('/')
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm = () => {
    let { Wallet, selectedPayment, BankName, BankAccountHolder, BankAccountNumber, Ifsc, min_withdraw_amt, max_withdraw_amt, paytm, phonePe, googlePay } = this.state;


    let validate = false;

    if (selectedPayment == 1) {
      validate = selectedPayment != '' &&
        BankName != '' &&
        BankAccountHolder != '' &&
        BankAccountNumber != '' &&
        Ifsc != ''
    }

    if (selectedPayment == 2) {
      validate = Utils.isValid("mobile", paytm)
    }

    if (selectedPayment == 3) {
      validate = Utils.isValid("mobile", phonePe)
    }

    if (selectedPayment == 4) {
      validate = Utils.isValid("mobile", googlePay)
    }

    this.setState({
      formValid: selectedPayment != '' &&
        Wallet != '' &&
        Wallet >= min_withdraw_amt &&
        Wallet <= max_withdraw_amt &&
        validate
    })
  }


  paymentOptHandler = key => {
    this.setState({ selectedPayment: key }, this.validateForm)
  }

  amtSelectHandler = amt => {
    this.setState({ Wallet: amt }, this.validateForm)
  }

  paymentArrayCreate = (data) => {
    let paymentList = [];
    data.forEach(pg => {
      if (pg.name === 'Bank') {
        pg.img = Images.BANK_TRANSFER;
        pg.img_white = Images.BANK_WHITE;
      }
      if (pg.name === 'PayTm') {
        pg.img = Images.PAYTM_LOGO;
        pg.img_white = Images.PAYTM_WHITE;
      }
      if (pg.name === 'PhonePe') {
        pg.img = Images.PHONEPE_LOGO;
        pg.img_white = Images.PHONEPAY_WHITE;
      }
      if (pg.name === 'GooglePay') {
        pg.img = Images.GPAY;
        pg.img_white = Images.GPAY_WHITE;
      }
      paymentList.push(pg)
    })
    this.setState({ paymentList })

  }

  user_payment_details_handler = (data) => {

    let user_pg_data = data.user_pg_data;

    if (user_pg_data !== null) {

      if (user_pg_data.bank_acc_detail) {

        let bank_acc_detail = JSON.parse(user_pg_data.bank_acc_detail);

        this.setState({
          BankName: bank_acc_detail.bank_name,
          BankAccountHolder: bank_acc_detail.account_holder,
          BankAccountNumber: bank_acc_detail.account_number,
          Ifsc: bank_acc_detail.ifsc_code,
        })
      }

      if (user_pg_data.paytm_detail) {
        let paytm = JSON.parse(user_pg_data.paytm_detail);
        this.setState({
          paytm: paytm.phone
        })
      }

      if (user_pg_data.phonepe_detail) {
        let phonepe = JSON.parse(user_pg_data.phonepe_detail);
        this.setState({
          phonePe: phonepe.phone
        })
      }

      if (user_pg_data.google_pay_detail) {
        let googlePay = JSON.parse(user_pg_data.google_pay_detail);
        this.setState({
          googlePay: googlePay.phone
        })
      }

    }
  }

  getMasterData = () => {
    Http.post(API.GET_WITHDRAW_REQUEST_MASTER)
      .then(res => {

        // console.log(Object.keys(res.data.payment_gateway_type));

        // res.data.payment_gateway_type.map((cal) => {
        //   console.log(cal)
        // })

        let data = res.data;

        this.paymentArrayCreate(data.payment_gateway_type);

        this.user_payment_details_handler(data);


        this.setState({
          min_withdraw_amt: data.min_withdraw_amt,
          max_withdraw_amt: data.max_withdraw_amt,
          withdraw_amt_const: data.withdraw_amt_const
        })
      })
  }


  componentDidMount() {
    this.getMasterData()
  }



  submitHandler = e => {
    e.preventDefault();

    let { Wallet, selectedPayment, BankName, BankAccountHolder, BankAccountNumber, Ifsc, paytm, phonePe, googlePay } = this.state;

    let param = {
      amount: Wallet,
      payment_gateway: selectedPayment,
    }

    if (selectedPayment == 1) {
      param['bank_name'] = BankName
      param['ifsc_code'] = Ifsc
      param['account_holder'] = BankAccountHolder
      param['account_number'] = BankAccountNumber
    }

    if (selectedPayment == 2) {
      param['phone'] = paytm
    }

    if (selectedPayment == 3) {
      param['phone'] = phonePe
    }

    if (selectedPayment == 4) {
      param['phone'] = googlePay
    }

    this.setState({ posting: true })

    Http.post(API.MAKE_WITHDRAW_REQUEST, param)
      .then(res => {
        notify.show(res.message, "success", 2500)
        setTimeout(() => window.location.reload(), 2500)
      })
      .catch(err => {
        this.setState({ posting: false })
      })

  }


  render() {
    const { t } = this.props
    const { Wallet, formValid, posting, BankName, BankAccountHolder, BankAccountNumber, Ifsc, selectedPayment,
      withdraw_amt_const, paytm, phonePe, googlePay, paymentList, min_withdraw_amt, VideoModalOpen
    } = this.state;

    // console.log(this.state)
    const CurrentUser = Auth.currentUserValue;

    const VideoModalProps = {
      isOpen: VideoModalOpen,
      toggle: () => this.setState({ VideoModalOpen: false }),
      videoId: selectedPayment == 1 ? "slDKQ4hqybk" :
        selectedPayment == 2 ? "ouAENIUXk4g" :
          selectedPayment == 3 ? "5GMJjNNT8PI" :
            "_3nTlZRXe_M"
    }



    return (
      <SiteContainer {...this.props}>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <AgentLinkNav {...this.props} />
            </Col>
            <Col>
              <div className="white-box">
                <div className="title">{t('Withdraw')}</div>

                <Form onSubmit={this.submitHandler}>

                  <ul className="payment-option-row">
                    {
                      paymentList.map((pl, i) => {
                        return <li key={i} onClick={() => this.paymentOptHandler(pl.id)} className={selectedPayment == pl.id ? 'active' : ''}>
                          <img src={pl.img} alt="" className={'main_img'} />
                          <img src={pl.img_white} alt="" className={'active_img'} />
                        </li>
                      })
                    }

                  </ul>

                  <Row>
                    <Col className={'dp-head-note mb-4 c-pointer'} onClick={() => this.setState({ VideoModalOpen: true })}>Learn more about withdraw, watch this video.</Col>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      <FormGroup className='input-border-bottom'>
                        <span className='label pl-0'>{t('Transfer money to Isocial using below details')}</span>
                        <div>Minimum Transfer value : {min_withdraw_amt}</div>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup className='input-border-bottom'>
                        <span className='label pl-0'>{t('Current Balance')}</span>
                        <div>{CurrentUser ? `Pts. ${_.round(CurrentUser.balance, 2)}` : '-'}</div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>

                    {
                      selectedPayment == 1 ?
                        <React.Fragment>

                          <Col sm={6} xs={12}>

                            <FormGroup className='input-border-bottom pb-3' >
                              <span className='label'>{t('Bank Name')}</span>
                              <Input
                                type="text"
                                id='BankName'
                                name='BankName'
                                required
                                value={BankName || ''}
                                autoComplete="off"
                                onChange={this.handleChange}
                                invalid={Utils.isInvalid("reqiured", BankName)}
                                valid={Utils.isValid("reqiured", BankName)} />
                            </FormGroup>

                          </Col>
                          <Col sm={6} xs={12}>
                            <FormGroup className='input-border-bottom pb-3' >
                              <span className='label'>{t('Bank Account Holder')}</span>
                              <Input
                                type="text"
                                id='BankAccountHolder'
                                name='BankAccountHolder'
                                required
                                value={BankAccountHolder || ''}
                                autoComplete="off"
                                onChange={this.handleChange}
                                invalid={Utils.isInvalid("reqiured", BankAccountHolder)}
                                valid={Utils.isValid("reqiured", BankAccountHolder)} />
                            </FormGroup>
                          </Col>

                          <Col sm={6} xs={12}>
                            <FormGroup className='input-border-bottom pb-3' >
                              <span className='label'>{t('Bank Account Number')}</span>
                              <Input
                                type="number"
                                id='BankAccountNumber'
                                name='BankAccountNumber'
                                required
                                value={BankAccountNumber || ''}
                                autoComplete="off"
                                onChange={this.handleChange}
                                invalid={Utils.isInvalid("reqiured", BankAccountNumber)}
                                valid={Utils.isValid("reqiured", BankAccountNumber)} />
                            </FormGroup>
                          </Col>

                          <Col sm={6} xs={12}>
                            <FormGroup className='input-border-bottom pb-3' >
                              <span className='label'>{t('IFSC Code')}</span>
                              <Input
                                type="text"
                                id='Ifsc'
                                name='Ifsc'
                                required
                                value={Ifsc || ''}
                                autoComplete="off"
                                onChange={this.handleChange}
                                invalid={Utils.isInvalid("reqiured", Ifsc)}
                                valid={Utils.isValid("reqiured", Ifsc)} />
                            </FormGroup>
                          </Col>
                        </React.Fragment>
                        :
                        <Col sm={6} xs={12}>
                          <FormGroup className='input-border-bottom pb-3' >
                            {
                              selectedPayment == 2 ?
                                <React.Fragment>
                                  <span className='label'> {t('Paytm Number')}</span>
                                  <Input
                                    type="text"
                                    id='paytm'
                                    name='paytm'
                                    required
                                    value={paytm || ''}
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                    invalid={Utils.isInvalid("mobile", paytm)}
                                    valid={Utils.isValid("mobile", paytm)}
                                  />
                                </React.Fragment>
                                :
                                selectedPayment == 3 ?
                                  <React.Fragment>
                                    <span className='label'>{t('Phonepe Number')}</span>
                                    <Input
                                      type="text"
                                      id='phonePe'
                                      name='phonePe'
                                      required
                                      value={phonePe || ''}
                                      autoComplete="off"
                                      onChange={this.handleChange}
                                      invalid={Utils.isInvalid("mobile", phonePe)}
                                      valid={Utils.isValid("mobile", phonePe)}
                                    />
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    <span className='label'>{t('Gpay Number')}</span>
                                    <Input
                                      type="text"
                                      id='googlePay'
                                      name='googlePay'
                                      required
                                      value={googlePay || ''}
                                      autoComplete="off"
                                      onChange={this.handleChange}
                                      invalid={Utils.isInvalid("mobile", googlePay)}
                                      valid={Utils.isValid("mobile", googlePay)}
                                    />
                                  </React.Fragment>


                            }


                          </FormGroup>
                        </Col>


                    }


                  </Row>


                  <Row>
                    <Col sm={6} xs={12}>

                      <FormGroup className='input-border-bottom' >
                        <span className='label'>{t('Withdraw Amount')}</span>
                        <Input
                          type="number"
                          id='Wallet'
                          name='Wallet'
                          required
                          value={Wallet || ''}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", Wallet)}
                          valid={Utils.isValid("reqiured", Wallet)}
                          min={this.state.min_withdraw_amt}
                          max={this.state.max_withdraw_amt}
                        />
                      </FormGroup>

                      <ul className="list-widthdraw-amount ">
                        {
                          withdraw_amt_const.map((amt, i) => {
                            return <li key={i} onClick={() => this.amtSelectHandler(amt)}>{amt}</li>
                          })
                        }
                      </ul>



                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <FormGroup className='m-0 text-center pt-5 '>
                        <Button disabled={!formValid || posting} type='submit' size="lg" color="primary"
                          className='round px-5'>{t('Request Withdraw')}</Button>
                      </FormGroup>
                    </Col>
                  </Row>

                </Form>
              </div>
            </Col>
          </Row>
          {VideoModalOpen && <VideoModal {...VideoModalProps} />}
        </Container>

      </SiteContainer>
    )
  }
}

export default withTranslation()(Withdraw);

