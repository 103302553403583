import React, { Component } from "react";
import { Container, Row, Col, Modal, ModalBody, FormGroup } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { withTranslation } from "react-i18next";

import { SiteContainer, MessageBox, AgentLinkNav } from "Components";
import { CONSTANT } from "Constants";
//Images
import { Http, API, Utils, _, Role } from "Helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "Service";
import { ScrollSportstab } from "Views";

class AccountStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: false,
      posting: false,
      Offset: CONSTANT.CURRENT_OFFSET,
      from_date: moment().subtract(1, "month").toDate(),
      to_date: moment().toDate(),
      StatementList: [],
      FilValue: [{ id: "0", name: "All" }],
      payment_gateway_type: [],
      NavigationList: [],
      page_id: "sportbook_betting",
      activeSportsTab: "",
      isLoaderShow: false,
      filterModalShow: false,
    };
    this.CurrentUser = Auth.currentUserValue;
    this.datePickerRef = React.createRef();
    this.datePickerRefClick = React.createRef();
  }

  handleIconClick = () => {
    if (this.datePickerRef.current) {
      this.datePickerRef.current.setFocus();
    }
  };
  handleIconClickS = () => {
    if (this.datePickerRefClick.current) {
      this.datePickerRefClick.current.setFocus();
    }
  };

  //LIST API

  getStatemntList = () => {
    this.setState({ isLoaderShow: true });
    const {
      Offset,
      StatementList,
      from_date,
      to_date,
      FilValue,
      page_id,
      activeSportsTab,
    } = this.state;
    let alltab = [
      {
        category_id: "",
        sports_name: "All",
        status: "1",
      },
    ];
    let param = {
      limit: CONSTANT.ITEMS_PERPAGE,
      offset: Offset,
      from_date: moment.utc(from_date).format("Y-MM-DD HH:mm"),
      to_date: moment.utc(to_date).format("Y-MM-DD HH:mm"),
      filter: FilValue.id,
      sports_id: activeSportsTab ? activeSportsTab : "",
      search: "",
    };

    this.setState({ posting: true });
    Http.post(API.ACCOUNTS_STATEMENT, param)
      .then((response) => {
        this.setState(
          {
            posting: false,
            StatementList:
              Offset == CONSTANT.CURRENT_OFFSET
                ? response.data
                : [...StatementList, ...response.data],
            hasMore: !_.isEmpty(response.data),
            NavigationList: [
              ...alltab,
              ...Utils.getMasterData().sports_list[page_id],
            ],
          },
          () => {}
        );
      })
      .catch((error) => {
        this.setState({ posting: false });
      });
  };

  //Handlers

  handleFilterChange = (FilValue) => {
    this.setState(
      { FilValue: FilValue, Offset: CONSTANT.CURRENT_OFFSET },
      () => {
        this.getStatemntList();
      }
    );
  };

  validateForm() {
    const { from_date } = this.state;
    this.setState({
      formValid: Utils.isInvalid("date", from_date) === false,
    });
  }

  fetchMoreData = () => {
    let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE;
    this.setState({ Offset: offset }, () => {
      this.getStatemntList();
    });
  };

  SearchData = () => {
    this.setState(
      {
        Offset: CONSTANT.CURRENT_OFFSET,
        filterModalShow: false,
      },
      () => {
        this.getStatemntList();
      }
    );
  };

  onDateChange = (date, key) => {
    this.setState({ [key]: date }, this.validateForm);
  };

  ResetData = () => {
    this.setState(
      {
        from_date: moment().subtract(1, "month").toDate(),
        to_date: moment().toDate(),
        FilValue: [{ id: "0", name: "All" }],
        Offset: CONSTANT.CURRENT_OFFSET,
        //filterModalShow:false
      },
      () => {
        this.getStatemntList();
      }
    );
  };

  // LIFE CYLCE METHODS
  componentDidMount() {
    if (this.CurrentUser != null && this.CurrentUser.role == Role.AgentPunter) {
      this.getPaymentGatewayList();
    }

    this.getStatemntList();
  }

  //download
  downloadCsv = () => {
    // this.setState({ isLoaderShow: true });

    const {
      Offset,
      from_date,
      to_date,
      activeSportsTab,
      FilValue,
    } = this.state;

    const param = {
      limit: CONSTANT.ITEMS_PERPAGE,
      offset: Offset,
      from_date: moment.utc(from_date).format("Y-MM-DD HH:mm"),
      to_date: moment.utc(to_date).format("Y-MM-DD HH:mm"),
      filter: FilValue.id,
      sports_id: activeSportsTab ? activeSportsTab : "",
      search: "",
      is_export: 1,
    };

    Http.post(API.ACCOUNTS_STATEMENT, param)
      .then((response) => {
        const data = response.data;

        // Define headers and values for CSV
        const headers = [
          "Market name",
          "Event name",
          "Date",
          "Order ID",
          "Credit/Debit",
        ];
        const values = data.map((item) => [
          item.bet_data.market_name,
          item.event_name,
          moment(item.date_added).format("DD/MM/YYYY"),
          item.order_unique_id,
          item.bet_data.profit_loss,
        ]);

        const csvData = [headers, ...values];
        const csvString = csvData.map((row) => row.join(",")).join("\n");

        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "account-statement.csv";
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // this.setState({ isLoaderShow: false });
      })
      .catch((error) => {
        this.setState({ posting: false, isLoaderShow: false });
      });
  };

  getPaymentGatewayList = () => {
    Http.post(API.GET_DEPOSIT_REQUEST_MASTER, {}).then((res) => {
      this.setState({
        payment_gateway_type: res.data.payment_gateway_type,
      });
    });
  };

  gamesChipsLabel = (source) => {
    source = source ? Number(source) : source;

    let chips = [1, 2, 3, 4, 27, 30];
    let games = [6, 10];

    let Agentchips = [16, 17, 18, 19];
    let Agentgames = [21, 22];

    if (chips.includes(source) || Agentchips.includes(source)) {
      return "Chips";
    }
    if (games.includes(source) || Agentgames.includes(source)) {
      return "Games";
    }

    return "N/A";
  };

  descriptionLabel = (itm) => {
    let gateways = this.state.payment_gateway_type;
    let data = itm.custom_data ? JSON.parse(itm.custom_data) : null;
    // console.log(itm.payment_gateway, itm.source, data);

    if (!data) {
      return `Admin`;
    }

    if (gateways.length == 0) return " ";

    let getGateway = gateways.find((dt) => dt.id === itm.payment_gateway);

    if (itm.payment_gateway === "1") {
      if (itm.source === "16") {
        return `${getGateway.name}, Ref. ID:- ${data.ref_txn_id}, Remarks:- ${itm.remark}`;
      } else {
        return (
          getGateway.name + " " + data.bank_name + " " + data.account_number
        );
      }
    } else {
      if (itm.source === "16") {
        return `${getGateway.name}, ${data.phone} Ref. ID:- ${data.ref_txn_id}, Remarks:- ${itm.remark}`;
      } else {
        return getGateway.name + " " + data.phone;
      }
    }
  };
  onTabClick = (item) => {
    this.setState(
      {
        activeSportsTab: item.sports_id,
        StatementList: [],
        isLoaderShow: false,
        Offset: CONSTANT.CURRENT_OFFSET,
      },
      () => {
        this.getStatemntList();
      }
    );
  };
  filterModal = () => {
    this.setState({ filterModalShow: true });
  };
  filterModalHide = () => {
    this.setState({
      from_date: moment().subtract(1, "month").toDate(),
      to_date: moment().toDate(),
      FilValue: [{ id: "0", name: "All" }],
      Offset: CONSTANT.CURRENT_OFFSET,
      filterModalShow: false,
    });
  };
  isWithin30Days(selectedDate) {
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);
    const differenceInMilliseconds = selectedDateObj - currentDate;
    const millisecondsIn31Days = 31 * 24 * 60 * 60 * 1000;
    return Math.abs(differenceInMilliseconds) <= millisecondsIn31Days;
  }
  renderSportsNav = (AvaSports) => {
    return (
      <ScrollSportstab
        tabsContainerClassName="sp-tb-scroll-container"
        {...this.props}
      >
        {({ Tab }) => {
          return _.map(AvaSports, (item, idx) => {
            return (
              <Tab className="sp-item" key={idx}>
                <span onClick={() => this.onTabClick(item, idx)}>
                  {
                    item.sports_name
                    // Sports.url[item.value + (WSManager.getAppLang() || '')]
                  }
                </span>
              </Tab>
            );
            // }
          });
        }}
      </ScrollSportstab>
    );
  };

  render() {
    const { t } = this.props;
    const {
      StatementList,
      hasMore,
      NavigationList,
      isLoaderShow,
      from_date,
      to_date,
      filterModalShow,
      posting,
    } = this.state;
    // const options = [
    //   { id: '0', name: 'All' },
    //   { id: '1', name: 'Chips' },
    //   { id: '2', name: 'Games' }
    // ]

    return (
      <SiteContainer {...this.props}>
        <Container fluid className="gutters-container-7px">
          <Row>
            <Col className="leftnav-col">
              <AgentLinkNav {...this.props} />
            </Col>
            <Col>
              {/* <div className="bet-history-heading">
                <div className="bet-history-view">
                  <img src={Images.BACK_IMG} alt="" />
                  <i className="icon-previous" />
                  <span> {t("Account Statement")}</span>
                </div>
                <i
                  className="icon-hamburger-ic"
                  onClick={() => {
                    this.filterModal();
                  }}
                />
              </div> */}
              <div className="sports-tab-view">
                <div className="sports-name">
                  {this.renderSportsNav(NavigationList)}
                </div>
              </div>

              <div className="container">
                {/* <div className="odds-padding-row heading">
                  <div className="title">{t('Account Statement')}</div>
                </div> */}
                <div className="history-last-six-month-view">
                  <div className="text-six-month">
                    <div>
                      P&L History{" "}
                      <span>
                        {this.isWithin30Days(from_date) ? "(last 30 Days)" : ""}
                      </span>
                    </div>
                    {!_.isEmpty(StatementList) && <div
                      className="csv-text"
                      onClick={() => this.downloadCsv()}
                    >
                      <i className="icon-csv-download" />
                      CSV
                    </div>}
                    <i
                      className="icon-hamburger-ic"
                      onClick={() => {
                        this.filterModal();
                      }}
                    />
                  </div>
                </div>

                {!_.isEmpty(StatementList) && isLoaderShow ? (
                  <>
                    <div className="history-last-six-month-view">
                      <div className="history-container">
                        <InfiniteScroll
                          dataLength={StatementList.length}
                          next={this.fetchMoreData}
                          hasMore={hasMore}
                          hasChildren={false}
                          className=""
                        >
                          {_.map(StatementList, (item, index) => {
                            return (
                              <div
                                className="history-container-view"
                                key={index}
                              >
                                <div className="history-inner-header">
                                  <i
                                    className={`img-view icon-${item.sports_id}`}
                                  ></i>
                                  {/* <img alt="" src={Images.ENGLAND_LOGO} /> */}
                                  <div className="league-name">
                                    {item.league_name}
                                  </div>
                                </div>
                                <div className="team-name-and-date-con">
                                  <div className="team-name">
                                    {item.event_name}
                                  </div>
                                  <div className="date-view">
                                    {Utils.dateTimeFormat(
                                      item.date_added,
                                      "DD/MM/YYYY"
                                    )}
                                  </div>
                                </div>
                                <div className="inner-view-data">
                                  <Row>
                                    <Col xs={6}>
                                      <div className="text-view">Order ID</div>
                                      <div className="number-value-view">
                                        {item.order_unique_id
                                          ? item.order_unique_id
                                          : "--"}
                                      </div>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                      <div className="text-view">
                                        {t("Credit/Debit")}
                                      </div>
                                      <div
                                        className={`number-value-view ${
                                          item.bet_data.profit_loss > 0
                                            ? "text-color-success"
                                            : "text-color-danger"
                                        }`}
                                      >
                                        {item.bet_data.profit_loss
                                          ? item.bet_data.profit_loss
                                          : "--"}
                                      </div>
                                    </Col>
                                    {/* <Col xs={4} className='text-right'>
                                        <div className='text-view'>Balance</div>
                                        <div className='number-value-view'>{item.user_balance}</div>
                                      </Col> */}
                                  </Row>
                                </div>
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </>
                ) : (
                  !posting && (
                    <MessageBox paragraph={t(CONSTANT.BLANK_HISTORY)} />
                  )
                )}

                <Modal isOpen={filterModalShow} className="filter-modal-view">
                  <ModalBody>
                    <div className="filter-body-container">
                      <div className="filter-body-header">
                        <div className="sort-fitler-text">
                          <div
                            className="back-icon-view"
                            onClick={() => this.filterModalHide()}
                          >
                            {" "}
                            <i
                              className="icon-previous"
                              onClick={() => this.filterModalHide()}
                            />
                          </div>
                          <span> Sort & Filter</span>
                        </div>
                        <div
                          className="clear-all-view"
                          onClick={() => this.ResetData()}
                        >
                          clear All
                        </div>
                      </div>
                      <div className="filter-body-view">
                        {/* <div className='Date-view-text'>Date</div> */}
                        <Row className="filter-row-view">
                          <Col xs={4} className="filter-first-column">
                            <div className="date-text-view">From date</div>
                          </Col>
                          <Col xs={8} className="filter-second-column">
                            <div className="date-text-view">
                              <FormGroup>
                                {/* <Label>From Date</Label> */}

                                <div className={"cs-datepicker"}>
                                  <DatePicker
                                    ref={this.datePickerRef}
                                    selected={from_date}
                                    onChange={(e) =>
                                      this.onDateChange(e, "from_date")
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    timeCaption="time"
                                    className={""}
                                    maxDate={to_date}
                                  />
                                  <i
                                    className="icon-calendar cs-icon"
                                    onClick={this.handleIconClick}
                                  />
                                </div>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row className="filter-row-view">
                          <Col xs={4} className="filter-first-column">
                            <div className="date-text-view">To date</div>
                          </Col>
                          <Col xs={8} className="filter-second-column">
                            <div className="date-text-view">
                              <FormGroup>
                                {/* <Label>{t('To Date')}</Label> */}

                                <div className={"cs-datepicker"}>
                                  <DatePicker
                                    ref={this.datePickerRefClick}
                                    selected={to_date}
                                    onChange={(e) =>
                                      this.onDateChange(e, "to_date")
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    className={""}
                                    minDate={from_date}
                                    maxDate={moment().toDate()}
                                  />
                                  <i
                                    className="icon-calendar cs-icon"
                                    onClick={this.handleIconClickS}
                                  />
                                </div>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row className='filter-row-view' style={{ height: "250px" }}>
        <Col xs={4} className='filter-first-column'>
        </Col>
        <Col xs={8} className='filter-second-column'>
        </Col>
      </Row> */}
                      </div>
                    </div>
                    <div className="filter-modal-footer">
                      <div
                        className="botton-view-cancel"
                        onClick={() => this.filterModalHide()}
                      >
                        cancel
                      </div>
                      <div
                        className="botton-view-apply"
                        onClick={() => this.SearchData()}
                      >
                        Apply
                      </div>
                    </div>
                  </ModalBody>
                </Modal>

                {/* {<Row className="inline-form gutters-10px">
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('Start date')}</Label>
                      <div className={'cs-datepicker'}>
                        <DatePicker
                          selected={from_date}
                          onChange={(e) => this.onDateChange(e, 'from_date')}
                          dateFormat="dd/MM/yyyy HH:mm"
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          className={'form-control w-100'}
                          maxDate={to_date}
                        />
                        <i className='icon-calendar cs-icon' />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('End date')}</Label>
                      <div className={'cs-datepicker'}>
                        <DatePicker
                          selected={to_date}
                          onChange={(e) => this.onDateChange(e, 'to_date')}
                          dateFormat="dd/MM/yyyy HH:mm"
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          className={'form-control w-100'}
                          minDate={from_date}
                          maxDate={moment().toDate()}
                        />
                        <i className='icon-calendar cs-icon' />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('Filter')}</Label>
                      <Select
                        className='react-select'
                        classNamePrefix="react-select"
                        isSearchable={false}
                        options={options}
                        value={FilValue}
                        onChange={this.handleFilterChange}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <Button color="primary" disabled={posting} size="sm" onClick={() => this.SearchData()}>{t('Search')}</Button>
                    <Button color="primary" disabled={posting} size="sm" onClick={() => this.ResetData()}>{t('Reset')}</Button>
                   
                  </Col>
                </Row>} */}
                {/* <Row>
                  <Col style={{ minHeight: StatementList.length >= 1 ? 550 : 'auto' }}>
                    {StatementList.length > 0 ?
                      <InfiniteScroll
                        dataLength={StatementList.length}
                        next={this.fetchMoreData}
                        hasMore={hasMore}
                        hasChildren={false}
                        className=''
                      >
                        <Table className="table-primary mt20 statement-responise-tbl">
                          <thead>
                            <tr>
                              <th>{t('S.No')}</th>
                              <th>{t('Date')}</th>
                              <th>{t('Type')}</th>
                              <th>{t('Description')}</th>
                              <th>{t('Credit / Debit')}</th>
                              <th>{t('Balance')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              _.map(StatementList, (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{Utils.dateTimeFormat(item.date_added, 'DD/MM/YYYY, h:mm A')}</td>
                                    <td>{this.gamesChipsLabel(item.source)}</td>
                                    <td>
                                      {
                                        item.source === '3' ? " Amount deducted for user's deposit "
                                          : item.source === '4' ? " Amount received from user's withdrawal "
                                            : item.source == '5' ? ' Stake for '
                                              : item.source == '7' ? ' LayBetSettlement'
                                                : item.source == '8' ? ' Bet Cancelled for '
                                                  : item.source == '9' ? ' Rollback Settlement for '
                                                    : item.source == '10' ? ' Commission Earned for  '
                                                      : item.source == '11' ? '  Commission Deducted for '
                                                        : item.source == '12' ? ' Commission Reversal Added for '
                                                          : item.source == '13' ? ' Commission Reversal Deducted for '
                                                            : item.source == '14' ? ' Refund Processed for '
                                                              : item.source == '15' ? ' Cancelled by admin '
                                                                : item.source == '16' ? ` Amount Deposited by ${this.descriptionLabel(item)}`
                                                                  : item.source == '17' ? ` Amount Withdrawn by ${this.descriptionLabel(item)}`
                                                                    : item.source === "18" ? " Amount Withdrawn "
                                                                      : item.source === "19" ? " Amount Deposited "
                                                                        : item.source === "20" ? "Placebet "
                                                                          : item.source === "21" ? "bet settled "
                                                                            : item.source === "22" ? "Agent Commission added "
                                                                              : item.source === "23" ? "Commission Deducted "
                                                                                : item.source === "24" ? "Against Punter win "
                                                                                  : item.source === "25" ? "Txn against agent commission "
                                                                                    : item.source === "26" ? "Txn against sub agent commission "
                                                                                      : item.source === "27" ? " Welcome Bonus "
                                                                                        : item.source === "30" ? " Refund against withdraw reject "
                                                                                          : item.source === "31" ? " Bet Cancelled for "
                                                                                            : ""

                                      }

                                      {
                                        item.source === "1" ? <React.Fragment>
                                          {
                                            item.reason === "Opening Balance" ?
                                              <React.Fragment>Opening balance by Admin</React.Fragment> :
                                              <React.Fragment>Deposited by Admin</React.Fragment>
                                          }
                                        </React.Fragment>
                                          :
                                          item.source == '2' ? 'Withdrawal by Admin' :
                                            !_.isEmpty(item.bet_data) ?
                                              <Fragment>
                                                {
                                                  item.bet_data.event_name
                                                } {' '}
                                                {item.source == '6' &&
                                                  (item.bet_data.bookmaker_type == 2 || item.bet_data.bookmaker_type == 4) ?
                                                  <Fragment>
                                                    {item.bet_data.market_name}
                                                    {' '}
                                                    {
                                                      item.bet_data.winning > 0 ?
                                                        'Profit' : 'Loss'
                                                    }
                                                    {' '}
                                                    {
                                                      !_.isNull(item.bet_data.market_runners) &&

                                                      <span>
                                                        {item.bet_data.settlement_result !== '0' ?
                                                          <React.Fragment>
                                                            [Winner : {_.map(JSON.parse(item.bet_data.market_runners), (itm, indx) => {
                                                              return (
                                                                Number(item.bet_data.bookmaker_type) == 4 ?
                                                                  item.bet_data.settlement_result == itm.id ? itm.name : '' :
                                                                  item.bet_data.settlement_result == itm.selectionId &&
                                                                  itm.runnerName
                                                              )
                                                            })}]
                                                          </React.Fragment>
                                                          :
                                                          <React.Fragment>[Total Loss]</React.Fragment>}
                                                      </span>



                                                    }
                                                    {
                                                      item.bet_data.bookmaker_type == 4 &&
                                                      <span>
                                                        {' '}Round ID {' '}
                                                        {item.bet_data.event_id}
                                                      </span>
                                                    }

                                                  </Fragment> :
                                                  <Fragment>
                                                    {item.bet_data.market_name}
                                                    {Number(item.bet_data.bookmaker_type) !== 5 && <>
                                                      {' '}
                                                      Result({item.bet_data.settlement_result})
                                                    </>}
                                                  </Fragment>

                                                }
                                                {(item.source !== '6' && item.source !== '21') && <span className="block">
                                                  Bet ID: {item.bet_data.betslip_uid}

                                                </span>}
                                              </Fragment>
                                              : ''

                                      }



                                    </td>
                                    <td>
                                      {item.real_amount !== 0 ? <span className={item.type == 2 ? 'danger-color' : 'green-color'}>
                                        {item.type == 2 ? '-' : ''}
                                        {
                                          item.real_amount
                                        }

                                      </span>
                                        :
                                        <span>
                                          {item.real_amount}
                                        </span>
                                      }
                                    </td>
                                    <td>{item.user_balance}</td>
                                  </tr>
                                )
                              })
                            }


                          </tbody>

                        </Table>
                      </InfiniteScroll>
                      :
                      <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} />
                    }
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
        </Container>
      </SiteContainer>
    );
  }
}

export default withTranslation()(AccountStatement);
