import React, { Component } from 'react';
import { _, API, Http } from 'Helpers';
import { CONSTANT } from 'Constants';
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      Selected: {},
      SubMenu: []
    }
  }
  
  handleSelect = (Selected = {}) => {
    this.setState({
      Selected
    }, () => {
      if (_.isEmpty(Selected)) {
        this.setState({
          SubMenu: []
        });
      } else {
        this.getActiveLeague()
      }
    })
  }

  getActiveLeague = () => {
    const { page_id } = this.props
    const { Selected } = this.state
    this.setState({ posting: true })
    let param = {
      "sports_id": Selected.sports_id
    }
    Http.post(API[('GET_ACTIVE_LEAGUES_BY_SPORT_' + page_id).toUpperCase()], param).then(response => {
      this.setState({ 
        posting: false,
        SubMenu: response.data.league_list
      })      
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  handleLeague = (item) => {
    const { Selected } = this.state
    this.props.callback({ Sport: Selected, League: item, unbind: true })
  }

  render() {
    const { 
      menu
    } = this.props;
    const { 
      Selected,
      posting,
      SubMenu
    } = this.state;
    return (
      <div className="left-navigation">
        {
          !_.isEmpty(Selected) &&
          <div className="back-nav" onClick={() => this.handleSelect()}><i className="icon-previous"/> {Selected.sports_name}</div>
        }
        {
          _.isEmpty(Selected) &&
          _.map(menu, (item, key) => <div onClick={() => this.handleSelect(item)} className="left-nav-item" {...{ key }}>{item.sports_name}</div>)
        }
        {
          posting &&
          <div className="left-nav-item posting" />
        }
        {
          (!_.isEmpty(SubMenu) && !_.isEmpty(Selected)) &&
          _.map(SubMenu, (item, key) => <div onClick={() => this.handleLeague(item)} className="left-nav-item" {...{ key }}>{item.league_name}</div>)  
        }
        {
          (_.isEmpty(SubMenu) && !_.isEmpty(Selected) && !posting) &&
          <div className="left-nav-item">{CONSTANT.SIDEBAR_NO_LEAGUE}</div>
        }
      </div>
    );
  }
}
Sidebar.defaultProps = {
  menu: {},
  callback: () => {},
  page_id: ''
}
export default Sidebar;
