/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { API, Http, Socket, Utils, _, Role } from 'Helpers';
import { withTranslation } from 'react-i18next';
import validator from "validator";
import { connect } from "react-redux";

import { CONSTANT, SocketEvent } from 'Constants';
import { MessageBox, Scoreboard, TitleComponent } from 'Components';
import { Auth } from 'Service';
import { updateMatchId, updateMatchStatus } from '../../Redux/Reducers'


const queryString = require('query-string');

class FixtureDetailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Init: true,
      FixturePosting: true,
      isFixtureDetailsChanged: false,

      ScoresData: [],
      FancyData: [],
      MarketList: [],
      SelectedFixture: {},
      EventDetails: {},
      fancyChanged: false,
      recently_changed: [],
      expData: [],
      activeId: '',
      ExposurePosting: false,
      oddShowType: localStorage.getItem('OddSelectiontype'),
      sports_id: '',
      bookmakerList: [],
      Mid:''
    }
  }

  // Fetch Request(s) 
  GetFixtureMarket = () => {

    const { match } = this.props;
    const parsed = queryString.parse(this.props.location.search);

    let param = {
      "event_id": match.params.event_id,
      "sports_id": parsed.sports
    }
    this.setState({
      Init: false,
      FixturePosting: true,
    })
    Http.post(API.GET_FIXTURE_MARKETS, param).then(response => {

      const { location } = this.props
      const searchParams = new URLSearchParams(location.search);

      // condition where someone manually change the wrong sport id in url
      if (searchParams.get('sports') != response.data.event.sports_id) {
        this.props.changeSports(response.data.event.sports_id)
      }

      if (response.data.markets.length == 0 && response.data.bookmaker.length == 0) {

        window.location.href = "/";
      }


      let new_market_list = _.map(response.data.markets, market => {
        // console.log(market, JSON.parse(market.runners_odds), 'market')
        let new_runners_odds = _.map(JSON.parse(market.runners_odds), item => {
          let blankObj = { price: '', size: '' }
          // console.log(item.ex.availableToLay.length);

          if (item.ex.availableToBack != undefined) {
            switch (item.ex.availableToBack.length) {
              case 0:
                item.ex.availableToBack = [...item.ex.availableToBack, blankObj, blankObj, blankObj]
                break;
              case 1:
                item.ex.availableToBack = [...item.ex.availableToBack, blankObj, blankObj]
                break;
              case 2:
                item.ex.availableToBack = [...item.ex.availableToBack, blankObj]
                break;
              default:
                break;
            }
          }
          else {
            item.ex.availableToBack = [blankObj, blankObj, blankObj]
          }
          if (item.ex.availableToLay != undefined) {
            switch (item.ex.availableToLay.length) {
              case 0:
                item.ex.availableToLay = [...item.ex.availableToLay, blankObj, blankObj, blankObj]
                break;
              case 1:
                item.ex.availableToLay = [...item.ex.availableToLay, blankObj, blankObj]
                break;
              case 2:
                item.ex.availableToLay = [...item.ex.availableToLay, blankObj]
                break;
              default:
                break;
            }
          }
          else {
            item.ex.availableToLay = [blankObj, blankObj, blankObj]
          }
          // console.log(item, 'item')
          return item
        })
        // console.log({ ...market, runners_odds: JSON.stringify(new_runners_odds) }, 'new_market_list')
        return { ...market, runners_odds: JSON.stringify(new_runners_odds) }
      })

      // console.log(new_market_list, 'new')


      if (response.data.event.is_live === '1') {
        this.props.updateMatchStatus(true)
      } else {
        this.props.updateMatchStatus(false)
      }




      this.setState({
        FixturePosting: false,
        EventDetails: response.data.event,
        ScoresData: response.data.scores,
        MarketList: new_market_list,
        FancyData: response.data.fancy,
        bookmakerList: response.data.bookmaker,
        Mid : response.data.bookmaker.mid
      }, () => {
        this.setState({
          ...(!_.isEmpty(this.state.MarketList) && { SelectedFixture: this.state.MarketList[0] })
        })

        if (this.state.EventDetails.is_live == 1) {
          let _this = this
          Socket.on(SocketEvent.SCORE, function (res) {
            _this.SocketScorehandler(res)
          })
        }

      })
    }).catch(error => {
      this.setState({ FixturePosting: false })
    });
  }

  GetExposeData = (item) => {
    this.setState({ ExposurePosting: true })
    let param = {
      "odd_id": item.session_id
    }

    Http.post(API.EXPOSURE_DATA, param).then(response => {
      this.setState({
        expData: response.data,
        ExposurePosting: false
      })


    }).catch(error => {
      this.setState({ FixturePosting: false })
    });
  }



  // Life cycle
  componentDidMount() {


    const parsed = queryString.parse(this.props.location.search);
    if (!parsed.sports) {
      window.location.href = "/"
    }

    this.setState({ sports_id: parsed.sports })

    const { match } = this.props;
    this.props.updateMatchId(match.params.event_id)

    this.GetFixtureMarket()
    const _this = this;

    Socket.on(SocketEvent.MARKET, function (res) {
      _this.SocketMarkethandler(res)
    })

    Socket.on(SocketEvent.FANCY, function (res) {
      _this.SocketFancyhandler(res)
    })

    Socket.on(SocketEvent.BOOKMAKER_MARKET, function (res) {
      _this.SocketBookmakerhandler(res)
    })


    Socket.on(SocketEvent.PROVIDER_UPDATED, function (res) {
      _this.props.removeExchangeBets();
      _this.setState({
        EventDetails: {},
        ScoresData: [],
        MarketList: [],
        FancyData: []
      }, () => {
        _this.GetFixtureMarket()
      })

    })


    Socket.on(SocketEvent.STATUS_UPDATED_MK, function (res) {
      let MarketList = [..._this.state.MarketList];
      let getIdx = MarketList.findIndex(itm => itm.market_event_id == res.market_event_id);
      if (getIdx > -1) {
        MarketList[getIdx]['lock_betting'] = res.lock_betting;
        _this.setState({ MarketList })
      }

    })

    Socket.on(SocketEvent.STATUS_UPDATED_BK, function (res) {
      const {bookmakerList} = _this.state
      if(res.event_id == bookmakerList.event_id){
        bookmakerList['lock_betting'] = res.lock_betting;
        _this.setState({ bookmakerList })
      }
      

    })


    // setTimeout(() => {
    //   let res = {
    //     bet_delay: 0,
    //     bsp_reconciled: false,
    //     complete: true,
    //     cross_matching: true,
    //     event_id: "30043096",
    //     inplay: false,
    //     is_market_data_delayed: false,
    //     last_match_time: "2020-10-06T09:12:44.861Z",
    //     market_id: "1.173705728",
    //     number_of_active_runners: 2,
    //     number_of_runners: 2,
    //     number_of_winners: 1,
    //     odd_status: 1,
    //     runners_odds: `[{"selectionId":7671296,"handicap":0,"status":"ACTIVE","lastPriceTraded":2.06,"totalMatched":332.7,"ex":{"availableToBack":[{"price":2.06,"size":210.53},{"price":1.94,"size":45.37},{"price":1.93,"size":10.39}],"availableToLay":[{"price":2.1,"size":600.95},{"price":2.14,"size":1512.8},{"price":2.16,"size":939.77}],"tradedVolume":[]}},{"selectionId":2954262,"handicap":0,"status":"ACTIVE","lastPriceTraded":1.94,"totalMatched":439.42,"ex":{"availableToBack":[{"price":1.92,"size":657.29},{"price":1.89,"size":517.08},{"price":1.88,"size":1039.2}],"availableToLay":[{"price":1.94,"size":211.14},{"price":1.95,"size":12.35},{"price":2.06,"size":2.36}],"tradedVolume":[]}}]`,
    //     runners_voidable: false,
    //     status: "OPEN",
    //     total_available: 456966.55,
    //     total_matched: 772.13,
    //     version: 3396216609,
    //   }
    //   _this.SocketMarkethandler(res)
    // }, 2500)

  }



  componentWillUnmount() {
    Socket.off(SocketEvent.MARKET)
    Socket.off(SocketEvent.FANCY)
    Socket.off(SocketEvent.SCORE)
    Socket.off(SocketEvent.STATUS_UPDATED_MK)
    Socket.off(SocketEvent.PROVIDER_UPDATED)

    this.props.updateMatchId(null)
    this.props.updateMatchStatus(false)

    this.setState = () => {
      return;
    };
  }

  // Other Handler


  showExposure = (item) => {
    if (item.session_id === this.state.activeId) {
      this.setState({ activeId: '' })
    } else {

      this.setState({ expData: [], activeId: item.session_id }, () => {
        this.GetExposeData(item)
      })
    }


  }
  SocketScorehandler = (res = {}) => {
    const { ScoresData, EventDetails } = this.state;
    if (EventDetails.is_live == 1 && EventDetails.event_id == res.event_id) {
      this.setState({
        ScoresData: { ...ScoresData, ...res }
      });
    }
  }

  SocketFancyhandler = (res = {}) => {


    const { FancyData, EventDetails } = this.state;
    const currentUser = Auth.currentUserValue;
    if (EventDetails.event_id == res.event_id) {
      if (currentUser != null && currentUser.role == Role.AgentPunter) {
        if (
          res.agent_status == "0" ||
          res.settlement_status != "0" ||
          res.is_active == "0"
        ) {
          _.remove(FancyData, (obj) => {
            return obj.selection_id == res.selection_id
          })
          this.setState({ FancyData })
        }
        else {
          if (Utils.containsObject(res, FancyData)) {
            let newFancyData = _.map(FancyData, (item) => {
              if (item.selection_id == res.selection_id) {
                return { ...item, ...res }
              }
              return item
            })
            this.setState({ FancyData: newFancyData })

          } else {
            this.setState({ FancyData: [...FancyData, res] })
          }
        }
      } else {
        if (
          res.admin_status == "0" ||
          res.settlement_status != "0" ||
          res.is_active == "0"
        ) {
          _.remove(FancyData, (obj) => {
            return obj.selection_id == res.selection_id
          })
          this.setState({ FancyData })
        }
        else {
          if (Utils.containsObject(res, FancyData)) {
            let newFancyData = _.map(FancyData, (item) => {
              if (item.selection_id == res.selection_id) {
                return { ...item, ...res }
              }
              return item
            })
            this.setState({ FancyData: newFancyData })

          } else {
            this.setState({ FancyData: [...FancyData, res] })
          }
        }
      }




    }
  }

  SocketMarkethandler = (res = {}) => {
    // console.log('marketUpdated Socket response:-', "market_id-", res.market_id, 'runners_odds-', JSON.parse(res.runners_odds))

    const { MarketList, recently_changed } = this.state
    let newMarketList = _.map(MarketList, item => {
      if (item.market_id == res.market_id) {
        let new_recently_changed = recently_changed
        let item_runners_odds = JSON.parse(item.runners_odds)

        let new_runners_odds = _.map(JSON.parse(res.runners_odds), (odds, idx) => {

          let blankObj = { price: '', size: '' }

          if (odds.ex.availableToBack != undefined) {
            switch (odds.ex.availableToBack.length) {
              case 0:
                odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj, blankObj, blankObj]
                break;
              case 1:
                odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj, blankObj]
                break;
              case 2:
                odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj]
                break;
              default:
                break;
            }
          } else {
            odds.ex.availableToBack = [blankObj, blankObj, blankObj]
          }

          if (odds.ex.availableToLay != undefined) {
            switch (odds.ex.availableToLay.length) {
              case 0:
                odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj, blankObj, blankObj]
                break;
              case 1:
                odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj, blankObj]
                break;
              case 2:
                odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj]
                break;
              default:
                break;
            }
          } else {
            odds.ex.availableToLay = [blankObj, blankObj, blankObj]
          }

          if (item_runners_odds[idx] && item_runners_odds[idx].ex.selectionId == odds.ex.selectionId) {
            let new_availableToBack = _.map(odds.ex.availableToBack, (o, back_key) => {
              if (
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToBack) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToBack[back_key]) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToBack[back_key].price) &&
                  item_runners_odds[idx].ex.availableToBack[back_key].price != o.price
                )
                ||
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToBack) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToBack[back_key]) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToBack[back_key].size) &&
                  item_runners_odds[idx].ex.availableToBack[back_key].size != o.size
                )
              ) {
                new_recently_changed = [...new_recently_changed, JSON.stringify(odds.selectionId) + '_back_' + back_key]
                this.setState({
                  isChanged: true,
                  recently_changed: new_recently_changed
                })
                return o
              }
              return o
            })

            let new_availableToLay = _.map(odds.ex.availableToLay, (o, lay_key) => {
              if (
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToLay) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToLay[lay_key]) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToLay[lay_key].price) &&
                  item_runners_odds[idx].ex.availableToLay[lay_key].price != o.price)
                ||
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToLay) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToLay[lay_key]) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToLay[lay_key].size) &&
                  item_runners_odds[idx].ex.availableToLay[lay_key].size != o.size
                )
              ) {
                new_recently_changed = [...new_recently_changed, JSON.stringify(odds.selectionId) + '_lay_' + lay_key]
                this.setState({
                  isChanged: true,
                  recently_changed: new_recently_changed
                })
                return o
              }
              return o
            })

            return { ...odds, ex: { ...odds.ex, availableToBack: new_availableToBack, availableToLay: new_availableToLay } }
          }

          return odds
        })

        if (item.status != res.status) {
          this.setState({
            isChanged: true
          })
        }
        return {
          ...item,
          status: res.status,
          runners_odds: JSON.stringify(new_runners_odds)
        };
      }
      return item
    })

    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ MarketList: newMarketList }, () => {
        this.setState({ isChanged: false });
      })
    }
  }

  SocketBookmakerhandler = (res = {}) => {
    

    const { bookmakerList, Mid } = this.state;
    const currentUser = Auth.currentUserValue;
    //console.log(res,"resres")
    if (currentUser != null &&  Number(res.user_role) == Number(currentUser.role)) {
    
    if (Number(Mid) == Number(res.mid)) {
      _.map(bookmakerList.runners_odds, (item, idx) => {
        if (Number(item.selection_id) == Number(res.runners_odds.selection_id)) {
          let obj = {
            ...bookmakerList.runners_odds[idx],
            ...res.runners_odds
          }
          bookmakerList.runners_odds[idx] = obj
        }
      })

    } else if(res.isBookmakerRemoved == '0' && Number(Mid) == Number(res.data.mid)){
      this.setState({
        bookmakerList:[]
      })
    } 
    else if(res.isBookmakerRemoved == '1' && Number(Mid) == Number(res.data.mid)){
      this.setState({
        bookmakerList:res.data
      })
    } 
  } 
  else if(currentUser == null && Number(res.user_role) !== 10){
    if (Number(Mid) == Number(res.mid)) {
      _.map(bookmakerList.runners_odds, (item, idx) => {
        if (Number(item.selection_id) == Number(res.runners_odds.selection_id)) {
          let obj = {
            ...bookmakerList.runners_odds[idx],
            ...res.runners_odds
          }
          bookmakerList.runners_odds[idx] = obj
        }
      })

    } else if(res.isBookmakerRemoved == '0' && Number(Mid) == Number(res.data.mid)){
      this.setState({
        bookmakerList:[]
      })
    } 
    else if(res.isBookmakerRemoved == '1' && Number(Mid) == Number(res.data.mid)){
      this.setState({
        bookmakerList:res.data
      })
    } 
  } else {
    
    if (Number(Mid) == Number(res.mid)) {
      _.map(bookmakerList.runners_odds, (item, idx) => {
        if (Number(item.selection_id) == Number(res.runners_odds.selection_id)) {
          let obj = {
            ...bookmakerList.runners_odds[idx],
            ...res.runners_odds
          }
          bookmakerList.runners_odds[idx] = obj
        }
      })

    } 
  }

  }

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state
    if (_.isEmpty(recently_changed)) return ''
    let _id = _.isString(id) ? id : JSON.stringify(id)
    if (_.includes(recently_changed, _id)) {
      this.cleanSocketHighlight()
      return ' blink '
    }
    else return '';
  }

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] })
      }
    }, timer)
  }

  reinitiateComponent = () => {
    const { reinitiateComponent, location, is_inplay } = this.props
    const searchParams = new URLSearchParams(location.search);
    if (is_inplay) {
      reinitiateComponent(searchParams.get('sports'), true)
    } else {
      reinitiateComponent(searchParams.get('sports'))
    }
  }

  componentWillReceiveProps(nextProps) {
    

    const { WinlossObject } = this.props
    if (nextProps.WinlossObject.lastupdate !== WinlossObject.lastupdate) {
      if (!nextProps.WinlossObject.is_place_bet) {
        switch (nextProps.WinlossObject.type) {
          case 0:
            this.UpdateWinloss(nextProps.WinlossObject)
            this.UpdateWinlossBookmaker(nextProps.WinlossObject)
            break;
          case 1:
            console.log(nextProps.WinlossObject)
            this.RunTimeUpdateWinloss(nextProps.WinlossObject)
            this.RunTimeUpdateWinlossBookmaker(nextProps.WinlossObject)
            break;
          case 2:
            this.ResetWinloss(nextProps.WinlossObject)
            this.ResetWinlossBookmaker(nextProps.WinlossObject)
            break;
          default:
            break;
        }
      } else {
        //alert("radhe")
        //this.ResetWinloss(nextProps.WinlossObject)
      }
    }
  }

  UpdateWinloss = (obj) => {
    if (obj.bookmaker_type == 3) {
      const { FancyData } = this.state
      console.log(obj)
      let newFancyData = _.map(FancyData, (item) => {
        if (item.session_id == obj.session_id) {
          return { ...item, exposure: obj.fexposure }
        }
        return item
      })
      this.setState({
        FancyData: newFancyData
      })
    } else {
      const { MarketList } = this.state
      let newMarketList = _.map(MarketList, (item) => {
        if (item.market_event_id == obj.market_event_id) {
          let new_runners = _.map(JSON.parse(item.runners), (_obj) => {
            return {
              ..._obj,
              winloss: obj.win_loss[_obj.selectionId]
            }
          })
          return { ...item, runners: JSON.stringify(new_runners) }
        }
        return item
      })
      this.setState({
        MarketList: newMarketList
      })
    }
  }
  UpdateWinlossBookmaker = (obj) => {
    if (obj.bookmaker_type == 3) {
      const { FancyData } = this.state
      console.log(obj)
      let newFancyData = _.map(FancyData, (item) => {
        if (item.session_id == obj.session_id) {
          return { ...item, exposure: obj.fexposure }
        }
        return item
      })
      this.setState({
        FancyData: newFancyData
      })
    } else {
      const { bookmakerList } = this.state
      // let newbookmakerList = _.map(bookmakerList, (item) => {
      //   if (item.market_event_id == obj.market_event_id) {
      //     let new_runners = _.map(JSON.parse(item.runners_odds), (_obj) => {
      //       return {
      //         ..._obj,
      //         winloss: obj.win_loss[_obj.selection_id]
      //       }
      //     })
      //     return { ...item, runners_odds: JSON.stringify(new_runners) }
      //   }
      //   return item
      // })
      this.setState({
      //  bookmakerList: newbookmakerList
      })
    }
  }

  ResetWinloss = (obj) => {
    const { MarketList } = this.state
    let newMarketList = _.map(MarketList, (item) => {
      let new_runners = _.map(JSON.parse(item.runners), (_obj) => {
        return {
          ..._obj,
          temp_winloss: undefined
        }
      })
      return { ...item, runners: JSON.stringify(new_runners) }
    })
    this.setState({
      MarketList: newMarketList
    })
  }

  ResetWinlossBookmaker = (obj) => {
    
    const { bookmakerList } = this.state
    
      let new_runners = _.map(bookmakerList.runners_odds, (_obj,idx) => {
        let NObj =  {
          ..._obj,
          temp_winloss: undefined
        }
       
        bookmakerList.runners_odds[idx] = NObj
        return _obj
      })
      
      
    
    this.setState({
     // bookmakerList: newbookmakerList
    })
  }


  RunTimeUpdateWinloss = ({
    market_event_id,
    selectionId,
    stack,
    betting_type,
    pp,
    liability,
    bookmaker_type,
    session_id
  }) => {
    const { MarketList, FancyData } = this.state
    if (bookmaker_type == CONSTANT.BOOKMAKER_TYPE['exchange_betting']) {
      let newMarketList = _.map(MarketList, (item) => {
        if (item.market_event_id == market_event_id) {
          let new_runners = _.map(JSON.parse(item.runners), (_object) => {
            let _obj = _.cloneDeep(_object)
            if (betting_type == 1) {
              if (_obj.selectionId == selectionId) {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) + Number(pp) }
              } else {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) - Number(liability) }
              }
            } else if (betting_type == 2) {
              if (_obj.selectionId == selectionId) {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) - Number(liability) }
              } else {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) + Number(stack) }
              }
            }
            return _obj
          })
          return { ...item, runners: JSON.stringify(new_runners) }
        }
        return item
      })
      this.setState({
        MarketList: newMarketList
      })
    }
  }
  RunTimeUpdateWinlossBookmaker = ({
    market_event_id,
    selectionId,
    stack,
    betting_type,
    pp,
    liability,
    bookmaker_type,
    session_id
  }) => {
    const { bookmakerList, FancyData } = this.state
    if (bookmaker_type == CONSTANT.BOOKMAKER_TYPE['exchange_betting']) {
      
   
        if (bookmakerList.mid == market_event_id) {
          
           _.map(bookmakerList.runners_odds, (_object,idx) => {
            let _obj = _.cloneDeep(_object)
            if (betting_type == 1) {
              if (_obj.selection_id == selectionId) {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) + Number(pp) }
              } else {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) - Number(liability) }
              }
            } else if (betting_type == 2) {
              if (_obj.selection_id == selectionId) {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) - Number(liability) }
                
              } else {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) + Number(stack) }
              }
            }
            bookmakerList.runners_odds[idx] = _obj
            return _obj
          })
          
        }
       
      
      
    }
  }


  render() {
    const {
      SelectedSports,
      // Methods
      handleOptionChange,
      _fancyHandleOptionChange,
      _oddsActiveClass,
      _fancyOddsActiveClass,
      t,
    } = this.props
    const {
      MarketList,
      FancyData,
      SelectedFixture,
      EventDetails,
      FixturePosting,
      ScoresData,
      activeId,
      expData,
      ExposurePosting,
      oddShowType,
      bookmakerList
    } = this.state
    const currentUser = Auth.currentUserValue

    const isDisabled = (agent_status) => {
      return agent_status != 1
    }


    // console.log(MarketList, 'MarketList')

    return (
      <Fragment>
        <TitleComponent title={EventDetails.event_name} />
        {/* {console.log(SelectedSports, 'fixtureDetailList')} */}
        <Scoreboard {...{
          sports_id: SelectedSports.sports_id,
          ScoresData: ScoresData,
          EventDetails: EventDetails
        }} />

        {
          FixturePosting ?
            <div className="m-4"><Utils.BoxLoader /></div>
            :
            <Fragment>
              {
                !_.isEmpty(EventDetails) &&
                <div className="odds-padding-row heading">

                  <i className="back-btn icon-previous" onClick={() => this.reinitiateComponent()} />
                  <div className="overflow">
                    <div className="small">{EventDetails.league_name || 'League Name'}</div>
                    <div className="title">
                      {EventDetails.event_name}
                    </div>
                    <div className="timestamp">
                      {
                        EventDetails.is_live == 1 ?
                          <span className="is-live-lbl">{t('In-Play')}</span>
                          :
                          <Fragment>
                            <i className="icon-clock" /> {Utils.dateTimeFormat(EventDetails.open_date, 'HH:mm, DD/MM/YYYY')}
                          </Fragment>
                      }
                    </div>
                  </div>
                </div>
              }
              {
                _.map(MarketList, (item, key) => {
                  return (
                    <div key={key} className="layback-odd">
                      <div className="heading">
                        <div className="first-cell">
                          {item.market_name}
                        </div>
                        <div className="cell">
                          <div className='light'></div>
                          <div className='light'></div>
                          <div className="back-odd">{t('Back All')}</div>
                        </div>
                        <div className="cell">
                          <div className="inner-cell lay-odd">{t('Lay All')}</div>
                          <div className='light'></div>
                          <div className='light'></div>
                        </div>
                      </div>
                      {
                        _.map(JSON.parse(item.runners), (runner, key) => {
                          return (
                            <div className="layback-row" {...{ key }}>
                              <div className="first-cell">
                                <div className="v-center">
                                  {runner.runnerName}
                                  {
                                    (currentUser && !_.isUndefined(runner.winloss)) &&
                                    <Fragment>
                                      {
                                        _.isUndefined(runner.temp_winloss) ?
                                          <span {...{ className: `winloss-${Math.sign(runner.winloss)}` }} >
                                            {_.round(runner.winloss, 2).toFixed(2)}
                                          </span>
                                          :
                                          <span {...{ className: `winloss-${Math.sign(runner.temp_winloss)}` }} >
                                            {_.round(runner.temp_winloss, 2).toFixed(2)}
                                          </span>
                                      }

                                    </Fragment>

                                  }
                                </div>
                              </div>

                              {
                                _.map(JSON.parse(item.runners_odds), (odds, key) => {
                                  return (
                                    odds.selectionId == runner.selectionId &&
                                    <Fragment {...{ key }}>
                                      <div className={`cell back-odd`}>
                                        {
                                          _.map(Utils.getSelectedOdds(odds, 1), (o, key) => {
                                            if (key >= 3) {
                                              return;
                                            }
                                            return (
                                              <div {...{ className: `odds-inner-toggle ${_.isUndefined(o.price) ? 'cursor-default' : ''} ${_.isUndefined(o.price) ? null : this.SocketHighlight({ id: `${odds.selectionId}_back_${key}` })} ${_oddsActiveClass(odds.selectionId, item.market_event_id, 1) ? 'yellow' : ''}`, key }}
                                                onClick={
                                                  key == 0 && !_.isUndefined(o.price)
                                                    ? () => [
                                                      handleOptionChange(
                                                        {
                                                          fixture: { ...item, sports_id: SelectedSports.sports_id },
                                                          odd: { ...odds, market_bet_id: odds.selectionId },
                                                          betting_type: 1,
                                                          selected_odd: o,
                                                          bookmakerHandle: false
                                                        }
                                                      ),
                                                      this.ResetWinloss(item),
                                                      this.ResetWinlossBookmaker()
                                                    ] : null}>
                                                <div className="price">
                                                  {
                                                    o.price ?
                                                      oddShowType == 'decimal' ? Utils.DecimalOdds(o.price || '') : oddShowType == 'fractional' ? Utils.FractionalOdds(o.price || '') || '--' : oddShowType == 'american' ? Utils.AmericanOdds(o.price || '')
                                                        : '' : '--'
                                                  }

                                                </div>
                                                <div className="price_volume">{o.size || '--'}</div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                      <div className={`cell lay-odd`}>
                                        {
                                          _.map(Utils.getSelectedOdds(odds, 2), (o, key) => {
                                            if (key >= 3) {
                                              return;
                                            }
                                            return (
                                              <div {...{ className: `odds-inner-toggle ${_.isUndefined(o.price) ? 'cursor-default' : ''} ${_.isUndefined(o.price) ? null : this.SocketHighlight({ id: `${odds.selectionId}_lay_${key}` })} ${_oddsActiveClass(odds.selectionId, item.market_event_id, 2) ? 'yellow' : ''}`, key }}
                                                onClick={
                                                  key == 0 && !_.isUndefined(o.price)
                                                    ? () => [handleOptionChange({ fixture: { ...item, sports_id: SelectedSports.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 2, selected_odd: o }), this.ResetWinloss(item),this.ResetWinlossBookmaker()] : null}>
                                                <div className="price">
                                                  {
                                                    o.price ?
                                                      oddShowType == 'decimal' ? Utils.DecimalOdds(o.price || '') : oddShowType == 'fractional' ? Utils.FractionalOdds(o.price || '') || '--' : oddShowType == 'american' ? Utils.AmericanOdds(o.price || '')
                                                        : '' : '--'
                                                  }
                                                </div>
                                                <div className="price_volume">{o.size || '--'}</div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    </Fragment>
                                  )
                                })
                              }
                              {/* {
                                (item.status == 'SUSPENDED' || item.status == 'CLOSED') &&
                                <div className="game-status"><span>{item.status}</span></div>
                              } */}

                              {/* {
                                (item.odd_status == '2' || item.odd_status == '3') &&
                                <div className="game-status"><span>SUSPENDED</span></div>
                              } */}


                              {
                                (item.status == 'SUSPENDED' || item.status == 'CLOSED' || item.odd_status == '2' || item.odd_status == '3') &&
                                <div className="game-status"><span>SUSPENDED</span></div>
                              }

                              {
                                (item.lock_betting == '1' && item.status != 'SUSPENDED') ?
                                  <div className="game-status"><span>SUSPENDED</span></div>
                                  :
                                  _.map(JSON.parse(item.team_bet_setting), (ib, ibx) => {
                                    return (
                                      ib.selectionId == runner.selectionId && ib.lock_team_betting == '1' &&
                                      <div className="game-status" key={ibx}><span>SUSPENDED</span></div>
                                    )
                                  })
                              }

                            </div>
                          )
                        })
                      }
                    </div>

                  )
                }
                )
              }

              {/* For bookmaker market start */}
              {
                !_.isEmpty(bookmakerList) &&

                <div className="layback-odd">
                  <div className="heading">
                    <div className="first-cell">
                      {bookmakerList.mname}
                    </div>
                    <div className="cell">
                      <div className='light'></div>
                      <div className='light'></div>
                      <div className="back-odd">{t('Back All')}</div>
                    </div>
                    <div className="cell">
                      <div className="inner-cell lay-odd">{t('Lay All')}</div>
                      <div className='light'></div>
                      <div className='light'></div>
                    </div>
                  </div>
                  {
                    _.map(bookmakerList.runners_odds, (itemB, idxB) => {
                      return (
                        <div className="layback-row" key={idxB}>
                          <div className="first-cell">
                            <div className="v-center">
                              {itemB.name}
                              {
                                (currentUser && !_.isUndefined(itemB.winloss)) &&
                                <Fragment>
                                  {
                                    _.isUndefined(itemB.temp_winloss) ?
                                      <span {...{ className: `winloss-${Math.sign(itemB.winloss)}` }} >
                                        {_.round(itemB.winloss, 2).toFixed(2)}
                                      </span>
                                      :
                                      <span {...{ className: `winloss-${Math.sign(itemB.temp_winloss)}` }} >
                                        {_.round(itemB.temp_winloss, 2).toFixed(2)}
                                      </span>
                                  }

                                </Fragment>

                              }
                            </div>
                          </div>
                          <div className="cell back-odd">
                            <div 
                              {...{ className: `odds-inner-toggle ${_.isUndefined(itemB.b1) ? 'cursor-default' : ''} ${_.isUndefined(itemB.b1) ? null : this.SocketHighlight({ id: `${itemB.selection_id}_back_${idxB}` })} ${_oddsActiveClass(itemB.selection_id, bookmakerList.bookmaker_id, 1) ? 'yellow' : ''}`, idxB }}
                              onClick={
                                () => [
                                  handleOptionChange(
                                    {
                                      fixture: { bookmakerList, sports_id: SelectedSports.sports_id, bookmakerHandle: true },
                                      odd: { ...bookmakerList.runners_odds, market_bet_id: itemB.selection_id },
                                      betting_type: 1,
                                      selected_odd: itemB,
                                      bookmakerHandle: true
                                    }
                                  ),
                                    this.ResetWinlossBookmaker(bookmakerList),
                                    this.ResetWinloss()
                                ]}
                            >
                              <div className="price">{itemB.b1 || '--'}</div>
                              <div className="price_volume">{itemB.bs1 || '--'}</div>
                            </div>
                            <div className="odds-inner-toggle">
                              <div className="price">{itemB.b2 || '--'}</div>
                              <div className="price_volume">{itemB.bs2 || '--'}</div>
                            </div>
                            <div className="odds-inner-toggle">
                              <div className="price">{itemB.b3 || '--'}</div>
                              <div className="price_volume">{itemB.bs3 || '--'}</div>
                            </div>
                          </div>
                          <div className="cell lay-odd">
                            <div 
                            {...{ className: `odds-inner-toggle ${_.isUndefined(itemB.b1) ? 'cursor-default' : ''} ${_.isUndefined(itemB.b1) ? null : this.SocketHighlight({ id: `${itemB.selection_id}_lay_${idxB}` })} ${_oddsActiveClass(itemB.selection_id, bookmakerList.bookmaker_id, 2) ? 'yellow' : ''}`, idxB }}
                              onClick={
                                () => [
                                  handleOptionChange(
                                    {
                                      fixture: { bookmakerList, sports_id: SelectedSports.sports_id, bookmakerHandle: true },
                                      odd: { ...bookmakerList.runners_odds, market_bet_id: itemB.selection_id },
                                      betting_type: 2,
                                      selected_odd: itemB,
                                      bookmakerHandle: true
                                    }
                                  ),
                                    this.ResetWinlossBookmaker(bookmakerList),
                                    this.ResetWinloss()
                                ]}
                            >
                              <div className="price">{itemB.l1 || '--'}</div>
                              <div className="price_volume">{itemB.ls1 || '--'}</div>
                            </div>
                            <div className="odds-inner-toggle">
                              <div className="price">{itemB.l2 || '--'}</div>
                              <div className="price_volume">{itemB.ls2 || '--'}</div>
                            </div>
                            <div className="odds-inner-toggle">
                              <div className="price">{itemB.l3 || '--'}</div>
                              <div className="price_volume">{itemB.ls3 || '--'}</div>
                            </div>
                          </div>
                          {
                            (itemB.status == 'SUSPENDED' || itemB.status == 'CLOSED' || itemB.odd_status == '2' || itemB.odd_status == '3') &&
                            <div className="game-status"><span>SUSPENDED</span></div>
                          }
                          {
                                (bookmakerList.lock_betting == '1' && itemB.status != 'SUSPENDED') ?
                                  <div className="game-status"><span>SUSPENDED</span></div>
                                  :
                                  ''
                                  // _.map(JSON.parse(bookmakerList.team_bet_setting), (ib, ibx) => {
                                  //   return (
                                  //     ib.selection_id == itemB.selection_id && ib.lock_team_betting == '1' &&
                                  //     <div className="game-status" key={ibx}><span>SUSPENDED</span></div>
                                  //   )
                                  // })
                              }
                        </div>
                      )
                    })
                  }


                </div>


              }
              {/* For bookmaker market end */}

              {
                !_.isEmpty(FancyData) &&
                <div className="fancy-odd">
                  <div className="heading">
                    <div className="first-cell">{t('Fancy Bet')}</div>
                    <div className="cell">{t('No')}</div>
                    <div className="cell">{t('Yes')}</div>
                    <div className="cell" />
                  </div>

                  {
                    _.map(FancyData, (item, key) => {
                      return (
                        <Fragment {...{ key }}>
                          <div className="fancy-odd-row" {...{ key }}>
                            <div className="first-cell">{item.runner_name}</div>
                            <div className="cell">
                              <div
                                className={`odds-inner-toggle lay ${_fancyOddsActiveClass(item.market_bet_id, item.selection_id, 2) ? 'yellow' : ''}`}
                                onClick={() => _fancyHandleOptionChange({
                                  fixture: { ...SelectedFixture, sports_id: SelectedSports.sports_id, provider_id: item.provider_id },
                                  odd: { ...item },
                                  betting_type: 2,
                                  selected_odd: { price: item.lay_price_1, size: '' }
                                })}>
                                <div className="price">
                                  {
                                    item.lay_price_1 ?
                                      oddShowType == 'decimal' ? Utils.DecimalOdds(item.lay_price_1 || '') : oddShowType == 'fractional' ? Utils.FractionalOdds(item.lay_price_1 || '') || '--' : oddShowType == 'american' ? Utils.AmericanOdds(item.lay_price_1 || '')
                                        : '' : '--'
                                  }
                                </div>
                                <div className="volume">{item.lay_size_1}</div>
                              </div>
                            </div>
                            <div className="cell">
                              <div
                                className={`odds-inner-toggle back ${_fancyOddsActiveClass(item.market_bet_id, item.selection_id, 1) ? 'yellow' : ''}`}
                                onClick={() => _fancyHandleOptionChange({
                                  fixture: { ...SelectedFixture, sports_id: SelectedSports.sports_id, provider_id: item.provider_id },
                                  odd: { ...item },
                                  betting_type: 1,
                                  selected_odd: { price: item.back_price_1, size: '' }

                                })}>
                                <div className="price">

                                  {
                                    item.back_price_1 ?
                                      oddShowType == 'decimal' ? Utils.DecimalOdds(item.back_price_1 || '') : oddShowType == 'fractional' ? Utils.FractionalOdds(item.back_price_1 || '') || '--' : oddShowType == 'american' ? Utils.AmericanOdds(item.back_price_1 || '')
                                        : '' : '--'
                                  }
                                </div>
                                <div className="volume">{item.back_size_1}</div>
                              </div>
                              {/* {item.game_status || 'Active'} - {item.is_suspend} */}
                            </div>
                            <div className="cell">
                              {
                                (item.exposure != 0 && currentUser) &&
                                <div className="pointer" onClick={() => this.showExposure(item)}>{t('Exposure')}:{" "}{item.exposure}</div>
                              }
                            </div>
                            {/* Other status show */}
                            {
                              ((!validator.isEmpty(item.game_status) && item.game_status != 'SUSPENDED') && item.is_suspend == 0) &&
                              <div className="fancy-status-cell">
                                <span>{item.game_status}</span>
                              </div>
                            }
                            {
                              (item.game_status == "CLOSED" || item.game_status == "2" || item.game_status == "3") &&
                              <div className="fancy-status-cell">
                                <span>{t('SUSPENDED')}</span>
                              </div>
                            }
                            {
                              (item.game_status == 'SUSPENDED' || item.is_suspend == 1) &&
                              <div className="fancy-status-cell">
                                <span>{t('SUSPENDED')}</span>
                              </div>
                            }
                          </div>
                          {activeId == item.session_id && <div className="exposure-block">
                            <div className="heading exposure-head">
                              <div className="first-cell"></div>
                              <div className="first-cell"></div>
                              <div className="cell">
                                Runs
                              </div>
                              <div className="cell">
                                P&L
                              </div>
                            </div>
                            {
                              (ExposurePosting && _.isEmpty(expData)) ?
                                <Utils.Spinner /> :
                                _.map(expData, (EXitem, Eidx) => {
                                  return (
                                    <div className="fancy-odd-row exposure-row" key={Eidx}>
                                      <div className="first-cell"></div>
                                      <div className="first-cell"></div>
                                      <div className="cell">{EXitem.range}</div>
                                      <div className="cell">
                                        <span className={Number(EXitem.net_pl) > 0 ? "green-color" : Number(EXitem.net_pl) < 0 ? 'danger-color' : ''}>{EXitem.net_pl}</span>
                                      </div>
                                    </div>
                                  )
                                })

                            }

                          </div>}
                        </Fragment>
                      )
                    })
                  }
                </div>
              }

              {
                (_.isEmpty(bookmakerList) && _.isEmpty(MarketList) && _.isEmpty(FancyData)) &&
                <MessageBox paragraph={t(CONSTANT.MARKET_BLANK_PARAGRAPH)} />
              }

            </Fragment>
        }
      </Fragment>
    );
  }
}
FixtureDetailList.defaultProps = {
  _fancyOddsActiveClass: () => { },
  _fancyHandleOptionChange: () => { }
}
const FixtureDetailListTrans = withTranslation()(FixtureDetailList)

function mapStateToProps(state) {
  const { app } = state;
  return {
    WinlossObject: app.WinlossObject
  };
}

const mapDispatchToProps = dispatch => ({
  updateMatchId: (type) => dispatch(updateMatchId(type)),
  updateMatchStatus: (key) => dispatch(updateMatchStatus(key))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FixtureDetailListTrans);