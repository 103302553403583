// import React, { Component, Fragment } from 'react';
// import { Container, Row, Col, Table, FormGroup, Label, Button } from 'reactstrap';
import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Modal, ModalBody } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
// import DatePicker from 'react-date-picker';
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Http, API, Utils, _ } from "Helpers";
// import { Http, API, Utils, _, Role } from 'Helpers';
import { CONSTANT } from "Constants";
//Images
import { SiteContainer, MessageBox, AgentLinkNav } from "Components";
// import { Auth } from 'Service';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { ScrollSportstab } from "Views";

class BetHistory extends Component {
  constructor(props) {
    super(props);
    // const { page_id } = props
    this.state = {
      hasMore: false,
      posting: false,
      Offset: CONSTANT.CURRENT_OFFSET,
      HistoryList: [],
      from_date: moment().subtract(1, "month").toDate(),
      to_date: moment().toDate(),
      TotalProfit: "",
      TotalLiability: "",
      filter: 1,
      NavigationList: [],
      page_id: "sportbook_betting",
      activeSportsTab: "",
      isLoaderShow: false,
      filterModalShow: false,
    };
    this.datePickerRef = React.createRef();
    this.datePickerRefClick = React.createRef();
  }

  handleIconClick = () => {
    if (this.datePickerRef.current) {
      this.datePickerRef.current.setFocus();
    }
  };
  handleIconClickS = () => {
    if (this.datePickerRefClick.current) {
      this.datePickerRefClick.current.setFocus();
    }
  };

  // LIST API

  getHistoryList = () => {
    this.setState({ isLoaderShow: true });
    const {
      filter,
      Offset,
      HistoryList,
      from_date,
      to_date,
      page_id,
      activeSportsTab,
    } = this.state;
    let alltab = [
      {
        category_id: "",
        sports_name: "All",
        status: "1",
      },
    ];
    let param = {
      limit: CONSTANT.ITEMS_PERPAGE_SM,
      offset: Offset,
      from_date: moment.utc(from_date).format("Y-M-D"),
      to_date: moment.utc(to_date).format("Y-M-D"),
      filter: filter,
      sports_id: activeSportsTab ? activeSportsTab : "",
    };

    this.setState({ posting: true });
    Http.post(API.HISTORY, param)
      .then((response) => {
        this.setState(
          {
            posting: false,
            HistoryList:
              Offset == CONSTANT.CURRENT_OFFSET
                ? response.data.bet_data
                : [...HistoryList, ...response.data.bet_data],
            hasMore: !_.isEmpty(response.data.bet_data),
            NavigationList: [
              ...alltab,
              ...Utils.getMasterData().sports_list[page_id],
            ],
          },
          () => {
            if (Offset === CONSTANT.CURRENT_OFFSET) {
              this.setState({
                TotalLiability: !_.isUndefined(response.data.profit_liability)
                  ? response.data.profit_liability.total_liability
                  : "",
                TotalProfit: !_.isUndefined(response.data.profit_liability)
                  ? response.data.profit_liability.total_pl
                  : "",
              });
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ posting: false });
      });
  };

  // Handlers

  handleOptionChange = (item) => {
    this.setState({
      filter: item,
    });
  };

  onDateChange = (date, key) => {
    this.setState({ [key]: date });
    // this.setState({ [key]: date }, this.getHistoryList)
  };

  fetchMoreData = () => {
    let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
    this.setState({ Offset: offset }, () => {
      this.getHistoryList();
    });
  };

  deleteUnmatchBet = (betslip_uid, index) => {
    this.setState({ posting: true });
    let param = {
      betslip_uid: betslip_uid,
    };
    Http.post(API.CANCEL_UM_BET, param)
      .then((response) => {
        let tempArr = this.state.HistoryList;
        tempArr.splice(index, 1);
        this.setState({
          posting: false,
          HistoryList: tempArr,
        });
        Utils.notify(response.message);
      })
      .catch((error) => {
        this.setState({ posting: false });
      });
  };

  SearchData = () => {
    this.setState(
      {
        Offset: CONSTANT.CURRENT_OFFSET,
        filterModalShow: false,
      },
      () => {
        this.getHistoryList();
      }
    );
  };

  // FilterData = (filter) => {
  //   this.setState(
  //     { filter, HistoryList: [], Offset: CONSTANT.CURRENT_OFFSET },
  //     () => {
  //       this.getHistoryList();
  //     }
  //   );
  // };

  ResetData = () => {
    this.setState(
      {
        filter: 1,
        from_date: moment().subtract(1, "month").toDate(),
        to_date: moment().toDate(),
        TotalProfit: "",
        TotalLiability: "",
        Offset: CONSTANT.CURRENT_OFFSET,
        //filterModalShow: false,
      },
      () => {
        this.getHistoryList();
      }
    );
  };

  // LIFE CYLCE METHODS
  componentDidMount() {
    this.getHistoryList();
  }

  onTabClick = (item) => {
    this.setState(
      {
        activeSportsTab: item.sports_id,
        HistoryList: [],
        isLoaderShow: false,
        Offset: CONSTANT.CURRENT_OFFSET,
      },
      () => {
        this.getHistoryList();
      }
    );
  };

  filterModal = () => {
    this.setState({ filterModalShow: true });
  };
  filterModalHide = () => {
    this.setState({
      filter: 1,
      from_date: moment().subtract(1, "month").toDate(),
      to_date: moment().toDate(),
      // TotalProfit: "",
      //TotalLiability: "",
      // Offset: CONSTANT.CURRENT_OFFSET,
      filterModalShow: false,
    });
  };

  renderSportsNav = (AvaSports) => {
    return (
      <ScrollSportstab
        tabsContainerClassName="sp-tb-scroll-container"
        {...this.props}
      >
        {({ Tab }) => {
          return _.map(AvaSports, (item, idx) => {
            return (
              <Tab
                className="sp-item"
                key={idx}
                onClick={() => this.onTabClick(item, idx)}
              >
                <span onClick={() => this.onTabClick(item, idx)}>
                  {
                    item.sports_name
                    // Sports.url[item.value + (WSManager.getAppLang() || '')]
                  }
                </span>
              </Tab>
            );
            // }
          });
        }}
      </ScrollSportstab>
    );
  };

  isWithin30Days(selectedDate) {
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);
    const differenceInMilliseconds = selectedDateObj - currentDate;
    const millisecondsIn31Days = 31 * 24 * 60 * 60 * 1000;
    return Math.abs(differenceInMilliseconds) <= millisecondsIn31Days;
  }

  render() {
    const { t } = this.props;
    const {
      HistoryList,
      hasMore,
      from_date,
      to_date,
      TotalLiability,
      TotalProfit,
      filter,
      NavigationList,
      isLoaderShow,
      filterModalShow,
      posting,
    } = this.state;
    // const { HistoryList, hasMore, from_date, to_date, TotalLiability, TotalProfit, filter ,NavigationList} = this.state
    // const CurrentUser = Auth.currentUserValue;
    const handleInputFocus = (event) => {
      event.target.blur(); // Remove focus from the input field
    };
    return (
      <SiteContainer {...this.props}>
        <Container fluid className="gutters-container-7px">
          {/* <button className="btn btn-primary btn-float"><i className="icon-hamburger-ic"></i></button> */}
          <Row>
            <Col className="leftnav-col">
              <AgentLinkNav {...this.props} />
            </Col>
            <Col>
              {/* <div className="bet-history-heading">
                <div className="bet-history-view">
                  <img src={Images.BACK_IMG} alt="" />
                  <i className="icon-previous" />
                  <span> {t("Bet History")}</span>
                </div>
                <i
                  className="icon-hamburger-ic"
                  onClick={() => {
                    this.filterModal();
                  }}
                />
              </div> */}
              <div className="sports-tab-view">
                <div className="sports-name">
                  {this.renderSportsNav(NavigationList)}
                </div>
              </div>
              <div className="container">
                <div className="history-last-six-month-view">
                  <div className="text-six-month">
                    <div>
                      History{" "}
                      <span>
                        {" "}
                        {this.isWithin30Days(from_date) ? "(last 30 Days)" : ""}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        this.filterModal();
                      }}
                    >
                      <i className="icon-hamburger-ic"></i>
                    </div>
                  </div>
                </div>
                {!_.isEmpty(HistoryList) && isLoaderShow ? (
                  <>
                    {(!_.isEmpty(TotalProfit) ||
                      (filter !== 3 && !_.isEmpty(TotalLiability))) && (
                      <div className="total-container">
                        {!_.isEmpty(TotalProfit) && (
                          <div className="profit-container">
                            <div className="total-profit-text">
                              {t("Total Profit")}
                            </div>
                            <div className="total-value">{TotalProfit}</div>
                          </div>
                        )}

                        {filter !== 3 && !_.isEmpty(TotalLiability) && (
                          <div className="profit-container mt-1">
                            <div className="total-profit-text">
                              {t("Total Stake")}
                            </div>
                            <div className="total-value">{TotalLiability}</div>
                            {/* {TotalLiability > 0 ? '-' : ''} */}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="history-last-six-month-view">
                      <div className="history-container">
                        <InfiniteScroll
                          dataLength={HistoryList.length}
                          next={this.fetchMoreData}
                          hasMore={hasMore}
                          hasChildren={false}
                          className=""
                        >
                          {_.map(HistoryList, (item, index) => {
                            return (
                              <div
                                className="history-container-view"
                                key={index}
                              >
                                <div className="history-inner-header">
                                  <i
                                    className={`img-view icon-${item.sports_id}`}
                                  ></i>

                                  <div className="league-name">
                                    {item.league_name}
                                  </div>
                                </div>
                                <div className="team-name-and-date-con">
                                  <div className="team-name">
                                    {item.event_name}
                                  </div>

                                  {item.winning_status == "0" ? (
                                    <span className="history-results-new pending">
                                      Pending
                                    </span>
                                  ) : item.winning_status == "1" ? (
                                    <span className="history-results-new won">
                                      Won
                                    </span>
                                  ) : item.winning_status == "2" ? (
                                    <span className="history-results-new loss">
                                      Loss
                                    </span>
                                  ) : item.winning_status == "3" ? (
                                    <span className="history-results-new cancel">
                                      Cancelled
                                    </span>
                                  ) : (
                                    <div className="date-view">
                                      {Utils.dateTimeFormat(
                                        item.created_date,
                                        "DD/MM/YYYY"
                                      )}
                                    </div>
                                  )}
                                </div>
                                {(item.winning_status == "0" ||
                                  item.winning_status == "1" ||
                                  item.winning_status == "2" ||
                                  item.winning_status == "3") && (
                                  <div className="date-view-out">
                                    {Utils.dateTimeFormat(
                                      item.created_date,
                                      "DD/MM/YYYY"
                                    )}
                                  </div>
                                )}

                                <div className="inner-view-data">
                                  <Row>
                                    <Col xs={4}>
                                      <div className="text-view">Bet ID</div>
                                      <div className="number-value-view">
                                        {item.bet_slip_id
                                          ? item.bet_slip_id
                                          : "--"}
                                      </div>
                                    </Col>
                                    <Col xs={4} className="">
                                      <div className="text-view">
                                        Market Name
                                      </div>
                                      <div className="number-value-view">
                                        {item.market_name}
                                      </div>
                                    </Col>
                                    {/* <Col xs={3} className=''>
                                      <div className='text-view'>Bet Type</div>
                                      <div className='number-value-view'>   {CONSTANT.GET_BOOKMAKER_TYPE[item.bookmaker_type]}</div>
                                    </Col> */}
                                    {/* <Col xs={4} className='text-right'>
                                      <div className='text-view'>Type</div>
                                      <div className='number-value-view'> {Number(item.bookmaker_type) !== 5 ?
                                        <>
                                          {Number(item.betting_type) == 1 ?
                                            <span className="">Back</span>
                                            :
                                            <span className="">
                                              Lay
                                            </span>}
                                        </> :
                                        '-'
                                      }</div>
                                    </Col> */}
                                    <Col xs={4}>
                                      <div className="text-view">Odds</div>
                                      <div className="number-value-view">
                                        {item.price ? item.price : "--"}
                                      </div>
                                    </Col>
                                    <Col xs={4} className="">
                                      <div className="text-view">Stake</div>
                                      <div className="number-value-view">
                                        {item.stake ? item.stake : "--"}
                                      </div>
                                    </Col>
                                    <Col xs={4} className="xtext-right">
                                      {item.winning_status == "2" ? (
                                        <div>
                                          <div className="text-view">Loss</div>
                                          <div className="number-value-view">
                                            {item.stake ? item.stake : "--"}
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className="text-view">
                                            {item.winning_status == "0"
                                              ? "Probable Win"
                                              : item.winning_status == "3"
                                              ? "Refund"
                                              : "Won"}
                                          </div>
                                          <div className="number-value-view">
                                            {item.winning_status == "3"
                                              ? item.stake
                                                ? item.stake
                                                : "--"
                                              : item.pp
                                              ? item.pp
                                              : "--"}
                                          </div>
                                        </div>
                                      )}
                                      <></>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </>
                ) : (
                  !posting && (
                    <MessageBox paragraph={t(CONSTANT.BLANK_HISTORY)} />
                  )
                )}
              </div>
              {/* {filterModalShow && <FilterModal filterModalHide ={this.filterModalHide} ResetData={this.ResetData} getHistoryList={this.getHistoryList} />} */}

              <Modal isOpen={filterModalShow} className="filter-modal-view">
                <ModalBody>
                  <div className="filter-body-container">
                    <div className="filter-body-header">
                      <div className="sort-fitler-text">
                        <div
                          className="back-icon-view"
                          onClick={() => this.filterModalHide()}
                        >
                          {" "}
                          <i
                            className="icon-previous"
                            onClick={() => this.filterModalHide()}
                          />
                        </div>
                        <span> Sort & Filter</span>
                      </div>
                      <div
                        className="clear-all-view"
                        onClick={() => this.ResetData()}
                      >
                        clear All
                      </div>
                    </div>
                    <div className="filter-body-view">
                      {/* <div className='Date-view-text'>Date</div> */}
                      <Row className="filter-row-view">
                        <Col xs={4} className="filter-first-column">
                          <div className="date-text-view">From date</div>
                        </Col>
                        <Col xs={8} className="filter-second-column">
                          <div className="date-text-view">
                            <FormGroup>
                              {/* <Label>From Date</Label> */}

                              <div className={"cs-datepicker"}>
                                <DatePicker
                                  ref={this.datePickerRef}
                                  popoverPlacement="bottom"
                                  //readOnly={true}
                                  selected={from_date}
                                  onFocus={handleInputFocus}
                                  onChange={(e) =>
                                    this.onDateChange(e, "from_date")
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  timeCaption="time"
                                  className={""}
                                  maxDate={to_date}
                                />
                                <i
                                  className="icon-calendar cs-icon"
                                  onClick={this.handleIconClick}
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row className="filter-row-view">
                        <Col xs={4} className="filter-first-column">
                          <div className="date-text-view">To date</div>
                        </Col>
                        <Col xs={8} className="filter-second-column">
                          <div className="date-text-view">
                            <FormGroup>
                              {/* <Label>{t('To Date')}</Label> */}

                              <div className={"cs-datepicker"}>
                                <DatePicker
                                  ref={this.datePickerRefClick}
                                  popoverPlacement="bottom"
                                  //readOnly={true}
                                  selected={to_date}
                                  onFocus={handleInputFocus}
                                  onChange={(e) =>
                                    this.onDateChange(e, "to_date")
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  className={""}
                                  minDate={from_date}
                                  maxDate={moment().toDate()}
                                />
                                <i
                                  className="icon-calendar cs-icon"
                                  onClick={this.handleIconClickS}
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row className="filter-row-view">
                        <Col xs={4} className="filter-first-column">
                          <div
                            className={`text-type ${
                              filter == 0 ? "other-text-view" : ""
                            }`}
                          >
                            Bet Status
                          </div>
                        </Col>
                        <Col xs={8} className="filter-second-column">
                          <div className="radio-wrap">
                            <div
                              className={`radio-button-view ${
                                filter == 1 ? "active-class" : ""
                              }`}
                              onClick={() => this.handleOptionChange(1)}
                            >
                              <div
                                className={
                                  filter == 1 ? "active-radio-button" : ""
                                }
                              ></div>
                            </div>
                            <div
                              className={`text-all ${
                                filter == 1 ? "other-text-view" : ""
                              }`}
                            >
                              Pending
                            </div>
                          </div>
                          <div className="radio-wrap">
                            <div
                              className={`radio-button-view ${
                                filter == 3 ? "active-class" : ""
                              }`}
                              onClick={() => this.handleOptionChange(3)}
                            >
                              <div
                                className={
                                  filter == 3 ? "active-radio-button" : ""
                                }
                              ></div>
                            </div>
                            <div
                              className={`text-all ${
                                filter == 3 ? "other-text-view" : ""
                              }`}
                            >
                              Completed
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="filter-modal-footer">
                    <div
                      className="botton-view-cancel"
                      onClick={() => this.filterModalHide()}
                    >
                      cancel
                    </div>
                    <div
                      className="botton-view-apply"
                      onClick={() => this.SearchData()}
                    >
                      Apply
                    </div>
                  </div>
                </ModalBody>
              </Modal>

              {/* <Row className="inline-form sm gutters-10px">
                <Col xs={6} sm={2}>
                  <FormGroup>
                    <Label>{t('From Date')}</Label>

                    <div className={'cs-datepicker'}>
                      <DatePicker
                        selected={from_date}
                        onChange={(e) => this.onDateChange(e, 'from_date')}
                        dateFormat="dd/MM/yyyy"
                        timeCaption="time"
                        className={'form-control w-100'}
                        maxDate={to_date}
                      />
                      <i className='icon-calendar cs-icon' />
                    </div>

                  </FormGroup>
                </Col>
                <Col xs={6} sm={2}>
                  <FormGroup>
                    <Label>{t('To Date')}</Label>

                    <div className={'cs-datepicker'}>
                      <DatePicker
                        selected={to_date}
                        onChange={(e) => this.onDateChange(e, 'to_date')}
                        dateFormat="dd/MM/yyyy"
                        className={'form-control w-100'}
                        minDate={from_date}
                        maxDate={moment().toDate()}
                      />
                      <i className='icon-calendar cs-icon' />
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={5}>
                  <Button color="primary" className={filter == 1 ? 'active' : ''} size="sm" onClick={() => this.FilterData(1)}>{t('Matched')}</Button>
                  {
                    CurrentUser != null && CurrentUser.role != Role.AgentPunter ? <Button color="primary" className={filter == 2 ? 'active' : ''} size="sm" onClick={() => this.FilterData(2)}>{t('Unmatched')}</Button>
                      : ''
                  }


                  <Button color="primary" className={filter == 3 ? 'active' : ''} size="sm" onClick={() => this.FilterData(3)}>{t('Past')}</Button>
                  <Button color="primary" className={filter == 4 ? 'active' : ''} size="sm" onClick={() => this.FilterData(4)}>{t('Fancy')}</Button>
                  <Button color="primary" size="sm" onClick={() => this.ResetData()}>{t('Reset')}</Button>
                </Col>
                <Col className="total-block" xs={12} sm={3}>
                  {
                    (filter !== 3 && !_.isEmpty(TotalLiability)) &&
                    <div>
                      <h4>{t('Total Liability')}</h4>
                      <p className={TotalLiability == 0 ? '' : 'danger-color'}>{TotalLiability > 0 ? '-' : ''}{TotalLiability}</p>

                    </div>
                  }
                  {
                    !_.isEmpty(TotalProfit) &&
                    <div>
                      <h4>{t('Total Profit')}</h4>
                      <p className={TotalProfit > 0 ? "green-color" : TotalProfit < 0 ? 'danger-color' : ''}>{TotalProfit}</p>
                    </div>
                  }
                </Col>

              </Row> */}

              {/* {!_.isEmpty(HistoryList) ? <InfiniteScroll
                dataLength={HistoryList.length}
                next={this.fetchMoreData}
                hasMore={hasMore}
                hasChildren={false}
                className=''
              >
                <Table className={"table-primary mt20 bethistory-responise-tbl " + (filter == 3 ? 'past' : '')}>
                  <thead>
                    <tr className="text-uppercase">
                      <th>{t('Placed')}</th>
                      <th style={{ width: '22%' }}>{t('Description')}</th>
                      <th>{t('Bet Type')}</th>
                      <th>{t('Type')}</th>
                      <th>{t('Odds')}</th>
                      <th>{t('Stake')}</th>
                      {filter !== 3 && <th>{t('Liability')}</th>}
                      <th>
                        {filter !== 3 ?
                          <span>
                            {t('Potential')}<br />{t('Profit')}

                          </span> :
                          <span>
                            {t('Profit/Loss')}

                          </span>}

                      </th>
                      <th>{t('Ip Address')}</th>
                      <th className="text-center">{t('Result')}</th>
                      <th>{t('Action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      _.map(HistoryList, (item, index) => {
                        return (
                          <Fragment key={index}>
                            <tr key={index}>
                              <td>
                                <span className="block">{Utils.dateTimeFormat(item.created_date, 'DD/MM/YYYY,')}</span>

                                <span className="block">{' '}{Utils.dateTimeFormat(item.created_date, 'h:mm A')}</span>
                              </td>
                              <td>
                                <span className="block">{item.event_name}</span>



                                {Number(item.bookmaker_type) !== 3 ?
                                  <span className="block">
                                    {Number(item.bookmaker_type) !== 5 &&
                                      <>
                                        {item.market_name} {' - '}
                                      </>}
                                    {_.map(item.market_runners, (ritm, rid) => {
                                      return (
                                        Number(item.bookmaker_type) == 4 ?
                                          item.selection_id == ritm.id ? ritm.name : ''
                                          :
                                          item.selection_id == ritm.selectionId ? ritm.runnerName : ''

                                      )
                                    })}
                                  </span>
                                  :
                                  <span className="block">
                                    {item.market_name} {' - '}
                                    {item.line}
                                  </span>
                                }
                                {
                                  Number(item.bookmaker_type) === 1 &&
                                  <span className="block">
                                    {item.odd_name}
                                  </span>
                                }
                                {Number(item.bookmaker_type) === 4 && <span className="block">
                                  {' '}
                                  Round ID: {item.event_id}
                                </span>}
                                <span className="block">
                                  {' '}
                                  Bet ID: {item.betslip_uid}
                                </span>
                              </td>
                              <td>
                                {CONSTANT.GET_BOOKMAKER_TYPE[item.bookmaker_type]}
                              </td>
                              <td>
                                {Number(item.bookmaker_type) !== 5 ?
                                  <>
                                    {Number(item.betting_type) == 1 ?
                                      <span className="type-color back">Back</span>
                                      :
                                      <span className="type-color lay">
                                        Lay
                                      </span>}
                                  </> :
                                  '-'
                                }
                              </td>
                              <td>
                                {Number(item.bookmaker_type) == 5 ? '-' :
                                  Number(item.bookmaker_type) !== 3 ?
                                    item.price :
                                    item.line}

                              </td>
                              <td>{item.stake}</td>
                              {filter !== 3 && <td>
                                <span className={item.liability == 0 ? '' : "danger-color"}>
                                  {item.liability == 0 ? '' : '-'}{item.liability}

                                </span>
                              </td>}
                              <td>
                                {
                                  filter !== 3 ?
                                    <span className="green-color">
                                      {filter == 1 ?

                                        item.pp
                                        :
                                        item.pp
                                        // item.payout
                                      }
                                    </span>
                                    :

                                    item.winning_status == '3' ?
                                      <span>0</span>
                                      :
                                      item.settlement_result == '66779' ?
                                        <span className={
                                          (Number(item.winning) - Number(item.liability)) / 2 > 0 ? "green-color" :
                                            (Number(item.winning) - Number(item.liability)) / 2 < 0 ? "danger-color" : ''}>
                                          {Utils.TwoDecimalVal((Number(item.winning) - Number(item.liability) / 2))}
                                        </span>
                                        :
                                        <span className={
                                          Number(item.winning) - Number(item.liability) > 0 ? "green-color" :
                                            Number(item.winning) - Number(item.liability) < 0 ? "danger-color" : ''}>
                                          {Utils.TwoDecimalVal(Number(item.winning) - Number(item.liability))}
                                        </span>

                                }

                                
                              </td>
                              <td>{item.ip_address}</td>
                              <td className="text-center">
                                {
                                  item.winning_status == '0' ?
                                    <span className="history-results pending">Pending</span> :
                                    item.winning_status == '1' ?
                                      <span className="history-results won">Won</span> :
                                      item.winning_status == '2' ?
                                        <span className="history-results loss">Loss</span> :
                                        item.winning_status == '3' ?
                                          <span className="history-results cancel">Cancelled</span> : ''

                                }
                              </td>
                              <td className="text-center">
                                {filter == 2 && <a onClick={() => { if (window.confirm(t('Are you sure you want to delete this bet?'))) { this.deleteUnmatchBet(item.betslip_uid, index) } }} className="delete-unmatch-row"><i className="icon-trash"></i></a>}
                              </td>
                            </tr>

                          </Fragment>
                        )
                      })
                    }





                  </tbody>
                </Table>
              </InfiniteScroll> :
                <MessageBox paragraph={t(CONSTANT.BLANK_HISTORY)} />
              } */}
            </Col>
          </Row>
        </Container>
      </SiteContainer>
    );
  }
}

export default withTranslation()(BetHistory);
