import React, { Component, Fragment } from 'react';
import { _ } from 'Helpers';
import Drawer from 'react-motion-drawer';
import { withTranslation } from 'react-i18next';
import { Betslip, DefaultFooter, ScoresAndTvAccordion } from 'Components';
//BetslipOpenBet
import { Collapse, Col } from 'reactstrap';

import Sticky from 'react-stickynode';
import { connect } from "react-redux";
//import { Auth } from 'Service';
import { OpenBet } from '../../Redux/Reducers'



class BetslipDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DrawerBetslipOpen: false,
      is_drawer_active: false,
      is_betslip_drawer: true,
      isFooterShow: false,
      tvAccordShow: false
    }
  }

  FooterToggle = () => {
    let LockScreen = 'lock-screen'
    this.setState({
      isFooterShow: !this.state.isFooterShow
    }, () => {
      if (this.state.isFooterShow) {
        document.body.classList.add(LockScreen);
      } else {
        document.body.classList.remove(LockScreen);
      }
    })
  }


  updateDimensions = () => {
    this.setState({
      is_drawer_active: window.innerWidth < 850,
      is_betslip_drawer: window.innerWidth > 767.98
    }, () => {
      // this.props.OpenBet({ type: false, bool: false })
      // this.props.OpenBet({ type: false, bool: false })
    });
  }
  updatePlaceholder = () => {
    if (this.divRef) {
      this.setState({
        placeholderHeight: this.divRef.clientHeight
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("scroll", this.updatePlaceholder);
  }

  componentWillMount() {

    // let path = this.props.location.pathname;
    // let checkExist = path.includes("in-play");
    // let checkDetailPageExist = path.includes("details");
    // if (checkExist && checkDetailPageExist) {
    //   this.setState({ tvAccordShow: true })
    // }

    this.updateDimensions();
    this.updatePlaceholder();
  }


  componentWillReceiveProps(props){
    if(props.isMatchLive && this.state.tvAccordShow != props.isMatchLive){
      this.setState({ tvAccordShow: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("scroll", this.updatePlaceholder);
    this.setState = () => {
      return;
    };
  }


  render() {
    const { openBetslipDrawer, OpenBet, t } = this.props
    //const currentUser = Auth.currentUserValue
    const {
      SelectedOdds
    } = this.props.BetslipProps
    
    const objLength = Object.keys(SelectedOdds).length;
    let {
      is_drawer_active,
      is_betslip_drawer,
      isFooterShow,
      placeholderHeight,
      tvAccordShow
    } = this.state

    const RosterDrawerProps = {
      ...this.props,
      overlayColor: "rgba(0,0,0,0.8)",
      width: 320,
      right: true,
      fadeOut: true,
      open: openBetslipDrawer,
      noTouchOpen: true,
      zIndex: 1030
    };



    return (
      is_drawer_active ?
        <Fragment>
          {
            is_betslip_drawer ?
              <div className="betslip-drawer-toggle" onClick={() => OpenBet({ type: true, bool: false })}>{t('Bet Slip')} {!_.isEmpty(SelectedOdds) && `[${_.size(SelectedOdds)}]`}</div>
              :
              <Fragment>
                <div className={`sticky-betslip sticky-betslip-new ${objLength > 1 ?' fullview-bets ' :''}`} ref={element => this.divRef = element}>
                  {
                    tvAccordShow &&
                    <ScoresAndTvAccordion {...this.props} is_betslip_drawer={is_betslip_drawer} />
                  }
                  <Betslip {...this.props.BetslipProps} />
                  {/* <div className="app-footer-toggle" onClick={() => this.FooterToggle()}>
                    {
                      isFooterShow ?
                        <i className="icon-up" />
                        :
                        <div className="app-footer-inner">
                          {process.env.REACT_APP_WEBSITE_COPYRIGHT}
                          <i className="icon-down" />
                        </div>
                    }
                  </div> */}
                  <Collapse isOpen={isFooterShow}>
                    <DefaultFooter />
                  </Collapse>
                </div>
                {
                  this.divRef &&
                  <div className="sticky-betslip-placeholder" style={{ height: placeholderHeight }} />
                }
              </Fragment>
          }

          <Drawer
            className='betslip-drawer'
            {...RosterDrawerProps}
            onChange={open => OpenBet({ type: open, bool: false })}
          >

            {
              tvAccordShow &&
              <ScoresAndTvAccordion {...this.props} is_betslip_drawer={is_betslip_drawer} />
            }
            {
              is_betslip_drawer &&
              <Betslip {...this.props.BetslipProps} />
            }
            {
              // currentUser &&
              // <BetslipOpenBet is_betslip_drawer={is_betslip_drawer} />
            }
          </Drawer>
        </Fragment>
        :
        
        <Col className='betslip-col' id='BetslipCol'>
          <Sticky bottomBoundary='#BetslipCol'>

            {
              tvAccordShow &&
              <ScoresAndTvAccordion {...this.props} is_betslip_drawer={is_betslip_drawer} />
            }
            <Betslip {...this.props.BetslipProps} />
            {
              // currentUser &&
              // <BetslipOpenBet is_betslip_drawer={is_betslip_drawer} />
            }
          </Sticky>
        </Col>
    )
  }
}

const BetslipDrawerTrans = withTranslation()(BetslipDrawer)

function mapStateToProps(state) {
  const { app } = state;
  return {
    openCollapse: app.openCollapse,
    openBetslipDrawer: app.openBetslipDrawer,
    isMatchLive:app.isMatchLive
  };
}

const mapDispatchToProps = dispatch => ({
  OpenBet: (option) => dispatch(OpenBet(option))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetslipDrawerTrans);
