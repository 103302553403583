/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import { API, Http, Socket, Utils, _ } from "Helpers";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { CONSTANT, Images, SocketEvent } from "Constants";
import {
  MessageBox,
  Scoreboard,
  TemplateOptionSB,
  TemplateTotalSB,
  TitleComponent,
  TemplateHandicapSB,
} from "Components";
import { Auth } from "Service";

class SportsBookFixtureDetailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Init: true,
      FixturePosting: true,
      isFixtureDetailsChanged: false,

      ScoresData: [],
      MarketList: [],
      SelectedFixture: {},
      EventDetails: {},
      fancyChanged: false,
      recently_changed: [],
      teamNameAway: [],
      teamNameHome: [],
    };
  }

  // Fetch Request(s)
  GetFixtureMarket = () => {
    const { match } = this.props;
    let param = {
      event_id: match.params.event_id,
    };
    this.setState({
      Init: false,
      FixturePosting: true,
    });
    Http.post(API.GET_FIXTURE_MARKETS_SP, param)
      .then((response) => {
        this.setState(
          {
            FixturePosting: false,
            EventDetails: response.data.event,
            MarketList: response.data.markets,
            ScoresData: response.data.scores,
          },
          () => {
          
            this.renderMarketName();
            this.setState({
              ...(!_.isEmpty(this.state.MarketList) && {
                SelectedFixture: this.state.MarketList[0],
              }),
            });

            if (this.state.EventDetails.is_live == 1) {
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ FixturePosting: false });
      });
  };

  // Life cycle
  componentDidMount() {
    this.GetFixtureMarket();

    const _this = this;
    Socket.on(SocketEvent.MARKET_SP, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketMarkethandler(res[0]);
      }
    });
    Socket.on(SocketEvent.STATUS_UPDATED_MK, function (res) {
      let newArray = [..._this.state.MarketList];
      //let getIdx = newArray.findIndex(itm => itm.event_id == res.event_id);

      _.map(newArray, (it, ix) => {
        let newData = JSON.parse(it.runners_odds);
        _.map(newData, (obj, key) => {
          if (Number(obj.selection_id) == Number(res.selection_id)) {
            obj.lock_betting = res.lock_betting;
          }
        });
        newArray[ix].runners_odds = JSON.stringify(newData);
      });

      _this.setState({ MarketList: newArray }, () => {});
    });

    Socket.on(SocketEvent.MARKETUPDATEDCS, function (res) {
      if (!_.isEmpty(res)) {
        let newArray = [..._this.state.MarketList];    
        newArray = newArray.filter((market) => {
          return (
              market.event_id !== res.event_id ||
              market.market_name !== res.market_name
          );
      });
      _this.setState({ MarketList: newArray }, () => {});
      }
    });

    this.sockeListenerScore()
    
  }

  componentWillUnmount() {
    Socket.off(SocketEvent.MARKET_SP);
    Socket.off(SocketEvent.STATUS_UPDATED_MK);
    Socket.off(SocketEvent.MARKETUPDATEDCS);
    Socket.off(SocketEvent.SCORE_UPDATED_SP);
    this.setState = () => {
      return;
    };
  }

  sockeListenerScore = () => {
    const _this = this;
    const {ScoresData} = _this.state;
    const { match } = _this.props;
    let temp = ScoresData
    Socket.on(SocketEvent.SCORE_UPDATED_SP, function (res) {
      if (!_.isEmpty(res)) {
        
       if(match.params.event_id == res.event_id){
        temp.scores = res.scores
        _this.setState({
          ScoresData:temp
        })
       }
       
      }
    });
  };

  

  // Other Handler
  SocketMarkethandler = (res = {}) => {
    const { MarketList, EventDetails, recently_changed } = this.state;

    // if(!_.isEmpty(MarketList)) {
    let newMarketList = _.map(MarketList, (item) => {
      if (item.market_event_id == res.market_event_id) {
        let ex_obj = {};
        let new_recently_changed = recently_changed;
        let item_runners_odds = JSON.parse(item.runners_odds);

        _.forEach(JSON.parse(res.runners_odds), (obj, idx) => {
          // return;
          if (item_runners_odds[idx] != undefined) {
            if (
              (item_runners_odds[idx] != undefined &&
                item_runners_odds[idx].is_suspended != obj.is_suspended) ||
              item_runners_odds[idx].selection_id != obj.selection_id ||
              item_runners_odds[idx].odds != obj.odds
            ) {
              new_recently_changed = [
                ...new_recently_changed,
                JSON.stringify(obj.selection_id),
              ];
              ex_obj = { ...res };
              this.setState({
                isChanged: true,
                recently_changed: new_recently_changed,
              });
            }
          }
        });

        return {
          ...item,
          ...ex_obj,
        };
      }
      return item;
    });
    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ MarketList: newMarketList }, () => {
        this.setState({ isChanged: false });
      });
    }
    // } else if(EventDetails.event_id ==  res.event_id) {
    //   this.setState({MarketList: [...MarketList, res]})
    // }
  };

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state;
    let _id = _.isString(id) ? id : JSON.stringify(id);
    if (_.includes(recently_changed, _id)) {
      this.cleanSocketHighlight();
      return " blink ";
    } else return "";
  };

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] });
      }
    }, timer);
  };
  reinitiateComponent = () => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    this.props.history.push(`/all-matches?tab=${searchParams.get("tab")}`);
    //alert('radhe')
    //return true
    // const { reinitiateComponent, location, is_inplay } = this.props
    // const searchParams = new URLSearchParams(location.search);
    // if (is_inplay) {
    //   reinitiateComponent(searchParams.get('sports'), true)
    // } else {
    //   reinitiateComponent(searchParams.get('sports'))
    // }
  };

  ResetWinloss = (obj) => {
    const { MarketList } = this.state;
    let newMarketList = _.map(MarketList, (item) => {
      let new_runners = _.map(JSON.parse(item.runners_odds), (_obj) => {
        return {
          ..._obj,
          temp_winloss: undefined,
        };
      });
      return { ...item, runners_odds: JSON.stringify(new_runners) };
    });
    this.setState({
      MarketList: newMarketList,
    });
  };

  componentWillReceiveProps(nextProps) {
    const { WinlossObject } = this.props;

    if (nextProps.WinlossObject.lastupdate !== WinlossObject.lastupdate) {
      if (!nextProps.WinlossObject.is_place_bet) {
        switch (nextProps.WinlossObject.type) {
          case 0:
            this.UpdateWinloss(nextProps.WinlossObject);
            break;
          case 1:
            this.RunTimeUpdateWinloss(nextProps.WinlossObject);
            break;
          case 2:
            this.ResetWinloss(nextProps.WinlossObject);
            break;
          default:
            break;
        }
      } else {
      }
    }
  }
  UpdateWinloss = (obj) => {
    const { MarketList } = this.state;
    let newMarketList = _.map(MarketList, (item) => {
      if (item.market_event_id == obj.market_event_id) {
        let new_runners = _.map(JSON.parse(item.runners_odds), (_obj) => {
          return {
            ..._obj,
            winloss: obj.win_loss[_obj.selection_id],
          };
        });
        return { ...item, runners_odds: JSON.stringify(new_runners) };
      }
      return item;
    });
    this.setState({
      MarketList: newMarketList,
    });
  };

  ResetWinloss = (obj) => {
    const { MarketList } = this.state;
    let newMarketList = _.map(MarketList, (item) => {
      let new_runners = _.map(JSON.parse(item.runners_odds), (_obj) => {
        return {
          ..._obj,
          temp_winloss: undefined,
        };
      });
      return { ...item, runners_odds: JSON.stringify(new_runners) };
    });
    this.setState({
      MarketList: newMarketList,
    });
  };

  RunTimeUpdateWinloss = ({
    market_event_id,
    selection_id,
    stack,
    betting_type,
    pp,
    liability,
    bookmaker_type,
    session_id,
  }) => {
    const { MarketList } = this.state;

    if (bookmaker_type == CONSTANT.BOOKMAKER_TYPE["sportbook_betting"]) {
      let newMarketList = _.map(MarketList, (item) => {
        if (item.market_event_id == market_event_id) {
          let new_runners = _.map(JSON.parse(item.runners_odds), (_object) => {
            let _obj = _.cloneDeep(_object);
            if (betting_type == 1) {
              if (_obj.selection_id == selection_id) {
                _obj = {
                  ..._obj,
                  temp_winloss: Number(_obj.winloss) + Number(pp),
                };
              } else {
                _obj = {
                  ..._obj,
                  temp_winloss: Number(_obj.winloss) - Number(liability),
                };
              }
            } else if (betting_type == 2) {
              if (_obj.selection_id == selection_id) {
                _obj = {
                  ..._obj,
                  temp_winloss: Number(_obj.winloss) - Number(liability),
                };
              } else {
                _obj = {
                  ..._obj,
                  temp_winloss: Number(_obj.winloss) + Number(stack),
                };
              }
            }
            return _obj;
          });
          return { ...item, runners_odds: JSON.stringify(new_runners) };
        }
        return item;
      });
      this.setState({
        MarketList: newMarketList,
      });
    }
  };

  renderMarketName = () => {
    let event_name = this.state.EventDetails.event_name;
    let team_names = event_name.split(" V ");
    const team1 = team_names[0];
    const team2 = team_names[1];
    this.setState({ teamNameHome: team1, teamNameAway: team2 });
  };


  render() {
    const {
      SelectedSports,
      // Methods
      t,
    } = this.props;

    const {
      MarketList,
      EventDetails,
      FixturePosting,
      ScoresData,
      teamNameAway,
      teamNameHome,
    } = this.state;

    

    const currentUser = Auth.currentUserValue;

    const isDisabled = (agent_status) => {
      return agent_status != 1;
    };
    const BetTitleNew = ({ home, away }) => {
      return (
        <div className="team-details-view">
          <div className="right-view">
            <i className={`img-view icon-${SelectedSports.sports_id}`}></i>
            {/* <img className='img-view' alt="" src={Images.ENGLAND_LOGO} /> */}
            <div className="team-name">{teamNameHome}</div>
          </div>
          <div className="middle-view middle-view-new">
            {EventDetails.is_live == 1 ? (
              <>
                {!_.isNull(ScoresData) && ScoresData.scores !== "" && (
                  <>
                    <div className="points">
                      {JSON.parse(ScoresData.scores).home} -{" "}
                      {JSON.parse(ScoresData.scores).away}
                    </div>

                    {/* <div className="time-view">  {Utils.dateTimeFormat(EventDetails.open_date, 'HH:mm')}</div> */}
                  </>
                )}
              </>
            ) : (
              <Fragment>
                <span className="time-view-container">
                  {" "}
                  <i className="icon-clock" />{" "}
                  {Utils.dateTimeFormat(
                    EventDetails.open_date,
                    "HH:mm, DD/MM/YYYY"
                  )}
                </span>
              </Fragment>
            )}
          </div>
          <div className="left-view">
            <i className={`img-view icon-${SelectedSports.sports_id}`}></i>
            {/* <img alt="" className='img-view' src={Images.ENGLAND_LOGO} /> */}
            <div className="team-name">{teamNameAway}</div>
          </div>
        </div>
      );
    };

    return (
      <Fragment>
        <TitleComponent title={EventDetails.event_name} />
        <Scoreboard
          {...{
            sports_id: SelectedSports.sports_id,
            // ScoresData: ScoresData,
            EventDetails: EventDetails,
          }}
          is_sportsbook
        />
        {FixturePosting ? (
          <div className="m-4">
            <Utils.BoxLoader />
          </div>
        ) : (
          <Fragment>
            {!_.isEmpty(EventDetails) && (
              <>
                <div className="odds-padding-row-new mt20">
                  <div
                    className="back-icon-view"
                    onClick={() => this.reinitiateComponent()}
                  >
                    <img
                      alt=""
                      src={Images.BACK_IMG}
                      onClick={() => this.reinitiateComponent()}
                    />
                    {/* 
                          onClick={() => this.reinitiateComponent()} 
                          this.props.history.goBack()
                      */}
                    <i className="icon-previous" />
                    <span className="l-name">{EventDetails.league_name}</span>
                  </div>
                  {/* <div className="team-view-container">
                      <div className="left-side-view">
                        <img src={Images.ENGLAND_LOGO} alt="" />
                        <div className='team-name'>
                          {teamNameHome}
                        </div></div>
                      <div className="middle-view">
                        <div className='points'>3 - 2</div>
                        {EventDetails.is_live == 1 ? <span className="">{t('In-Play')}</span> : <div className="time-view">{Utils.dateTimeFormat(EventDetails.open_date, 'HH:mm')}</div>}

                      </div>
                      <div className="right-side-view">
                        <img src={Images.ENGLAND_LOGO} alt="" />
                        <div className='team-name'>{teamNameAway}</div> </div>
                    </div> */}
                  {ScoresData !== "" && !_.isNull(ScoresData) ? (
                    <BetTitleNew {...JSON.parse(ScoresData.scores)} />
                  ) : (
                    <BetTitleNew />
                  )}
                </div>
                {/* <div className="odds-padding-row heading">
                    
                    <i className="back-btn icon-previous" onClick={() => this.reinitiateComponent()}/>
                    <div className="overflow">
                      <div className="small">{EventDetails.league_name || 'League Name'}</div>
                      <div className="title">      
                        {EventDetails.event_name}
                      </div>
                      <div className="timestamp">
                    {
                      EventDetails.is_live == 1 ? 
                      <span className="is-live-lbl">{t('In-Play')}</span>
                      :
                      <Fragment>
                        <i className="icon-clock" /> {Utils.dateTimeFormat(EventDetails.open_date, 'HH:mm, DD/MM/YYYY')}
                      </Fragment>
                    }
                  </div>
                    </div>
                </div> */}
                {/* <div className="team-view-data-con">
                    <div className="left-view"><div className='inner-view'>Nketiah 24’,90’ Saka 53’</div></div>
                    <div className="middle-view">
                      {this.props.SelectedSports.sports_id != '' ?
                        <i className={`icon-${this.props.SelectedSports.sports_id}`} />
                        :
                        null
                      }
                      
                    </div>
                    <div className="right-view"><div className='inner-view'>Rashford 17’ Martinez 59’</div></div>
                  </div> */}
              </>
            )}

            {_.map(MarketList, (item, key) => {
              let isFirstItem = key === 0; // Check if it's the first item
              let isItemOpen = isFirstItem && key === 0; // Check if it's the first item and set it to open by default
              switch (item.template_name) {
                case "options":
                  return (
                    <TemplateOptionSB
                      {...{
                        key,
                        item,
                        ...this.props,
                        SocketHighlight: this.SocketHighlight,
                        ResetWinloss: this.ResetWinloss,
                        open: isItemOpen,
                        league_name: EventDetails.league_name,
                      }}
                    />
                  );
                case "total":
                  return (
                    <TemplateTotalSB
                      {...{
                        key,
                        item,
                        ...this.props,
                        SocketHighlight: this.SocketHighlight,
                        ResetWinloss: this.ResetWinloss,
                        open: isItemOpen,
                      }}
                    />
                  );
                case "handicap":
                  return (
                    <TemplateHandicapSB
                      {...{
                        key,
                        item,
                        ...this.props,
                        SocketHighlight: this.SocketHighlight,
                        ResetWinloss: this.ResetWinloss,
                        open: isItemOpen,
                      }}
                    />
                  );
                default:
                  break;
              }
            })}

            {_.isEmpty(MarketList) && (
              <MessageBox paragraph={t(CONSTANT.MARKET_BLANK_PARAGRAPH)} />
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const SportsBookFixtureDetailListTrans = withTranslation()(
  SportsBookFixtureDetailList
);

function mapStateToProps(state) {
  const { app } = state;
  return {
    WinlossObject: app.WinlossObject,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsBookFixtureDetailListTrans);
