import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { composeWithDevTools } from 'redux-devtools-extension';

// We'll use redux-logger just as an example of adding another middleware
import logger from 'redux-logger';

// And use redux-batch as an example of adding enhancers
import { reduxBatch } from '@manaflair/redux-batch'

import rootReducer from '../Reducers/reducers';
import config from "config";



const middleware = [
  ...getDefaultMiddleware(), 
  logger
]
const middlewareWithoutLogger = [
  ...getDefaultMiddleware()
]
const preloadedState = {
}

export default configureStore({
  reducer: rootReducer,
  middleware: (config.REDUX_LOGGER ? middleware : middlewareWithoutLogger),
  preloadedState,
  devTools: composeWithDevTools,
  // devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch]
});

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were composed together