import { createSlice } from "@reduxjs/toolkit";
import { Utils } from "Helpers";
import { Auth } from "Service";

function getCurrentUser(){
    const currentUser = localStorage.getItem('currentUser');
    if (!currentUser) {
      return null;
    }
    return JSON.parse(currentUser);
}

export const ApplicationSlice = createSlice({
    name: "Application",
    initialState: {
        isLoaderShow: false,
        currentUser: getCurrentUser(),
        LangModalShow: Utils.getCookie('Isocial_lang') == "",
        openCollapse: '1',
        openBetslipDrawer: false,
        WinlossObject: {
            lastupdate: new Date().valueOf()
        },
        currentMatchId:"",
        isMatchLive:false
    },
    reducers: {
        LoaderShow: (state) => {
            state.isLoaderShow = true
        },
        LoaderHide: (state) => {
            state.isLoaderShow = false
        },
        UpdateProfile: (state) => {
            state.currentUser =  Auth.currentUserValue
        },
        LangModalOpen: (state) => {
            state.LangModalShow = true
        },
        LangModalClose: (state) => {
            state.LangModalShow = false
        },
        SlipToggle: (state, { payload }) => {
            console.log('SlipToggle', payload)
            
            switch (true) {
                case (payload == 1 && state.openCollapse == 1) || (payload == 2 && state.openCollapse == 2) || (payload == 3 && state.openCollapse == 3):
                    document.body.classList.remove('cno-scroll');
                    state.openCollapse = ''
                    break;
                default:
                    document.body.classList.add('cno-scroll');
                    state.openCollapse = payload
                    break;
            }
            
        },
        OpenBet: (state, data) => {
            const { type, bool } = data.payload
            state.openCollapse = bool ? '2' : '1'
            state.openBetslipDrawer = type;
        },
        updateWinloss: (state, { payload }) => {
            state.WinlossObject = payload
        },
        updateMatchId:(state, {payload}) => {
            state.currentMatchId = payload
        },
        updateMatchStatus:(state, {payload}) => {
            state.isMatchLive = payload
        }
    },
});

export const {
    LoaderShow,
    LoaderHide,
    UpdateProfile,
    LangModalOpen,
    LangModalClose,
    SlipToggle,
    OpenBet,
    updateWinloss,
    updateMatchId,
    updateMatchStatus
} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
