import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Modal, ModalBody } from "reactstrap";
// import DatePicker from 'react-date-picker';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { withTranslation } from "react-i18next";
// import Select from 'react-select';
import { SiteContainer, MessageBox, AgentLinkNav } from "Components";
import { CONSTANT } from "Constants";
//Images
import { Http, API, Utils, _ } from "Helpers";
import { Auth } from "Service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ScrollSportstab } from "Views";
//import { stringify } from "csv-stringify";

class ProfitLoss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: false,
      posting: false,
      Offset: CONSTANT.CURRENT_OFFSET,
      from_date: moment().subtract(1, "month").toDate(),
      to_date: moment().toDate(),
      activeId: "",
      ProfitList: [],
      // FilValue: [{ id: '2', name: 'Exchange' }],
      NavigationList: [],
      activeSportsTab: "",
      isLoaderShow: false,
      page_id: "sportbook_betting",
      filterModalShow: false,
    };
    this.CurrentUser = Auth.currentUserValue;
    this.datePickerRef = React.createRef();
    this.datePickerRefClick = React.createRef();
  }

  // LIST API

  getProfitList = () => {
    this.setState({ isLoaderShow: true });
    const {
      Offset,
      ProfitList,
      from_date,
      to_date,
      activeSportsTab,
      page_id,
    } = this.state;
    let alltab = [
      {
        category_id: "",
        sports_name: "All",
        status: "1",
      },
    ];
    let param = {
      limit: CONSTANT.ITEMS_PERPAGE_SM,
      offset: Offset,
      from_date: moment.utc(from_date).format("Y-MM-DD"),
      to_date: moment.utc(to_date).format("Y-MM-DD"),
      // "bookmaker_type": FilValue.id,
      bookmaker_type: 1,
      sports_id: activeSportsTab ? activeSportsTab : "",
      search: "",
    };

    this.setState({ posting: true });
    Http.post(API.PROFIT_LOSS, param)
      .then((response) => {
        this.setState(
          {
            posting: false,
            ProfitList:
              Offset == CONSTANT.CURRENT_OFFSET
                ? response.data
                : [...ProfitList, ...response.data],
            hasMore: !_.isEmpty(response.data),
            NavigationList: [
              ...alltab,
              ...Utils.getMasterData().sports_list[page_id],
            ],
          },
          () => {}
        );
      })
      .catch((error) => {
        this.setState({ posting: false });
      });
  };

  // Handlers

  handleFilterChange = (FilValue) => {
    console.log("first");
    this.setState(
      { FilValue: FilValue, Offset: CONSTANT.CURRENT_OFFSET },
      () => {
        // this.getProfitList()
      }
    );
  };

  ResetData = () => {
    this.setState(
      {
        from_date: moment().subtract(1, "month").toDate(),
        to_date: moment().toDate(),
        // FilValue: [{ id: '2', name: 'Exchange' }],
        Offset: CONSTANT.CURRENT_OFFSET,
        //filterModalShow: false,
      },
      () => {
        this.getProfitList();
      }
    );
  };

  SearchData = () => {
    this.setState(
      {
        Offset: CONSTANT.CURRENT_OFFSET,
        filterModalShow: false,
      },
      () => {
        this.getProfitList();
      }
    );
  };

  onDateChange = (date, key) => {
    this.setState({ [key]: date }, this.validateForm);
  };

  validateForm() {
    const { from_date } = this.state;
    this.setState({
      formValid: Utils.isInvalid("date", from_date) === false,
    });
  }

  showMore = (id) => {
    if (id === this.state.activeId) {
      this.setState({ activeId: "" });
    } else {
      this.setState({ activeId: id });
    }
  };

  fetchMoreData = () => {
    let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
    this.setState({ Offset: offset }, () => {
      this.getProfitList();
    });
  };

  colorHandler = (key, detail) => {
    if (detail.length > 0 && detail[0].settlement_result == "66779") {
      return key / 2 == 0 ? "" : key / 2 > 0 ? "green-color" : "danger-color";
    } else {
      return key == 0 ? "" : key > 0 ? "green-color" : "danger-color";
    }
  };

  // LIFE CYLCE METHODS
  componentDidMount() {
    this.getProfitList();
  }
  //download
  downloadCsv = () => {
    // this.setState({ isLoaderShow: true });

    const { Offset, from_date, to_date, activeSportsTab } = this.state;

    const param = {
      limit: CONSTANT.ITEMS_PERPAGE_SM,
      offset: Offset,
      from_date: moment.utc(from_date).format("Y-MM-DD"),
      to_date: moment.utc(to_date).format("Y-MM-DD"),
      bookmaker_type: 1,
      sports_id: activeSportsTab || "",
      search: "",
      is_export: 1,
    };

    Http.post(API.PROFIT_LOSS, param)
      .then((response) => {
        const data = response.data;

        // Define headers and values for CSV
        const headers = [
          "Market name",
          "Event name",
          "Date",
          "Stake",
          //"Gross P/L",
          "Net Profit",
        ];
        const values = data.map((item) => [
          item.detail[0].market_name,
          item.event_name,
          moment(item.game_start_date).format("DD/MM/YYYY"),
          item.total_stake,
          //  item.pl,
          item.net_pl,
        ]);

        const csvData = [headers, ...values];
        const csvString = csvData.map((row) => row.join(",")).join("\n");

        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "profit-loss.csv";
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // this.setState({ isLoaderShow: false });
      })
      .catch((error) => {
        this.setState({ posting: false, isLoaderShow: false });
      });
  };
  onTabClick = (item) => {
    this.setState(
      {
        activeSportsTab: item.sports_id,
        ProfitList: [],
        isLoaderShow: false,
        Offset: CONSTANT.CURRENT_OFFSET,
      },
      () => {
        this.getProfitList();
      }
    );
  };
  renderSportsNav = (AvaSports) => {
    return (
      <ScrollSportstab
        tabsContainerClassName="sp-tb-scroll-container"
        {...this.props}
      >
        {({ Tab }) => {
          return _.map(AvaSports, (item, idx) => {
            return (
              <Tab className="sp-item" key={idx}>
                <span onClick={() => this.onTabClick(item, idx)}>
                  {
                    item.sports_name
                    // Sports.url[item.value + (WSManager.getAppLang() || '')]
                  }
                </span>
              </Tab>
            );
            // }
          });
        }}
      </ScrollSportstab>
    );
  };

  filterModal = () => {
    this.setState({ filterModalShow: true });
  };
  filterModalHide = () => {
    this.setState({
      filterModalShow: false,
      from_date: moment().subtract(1, "month").toDate(),
      to_date: moment().toDate(),
      Offset: CONSTANT.CURRENT_OFFSET,
    });
  };

  isWithin30Days(selectedDate) {
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);
    const differenceInMilliseconds = selectedDateObj - currentDate;
    const millisecondsIn31Days = 31 * 24 * 60 * 60 * 1000;
    return Math.abs(differenceInMilliseconds) <= millisecondsIn31Days;
  }

  handleIconClick = () => {
    if (this.datePickerRef.current) {
      this.datePickerRef.current.setFocus();
    }
  };
  handleIconClickS = () => {
    if (this.datePickerRefClick.current) {
      this.datePickerRefClick.current.setFocus();
    }
  };

  render() {
    const { t } = this.props;
    const {
      from_date,
      to_date,
      ProfitList,
      hasMore,
      NavigationList,
      isLoaderShow,
      filterModalShow,
      posting,
    } = this.state;
    // const options = [
    //   { id: '1', name: 'Sportsbook' },
    //   { id: '2', name: 'Exchange' },
    //   { id: '6', name: 'Bookmaker' },
    //   { id: '4', name: 'Live Cards' },
    //   { id: '5', name: 'Live Casino' }

    // ]

    return (
      <SiteContainer {...this.props}>
        <Container fluid className="gutters-container-7px">
          <Row>
            <Col className="leftnav-col">
              <AgentLinkNav {...this.props} />
            </Col>
            <Col>
              <div className="">
                {/* <div className="bet-history-heading">
                  <div className="bet-history-view">
                    <img src={Images.BACK_IMG} alt="" />
                    <i className="icon-previous" />
                    <span> {t("Profit & Loss")}</span>
                  </div>
                  <i
                    className="icon-hamburger-ic"
                    onClick={() => {
                      this.filterModal();
                    }}
                  />
                </div> */}
                <div className="sports-tab-view">
                  <div className="sports-name">
                    {this.renderSportsNav(NavigationList)}
                  </div>
                </div>
                <div className="container">
                  <div className="history-last-six-month-view">
                    <div className="text-six-month">
                      <div>
                        {" "}
                        History{" "}
                        <span>
                          {this.isWithin30Days(from_date)
                            ? "(last 30 Days)"
                            : ""}
                        </span>
                      </div>
                      {!_.isEmpty(ProfitList) && <div
                        className="csv-text"
                        onClick={() => this.downloadCsv()}
                      >
                        <i className="icon-csv-download" />
                        CSV
                      </div>}
                      <i
                        className="icon-hamburger-ic"
                        onClick={() => {
                          this.filterModal();
                        }}
                      />
                    </div>
                  </div>
                  {!_.isEmpty(ProfitList) && isLoaderShow ? (
                    <>
                      <div className="history-last-six-month-view">
                        <div className="history-container">
                          <InfiniteScroll
                            dataLength={ProfitList.length}
                            next={this.fetchMoreData}
                            hasMore={hasMore}
                            hasChildren={false}
                            className=""
                          >
                            {_.map(ProfitList, (item, index) => {
                              return (
                                <div
                                  className="history-container-view"
                                  key={index}
                                >
                                  <div className="history-inner-header">
                                    <i
                                      className={`img-view icon-${item.sports_id}`}
                                    ></i>
                                    <div className="league-name">
                                      {item.league_name}
                                    </div>
                                    {/* {_.map(item.detail, (obj, idx) => {
                                    return (
                                      <div className="league-name" key={idx}>
                                        {obj.market_name}
                                      </div>
                                    );
                                  })} */}
                                  </div>
                                  <div className="team-name-and-date-con">
                                    <div className="team-name">
                                      {item.event_name}
                                    </div>
                                    <div className="date-view">
                                      {Utils.dateTimeFormat(
                                        item.created_date,
                                        "DD/MM/YYYY"
                                      )}
                                    </div>
                                  </div>
                                  <div className="inner-view-data">
                                    <Row>
                                      <Col xs={4}>
                                        <div className="text-view">
                                          {t("Stake")}
                                        </div>
                                        <div className="number-value-view">
                                          {item.total_stake}
                                        </div>
                                      </Col>
                                      {/* <Col xs={5} className="">
                                      <div className="text-view">
                                        {t("Gross P/L")}
                                      </div>
                                      <div
                                        className={`number-value-view ${
                                          item.pl > 0
                                            ? "text-color-success"
                                            : "text-color-danger"
                                        }`}
                                      >
                                        {item.pl ? item.pl : "-"}
                                      </div>
                                    </Col> */}
                                      <Col xs={3} className="ctext-right">
                                        <div className="text-view">
                                          {t("Net Profit")}
                                        </div>
                                        <div
                                          className={`number-value-view ${
                                            item.net_pl > 0
                                              ? "text-color-success"
                                              : "text-color-danger"
                                          }`}
                                        >
                                          {item.net_pl ? item.net_pl : "-"}
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              );
                            })}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </>
                  ) : (
                    !posting && (
                      <MessageBox paragraph={t(CONSTANT.BLANK_HISTORY)} />
                    )
                  )}
                </div>
                {/* {filterModalShow && <FilterModal SearchData={this.SearchData} filterModalHide ={this.filterModalHide} validateForm={this.validateForm} handleFilterChange={this.handleFilterChange} profitLossKey={true} ResetData={this.ResetData} getProfitList={this.getProfitList} />} */}

                {
                  // filterModalShow ?

                  <Modal isOpen={filterModalShow} className="filter-modal-view">
                    <ModalBody>
                      <div className="filter-body-container">
                        <div className="filter-body-header">
                          <div className="sort-fitler-text">
                            <div
                              className="back-icon-view"
                              onClick={() => this.filterModalHide()}
                            >
                              {" "}
                              <i
                                className="icon-previous"
                                onClick={() => this.filterModalHide()}
                              />
                            </div>
                            <span> Sort & Filter</span>
                          </div>
                          <div
                            className="clear-all-view"
                            onClick={() => this.ResetData()}
                          >
                            clear All
                          </div>
                        </div>
                        <div className="filter-body-view">
                          {/* <div className='Date-view-text'>Date</div> */}
                          <Row className="filter-row-view">
                            <Col xs={4} className="filter-first-column">
                              <div className="date-text-view">From date</div>
                            </Col>
                            <Col xs={8} className="filter-second-column">
                              <div className="date-text-view">
                                <FormGroup>
                                  {/* <Label>From Date</Label> */}

                                  <div className={"cs-datepicker"}>
                                    <DatePicker
                                      ref={this.datePickerRef}
                                      selected={from_date}
                                      onChange={(e) =>
                                        this.onDateChange(e, "from_date")
                                      }
                                      dateFormat="dd/MM/yyyy"
                                      timeCaption="time"
                                      className={""}
                                      maxDate={to_date}
                                    />
                                    <i
                                      className="icon-calendar cs-icon"
                                      onClick={this.handleIconClick}
                                    />
                                  </div>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                          <Row className="filter-row-view">
                            <Col xs={4} className="filter-first-column">
                              <div className="date-text-view">To date</div>
                            </Col>
                            <Col xs={8} className="filter-second-column">
                              <div className="date-text-view">
                                <FormGroup>
                                  {/* <Label>{t('To Date')}</Label> */}

                                  <div className={"cs-datepicker"}>
                                    <DatePicker
                                      ref={this.datePickerRefClick}
                                      selected={to_date}
                                      onChange={(e) =>
                                        this.onDateChange(e, "to_date")
                                      }
                                      dateFormat="dd/MM/yyyy"
                                      className={""}
                                      minDate={from_date}
                                      maxDate={moment().toDate()}
                                    />
                                    <i
                                      className="icon-calendar cs-icon"
                                      onClick={this.handleIconClickS}
                                    />
                                  </div>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>

                          {/* <Row className='filter-row-view' style={{ height: "250px" }}>
                        <Col sm={2}>
                              <FormGroup>
                                <Label>{t('Filter')}</Label>
                                <Select
                                  className='react-select'
                                  classNamePrefix="react-select"
                                  isSearchable={false}
                                  options={options}
                                  value={FilValue}
                                  onChange={this.handleFilterChange}
                                  getOptionLabel={({ name }) => name}
                                  getOptionValue={({ id }) => id}
                                />
                              </FormGroup>
                            </Col>
                          
                        </Row> */}
                          {/* <Row
                            className="filter-row-view"
                            style={{ height: "250px" }}
                          >
                            <Col xs={4} className="filter-first-column"></Col>
                            <Col xs={8} className="filter-second-column"></Col>
                          </Row> */}
                        </div>
                      </div>
                      <div className="filter-modal-footer">
                        <div
                          className="botton-view-cancel"
                          onClick={() => this.filterModalHide()}
                        >
                          cancel
                        </div>
                        <div
                          className="botton-view-apply"
                          onClick={() => this.SearchData()}
                        >
                          Apply
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                  // :""
                }

                {/* <div className="odds-padding-row heading">
                  <div className="title">{t('Profit & Loss')}</div>
                </div> */}

                {/* <Row className="inline-form gutters-10px">
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('Opening Date')}</Label>

                      <div className={'cs-datepicker'}>
                        <DatePicker
                          selected={from_date}
                          onChange={(e) => this.onDateChange(e, 'from_date')}
                          dateFormat="dd/MM/yyyy"
                          timeCaption="time"
                          className={'form-control w-100'}
                          maxDate={to_date}
                        />
                        <i className='icon-calendar cs-icon' />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('End Date')}</Label>
                      <div className={'cs-datepicker'}>
                        <DatePicker
                          selected={to_date}
                          onChange={(e) => this.onDateChange(e, 'to_date')}
                          dateFormat="dd/MM/yyyy"
                          className={'form-control w-100'}
                          minDate={from_date}
                          maxDate={moment().toDate()}
                        />
                        <i className='icon-calendar cs-icon' />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('Filter')}</Label>
                      <Select
                        className='react-select'
                        classNamePrefix="react-select"
                        isSearchable={false}
                        options={options}
                        value={FilValue}
                        onChange={this.handleFilterChange}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <Button color="primary" size="sm" onClick={() => this.SearchData()}>{t('Search')}</Button>
                    <Button color="primary"  size="sm" onClick={() => this.ResetData()}>{t('Reset')}</Button>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col>
                    {ProfitList.length > 0 ?
                      <InfiniteScroll
                        dataLength={ProfitList.length}
                        next={this.fetchMoreData}
                        hasMore={hasMore}
                        hasChildren={false}
                        className=''
                      >
                        <Table className="table-primary mt20 pl-responise-tbl">
                          <thead>
                            <tr>
                              <th>{t('Date')}</th>
                              <th style={{ width: '30%' }}>{t('Game Name')}</th>
                              <th>{t('Sport')}</th>
                              <th>{t('Stake')}</th>
                              <th>{t('Gross P/L')}</th>
                              {
                                (this.CurrentUser != null && this.CurrentUser.role != Role.AgentPunter) ?
                                  <React.Fragment>
                                    <th>{t('Commission')}</th>
                                    <th>{t('Net Profit')}</th>
                                  </React.Fragment> : null
                              }

                            </tr>
                          </thead>
                          <tbody>
                            {
                              _.map(ProfitList, (item, index) => {
                                return (
                                  <Fragment key={index}>


                                    <tr key={index}>
                                      <td>{Utils.dateTimeFormat(item.game_start_date, 'DD/MM/YYYY')}</td>
                                      <td>

                                        { Number(item.bookmaker_type) !== 5 &&<span>
                                          <a onClick={() => this.showMore(item.event_id)} className="expand-bets">{activeId === item.event_id ? '-' : '+'}</a>

                                        </span>}

                                        <span>
                                          {
                                            item.event_name
                                          }
                                        </span>
                                        {Number(item.bookmaker_type) === 4 && <span>
                                          {' '}
                                          ({'Round ID '}
                                          {
                                            item.event_id
                                          })
                                        </span>}



                                      </td>
                                      <td>
                                        {Number(item.bet_type) !== 2 &&
                                          item.sports_name
                                        }
                                      </td>
                                      <td>{item.total_stake}</td>
                                      <td>
                                        <span
                                          className={item.pl == 0 ? '' : item.pl > 0 ? 'green-color' : 'danger-color'}
                                        // className={this.colorHandler(item.pl, item.detail)}
                                        >
                                         
                                          <React.Fragment>{Utils.TwoDecimalVal(item.pl)}</React.Fragment>
                                        </span></td>

                                      {
                                        (this.CurrentUser != null && this.CurrentUser.role != Role.AgentPunter) ?
                                          <React.Fragment>
                                            <td><span className={item.admin_commission == 0 ? '' : 'danger-color'}>{item.admin_commission != 0 ? '-' : ''}{Utils.TwoDecimalVal(item.admin_commission)}</span></td>
                                            <td>
                                              <span
                                                className={item.net_pl == 0 ? '' : item.net_pl > 0 ? 'green-color' : 'danger-color'}
                                                // className={this.colorHandler(item.net_pl, item.detail)}
                                              >
                                                
                                                {Utils.TwoDecimalVal(item.net_pl)}
                                              </span>
                                            </td>
                                          </React.Fragment> : null
                                      }

                                    </tr>
                                    {activeId == item.event_id &&
                                      <Fragment>
                                        {
                                          _.map(item.detail, (itm, idx) => {
                                            return (
                                              <tr key={idx} className={activeId == item.event_id ? 'active-row' : 'none'}>
                                                <td></td>
                                                <td>
                                                  {
                                                    itm.market_name
                                                  }
                                                </td>
                                                <td></td>
                                                <td>{itm.stake}</td>
                                                <td>
                                                  <span
                                                    className={itm.pl == 0 ? '' : itm.pl > 0 ? 'green-color' : 'danger-color'}
                                                    // className={this.colorHandler(itm.pl, item.detail)}
                                                  >
                                                    
                                                    {Utils.TwoDecimalVal(itm.pl)}
                                                  </span>
                                                </td>
                                                {
                                                  (this.CurrentUser != null && this.CurrentUser.role != Role.AgentPunter) ?
                                                    <React.Fragment>
                                                      <td><span className={itm.admin_commission == 0 ? '' : 'danger-color'}>{itm.admin_commission != 0 ? '-' : ''}{Utils.TwoDecimalVal(itm.admin_commission)}</span></td>
                                                      <td>
                                                        <span
                                                          className={itm.net_pl == 0 ? '' : itm.net_pl > 0 ? 'green-color' : 'danger-color'}
                                                          // className={this.colorHandler(itm.net_pl, item.detail)}
                                                        >
                                                         
                                                          {Utils.TwoDecimalVal(itm.net_pl)}
                                                        </span></td>
                                                    </React.Fragment> : null
                                                }


                                              </tr>
                                            )
                                          }
                                          )}
                                      </Fragment>


                                    }
                                  </Fragment>

                                )
                              })
                            }

                          </tbody>

                        </Table>

                      </InfiniteScroll>
                      :
                      <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} />

                    }

                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
        </Container>
      </SiteContainer>
    );
  }
}

export default withTranslation()(ProfitLoss);
