import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Button, Form, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { Http, API, Utils } from 'Helpers';
import { SiteContainer, AgentLinkNav } from 'Components';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      password: '',
      OldPassword: '',
      ConfrimPassword: '',
    }
  }


  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    const { OldPassword, password, ConfrimPassword } = this.state
    this.setState({
      formValid:
        password !== '' &&
        OldPassword !== '' &&
        ConfrimPassword !== '' &&
        Utils.isInvalid("Password", password) === false &&
        this.comparePassword() === false
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { OldPassword, password } = this.state
    this.setState({ posting: true });
    let param = {
      "old_password": OldPassword,
      "password": password,
    }
    Http.post(API.CHANGE_PASSWORD, param).then(response => {
      this.setState({ posting: false, password: '', OldPassword: '', ConfrimPassword: '' });
      Utils.notify(response.message)
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  comparePassword() {
    const { ConfrimPassword, password } = this.state;
    return ConfrimPassword !== '' && ConfrimPassword !== password
  }

  render() {
    const { t } = this.props;
    const { password, OldPassword, ConfrimPassword, formValid, posting } = this.state
    return (
      <SiteContainer {...this.props}>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <AgentLinkNav {...this.props} />

            </Col>
            <Col>
              <div className="odds-padding-row heading">
                <div className="title">{t('Change Password')}</div>
              </div>
              <div className="change-paswd-wrap">
                <Form onSubmit={this.onSubmit}>
                  <Row>
                    <Col sm={6}>
                      <FormGroup className='input-border-bottom ' >
                        <span className='label'>{t('Old Password')}</span>
                        <Input
                          id='OldPassword'
                          name='OldPassword'
                          required
                          type='password'
                          maxLength={50}
                          value={OldPassword}
                          autoComplete="off"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <FormGroup className='input-border-bottom ' >
                        <span className='label'>{t('New Password')}</span>
                        <Input
                          id='password'
                          name='password'
                          required
                          type='password'
                          maxLength={16}
                          value={password}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("Password", password)}
                          valid={Utils.isValid("Password", password)} />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup className='input-border-bottom ' >
                        <span className='label'>{t('Confirm Password')}</span>
                        <Input
                          id='ConfrimPassword'
                          name='ConfrimPassword'
                          required
                          type='password'
                          maxLength={16}
                          value={ConfrimPassword}
                          autoComplete="off"
                          onChange={this.handleChange}
                          // invalid={Utils.isInvalid("Password", ConfrimPassword)}
                          // valid={Utils.isValid("Password", ConfrimPassword)} 
                          invalid={Utils.isInvalid("Password", ConfrimPassword) || (this.comparePassword() && ConfrimPassword !== '') ? 'true' : undefined}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round'>{t('Change Password')}</Button>
                    </Col>
                  </Row>

                </Form>
              </div>

            </Col>
          </Row>
        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(ChangePassword);
