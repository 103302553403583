import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Alert, Collapse } from 'reactstrap';

import { Auth } from 'Service';
import { _, Utils, Http, API, AuthHelp, Socket } from 'Helpers';
import { MessageBox } from 'Components';
import { CONSTANT, Images, SocketEvent } from 'Constants';
//Images
import { EditStakeModal } from 'Modals';

import { connect } from "react-redux";
import { loginOpen, LoaderShow, LoaderHide, UpdateProfile, SlipToggle, updateWinloss } from '../../Redux/Reducers'
import ReactSwipeButton from "react-swipe-button";
import { withTranslation } from 'react-i18next';
// import SlideButton from 'react-slide-button';
// import ReactSwipeButton from 'react-swipe-button';

class Betslip extends Component {
  constructor(props) {
    super(props);
    const { page_id } = props
    this.state = {
      showSwipeButton:false,
      BetslipType: page_id,
      isSingleBet: true,
      AppMasterData: Utils.getMasterData(),
      SelectedOdds: {},
      MultipleBetStake: Utils.getMultiBetSlip(CONSTANT.BETSLIP[page_id]),
      BetPosting: false,
      global_error_msz: '',
      EditStakeModalShow: false,
      slideButton: false,
      slideButtonActive: false,
      showOkbtn: false,
      closeAll: false,
      user_balance:null,
      Balposting:false
      
    }
    // this.winlossHandler = this.winlossHandler.bind(this);
    // this.winlossHandler = _.debounce(this.winlossHandler.bind(this), 0);
  }


  sockeListener = () => {
    const _this = this
    Socket.on(SocketEvent.MARKET, function (res) {
      _this.SocketMarkethandler(res)
    })
  }
  removeSockeListener = () => {
    Socket.off(SocketEvent.MARKET)
  }

  SocketMarkethandler = (res = {}) => {
    const { SelectedOdds } = this.state
    // res = {
    //     base_line: "2:0",
    //     bet_id: 5611661295904321,
    //     book_maker_id: 8,
    //     fixture_id: 5904321,
    //     last_update: "2020-09-07 13:05:28",
    //     lay_price: null,
    //     lay_price_volume: null,
    //     line: "0:2",
    //     market_id: 13,
    //     name: "1",
    //     participant_id: null,
    //     price: "100.0",
    //     price_volume: null,
    //     provider_bet_id: null,
    //     settlement: null,
    //     start_price: "9.0",
    //     status: 1
    // }


    if (!_.isEmpty(SelectedOdds)) {
      let newSelectedOdds = {}
      _.map(SelectedOdds, (item, idx) => {
        let new_odd_list = item.odd_list;
        let new_picked_odd = item.picked_odd
        if (!item.is_unmatch_bet && item.fixture_id == res.fixture_id) {
          if (new_picked_odd.bet_id == res.bet_id) {
            new_picked_odd = { ...new_picked_odd, ...res }
          }
          newSelectedOdds = {
            ...newSelectedOdds, [idx]: {
              ...item,
              odd_list: new_odd_list,
              picked_odd: new_picked_odd
            }
          }
        } else {
          newSelectedOdds = { ...newSelectedOdds, [idx]: item }
        }
      })
      if (!_.isEmpty(newSelectedOdds)) {
        this.setState({
          SelectedOdds: newSelectedOdds
        }, () => {
          Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
        })
      }
    }
  }

  CallremoveMArketFromAdmin = (res)=>{
    const _this = this;
    const { page_id } = _this.props
    Socket.on(SocketEvent.MARKETUPDATEDCS, function (res) {
      if (!_.isEmpty(res)) {
        _this.handleRemoveSlip(0)
        _this.clearAllBet(page_id)
      }
    })
  }

  // Life cycle
  componentDidMount() {
    this.CallremoveMArketFromAdmin()
    this.getBalanace()
    //this.props.removeMArketFromAdmin()
    const { SelectedOdds } = this.props
    this.setState({
      SelectedOdds: SelectedOdds,
      BetslipOddsStack: Auth.currentUserValue ? Auth.currentUserValue.odds_stack : [],
      BetslipFancyStack: Auth.currentUserValue ? Auth.currentUserValue.fancy_stack : [],
      BetslipCasinoStack: Auth.currentUserValue ? Auth.currentUserValue.casino_stack : []
    }, () => {

      const { SelectedOdds } = this.state
      if (!_.isEmpty(SelectedOdds)) {
        // console.log('betslip remount')
        setTimeout(() => {
          //this.winlossHandler(0)
        }, 500)


      }
    })
    // this.sockeListener()
    // setTimeout(() => {
    //   this.SocketMarkethandler()
    // }, 3000)

  }

  getBalanace = () => {
    let param = {};
    this.setState({ Balposting: true });
    Http.post(API.GET_USER_BALANCE, param)
      .then((response) => {
        this.setState({ user_balance: response.data.user_balance, Balposting:false });
      })
      .catch((error) => {
        this.setState({ Balposting: false });
      });
  };

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps, "nextPropsnextPropsnextProps")
    if (nextProps.betslip_lastupdate !== this.props.betslip_lastupdate) {
      const { page_id } = this.props
      this.setState({
        SelectedOdds: nextProps.fancy_betslip == CONSTANT.FANCY_BETTING ? nextProps.SelectedOddsFancy : nextProps.SelectedOdds,
        BetslipType: nextProps.fancy_betslip == CONSTANT.FANCY_BETTING ? nextProps.fancy_betslip : page_id,
        // isSingleBet : true
      });
    }
  }

  // Handlers
  OpenStackModal = (event) => {
    event.stopPropagation();
    this.modalShow('EditStakeModalShow')
  }

  modalShow = (name) => {
    this.setState({
      [name]: true
    })
  }

  modalHide = (name) => {
    // console.log(JSON.parse(localStorage.getItem('currentUser')), "JSON.parse(localStorage.getItem('currentUser'))")
    this.setState({
      EditStakeModalShow: false,
      BetslipOddsStack: JSON.parse(localStorage.getItem('currentUser')).odds_stack,
      BetslipFancyStack: JSON.parse(localStorage.getItem('currentUser')).fancy_stack,
      BetslipCasinoStack: JSON.parse(localStorage.getItem('currentUser')).casino_stack
    })
  }

  switchBetting = (BetslipType) => {
    this.setState({
      BetslipType,
      isSingleBet: true
    }, () => {
      this.setState({
        SelectedOdds: Utils.getBetSlip(BetslipType)
      })
    })
  }
  // Single Bet handler
  stackChangeHandle = idx => evt => {

    const re = /^\d*\.?(?:\d{1,2})?$/;

    let { SelectedOdds } = this.state
    let value = evt.target.value;
    if (SelectedOdds[idx]) {
      if (!re.test(value)) return SelectedOdds[idx].stack_value
      SelectedOdds[idx].stack_value = (value === '' || re.test(value)) ? value : SelectedOdds[idx].stack_value
      SelectedOdds[idx].invalid_bet = 0
      SelectedOdds[idx].return_stake_msz = ''
      SelectedOdds[idx].return_msz = ''
      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    }
    else {
      if (!re.test(value)) return SelectedOdds[idx].stack_value.stack_value
      SelectedOdds[idx].stack_value = (value === '' || re.test(value)) ? value : SelectedOdds[idx].stack_value.stack_value
      SelectedOdds[idx].invalid_bet = 0
      SelectedOdds[idx].return_stake_msz = ''
      SelectedOdds[idx].return_msz = ''
      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    }
  }


  winlossHandler(idx) {

    const { updateWinloss } = this.props
    let { SelectedOdds, BetslipType } = this.state
    let selected_odds = SelectedOdds[idx]
    console.log(JSON.stringify(SelectedOdds), "selected_odds", idx);

    let stack_value = selected_odds.stack_value
    // if(stack_value === '') return
    if (SelectedOdds[0]) {
      let bookmaker_type = CONSTANT.BOOKMAKER_TYPE[BetslipType];
      let betting_type = selected_odds.picked_odd.betting_type;   // 1 = back , 2= Lay
      let price = selected_odds.picked_odd.betting_type == 1 ? selected_odds.picked_odd.b1 : selected_odds.picked_odd.l1;
      let stake = stack_value;
      var payout = stake * price;

      if (bookmaker_type == 2) { // In case Fancy bet
        //pp =  Potential Profit
        var pp = payout - stake; // in case of back betting;
        var liability = stake; // in case of back 
        if (betting_type == 2 && bookmaker_type != 3)   // In case OF lay 
        {
          pp = stake;
          liability = payout - stake;
        }
        updateWinloss({
          lastupdate: new Date().valueOf(),
          type: 1,
          market_event_id: selected_odds.bookmakerList.mid,
          session_id: selected_odds.picked_odd.session_id,
          selectionId: selected_odds.picked_odd.selection_id,
          stack: stack_value,
          betting_type: selected_odds.picked_odd.betting_type,
          pp: pp,
          liability: liability,
          bookmaker_type: bookmaker_type,
          is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
          is_place_bet: false
        })
      } else {
        pp = payout - stake; // in case of back betting;
        liability = stake; // in case of back 
        updateWinloss({
          lastupdate: new Date().valueOf(),
          type: 1,
          market_event_id: selected_odds.market_event_id,
          session_id: selected_odds.picked_odd.session_id,
          selection_id: selected_odds.picked_odd.selectionId,
          stack: stack_value,
          betting_type: selected_odds.picked_odd.betting_type,
          pp: pp,
          liability: liability,
          bookmaker_type: bookmaker_type,
          is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
          is_place_bet: false
        })
      }
    }
    else {
      let bookmaker_type = CONSTANT.BOOKMAKER_TYPE[BetslipType];
      let betting_type = selected_odds.picked_odd.betting_type;   // 1 = back , 2= Lay
      let price = selected_odds.picked_odd.price;
      let stake = stack_value;
      payout = stake * price;

      if (bookmaker_type == 2) { // In case Fancy bet
        //pp =  Potential Profit
        pp = payout - stake; // in case of back betting;
        liability = stake; // in case of back 
        if (betting_type == 2 && bookmaker_type != 3)   // In case OF lay 
        {
          pp = stake;
          liability = payout - stake;
        }
        updateWinloss({
          lastupdate: new Date().valueOf(),
          type: 1,
          market_event_id: selected_odds.market_event_id,
          session_id: selected_odds.picked_odd.session_id,
          selectionId: selected_odds.picked_odd.selectionId,
          stack: stack_value,
          betting_type: selected_odds.picked_odd.betting_type,
          pp: pp,
          liability: liability,
          bookmaker_type: bookmaker_type,
          is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
          is_place_bet: false
        })
      } else {
        pp = payout - stake; // in case of back betting;
        liability = stake; // in case of back 
        updateWinloss({
          lastupdate: new Date().valueOf(),
          type: 1,
          market_event_id: selected_odds.market_event_id,
          session_id: selected_odds.picked_odd.session_id,
          selection_id: selected_odds.picked_odd.selectionId,
          stack: stack_value,
          betting_type: selected_odds.picked_odd.betting_type,
          pp: pp,
          liability: liability,
          bookmaker_type: bookmaker_type,
          is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
          is_place_bet: false
        })
      }
    }
  }

  handleKey = (evt, idx) => {
    let { SelectedOdds } = this.state
    const input = evt.target;
    const BaseValue = 10;
    const value = input.value == '' ? 0 : input.value;

    let item = SelectedOdds[idx]
    if (evt.keyCode === 38) {
      let newValue = item.stack_value == '' || item.stack_value < BaseValue ? BaseValue : (value == 0 ? BaseValue : Number(value) + 1)
      SelectedOdds[idx].stack_value = newValue

      SelectedOdds[idx].invalid_bet = 0
      SelectedOdds[idx].return_stake_msz = ''
      SelectedOdds[idx].return_msz = ''

      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    } else if (evt.keyCode === 40) {
      let newValue = item.stack_value <= BaseValue ? item.stack_value : Number(value) - 1
      SelectedOdds[idx].stack_value = newValue;

      SelectedOdds[idx].invalid_bet = 0
      SelectedOdds[idx].return_stake_msz = ''
      SelectedOdds[idx].return_msz = ''

      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    }
  }

  marketChangeHandle = idx => evt => {
    // console.log(Utils.getBetSlip(this.state.BetslipType), 'bet')
    // const re = /^[0-9\.\b]+$/;

    const re = /^\d*\.?(?:\d{1,2})?$/;

    let { SelectedOdds } = this.state;
    if (SelectedOdds[0].bookmakerHandle) {
      let value = evt.target.value;
      let item = SelectedOdds[idx]
      // item.picked_odd.betting_type == 1 ?  item.picked_odd['b1'] : item.picked_odd['l1']

      // = (value === '' || re.test(value)) ? value : (item.picked_odd.betting_type == 1 ?  item.picked_odd.b1 : item.picked_odd.l1)

      console.log(value, "valuevaluevaluevaluevalue")

      item.invalid_bet = 0
      item.return_stake_msz = ''
      item.return_msz = ''

      let newSelectedOdds = {
        ...item,
        is_unmatch_bet: Number(item.picked_odd.betting_type == 1 ? item.picked_odd.b1 : item.picked_odd.l1) == Number(value) ? false : true
      };
      SelectedOdds[idx] = newSelectedOdds
      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    }
    else {
      let value = evt.target.value;
      let item = SelectedOdds[idx]
      item.picked_odd['price'] = (value === '' || re.test(value)) ? value : (item.picked_odd.price)

      // console.log(value, re.test(value), value === '')

      item.invalid_bet = 0
      item.return_stake_msz = ''
      item.return_msz = ''

      let newSelectedOdds = {
        ...item,
        is_unmatch_bet: Number(item.picked_odd_price) == Number(value) ? false : true
      };
      SelectedOdds[idx] = newSelectedOdds
      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    }
  }

  StackClickHandle = (idx, value) => {
    let { SelectedOdds } = this.state;
    let item = SelectedOdds[idx]

    item.stack_value = _.isUndefined(item.stack_value) ? Number(value) : Number(item.stack_value) + Number(value)

    item.invalid_bet = 0
    item.return_stake_msz = ''
    item.return_msz = ''

    SelectedOdds[idx] = item;
    this.setState({ SelectedOdds }, () => {
      //this.winlossHandler(0)
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
    });
  }

  // Multiple Bet handler
  multipleBetStackHandle = evt => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state
    let value = evt.target.value;
    const re = /^[0-9\.\b]+$/;
    MultipleBetStake = (value === '' || re.test(value)) ? value : MultipleBetStake
    this.setState({ MultipleBetStake }, () => {
      Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
    });
  }

  multipleBetHandleKey = (evt) => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state
    const input = evt.target;
    const BaseValue = 10;
    const value = input.value == '' ? 0 : input.value;
    if (evt.keyCode === 38) {
      let newValue = (MultipleBetStake == '' || MultipleBetStake < BaseValue) ? BaseValue : (value == 0 ? BaseValue : Number(value) + 1)
      this.setState({ MultipleBetStake: newValue }, () => {
        Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
      });
    } else if (evt.keyCode === 40) {
      let newValue = MultipleBetStake <= BaseValue ? MultipleBetStake : Number(value) - 1
      this.setState({ MultipleBetStake: newValue }, () => {
        Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
      });
    }
  }

  multipleBetStackClickHandle = (value) => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state;
    let newValue = _.isUndefined(MultipleBetStake) ? Number(value) : Number(MultipleBetStake) + Number(value)
    this.setState({ MultipleBetStake: newValue }, () => {
      Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
    });
  }

  // Other
  ModeUpdate = () => {
    this.setState({ global_error_msz: '' })
    setTimeout(() => {
      const { SelectedOdds } = this.props
      if (_.size(SelectedOdds) == 1) {
        this.props.SlipToggle(1)
        this.setState({ isSingleBet: true });
      }
    }, 100)
  }

  totalValue = (type) => {
    const { SelectedOdds } = this.state
    let obj = {
      stake: [],
      odd: []
    }
    if (type === 'stake') {
      _.forEach(SelectedOdds, item => {
        obj.stake.push(_.isUndefined(item.stack_value) ? 0 : Number(item.stack_value))
      })
    } else if (type === 'odd') {
      _.forEach(SelectedOdds, item => {
        obj.odd.push(Number(item.picked_odd.price))
      })
    }
    return _.round(_.sum(obj[type]), 2)
  }


  isDuplicateBetSlip = () => {
    let { SelectedOdds } = this.state;
    let _arr = []
    _.forEach(SelectedOdds, item => {
      _arr.push(item.fixture_id)
    })
    return !(_arr.length === new Set(_arr).size);
  }

  isLayMultiBet = () => {
    // let { SelectedOdds } = this.state;
    // let _lay = []
    // _.forEach(SelectedOdds, item => {
    //   if (item.picked_odd.betting_type == 2) {
    //     _lay.push(item.picked_odd.betting_type)
    //   }
    // })
    // return _lay.length > 0;
  }

  isStackValueEmptyOrUndefined(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const entry = data[key];
        if (entry.stack_value === "" || entry.stack_value == 0 || typeof entry.stack_value === "undefined") {
          return false; // Return false if stack_value is empty or undefined
        }
      }
    }
    return true; // Return true if all stack_values are non-empty and defined
  }

  isValidate = () => {
    const { SelectedOdds, showSwipeButton } = this.state;
    if(!showSwipeButton){
      this.setState({
        showSwipeButton:true
      })
    }
    
    //isSingleBet
    //MultipleBetStake
    //console.log((SelectedOdds),"11111SelectedOddsSelectedOdds");
   // let arr = []
    // if (SelectedOdds[0]) {
    //   //console.log("ja riya hai");
    //   _.forEach(SelectedOdds, item => {
    //     if (isSingleBet) {
    //       if ((!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0) && Number(item.picked_odd.betting_type == 1 ? item.picked_odd.b1 : item.picked_odd.l1) > 0) {
    //         arr.push(item)
    //       }
    //     } else {
    //       if (Number(item.picked_odd.price) > 0) {
    //         arr.push(item)
    //       }
    //     }
    //   })
    // }
    //else {
      const result = this.isStackValueEmptyOrUndefined(SelectedOdds);
      return !result

      // _.forEach(SelectedOdds, item => {

      // if (isSingleBet) {
      //   console.log('Shayma',item);
      //   if ((!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0) && Number(item.picked_odd.price) > 0) {
      //     arr.push(item)
      //   }
      // } else {
      //   console.log('kishori jiiiiiii');
      //   if (Number(item.picked_odd.price) > 0) {
      //     arr.push(item)
      //   }
      // }
      //})
  //  }
  //  return
    //isSingleBet ? (arr.length == 0) : (arr.length == 0 || (MultipleBetStake == '' || MultipleBetStake == 0))
  }

  PlaceBetHandlerPrev = () => {
    const { LoaderShow, LoaderHide, UpdateProfile } = this.props
    let { SelectedOdds, isSingleBet, MultipleBetStake, BetslipType } = this.state;
    let bet_data = []
    let not_stack = {}
    _.forEach(SelectedOdds, (item, key) => {
      if (
        ((isSingleBet && Number(item.picked_odd.price) > 0 && (!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0))
          ||
          (!isSingleBet && Number(item.picked_odd.price) > 0))
      ) {
        if (item.invalid_bet != 1) {
          bet_data = [...bet_data, {
            "fixture_id": item.event_id,
            "market_bet_id": item.market_bet_id,
            "betting_type": item.picked_odd.betting_type,
            "is_custom_odds": item.is_unmatch_bet ? 1 : 0,
            ...(BetslipType == CONSTANT.FANCY_BETTING ? { "line": item.picked_odd.price } : { "line": item.picked_odd.line }),
            ...(BetslipType == CONSTANT.FANCY_BETTING ? { "price": "2" } : { "price": item.picked_odd.price }),
            ...(isSingleBet && { "stake": item.stack_value }),
            "provider_id": item.provider_id
          }]
        } else {
          not_stack = { ...not_stack, [key]: { ...item } }
        }
      } else {
        not_stack = { ...not_stack, [key]: { ...item, return_stake_msz: CONSTANT.BLANK_STAKE, return_msz: '' } }
      }
    })
    let param = {
      bet_data,
      "bookmaker_type": '1',
      //CONSTANT.BOOKMAKER_TYPE[BetslipType], // 1 = Sportsdata, 2 = Exchange, 3 = Fancy
      "bet_type": isSingleBet ? "1" : "2",          // 1 = Single,  2= Multiple
      ...(!isSingleBet && { "stake": MultipleBetStake })
    }
    this.setState({ BetPosting: true, global_error_msz: '' }, () => {
      LoaderShow()
    })
    this.getMaxDelay().then(res => {
      Http.post(API.PLACE_BET, param, true).then(response => {
        Utils.notify(response.message)
        this.setState({ BetPosting: false }, () => {
          Auth.updateBalance(response.data.balance, response.data.exposure)
          UpdateProfile()
          LoaderHide()
          this.clearAllBet(BetslipType)
          setTimeout(() => {
            if (!_.isEmpty(not_stack)) {
              this.setState({ SelectedOdds: not_stack }, () => {
                Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
              })
            }
          }, 100)

        })
      }).catch(err => {

        if (err.response_code === 500) {
          let newSelectedOdds = {}
          _.forEach(SelectedOdds, (item, key) => {
            _.forEach(err.error.odds_changed, obj => {
              if (item.market_bet_id == obj.market_bet_id) {
                item.picked_odd.price = obj.price
                // item.return_msz = obj.return_msz
                item.return_stake_msz = ''
                item.return_msz = CONSTANT.BETSLIP_RETURN_MSZ
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
              }
            })
            _.forEach(err.error.invalid_bet, obj => {
              if (item.market_bet_id == obj.market_bet_id) {
                item.picked_odd.price = obj.price
                item.invalid_bet = 1
                item.return_msz = obj.return_msz
                item.return_stake_msz = ''
                // item.return_msz = CONSTANT.BETSLIP_INVALID_BET_MSZ
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
              }
            })
            if (
              !(((isSingleBet && Number(item.picked_odd.price) > 0 && (!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0))
                ||
                (!isSingleBet && Number(item.picked_odd.price) > 0)))
            ) {
              item.return_stake_msz = CONSTANT.BLANK_STAKE
            }

            newSelectedOdds = { ...newSelectedOdds, [key]: item }
          })
          if (_.isEmpty(newSelectedOdds)) {
            Utils.notify(err.message, 'error')
          } else {
            // Utils.notify(CONSTANT.PARTIALLY_SUBMIT_BETSLIP, '')
            this.setState({ SelectedOdds: newSelectedOdds }, () => {
              Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
            })
          }
        } else {
          AuthHelp.checkAuth(err)
        }
        this.setState({
          BetPosting: false,
          global_error_msz: err.global_error
        }, () => {
          LoaderHide()
        })
      });
    })
  }
  parseErrorMessage = (response) => {
    var errorMsg = response.message != "" ? response.message : response.global_error;
    if (errorMsg == "") {
      for (var key in response.error) {
        if (key == 'odds_changed' || key == 'invalid_bet') {
          break;
        }
        errorMsg = response.error[key];
        if (errorMsg != "") {
          break;
        }
      }
    }
    return errorMsg
  }
  PlaceBetHandler = () => {
    this.setState({
      BetPosting:true
    })
    const { LoaderShow, LoaderHide } = this.props
    //UpdateProfile, updateWinloss
    let { SelectedOdds, BetslipType } = this.state;
    //console.log(SelectedOdds, "SelectedOdds");



    let param = {}
    let tempArr = []

    // if (SelectedOdds[0]) {
    //   //alert('radheee')
    //   let new_selectedodds = _.map(SelectedOdds, item => {
    //     param = {
    //       "event_id": item.bookmakerList.event_id,
    //       "betting_type": item.picked_odd.betting_type,
    //       "is_custom_odds": item.is_unmatch_bet ? 1 : 0,
    //       "stake": item.stack_value,
    //       "bookmaker_type": 1,     // 1 = Sportsdata, 2 = Exchange, 3 = Fancy
    //       "market_event_id": item.picked_odd.id,
    //       "selection_id": item.picked_odd.selection_id,
    //       "price": item.picked_odd.betting_type == 1 ? item.picked_odd.b1 : item.picked_odd.l1,
    //       "provider_id": '2'
    //     }
    //     return { ...item, return_msz: '' }
    //   })
    //   this.setState({
    //     SelectedOdds: new_selectedodds,
    //     BetPosting: true,
    //     slideButton: true,
    //     global_error_msz: ''
    //   }, () => {
    //     Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
    //     LoaderShow()
    //   })
    // }
   // else {
      //alert('hao')

      let new_selectedodds = _.map(SelectedOdds, item => {
        //console.log(item,"OOOOOOOO");
        param = {
          "event_id": item.event_id,
          "betting_type": item.picked_odd.betting_type,
          "is_custom_odds": item.is_unmatch_bet ? 1 : 0,
          "stake": item.stack_value,
          "bookmaker_type": '1',
          //CONSTANT.BOOKMAKER_TYPE[BetslipType],     // 1 = Sportsdata, 2 = Exchange, 3 = Fancy
          "market_event_id":
            BetslipType == CONSTANT.FANCY_BETTING ? item.picked_odd.session_id :
              BetslipType == CONSTANT.CASINO_BETTING ? item.market_uid :
                item.market_event_id,
          // ...(BetslipType == CONSTANT.FANCY_BETTING ? { "market_event_id": item.picked_odd.session_id } : { "market_event_id": item.market_event_id }),
          //...(BetslipType == CONSTANT.CASINO_BETTING ? { "market_event_id": item.market_uid } : { "market_event_id": "item.market_event_id" }),
          ...(BetslipType == CONSTANT.CASINO_BETTING ? { "event_id": item.event_uid } : { "event_id": item.event_id }),
          ...(BetslipType != CONSTANT.FANCY_BETTING && { "selection_id": item.picked_odd.selectionId }),
          ...(BetslipType == CONSTANT.FANCY_BETTING ? { "price": "2" } : { "price": item.picked_odd.price }),
          ...(BetslipType == CONSTANT.FANCY_BETTING ? { "line": item.picked_odd.price } : { "line": "" }),
          "provider_id": item.provider_id
        }
        tempArr.push(param)
        return { ...item, return_msz: '' }
      })
      this.setState({
        SelectedOdds: new_selectedodds,
        BetPosting: true,
        global_error_msz: ''
      }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
        LoaderShow()
      })
      //console.log(tempArr,"param");
  //  }



    // return true
    this.getMaxDelay().then(res => {
      Http.post(API.PLACE_BET, tempArr, true).then(response => {
       // Utils.notify(response.message)
        this.setState({
          BetPosting: false
        }, () => {
          //Auth.updateBalance(response.data.balance, response.data.exposure)
          // updateWinloss({
          //   lastupdate: new Date().valueOf(),
          //   type: 0,
          //   win_loss: response.data.win_loss,
          //   fexposure: response.data.fexposure,
          //   market_event_id: param.market_event_id,
          //   session_id: param.market_event_id,
          //   bookmaker_type: CONSTANT.BOOKMAKER_TYPE[BetslipType],
          //   is_unmatch_bet: param.is_custom_odds == 1 ? true : false,
          //   event_id: param.event_id,
          //   is_place_bet: param.is_custom_odds == 1 ? true : false,
          // })
          // UpdateProfile()
          LoaderHide()
          //for msg show
          const invalid_bet = response.error;
          const validbet = response.data
          //const { odds_changed = response.data, invalid_bet = response.error  } = err.error
          if(!_.isEmpty(invalid_bet)){
           // alert('in')
            let newSelectedOdds = {}
          _.forEach(SelectedOdds, (item, key) => {
            _.map(invalid_bet,(bt,bx)=>{
            if (item.market_bet_id == invalid_bet[bx].selection_id) {
              item.return_msz = invalid_bet[bx].message
             item.picked_odd.price = bt.changed_odds ? bt.changed_odds : item.picked_odd.price
              newSelectedOdds = { ...newSelectedOdds, [key]: item }
            }
            newSelectedOdds = { ...newSelectedOdds, [key]: item }
            })
              this.setState({ SelectedOdds: newSelectedOdds }, () => {
              Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
            })

            // sucees bet
            
            //sucess bet end

            
            // if (odds_changed != '' && item.market_event_id == odds_changed.market_event_id) {
            //   item.picked_odd.price = odds_changed.price
            //   item.return_msz = odds_changed.return_msz
            //   newSelectedOdds = { ...newSelectedOdds, [key]: item }
            // }

            
          })
           if(!_.isEmpty(validbet)){
            // bet success 
            let newSelectedOddsucc = {}
            _.forEach(SelectedOdds, (item, key) => {
              _.map(validbet,(bt,bx)=>{
              if (item.market_bet_id == validbet[bx].selection_id) {
                item.return_msz = 'successfully'
                newSelectedOddsucc = { ...newSelectedOddsucc, [key]: item }
              }
              newSelectedOddsucc = { ...newSelectedOddsucc, [key]: item }
              })
                this.setState({ SelectedOdds: newSelectedOddsucc }, () => {
                  //console.log(this.state.SelectedOdds,"this.state.SelectedOdds");
                Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
              })
  
              
            })
            // bet sucess end
          }

          this.setState({
            showOkbtn:!this.state.showOkbtn,
            closeAll:true
          })
        } 
        
        else {
          this.handleRemoveSlip(0)
          this.clearAllBet(BetslipType)
          LoaderHide()
            Utils.notify(response.message)
            this.clearAllBet(BetslipType)
            this.props.SlipToggle(1)
          }
          // if (_.isEmpty(newSelectedOdds)) {
          //   Utils.notify(err.message, 'error')
          // } else {
          //   this.setState({ SelectedOdds: newSelectedOdds }, () => {
          //     Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
          //   })
          // }


         // this.clearAllBet(BetslipType)
        })

      }).catch(err => {
        this.setState({
          showOkbtn:!this.state.showOkbtn,
         // global_error_msz: err.message
        })
        
        if (!_.isEmpty(err.error)) {
          const { odds_changed = {}, invalid_bet = {} } = err.error
          if (err.response_code === 500) {
            let newSelectedOdds = {}
            _.forEach(SelectedOdds, (item, key) => {

              if (odds_changed != '' && item.market_event_id == odds_changed.market_event_id) {
                item.picked_odd.price = odds_changed.price
                item.return_msz = odds_changed.return_msz
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
              }

              if (invalid_bet != '' && item.market_event_id == invalid_bet.market_event_id) {
                item.return_msz = invalid_bet.return_msz
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
              }
              newSelectedOdds = { ...newSelectedOdds, [key]: item }
            })
            if (_.isEmpty(newSelectedOdds)) {
              Utils.notify(err.message, 'error')
            } else {
              this.setState({ SelectedOdds: newSelectedOdds }, () => {
                Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
                alert('iiii')
              })
            }
          } else {
            AuthHelp.checkAuth(err)
          }
          this.setState({
            BetPosting: false,
            global_error_msz: err.error.message
          }, () => {
            LoaderHide()
          })
        }

        if (err.response_code === 500) {

          //alert(err.message)
          this.setState({
            BetPosting: false,
           global_error_msz: err.message
          },()=>{
            LoaderHide()
          })
        }
        
       // alert(err.error.message)
       

      });
    })
  }

  clearAllBet = (BetslipType) => {
    const { clearAllBet } = this.props
    Utils.setBetSlip(BetslipType, {})
    clearAllBet(BetslipType)
    this.setState({
      MultipleBetStake: '',
      global_error_msz: '',
    }, () => {
      this.setState({
        SelectedOdds: Utils.getBetSlip(BetslipType),
        BetslipType: BetslipType
      })
    })
  }
  handleRemoveSlip = (key) => {
    const { handleRemoveSlip, _fancyHandleRemoveSlip, updateWinloss } = this.props
    const { BetslipType, SelectedOdds } = this.state
    if (SelectedOdds[0]) {
    //  alert('in')
      this.setState({
        global_error_msz: ''
      })
      if (BetslipType != CONSTANT.FANCY_BETTING) {

        let selected_odds = SelectedOdds[key]
        updateWinloss({
          lastupdate: new Date().valueOf(),
          type: 2,
          market_event_id: selected_odds.market_event_id,
          session_id: selected_odds.picked_odd.session_id,
          bookmaker_type: CONSTANT.BOOKMAKER_TYPE[BetslipType],
          is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
          is_place_bet: false
        })

        handleRemoveSlip(key)
        this.ModeUpdate()
        
      } else {
        _fancyHandleRemoveSlip(key)
      }
    }
    else {
    //  alert('out')
      this.setState({
        global_error_msz: ''
      })
      if (BetslipType != CONSTANT.FANCY_BETTING) {
        let selected_odds = SelectedOdds[key]
        updateWinloss({
          lastupdate: new Date().valueOf(),
          type: 2,
          market_event_id: selected_odds.market_event_id,
          session_id: selected_odds.picked_odd.session_id,
          bookmaker_type: CONSTANT.BOOKMAKER_TYPE[BetslipType],
          is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
          is_place_bet: false
        })

        handleRemoveSlip(key)
        this.ModeUpdate()
      } else {
        _fancyHandleRemoveSlip(key)
      }
    }
  }

  // 
  getMaxDelay = () => {
    const { SelectedOdds, BetslipType } = this.state;
    const currentUser = Auth.currentUserValue
    let timeArr = []
    _.forEach(SelectedOdds, slip => {
      _.forEach(currentUser.sport_settings, item => {
        if (item.sports_id == slip.sports_id) {
          if (BetslipType != CONSTANT.FANCY_BETTING) {
            timeArr.push(Number(item.odds_delay))
          } else {
            timeArr.push(Number(item.fancy_delay))
          }
        }
      })
    })

    return new Promise((resolve, reject) => {
      // console.log(_.max(timeArr) * 1000, 'getMaxDelay')
      setTimeout(() => {
        console.log('Bet place execute!')
        resolve(true)
      }, _.max(timeArr) * 1000)
    })
  }
  onSlideDone = () => {
    console.log("first", this.state.slideButton)
    this.setState({ slideButton: true })
  }
  onSuccess = () => {
    console.log("first")
  }

  render() {

    // console.log(this.props)
    const {
      //loginOpen,
      openCollapse,
      SlipToggle,
      // i18n,
      t
    } = this.props



    // console.log(i18n.language, 'i18n.language')

    const {
      isSingleBet,
      SelectedOdds,
      BetslipOddsStack,
      BetslipFancyStack,
      BetslipCasinoStack,
      BetslipType,
      MultipleBetStake,
      BetPosting,
      global_error_msz,
      EditStakeModalShow,
      //slideButton,
      //slideButtonActive,
      showSwipeButton,
      showOkbtn,
      closeAll,
      user_balance,
      Balposting
    } = this.state

    
    

    const EditStakeModalProps = {
      isOpen: EditStakeModalShow,
      toggle: this.modalHide,
      BetslipOddsStack: BetslipOddsStack
    }

    const currentUser = Auth.currentUserValue

    const AvalStacks = ({ idx }) => {
      return (
        <div className="betslip--footer">
          {

            _.map(BetslipType == CONSTANT.FANCY_BETTING ? BetslipFancyStack :
              BetslipType == CONSTANT.CASINO_BETTING ? BetslipCasinoStack :
                BetslipOddsStack,
              (item, key) => <a onClick={() => this.StackClickHandle(idx, item)} {...{ key }}>{item}</a>)
          }
        </div>
      )
    }

    const BetTitle = ({ home, away }) => {
      return (
        (home && away) ?
          <Fragment>
            <span title={home.name}>{`${home.name}`}</span>
            <span title={away.name}>{` vs ${away.name}`}</span>
          </Fragment> : null
      )
    }


    return (
      <>
      {!_.isEmpty(SelectedOdds) &&  
      <div {...{
        className: `betslip-wrap betslip-wrap-new ${openCollapse == 1 ? !CONSTANT.IS_MULTIBET_ALLOW ? 'ful-h' : '' : ''} ${_.isEmpty(SelectedOdds) ? ' blank' : ''}${CONSTANT.ONLY_SINGLE_BET ? ' only-single-bet' : ''}`
      }}
        style={{ backgroundColor: openCollapse == 1 ? "rgba(0,0,0,0.9)" : "", height: openCollapse == 1 ? "100vh" : "" }}
      >
        {/* {console.log("MULTIBET_ALLOW????", CONSTANT.IS_MULTIBET_ALLOW)} */}
        {/* <div className={`betslip-container ${openCollapse == 1 ? "`betslip-container betslip-container-new" : ""}`}> */}
        {openCollapse == 1 ? "" :
          <div className='open-view-bet-slip'>
            <div className='bet-slip-icon' onClick={() => SlipToggle(1)}><i className='icon-letter-ic' /><span>1</span></div>
          </div>
        }
        <div className="betslip-container betslip-container-new" >
          {
            currentUser ?
              // <div className="betslip-header" onClick={() => SlipToggle(1)}>
              //   {
              //     t('Bet Slip')
              //   }
              //   {
              //     // <a className="stack-change-head" onClick={(e) => this.OpenStackModal(e)}>{t('Edit stake')}</a>
              //   }
              //   {
              //     openCollapse == 1 ?
              //       <i className="icon-up" />
              //       :
              //       <i className="icon-down" />
              //   }
              // </div>
              // <div className={openCollapse == 1 ? "betslip-header-view " : " betslip-header"} onClick={() => SlipToggle(1)}>
              //   <div className='bet-text-view'>{
              //     t('Bet Slip')
              //   }</div>
              //   <div>{
              //     openCollapse == 1 ?
              //       <i className="icon-close-circle" />
              //       :
              //       <i className="icon-down" />
              //   }</div>
              // </div>
              <>
                {
                  (openCollapse == 1) &&
                  <div className="betslip-header-view " onClick={() => closeAll ? [this.handleRemoveSlip(0),SlipToggle(1)] :SlipToggle(1) }>
                    <img src={Images.BACK_IMG} alt="" />
                    <i className='icon-previous' />
                    <div className='bet-text-view'>{
                      t('Bet Slip')
                    }</div>
                    <i className="icon-close-circle" />
                  </div>
                  // :
                  // <div className='bet-slip-icon' onClick={() => SlipToggle(1)}><i className='icon-letter-ic'/><span>1</span></div>
                }
              </>
              :
              <div className="betslip-header text-center cursor-default">
                {
                  t('Bet Slip')
                }
              </div>
          }

          <Collapse isOpen={openCollapse == 1}>
            {/* <div className="betslip-tabs">
              <span onClick={() => this.switchBetting(CONSTANT.EXCHANGE_BETTING)} {...{ className: BetslipType == CONSTANT.EXCHANGE_BETTING ? 'active' : '' }}>{t('Exchange')}</span>
              <span onClick={() => this.switchBetting(CONSTANT.SPORTBOOK_BETTING)} {...{ className: BetslipType == CONSTANT.SPORTBOOK_BETTING ? 'active' : '' }}>{t('Sportsbook')}</span>
              <span onClick={() => this.switchBetting(CONSTANT.FANCY_BETTING)} {...{ className: BetslipType == CONSTANT.FANCY_BETTING ? 'active' : '' }}>{t('Fancy')}</span>
              <span onClick={() => this.switchBetting(CONSTANT.CASINO_BETTING)} {...{ className: BetslipType == CONSTANT.CASINO_BETTING ? 'active' : '' }}>{t('Live Cards')}</span>
            </div> */}

            {
              // (CONSTANT.IS_MULTIBET_ALLOW && !_.isEmpty(SelectedOdds)) &&
              // <div className="betslip-filter-pills">
              //   <span {...{ className: isSingleBet ? 'active' : '' }} onClick={() => this.setState({ isSingleBet: true })}>{t('Single Bet')}</span>
              //   {
              //     BetslipType != CONSTANT.FANCY_BETTING &&
              //     <Fragment>
              //       {
              //         _.size(SelectedOdds) > 1 &&
              //         <span {...{ className: `${!isSingleBet ? 'active' : ''} ${this.isDuplicateBetSlip() || this.isLayMultiBet() ? 'warning' : ''}` }} onClick={() => this.setState({ isSingleBet: false })}> {t('Multiple Bet')}</span>
              //       }
              //     </Fragment>
              //   }
              // </div>
            }


            {
              global_error_msz != '' &&
              <Alert color="danger" className='betslip-common-error'>{t(global_error_msz)}</Alert>
            }

            {
              _.isEmpty(SelectedOdds) ?
                <MessageBox heading={t(CONSTANT.BETSLIP_BLANK_HEADING)} paragraph={t(CONSTANT.BETSLIP_BLANK_PARAGRAPH)} />
                // :
                // SelectedOdds[0].bookmakerHandle ?

                //   <div {...{ className: `betslip-list ${CONSTANT.IS_MULTIBET_ALLOW ? '' : ''} ${(!_.isEmpty(SelectedOdds) && !isSingleBet) ? 'mulitple-bet' : ''}` }}>

                //     {

                //       <div className="betslip-item">

                //         <div {...{ className: `betslip--header` }}>
                //           <i className="icon-close-circle" onClick={() => this.handleRemoveSlip(0)} />
                //           <div className="text-truncate">
                //             {

                //               <Fragment>
                //                 {SelectedOdds[0].picked_odd.name}
                //               </Fragment>


                //             }

                //           </div>
                //         </div>
                //         <div className="betslip--body">
                //           <Row className="gutters-10px">
                //             <Col xs={12} className="small">
                //               {
                //                 <Fragment>
                //                   {SelectedOdds[0].picked_odd[0].name + ' - ' + SelectedOdds[0].picked_odd[1].name}
                //                 </Fragment>

                //               }

                //             </Col>

                //           </Row>

                //           {

                //             <Row className="gutters-10px">
                //               <Col xs={12}>
                //                 <input
                //                   className='stake-input'
                //                   type="text"
                //                   placeholder='Odd'
                //                   value={SelectedOdds[0].picked_odd.betting_type == 1 ? SelectedOdds[0].picked_odd.b1 : SelectedOdds[0].picked_odd.l1}
                //                   disabled={BetslipType == CONSTANT.FANCY_BETTING || BetslipType == CONSTANT.CASINO_BETTING || (currentUser != null && currentUser.role == Role.AgentPunter)}
                //                   // disabled={(currentUser && currentUser.lock_um_betting != 0) || BetslipType == CONSTANT.FANCY_BETTING}
                //                   onChange={this.marketChangeHandle(0)}
                //                   onKeyUp={() => this.winlossHandler(0)}
                //                 />

                //                 <input
                //                   type="text"
                //                   placeholder='Stake'
                //                   className='stake-input'
                //                   value={SelectedOdds[0].stack_value}
                //                   onKeyDown={(e) => this.handleKey(e, 0)}
                //                   onChange={this.stackChangeHandle(0)}
                //                   onKeyUp={() => this.winlossHandler(0)}
                //                 />

                //                 {

                //                   <div className='return-value-col'>
                //                     <div className="return--small">Return</div>
                //                     <div className="return-val">
                //                       {

                //                         <Fragment>{_.round(Number(SelectedOdds[0].picked_odd.betting_type == 1 ? SelectedOdds[0].picked_odd.b1 : SelectedOdds[0].picked_odd.l1) * Number(SelectedOdds[0].stack_value), 2) || '--'}</Fragment>

                //                       }
                //                     </div>
                //                   </div>

                //                 }
                //               </Col>
                //             </Row>

                //           }
                //         </div>
                //         {
                //           isSingleBet &&
                //           <AvalStacks {...{ idx: 0 }} />
                //         }
                //       </div>

                //     }

                //   </div>


                :
                <div {...{ className: `betslip-list ${CONSTANT.IS_MULTIBET_ALLOW ? '' : ''} ${(!_.isEmpty(SelectedOdds) && !isSingleBet) ? 'mulitple-bet' : ''}` }}>
                  {!Balposting && <div className='current-balance'>Current Balance : <span>{user_balance}</span></div>}
                  {
                    _.map(SelectedOdds, (item, key) => {
                      return (
                        <div  {...{ className: `betslip-item ${item.picked_odd.betting_type == 2 ? 'lay-slip' : ''}`, key }}>

                          <div {...{ className: `betslip--header ${item.picked_odd.betting_type == 2 ? 'lay-slip' : ''}` }}>
                            <i className="icon-delete-trash" onClick={() => this.handleRemoveSlip(key)} />
                            <div className="text-truncate">
                            <i className={`s-sports  icon-${SelectedOdds[0].sports_id}`}></i>
                            <span>{SelectedOdds[0].league_name}</span>
                              {/* <img src={Images.ENGLAND_LOGO} alt="" className='img-logo-view'/> */}
                              {
                                // BetslipType != CONSTANT.FANCY_BETTING ?
                                //   <span className='text-bet-slip-view'>
                                //     {
                                //       BetslipType == CONSTANT.SPORTBOOK_BETTING || BetslipType == CONSTANT.CASINO_BETTING ?
                                //         item.picked_odd.name + (item.sports_id == '8' ? ' - ' + item.picked_odd.team_name : ' ')
                                //         :
                                //         Utils.getMarketName(item.picked_odd.selectionId, item.runners)
                                //     }
                                //   </span>
                                //   :
                                //   item.picked_odd.name

                              }

                            </div>
                          </div>
                          <div className="betslip--body">

                            <Row className="gutters-10px">
                            <Col xs={12} className="title small">
                            <div className='bet-team-date-con'>
                              <div className='ellipsis'> <BetTitle {...JSON.parse(SelectedOdds[0].runners)} /> </div>
                              <div className='date-view'>{Utils.dateTimeFormat(item.open_date, ' DD/MM/YYYY')}</div>
                            </div>
                                

                            </Col>
                            <Col xs={12} className="title">
                              {
                                SelectedOdds[0].market_name
                              }
                            </Col>
                              {/* <Col xs={12} className="title">{BetslipType == CONSTANT.CASINO_BETTING ? item.market_name : item.league_name}</Col> */}
                              <Col xs={12} className="small ">
                                {
                                  BetslipType == CONSTANT.SPORTBOOK_BETTING ?
                                    <Fragment>
                                      
                                      {/* <div className='bet-team-date-con'>
                                        <div>
                                          <BetTitle {...JSON.parse(item.runners)} />
                                        </div>
                                        <div className='date-view'>{Utils.dateTimeFormat(item.open_date, ' DD/MM/YYYY')}</div>
                                      </div> */}
                                    </Fragment>
                                    :
                                    SelectedOdds[0] ? '' :
                                      <Fragment>
                                        {
                                          _.map(_.orderBy(JSON.parse(item.runners), [function (o) { return Number(o.sortPriority) }], ['asc']), (participant, key) => {
                                            // console.log(participant)
                                            return (
                                              participant.sortPriority != 3 && BetslipType == CONSTANT.CASINO_BETTING ?
                                                <span {...{ key }} title={participant.name}>{`${key == 0 ? '' : ' - '} ${participant.name}`}</span>
                                                :
                                                participant.hasOwnProperty('runnerName') ?
                                                  <span {...{ key }} title={participant.runnerName}>{`${key == 0 ? '' : ' - '} ${participant.runnerName}`}</span>
                                                  :
                                                  <span {...{ key }} title={participant.name || '-'}>{`${key == 0 ? '' : ' - '} ${participant.name || '-'}`}</span>
                                            )
                                          })
                                        }
                                      </Fragment>
                                }


                              </Col>
                              {/* <Col xs={12} className="small">Winner (incl. super over)</Col> */}
                            </Row>
                            {
                              (item.return_msz && item.return_msz !== 'successfully') &&
                              <Row className="gutters-10px">
                                <Col xs={12} className="small danger-color">
                                  {item.return_msz}
                                </Col>
                              </Row>
                            }
                            {
                              item.return_msz == 'successfully' &&
                              <Row className="gutters-10px">
                                <Col xs={12} className="small green-color">
                                  Bet(s) has been placed successfully
                                </Col>
                              </Row>
                            }
                            
                            {
                              //(
                                //isSingleBet && 
                                (item.return_stake_msz) &&
                              <Row className="gutters-10px">
                                <Col xs={12} className="small danger-color">
                                  {item.return_stake_msz}
                                </Col>
                              </Row>
                            }


                            <div className='bet-slip-point'> <span>{item.picked_odd.name}: </span>  {item.picked_odd.price}</div>
                            <div className="stake-and-return-text">
                              <div className='stack-view'>
                                <div>Stake</div>
                                <input
                                  type="text"
                                  placeholder='Enter-Stake'
                                  className='stake-input'
                                  //disabled={BetPosting || showOkbtn}
                                  value={item.stack_value}
                                  onKeyDown={(e) => this.handleKey(e, key)}
                                  onChange={this.stackChangeHandle(key)}
                                  onKeyUp={() => this.isValidate()}
                                />
                              </div>
                              <div>

                                {
                                  item.picked_odd.betting_type == 1 ?
                                    <div className=''>
                                      <div className="">Return</div>
                                      <div className="return-val">
                                        {
                                          BetslipType !== CONSTANT.FANCY_BETTING ?
                                            <Fragment>{_.round(Number(item.picked_odd.price) * Number(item.stack_value), 2) || '--'}</Fragment>
                                            :
                                            <Fragment>{_.round((Number(item.stack_value) * 2), 2) || '--'}</Fragment>
                                        }
                                      </div>
                                    </div>
                                    :
                                    <div className='return-value-col'>
                                      <div className="">{t('Liability')}</div>
                                      <div className="return-val">
                                        {
                                          BetslipType != CONSTANT.FANCY_BETTING ?
                                            <Fragment>
                                              {
                                                _.round((Number(item.picked_odd.price) * Number(item.stack_value)) - Number(item.stack_value), 2)
                                                || '--'
                                              }
                                            </Fragment>
                                            :
                                            <Fragment>
                                              {
                                                _.round(Number(item.stack_value), 2)
                                                || '--'
                                              }
                                            </Fragment>
                                        }
                                      </div>
                                    </div>
                                }
                              </div>


                            </div>
                            {/* {
                                isSingleBet ?
                                  <Row className="gutters-10px">
                                    <Col xs={12}>
                                      <input
                                        className='stake-input'
                                        type="text"
                                        placeholder='Odd'
                                        value={item.picked_odd.price}
                                        disabled={BetslipType == CONSTANT.FANCY_BETTING || BetslipType == CONSTANT.CASINO_BETTING || (currentUser != null && currentUser.role == Role.AgentPunter)}
                                        // disabled={(currentUser && currentUser.lock_um_betting != 0) || BetslipType == CONSTANT.FANCY_BETTING}
                                        onChange={this.marketChangeHandle(key)}
                                        onKeyUp={() => this.winlossHandler(key)}
                                      />

                                      <input
                                        type="text"
                                        placeholder='Stake'
                                        className='stake-input'
                                        value={item.stack_value}
                                        onKeyDown={(e) => this.handleKey(e, key)}
                                        onChange={this.stackChangeHandle(key)}
                                        onKeyUp={() => this.winlossHandler(key)}
                                      />

                                      {
                                        item.picked_odd.betting_type == 1 ?
                                          <div className='return-value-col'>
                                            <div className="return--small">Return</div>
                                            <div className="return-val">
                                              {
                                                BetslipType !== CONSTANT.FANCY_BETTING ?
                                                  <Fragment>{_.round(Number(item.picked_odd.price) * Number(item.stack_value), 2) || '--'}</Fragment>
                                                  :
                                                  <Fragment>{_.round((Number(item.stack_value) * 2), 2) || '--'}</Fragment>
                                              }
                                            </div>
                                          </div>
                                          :
                                          <div className='return-value-col'>
                                            <div className="return--small">{t('Liability')}</div>
                                            <div className="return-val">
                                              {
                                                BetslipType != CONSTANT.FANCY_BETTING ?
                                                  <Fragment>
                                                    {
                                                      _.round((Number(item.picked_odd.price) * Number(item.stack_value)) - Number(item.stack_value), 2)
                                                      || '--'
                                                    }
                                                  </Fragment>
                                                  :
                                                  <Fragment>
                                                    {
                                                      _.round(Number(item.stack_value), 2)
                                                      || '--'
                                                    }
                                                  </Fragment>
                                              }
                                            </div>
                                          </div>
                                      }
                                    </Col>
                                  </Row>
                                  :
                                  <Row className="gutters-10px">
                                    <Col xs={12}>
                                      <input
                                        className='stake-input'
                                        type="text"
                                        placeholder='Odd'
                                        value={item.picked_odd.price}
                                        // disabled={currentUser && currentUser.lock_um_betting != 0}
                                        onChange={this.marketChangeHandle(key)} />

                                      {
                                        item.picked_odd.betting_type == 1 ?
                                          <div className='return-value-col'>
                                            <div className="return--small">{t('Return')}</div>
                                            <div className="return-val">{
                                              _.round(Number(item.picked_odd.price) * Number(MultipleBetStake), 2)
                                              || '--'}</div>
                                          </div>
                                          :
                                          <div className='return-value-col'>
                                            <div className="return--small">{t('Liability')}</div>
                                            <div className="return-val">{
                                              _.round((Number(item.picked_odd.price) * Number(MultipleBetStake)) - Number(MultipleBetStake), 2)
                                              || '--'}</div>
                                          </div>
                                      }
                                    </Col>
                                  </Row>
                              } */}
                          </div>
                          {
                            isSingleBet &&
                            <AvalStacks {...{ idx: key }} />
                          }
                        </div>
                      )
                    })
                  }
                  {
                    !CONSTANT.IS_MULTIBET_ALLOW &&
                    (!_.isEmpty(SelectedOdds) && !isSingleBet) &&
                    <div className="betslip-list-footer">
                      <Row className="no-gutters">
                        <Col xs={12}>
                          <input
                            type="text"
                            placeholder='Stake'
                            className='stake-input'
                            value={MultipleBetStake}
                            onKeyDown={this.multipleBetHandleKey}
                            onChange={this.multipleBetStackHandle}
                          />
                          <div className='return-value-col'>
                            <div className="return--small">{t('Return')}</div>
                            <div className="return-val">{_.round(this.totalValue('odd') * Number(MultipleBetStake), 2)}</div>
                          </div>
                        </Col>
                      </Row>
                      <div className="betslip--footer">
                        {
                          _.map(BetslipOddsStack, (item, key) => <a onClick={() => this.multipleBetStackClickHandle(item)} {...{ key }}>{item}</a>)
                        }
                      </div>
                    </div>
                  }
                </div>
            }
            {/* FOOTER: START */}
            {/* {
              console.log(currentUser)
            } */}
            {/* {
              currentUser && currentUser.lock_betting != 0 &&
              <Alert color="warning">{t(CONSTANT.LOCK_BETTING)}</Alert>
            } */}
            {
              (this.isDuplicateBetSlip() && !isSingleBet) &&
              <Alert color="danger">{t(CONSTANT.DUPLICATE_BETITEM)}</Alert>
            }
            {
              (this.isLayMultiBet() && !isSingleBet) &&
              <Alert color="danger">{t(CONSTANT.LAY_BETITEM)}</Alert>
            }
            {
            // !showOkbtn &&
            //   <a onClick={() => SlipToggle(1)} className='add-more-slip'>Add More</a>
            }
            {
              !_.isEmpty(SelectedOdds) &&
              <div className="betslip-footer">
                {
                  !CONSTANT.ONLY_SINGLE_BET &&
                  <Fragment>
                    {/* <i className="icon-trash" onClick={() => this.clearAllBet(BetslipType)} /> */}
                    {
                      BetslipType != CONSTANT.FANCY_BETTING &&
                      // <Row>
                      //   <Col>{t('Total Odds')} : <b>{this.totalValue('odd')}</b></Col>
                      // </Row>
                      <div className="betslip-footer-container">
                        <div className='total-text'>{t('Total Odds')} :</div>
                        <div className='value-text'>{this.totalValue('odd')}</div>
                      </div>
                    }
                    <div className="betslip-footer-container">
                      {isSingleBet ?
                        <>
                          <div className='total-text'>{t('Total Stake')} :</div>
                          <div className='value-text'>{this.totalValue('stake') == 0 ? '0.00' : this.totalValue('stake')}</div>
                        </>
                        :
                        <>
                          <div className='total-text'>{t('Total Stake')} :</div>
                          <div className='value-text'>{MultipleBetStake == 0 ? '0.00' : MultipleBetStake}</div>
                        </>
                      }

                    </div>
                    {/* <Row>
                      {
                        isSingleBet ?
                          <Col>{t('Total Stake')} : <b>{this.totalValue('stake') == 0 ? '0.00' : this.totalValue('stake')}</b></Col>
                          :
                          <Col>{t('Total Stake')} : <b>{MultipleBetStake == 0 ? '0.00' : MultipleBetStake}</b></Col>
                      }
                    </Row> */}
                  </Fragment>
                }


                {/* {
                  currentUser ?
                    <div className={BetPosting
                      || this.isValidate()
                      || (this.isDuplicateBetSlip() && !isSingleBet)
                      || (this.isLayMultiBet() && !isSingleBet) ? 'vector-img-view-disable' : 'vector-img-view'}>
                      <ReactSwipeButton
                        text='SWIPE TO PLACE BET'
                        color='#f00'
                        onSuccess={() => this.PlaceBetHandler()}
                      />
                    </div>
                    :
                    <Button
                      color="primary"
                      className={`round`}
                      block
                      onClick={() => loginOpen()}>{t('Login')}</Button>

                } */}



                <Row>
                  <Col>
                    {
                      currentUser ?

                        // BetslipType !== CONSTANT.CASINO_BETTING? // for poc
                        <>
                        { showOkbtn ?
                        <div className={BetPosting
                          || this.isValidate()
                          || (this.isDuplicateBetSlip())
                          || (this.isLayMultiBet()) ? 'vector-img-view-disable' : 'vector-img-view'}>
                          <ReactSwipeButton
                            text='SWIPE TO PLACE BET'
                            color='#f00'
                            onSuccess={() => this.PlaceBetHandler()}
                          />
                        </div>
                        //  <Button
                        //  color="primary"
                        //  className={`round`}
                        //  block
                        //  onClick={() => [this.clearAllBet(BetslipType),SlipToggle(1), this.handleRemoveSlip(0)]}>{t('Ok')}</Button>
                          : 
                          <>
                          {showSwipeButton ? <div className={BetPosting
                            || this.isValidate()
                            || (this.isDuplicateBetSlip())
                            || (this.isLayMultiBet()) ? 'vector-img-view-disable' : 'vector-img-view'}>
                            <ReactSwipeButton
                              text='SWIPE TO PLACE BET'
                              color='#f00'
                              onSuccess={() => this.PlaceBetHandler()}
                            />
                          </div>: 
                          <div>
                            <Button className="btn-disable-bet" disabled={true} color="Primary">SWIPE TO PLACE BET</Button>
                          </div>
                          }
                          </>
                          // <Button
                          // color="primary"
                          // className={`round`}
                          // block
                          // disabled={
                          //   // (currentUser && currentUser.lock_betting != 0) || 
                          //   BetPosting
                          //   || this.isValidate()
                          //   || (this.isDuplicateBetSlip() && !isSingleBet)
                          //   || (this.isLayMultiBet() && !isSingleBet)
                          // }
                          // onClick={() => this.PlaceBetHandler()}>{t('Place Bet')}</Button>
                        }
                        </>
                        
                        //: null
                        :
                        ''
                        // <Button
                        //   color="primary"
                        //   className={`round`}
                        //   block
                        //   onClick={() => loginOpen()}>{t('Login')}</Button>
                    }
                  </Col>
                </Row>
              </div>
            }
            {/* FOOTER: END */}
          </Collapse>



        </div>
        {/* only for casino result */}
        {/* {
          BetslipType == CONSTANT.CASINO_BETTING && openCollapse == 1 && this.props.page_id == 'casino_betting'?
            <div className={'game-result-box betslip-container'}>
              <div className={'heading'}>Game Result</div>
              <ul className={'winning_pl_list'}>
                <li>A</li>
                <li className={'color2'}>B</li>
                <li className={'color2'}>B</li>
                <li>A</li>
                <li className={'color2'}>B</li>
                <li>A</li>
                <li>A</li>
                <li className={'color2'}>B</li>
              </ul>
            </div>
            : ''} */}
        {EditStakeModalShow && <EditStakeModal {...EditStakeModalProps} />}
      </div>
  }
      </>
    )
  }
}

Betslip.defaultProps = {
  fancy_betslip: '',
  _fancyHandleRemoveSlip: () => { }
}
const BetslipTrans = withTranslation()(Betslip)

function mapStateToProps(state) {
  const { login, app } = state;
  return {
    isOpen: login.isOpen,
    openCollapse: app.openCollapse
  };
}

const mapDispatchToProps = dispatch => ({
  loginOpen: () => dispatch(loginOpen()),
  LoaderShow: () => dispatch(LoaderShow()),
  LoaderHide: () => dispatch(LoaderHide()),
  UpdateProfile: () => dispatch(UpdateProfile()),
  updateWinloss: (option) => dispatch(updateWinloss(option)),
  SlipToggle: (type) => dispatch(SlipToggle(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetslipTrans);