import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { AuthHelp } from "Helpers";

class CasinoGameListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <section className='casino_games_lisec'>


                    <h1 className={'mt-3 mb-5'}>Casino Games List </h1>

                    <Row>
                        <Col xs={12} md={4}>
                            <div className={'cas_game_card'}>
                                <h3>Teen Patti</h3>

                                <a href={`https://d2.fawk.app/#/splash-screen/{${AuthHelp.getAuth()}}/9295?opentable=${56767}`}><Button size={'sm'}>Play</Button></a>
                                {/* <Link to={'/casino_poc/teenpatti'}><Button size={'sm'}>Play</Button></Link> */}
                            </div>

                        </Col>
                    </Row>

                </section>
            </React.Fragment>
        )
    }
}

export default CasinoGameListing;