import React from 'react';
import { withTranslation } from 'react-i18next';
import { _, Role, Http, API, } from 'Helpers';
import { SiteContainer } from 'Components';
import { Row, Col, Container } from 'reactstrap';
import { Auth } from 'Service';
import { connect } from "react-redux";
import { loginOpen } from "../../Redux/Reducers";
import { LiveCasinoInfoModal } from 'Modals';
class LiveCasinoGames extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gamesList: [],
            posting: false,
            LiveCasinoInfoModalShow: false,
            SelectedItem:''
        }
    }



    componentDidMount() {
        let param = this.props.match.params;
        if (!param.id) {
            window.location.href = '/live-casino'
        }

        this.getCasinoGames()
    }

    GoToDetail = item => {

        const CurrentUser = Auth.currentUserValue;
        if (CurrentUser != null) {
            let url = `/live-casino/detail/${item.game_id}`;
            window.open(url, '_blank')

        } else {
            this.props.loginOpen()
        }
    }


    getCasinoGames = () => {
        let param = this.props.match.params;

        let obj = {
            league_id: param.id
        }

        this.setState({ posting: true })
        Http.post(API.GAME_LIST, obj)
            .then(response => {

                if (response.response_code === 200) {
                    this.setState({
                        gamesList: response.data,
                        posting: false
                    })
                }
            })
    }

    modalHide = (item) => {
        this.setState({
            SelectedItem:item,
            LiveCasinoInfoModalShow: !this.state.LiveCasinoInfoModalShow,
        })
      }

    render() {

        const { gamesList, LiveCasinoInfoModalShow, SelectedItem  } = this.state
        const CurrentUser = Auth.currentUserValue;

        const LiveCasinoInfoModalProps = {
            isOpen: LiveCasinoInfoModalShow,
            toggle: this.modalHide,
            GoToDetail:this.GoToDetail,
            SelectedItem:SelectedItem
          }

        return (
            <React.Fragment>


                <SiteContainer {...this.props} header>

                    <div className="live-casino-list-wrapper">
                        <Container fluid className='gutters-container-7px'>
                            <Row className={'live-casino-game-list'}>

                                {
                                    _.map(gamesList, (item, index) => {


                                        let league_status = item.is_active;
                                        let agent_league_status = item.is_agent_active;


                                        if (CurrentUser != null) {
                                            if (Number(CurrentUser.role) === Number(Role.Punter) && league_status == 0) {
                                                return;
                                            }
                                            if (Number(CurrentUser.role) === Number(Role.AgentPunter) && agent_league_status == 0) {
                                                return;
                                            }

                                        } else {
                                            if (league_status == 0) {
                                                return;
                                            }
                                        }

                                        return (
                                            <React.Fragment key={index}>
                                                <Col sm={4} className={'live-casino-col'} >
                                                    <div
                                                        className="live-casino-game"
                                                        onClick={()=>this.modalHide(item)}
                                                        xonClick={() => this.GoToDetail(item)}
                                                    // style={{backgroundImage:`url(${item.url_background ? item.url_background : `https://cdn.coingaming.io/casino-evolution/bitcoin-evolution-roulette-live-bg.jpg`})`}}
                                                    >
                                                        <img src={`${item.url_thumb ? item.url_thumb : "https://cdn.coingaming.io/casino-evolution/bitcoin-evolution-roulette-live-bg.jpg"}`} alt="" className="max-wid" />
                                                        <h4>{item.name}</h4>
                                                        
                                                    </div>
                                                </Col>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </Row>
                            {LiveCasinoInfoModalShow && <LiveCasinoInfoModal {...LiveCasinoInfoModalProps} />}
                        </Container>
                    </div>
                </SiteContainer>
            </React.Fragment>
        )
    }
}




const LiveCasinoTrans = withTranslation()(LiveCasinoGames);

const mapDispatchToProps = (dispatch) => ({
    loginOpen: () => dispatch(loginOpen())
});

export default connect(null, mapDispatchToProps)(LiveCasinoTrans);
