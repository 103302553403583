import React from 'react';
import { withTranslation } from 'react-i18next';
import { Utils, _, Role } from 'Helpers';
import { SiteContainer } from 'Components';
import { Row, Col, Container } from 'reactstrap';
import { Auth } from 'Service';
import config from 'config';


class LiveCasino extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gamesList: Utils.getMasterData().sports_list.casino_betting,
        }
        console.log(Utils.getMasterData().sports_list.casino_betting,"Utils.getMasterData().sports_list.casino_betting")

    }


    GoToDetail = item =>{
        this.props.history.push(`live-casino/games/${item.league_id}`)
    }


    render() {

        const { gamesList } = this.state
        const CurrentUser = Auth.currentUserValue;

        return (
            <React.Fragment>


                <SiteContainer {...this.props} header>

                    <div className="live-casino-list-wrapper">
                        <Container fluid className='gutters-container-7px'>
                            <Row>

                                {
                                    _.map(gamesList, (item, index) => {


                                        let league_status = item.league_status;
                                        let agent_league_status = item.agent_league_status;


                                        if (CurrentUser != null) {
                                            if (Number(CurrentUser.role) === Number(Role.Punter) && league_status == 0) {
                                                return;
                                            }
                                            if (Number(CurrentUser.role) === Number(Role.AgentPunter) && agent_league_status == 0) {
                                                return;
                                            }

                                        } else {
                                            if (league_status == 0) {
                                                return;
                                            }
                                        }

                                        return (
                                            <React.Fragment key={index}>
                                                <Col sm={4}  >
                                                    <div
                                                        className="casino-game-list"
                                                        onClick={() => this.GoToDetail(item)}
                                                    >
                                                        <img src={`${config.s3.UPLOAD}casino/${item.avatar || "tp1day.png"}`} alt="" className="max-wid" />
                                                        {/* <h4>{item.league_name}</h4> */}
                                                    </div>
                                                </Col>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </Row>
                        </Container>
                    </div>
                </SiteContainer>
            </React.Fragment>
        )
    }
}


const LiveCasinoTrans = withTranslation()(LiveCasino);


export default LiveCasinoTrans;