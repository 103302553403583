import React, { Fragment } from 'react';
import { NavLink } from "react-router-dom";
// import Select from 'react-select';
import { withTranslation } from 'react-i18next';


import { SubNavigationScrollbars } from 'Components';
import { Auth } from 'Service';
import {  AuthHelp, Utils, Role} from 'Helpers'
// import Select from 'react-select';

class SiteContainer extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      LangValue: { id: 'en', name: 'Eng' },
      LangOptions: [
        { id: 'en', name: 'English' },
        { id: 'hi', name: 'हिन्दी' },
        { id: 'ben', name: 'বাংলা' }
      ]
    }
  }

  setSavedLang = () => {
    let getLang = Utils.getCookie('Isocial_lang');
    if (!getLang) return;

    let getObj = this.state.LangOptions.find(itm => itm.id == getLang);
    if (!getObj) return;

    this.setState({ LangValue: getObj })

  }

  componentDidMount() {

    // this.setSavedLang();

    // if (
    //   localStorage.getItem("OddSelectiontype") == "" ||
    //   _.isNull(localStorage.getItem("OddSelectiontype"))
    // ) {
    //   this.setState({
    //     FilValue: [{ id: "decimal", name: "Decimal" }],
    //   });
    //   localStorage.setItem("OddSelectiontype", "decimal");
    // } else {
    //   this.setState({
    //     FilValue: [
    //       {
    //         id: localStorage.getItem("OddSelectiontype"),
    //         name: this.props.t(localStorage.getItem("OddSelectiontype")),
    //       },
    //     ],
    //   });
    // }
  }

  handleLanguageChange = (val) => {
    const { i18n } = this.props;
    i18n.changeLanguage(val.id);
    Utils.setCookie('Isocial_lang', val.id, 365)
    this.setState({ LangValue: val })
    if (AuthHelp.getAuth()) {
      localStorage.setItem('lang_posting', JSON.stringify(true));
    }
    window.location.reload(false);
  }

  handleFilterChange = (FilValue) => {
    this.setState({ FilValue, },
      () => {
        Utils.changeOdds(FilValue.id);
      }
    );
  };


  render() {

    const { match, header, staticPage, t } = this.props

    const CustomNavLink = ({ to, children }) => {
      return (
        <NavLink to={to} isActive={(match, location) => {
          if (!match) {
            return false;
          }
          let s = location.pathname
          s = s.substring(0, s.indexOf('/details'))
          return (
            location.pathname == match.url || s == match.url
          )
        }}>
          { children}
        </NavLink>
      )
    }

    // const options = [
    //   { id: "decimal", name: "Decimal" },
    //   { id: "fractional", name: "Fractional" },
    //   { id: "american", name: "American" },
    // ];

    // const optionsHi = [
    //   { id: "decimal", name: "दशमलव" },
    //   { id: "fractional", name: "आंशिक" },
    //   { id: "american", name: "अमेरिकन" },
    // ];

    // const optionsBen = [
    //   { id: "decimal", name: "দশমিক" },
    //   { id: "fractional", name: "ভগ্নাংশ" },
    //   { id: "american", name: "মার্কিন" },
    // ];

    // const { FilValue,
    //   LangValue,
    //   LangOptions
    // } = this.state;

    return (
      <Fragment>
        {
          header &&
          <header className="app-toolbar-bottom">
            <div className="max-width-container navi-grp">

              <div className="sub-navigation-scroll">
                <SubNavigationScrollbars autoHeight>
                  <nav className="sub-navigation">
                    {/* {
                      staticPage ?
                      <span onClick={() => props.history.goBack()}>{t('home')}</span>
                      :
                      <CustomNavLink to={`${match.url}`}>{t('home')}</CustomNavLink>
                    } */}
                    {
                      !staticPage &&
                      <Fragment>
                        <CustomNavLink to={`${match.url}`}>{t('home')}</CustomNavLink>
                        <CustomNavLink to={`${match.url}/in-play`}>{t('in play')}</CustomNavLink>
                      </Fragment>
                    }


                    <NavLink className={'bl-txt'} to={`/offers`} exact>{t('offers')}</NavLink>
                    <NavLink to={`/responsible-gambling`} exact>{t('responsible gambling')}</NavLink>
                    <NavLink to={`/terms-and-conditions`} exact>{t('terms & conditions')}</NavLink>

                    {
                      Auth.currentUserValue && Auth.currentUserValue.role == Role.AgentPunter ?
                        <React.Fragment>
                          <NavLink className={'bl-txt'} to={`/deposit`} exact>{t('Deposit')}</NavLink>
                          <NavLink className={'bl-txt'} to={`/withdraw`} exact>{t('Withdraw')}</NavLink>
                        </React.Fragment> : ''
                    }

                    <a href={'https://blog.Isocial.com/'} rel="noopener noreferrer" target={'_blank'}>{t('Blog')}</a>
                  </nav>
                </SubNavigationScrollbars>
              </div>


              {/* <div className={'d-flex sel-opts-bar'}>
                <div className="odds-format-select">
                  <Select
                    className='react-select'
                    classNamePrefix="react-select"
                    // defaultValue={LangOptions[0]}
                    value={LangValue ? LangValue : LangOptions[0]}
                    isSearchable={false}
                    options={LangOptions}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    onChange={this.handleLanguageChange}
                  // menuIsOpen={true}
                  />
                </div>
                <div className="odds-format-select">
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={FilValue}
                    isClearable={false}
                    isSearchable={false}
                    options={Utils.geti18nextLng() == 'ben' ? optionsBen : Utils.geti18nextLng() == 'hi' ? optionsHi : Utils.geti18nextLng() == 'en' ? options : ""}
                    onChange={this.handleFilterChange}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                  />
                </div>
              </div> */}
            </div>
          </header>
        }
        <div className='site-content' id='SiteContent'>
          <div className="max-width-container">
            {this.props.children}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation()(SiteContainer);