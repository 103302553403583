import React, { Component, Fragment } from "react";
import { Utils, _ } from "Helpers";
import { Auth } from "Service";
import { Collapse, Popover } from 'reactstrap';
class TemplateOptionSB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oddShowType: localStorage.getItem("OddSelectiontype"),
      selectedMarket: '',
      tooltipOpen: {},
    };
  }

  componentDidMount() {
    this.setState({
      selectedMarket: this.props.open
    })
  }

  setCollapse = (id) => {
    if (id == this.state.selectedMarket) {
      this.setState({
        selectedMarket: ''
      })
    } else {
      this.setState({
        selectedMarket: id
      })
    }
  }

  toggleTooltip = (e,itemId) => {
    e.stopPropagation()
    this.setState((prevState) => ({
      tooltipOpen: {
        ...prevState.tooltipOpen,
        [itemId]: !prevState.tooltipOpen[itemId],
      },
    }));
  };

  render() {
    const { oddShowType, selectedMarket } = this.state;
    const {
      SelectedSports,
      item,
      // Mehtods
      handleOptionChange,
      _oddsActiveClass,
      SocketHighlight,
      ResetWinloss,
      league_name
    } = this.props;
    const isDisabled = () => {
      return item.bet_status != 1;
      // ||  item.admin_status != 1
    };
    const currentUser = Auth.currentUserValue;
    return (
      <div {...{ className: `odd-market-collapse active odd-market-collapse-new` }}>
        <div className="heading radhe" onClick={() => this.setCollapse(item.market_event_id)}>
        
          <span className="market-name-text">{item.market_name}</span>
          
          <Popover
                className="info-pop-cust"
                placement="top"
                isOpen={this.state.tooltipOpen[item.market_event_id]}
                target={`tooltip-${item.market_event_id}`}
                toggle={(e) => this.toggleTooltip(e,item.market_event_id)}
              >
          {item.info}</Popover>
          
          {/* {Utils.isDev() && (
            <b>
              {" --- "}
              {_.toUpper(item.template_name)}
            </b>
          )} */}
          <div>
          <span className="info-m-name" id={`tooltip-${item.market_event_id}`}
                onClick={(e) => this.toggleTooltip(e,item.market_event_id)}
                >i</span>
          <a className="toogle-market" onClick={() => this.setCollapse(item.market_event_id)}>
            <i className={`${item.market_event_id == selectedMarket ? 'icon-up' : 'icon-down'}`}></i></a>
          </div>
          
        </div>
        <Collapse
          isOpen={item.market_event_id == selectedMarket}
        //  isOpen={selectedMarket}
        >
          

          {
            JSON.parse(item.runners_odds) && JSON.parse(item.runners_odds).length > 3 ?
            <div className="odd-market-body odd-market-body-new">
            {_.map(JSON.parse(item.runners_odds), (obj, key) => {
              return (
                <div className="cell" {...{ key }}>
                  {/* {obj.is_suspended == "1" && (
                    <div className="game-status-suspended template oooooo">
                      SUSPENDED
                    </div>
                  )} */}
                  

                  <div className="text-cell">
                    {obj.name}
                    {currentUser && !_.isUndefined(obj.winloss) && (
                      <Fragment>
                        {_.isUndefined(obj.temp_winloss) ? (
                          <span
                            {...{
                              className: `winloss-${Math.sign(
                                obj.winloss
                              )}`,
                            }}
                          >
                            {_.round(obj.winloss, 2).toFixed(2)}
                          </span>
                        ) : (
                          <span
                            {...{
                              className: `winloss-${Math.sign(
                                obj.temp_winloss
                              )}`,
                            }}
                          >
                            {_.round(obj.temp_winloss, 2).toFixed(
                              2
                            )}
                          </span>
                        )}
                      </Fragment>
                    )}
                  </div>

                  <div className="odd-market-last">
                  {(obj.is_suspended == "1" || obj.lock_betting == '1') && (
                    <div className="odd-market-btn">
                      <div className="game-status-suspended template-small">
                        SUSPENDED
                      </div>
                    </div>
                    
                  )}

                    {!(obj.is_suspended == "1" || obj.lock_betting == '1') &&
                    
                      <a
                      {...{
                        className: `odd-market-btn ${isDisabled() ? "disabled" : ""
                          } ${_oddsActiveClass(
                            obj.selection_id,
                            item.market_event_id,
                            1
                          )
                            ? "yellow"
                            : ""
                          } ${SocketHighlight({ id: obj.selection_id })}`,
                      }}
                      onClick={
                        isDisabled()
                          ? null
                          : (e) => [
                            handleOptionChange(e,{
                              fixture: {
                                ...item,
                                sports_id: SelectedSports.sports_id,
                                league_name:league_name
                              },
                              odd: {
                                ...obj,
                                price: obj.odds,
                                market_bet_id: obj.selection_id,
                                selectionId: obj.selection_id,
                              },
                              betting_type: 1,
                              selected_odd: obj
                            }),
                            ResetWinloss(item),
                          ]
                      }
                    ><>
                      {obj.odds
                        ? oddShowType == "decimal"
                          ? Utils.DecimalOdds(obj.odds)
                          : oddShowType == "fractional"
                            ? Utils.FractionalOdds(obj.odds) || "--"
                            : oddShowType == "american"
                              ? Utils.AmericanOdds(obj.odds)
                              : "--"
                        : "--"}
                        </>
                        
                    </a>}
                  </div>
                 
                </div>
                
              );
            })}
            {_.isEmpty(JSON.parse(item.runners_odds)) && (
              <div className="odd-market-row">
                <div className="odd-market-line text-center">
                  Odds Not available
                </div>
              </div>
            )}
          </div>
            // <Row className="multiple-data-view">
             
            //   {_.map(JSON.parse(item.runners_odds), (obj, key) => {
            //   return (
            //     <Col className="multiple-data-conainer">
            //     <div className={`cell  `} {...{ key }}>
            //       {obj.is_suspended == "1" || obj.lock_betting == '1' && (
            //         <div className="game-status-suspended template">
            //           SUSPENDED
            //         </div>
            //       )}
            //         <div className="text-view">
            //           {obj.name}
            //           {currentUser && !_.isUndefined(obj.winloss) && (
            //             <Fragment>
            //               {_.isUndefined(obj.temp_winloss) ? (
            //                 <span
            //                   {...{
            //                     className: `winloss-${Math.sign(
            //                       obj.winloss
            //                     )}`,
            //                   }}
            //                 >
            //                   {_.round(obj.winloss, 2).toFixed(2)}
            //                 </span>
            //               ) : (
            //                 <span
            //                   {...{
            //                     className: `winloss-${Math.sign(
            //                       obj.temp_winloss
            //                     )}`,
            //                   }}
            //                 >
            //                   {_.round(obj.temp_winloss, 2).toFixed(
            //                     2
            //                   )}
            //                 </span>
            //               )}
            //             </Fragment>
            //           )}
            //         </div>
            //     </div>
            //     <div
            //         {...{
            //           className: `odd-market-btn-new ${isDisabled() ? "disabled" : ""
            //             } ${_oddsActiveClass(
            //               obj.selection_id,
            //               item.market_event_id,
            //               1
            //             )
            //               ? "yellow"
            //               : ""
            //             } ${SocketHighlight({ id: obj.selection_id })}`,
            //         }}
            //         onClick={
            //           isDisabled()
            //             ? null
            //             : () => [
            //               handleOptionChange({
            //                 fixture: {
            //                   ...item,
            //                   sports_id: SelectedSports.sports_id,
            //                 },
            //                 odd: {
            //                   ...obj,
            //                   price: obj.odds,
            //                   market_bet_id: obj.selection_id,
            //                   selectionId: obj.selection_id,
            //                 },
            //                 betting_type: 1,
            //                 selected_odd: obj,
            //               }),
            //               ResetWinloss(item),
            //             ]
            //         }
            //       >
            //         {obj.odds
            //           ? oddShowType == "decimal"
            //             ? Utils.DecimalOdds(obj.odds)
            //             : oddShowType == "fractional"
            //               ? Utils.FractionalOdds(obj.odds) || "--"
            //               : oddShowType == "american"
            //                 ? Utils.AmericanOdds(obj.odds)
            //                 : "--"
            //           : "--"}
            //       </div>
            //     </Col>
                

            //   );
            // })}
            // {_.isEmpty(JSON.parse(item.runners_odds)) && (
            //   <div className="odd-market-row">
            //     <div className="odd-market-line text-center">
            //       Odds Not available
            //     </div>
            //   </div>
            // )}
            // </Row>
            :
            <>
            <div className="cell-box-view">
            {_.map(JSON.parse(item.runners_odds), (obj, key) => {
              return (
                <div className={`cell ${JSON.parse(item.runners_odds).length && JSON.parse(item.runners_odds).length == 3 ? "cell-new" : ""} `} {...{ key }}>
                  {/* {obj.is_suspended == "1" && (
                    <div className="game-status-suspended template pppppppppp">
                      SUSPENDED
                    </div>
                  )} */}
                  <div className="cell-text-view">
                    <div className="text-view">
                      {obj.name}
                      {currentUser && !_.isUndefined(obj.winloss) && (
                        <Fragment>
                          {_.isUndefined(obj.temp_winloss) ? (
                            <span
                              {...{
                                className: `winloss-${Math.sign(
                                  obj.winloss
                                )}`,
                              }}
                            >
                              {_.round(obj.winloss, 2).toFixed(2)}
                            </span>
                          ) : (
                            <span
                              {...{
                                className: `winloss-${Math.sign(
                                  obj.temp_winloss
                                )}`,
                              }}
                            >
                              {_.round(obj.temp_winloss, 2).toFixed(
                                2
                              )}
                            </span>
                          )}
                        </Fragment>
                      )}
                    </div>

                  </div>
                </div>

              );
            })}
            {_.isEmpty(JSON.parse(item.runners_odds)) && (
              <div className="odd-market-row">
                <div className="odd-market-line text-center">
                  Odds Not available
                </div>
              </div>
            )}

          </div>
          <div className="cell-value-view">
            {_.map(JSON.parse(item.runners_odds), (obj, key) => {
              

              return (
                <div className={`cell ${JSON.parse(item.runners_odds).length && JSON.parse(item.runners_odds).length == 3 ? "cell-new" : JSON.parse(item.runners_odds).length == 2 ? " cell-row-second" : ""} ${obj.is_suspended == "1" || obj.lock_betting == '1' ? ' suspended ' : ''} 
                ${_oddsActiveClass(
                  obj.selection_id,
                  item.market_event_id,
                  1
                )
                  ? "yellow"
                  : ""}

                `} {...{ key }}>
                  {(obj.is_suspended == "1" || obj.lock_betting == '1') && (
                    <div className="game-status-suspended template tttttttttt">
                      SUSPENDED
                    </div>
                  )}


                  {/* <div className="odd-market-last-new"> */}
                  <div
                    {...{
                      className: `odd-market-btn-new ${isDisabled() ? "disabled" : ""
                        } ${_oddsActiveClass(
                          obj.selection_id,
                          item.market_event_id,
                          1
                        )
                          ? "yellow"
                          : ""
                        } ${SocketHighlight({ id: obj.selection_id })}`,
                    }}
                    onClick={
                      isDisabled()
                        ? null
                        : (e) => [
                          
                          handleOptionChange(e,{
                            fixture: {
                              ...item,
                              sports_id: SelectedSports.sports_id,
                              league_name:league_name
                            },
                            odd: {
                              ...obj,
                              price: obj.odds,
                              market_bet_id: obj.selection_id,
                              selectionId: obj.selection_id,
                            },
                            betting_type: 1,
                            selected_odd: obj
                            
                          }),
                          ResetWinloss(item),
                        ]
                    }
                  >
                    {obj.odds
                      ? oddShowType == "decimal"
                        ? Utils.DecimalOdds(obj.odds)
                        : oddShowType == "fractional"
                          ? Utils.FractionalOdds(obj.odds) || "--"
                          : oddShowType == "american"
                            ? Utils.AmericanOdds(obj.odds)
                            : "--"
                      : "--"}
                  </div>
                </div>

                // </div>

              );
            })}
            {_.isEmpty(JSON.parse(item.runners_odds)) && (
              <div className="odd-market-row">
                <div className="odd-market-line text-center">
                  Odds Not available
                </div>
              </div>
            )}

          </div>
          </>
          }

          


          {/* <div className="odd-market-body">
            {_.map(JSON.parse(item.runners_odds), (obj, key) => {
              return (
                <div className="cell" {...{ key }}>
                  {obj.is_suspended == "1" || obj.lock_betting == '1' && (
                    <div className="game-status-suspended template">
                      SUSPENDED
                    </div>
                  )}
                  

                  <div className="text-cell">
                    {obj.name}
                    {currentUser && !_.isUndefined(obj.winloss) && (
                      <Fragment>
                        {_.isUndefined(obj.temp_winloss) ? (
                          <span
                            {...{
                              className: `winloss-${Math.sign(
                                obj.winloss
                              )}`,
                            }}
                          >
                            {_.round(obj.winloss, 2).toFixed(2)}
                          </span>
                        ) : (
                          <span
                            {...{
                              className: `winloss-${Math.sign(
                                obj.temp_winloss
                              )}`,
                            }}
                          >
                            {_.round(obj.temp_winloss, 2).toFixed(
                              2
                            )}
                          </span>
                        )}
                      </Fragment>
                    )}
                  </div>

                  <div className="odd-market-last">
                    <a
                      {...{
                        className: `odd-market-btn ${isDisabled() ? "disabled" : ""
                          } ${_oddsActiveClass(
                            obj.selection_id,
                            item.market_event_id,
                            1
                          )
                            ? "yellow"
                            : ""
                          } ${SocketHighlight({ id: obj.selection_id })}`,
                      }}
                      onClick={
                        isDisabled()
                          ? null
                          : () => [
                            handleOptionChange({
                              fixture: {
                                ...item,
                                sports_id: SelectedSports.sports_id,
                              },
                              odd: {
                                ...obj,
                                price: obj.odds,
                                market_bet_id: obj.selection_id,
                                selectionId: obj.selection_id,
                              },
                              betting_type: 1,
                              selected_odd: obj,
                            }),
                            ResetWinloss(item),
                          ]
                      }
                    >
                      {obj.odds
                        ? oddShowType == "decimal"
                          ? Utils.DecimalOdds(obj.odds)
                          : oddShowType == "fractional"
                            ? Utils.FractionalOdds(obj.odds) || "--"
                            : oddShowType == "american"
                              ? Utils.AmericanOdds(obj.odds)
                              : "--"
                        : "--"}
                    </a>
                  </div>
                 
                </div>
                
              );
            })}
            {_.isEmpty(JSON.parse(item.runners_odds)) && (
              <div className="odd-market-row">
                <div className="odd-market-line text-center">
                  Odds Not available
                </div>
              </div>
            )}
          </div> */}
        </Collapse>
      </div>
    );
  }
}
export default TemplateOptionSB;
