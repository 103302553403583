import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { SiteContainer, AgentLinkNav } from 'Components';
import { Images } from 'Constants';
import { Utils, Http, API, AuthHelp, Role, _ } from 'Helpers';
import { notify } from 'react-notify-toast';
import { Auth } from 'Service';
import { VideoModal } from 'Modals';

var globalThis = null;
class Deposit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      Wallet: '',
      reference: '',
      transaction_id: '',
      attachment: '',
      selectedPayment: 1,
      min_deposit_amount: 0,
      max_deposit_amount: 0,
      BankName: '',
      BankAccountHolder: '',
      BankAccountNumber: '',
      Ifsc: '',
      SAPaytm: '',
      SAPhonepe: '',
      SAGooglePay: '',
      receipt_image: '',
      paytm: '',
      phonePe: '',
      googlePay: '',
      deposit_amt_const: [],
      paymentList: [],
      VideoModalOpen: false
    }
    const CurrentUser = Auth.currentUserValue;
    if (CurrentUser != null && CurrentUser.role != Role.AgentPunter) {
      this.props.history.push('/')
    }

  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm = () => {

    const { Wallet, transaction_id, attachment, min_deposit_amount,
      max_deposit_amount, receipt_image, selectedPayment, paytm, phonePe, googlePay
    } = this.state;

    let validate = true;


    if (selectedPayment == 2) {
      validate = Utils.isValid("mobile", paytm)
    }

    if (selectedPayment == 3) {
      validate = Utils.isValid("mobile", phonePe)
    }

    if (selectedPayment == 4) {
      validate = Utils.isValid("mobile", googlePay)
    }


    this.setState({
      formValid: selectedPayment != '' &&
        Wallet != '' &&
        Wallet >= min_deposit_amount &&
        Wallet <= max_deposit_amount &&
        transaction_id != '' &&
        attachment != '' &&
        receipt_image != '' &&
        validate
    })
  }

  onDrop = (e) => {
    e.preventDefault();

    let mfile = e.target.files[0];
    if (e.target.files.length > 0) {

      var formData = new FormData();
      formData.append('receipt', mfile);

      var xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener("readystatechange", function () {
        if (this.readyState == 4) {
          var response = JSON.parse(this.responseText);

          if (response != '' && response.response_code == 200) {
            globalThis.setState({
              attachment: response.data.image_path,
              receipt_image: response.data.file_name
            }, globalThis.validateForm)
          }
          else {
            notify.show(response.error.receipt, "error", 2500);
          }
        }
      })

      xhr.open("POST", API.DO_UPLOAD_RECEIPT);
      xhr.setRequestHeader('Sessionkey', AuthHelp.getAuth())
      xhr.send(formData);

    }


  }

  paymentOptHandler = key => {
    this.setState({ selectedPayment: key }, this.validateForm)
  }

  amtSelectHandler = amt => {
    this.setState({ Wallet: amt }, this.validateForm)
  }

  paymentArrayCreate = (data) => {
    let paymentList = [];
    data.forEach(pg => {
      if (pg.name === 'Bank') {
        pg.img = Images.BANK_TRANSFER;
        pg.img_white = Images.BANK_WHITE;
      }
      if (pg.name === 'PayTm') {
        pg.img = Images.PAYTM_LOGO;
        pg.img_white = Images.PAYTM_WHITE;
      }
      if (pg.name === 'PhonePe') {
        pg.img = Images.PHONEPE_LOGO;
        pg.img_white = Images.PHONEPAY_WHITE;
      }
      if (pg.name === 'GooglePay') {
        pg.img = Images.GPAY;
        pg.img_white = Images.GPAY_WHITE;
      }
      paymentList.push(pg)
    })
    this.setState({ paymentList })

  }

  user_payment_details_handler = (data) => {

    let superadmin_pg_data = data.superadmin_pg_data;

    if (superadmin_pg_data !== null) {

      if (superadmin_pg_data.bank_acc_detail) {

        let bank_acc_detail = JSON.parse(superadmin_pg_data.bank_acc_detail);
        this.setState({
          BankName: bank_acc_detail.bank_name,
          BankAccountHolder: bank_acc_detail.account_holder,
          BankAccountNumber: bank_acc_detail.account_number,
          Ifsc: bank_acc_detail.ifsc_code,
        })
      }

      if (superadmin_pg_data.paytm_detail) {
        let paytm = JSON.parse(superadmin_pg_data.paytm_detail);
        this.setState({
          SAPaytm: paytm.phone
        })
      }

      if (superadmin_pg_data.phonepe_detail) {
        let phonepe = JSON.parse(superadmin_pg_data.phonepe_detail);
        this.setState({
          SAPhonepe: phonepe.phone
        })
      }

      if (superadmin_pg_data.google_pay_detail) {
        let googlePay = JSON.parse(superadmin_pg_data.google_pay_detail);
        this.setState({
          SAGooglePay: googlePay.phone
        })
      }
    }
  }




  getData = () => {
    Http.post(API.GET_DEPOSIT_REQUEST_MASTER)
      .then(res => {

        let data = res.data;

        this.paymentArrayCreate(data.payment_gateway_type);

        this.user_payment_details_handler(data);


        this.setState({
          min_deposit_amount: data.min_deposit_amt,
          max_deposit_amount: data.max_deposit_amt,
          deposit_amt_const: data.deposit_amt_const
        })

      })
  }

  removeImage = () => {
    this.setState({
      attachment: '',
      receipt_image: ''
    }, this.validateForm)
  }


  componentDidMount() {
    globalThis = this
    this.getData()
  }



  onSubmit = e => {
    e.preventDefault();

    let { Wallet, selectedPayment, paytm, phonePe, googlePay, transaction_id, reference, receipt_image } = this.state;

    let param = {
      amount: Wallet,
      ref_txn_id: transaction_id,
      remark: reference,
      payment_gateway: selectedPayment,
      receipt_image: receipt_image
    }

    if (selectedPayment == 2) {
      param['phone'] = paytm
    }

    if (selectedPayment == 3) {
      param['phone'] = phonePe
    }

    if (selectedPayment == 4) {
      param['phone'] = googlePay
    }

    this.setState({ posting: true })

    Http.post(API.MAKE_DEPOSIT_REQUEST, param)
      .then(res => {
        notify.show(res.message, "success", 2500)
        setTimeout(() => window.location.reload(), 2500)
      })
      .catch(err => {
        this.setState({ posting: false })
      })


  }


  render() {

    const { t } = this.props
    const {
      Wallet, formValid, posting, reference, transaction_id, attachment, selectedPayment,
      BankName, BankAccountHolder, BankAccountNumber, Ifsc, min_deposit_amount, max_deposit_amount,
      paytm, phonePe, googlePay, SAPaytm, SAPhonepe, SAGooglePay, deposit_amt_const, paymentList, VideoModalOpen
    } = this.state;

    const CurrentUser = Auth.currentUserValue;

    const SignupVideoModalProps = {
      isOpen: VideoModalOpen,
      toggle: () => this.setState({ VideoModalOpen: false }),
      videoId: selectedPayment == 1 ? "FIKgoOw_muY" :
        selectedPayment == 2 ? "qpz4lZ89C0w" :
          selectedPayment == 3 ? "NstzNYaGqxA" :
            "Ck5Pk7qFwSE"
    }


    //  console.log(CurrentUser)

    // const paymentList = [
    //   { id: 1, img: Images.BANK_TRANSFER, img_white: Images.BANK_WHITE, key: 'Bank' },
    //   { id: 2, img: Images.PAYTM_LOGO, img_white: Images.PAYTM_WHITE, key: 'PayTm' },
    //   { id: 3, img: Images.PHONEPE_LOGO, img_white: Images.PHONEPAY_WHITE, key: 'PhonePe' },
    //   { id: 4, img: Images.GPAY, img_white: Images.GPAY_WHITE, key: 'GooglePay' },
    // ]

    return (
      <SiteContainer {...this.props}>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <AgentLinkNav {...this.props} />

            </Col>
            <Col>
              <div className="white-box">

                {/* <div class="odds-filter-pills no-float ">
                  <span onClick={() => this.props.history.push('/withdraw')}> Withdraw</span>
                  <span class="active"> Deposit</span>
                </div>
                 */}

                <div className="title">{t('Deposit')}</div>
                <div className={'dp-head-note'}>Please do not use ‘Isocial’ anywhere, enter user id as transaction remark for successful deposit approval</div>

                <Form onSubmit={this.onSubmit}>

                  <ul className="payment-option-row">
                    {
                      paymentList.map((pl, i) => {
                        return <li key={i} onClick={() => this.paymentOptHandler(pl.id)} className={selectedPayment == pl.id ? 'active' : ''}>
                          <img src={pl.img} alt="" className={'main_img'} />
                          <img src={pl.img_white} alt="" className={'active_img'} />
                        </li>
                      })
                    }

                  </ul>


                  <Row>
                    <Col className={'dp-head-note mb-4 c-pointer'} onClick={()=>this.setState({VideoModalOpen:true})}>Learn more about deposit, watch this video.</Col>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      <FormGroup className='input-border-bottom'>
                        <span className='label pl-0'>{t('Transfer money to Isocial using below details')}</span>
                        <div>Minimum Transfer value : {min_deposit_amount}</div>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup className='input-border-bottom'>
                        <span className='label pl-0'>{t('Current Balance')}</span>
                        <div>{CurrentUser ? `Pts. ${_.round(CurrentUser.balance, 2)}` : '-'}</div>
                      </FormGroup>
                    </Col>

                    {
                      selectedPayment == 1 ?
                        <React.Fragment>
                          <Col md={6} lg={3} xs={12}>
                            <FormGroup className='input-border-bottom'>
                              <span className='label pl-0'>{t('Bank Name')}</span>
                              <div>{BankName || '-'}</div>
                            </FormGroup>
                          </Col>
                          <Col md={6} lg={3} xs={12}>
                            <FormGroup className='input-border-bottom'>
                              <span className='label pl-0'>{t('Bank Account Holder')}</span>
                              <div>{BankAccountHolder || '-'}</div>
                            </FormGroup>
                          </Col>
                          <Col md={6} lg={3} xs={12}>
                            <FormGroup className='input-border-bottom'>
                              <span className='label pl-0'>{t('Bank Account Number')}</span>
                              <div>{BankAccountNumber || '-'}</div>
                            </FormGroup>
                          </Col>
                          <Col md={6} lg={3} xs={12}>
                            <FormGroup className='input-border-bottom'>
                              <span className='label pl-0'>{t('IFSC Code')}</span>
                              <div>{Ifsc || '-'}</div>
                            </FormGroup>
                          </Col>
                        </React.Fragment>
                        :

                        <Col md={6} lg={3} xs={12}>
                          <FormGroup className='input-border-bottom'>
                            {
                              selectedPayment == 2 ?
                                <React.Fragment>
                                  <span className='label pl-0'>{t('Paytm Number transfer to')}</span>
                                  <div>{SAPaytm || '-'}</div>
                                </React.Fragment>
                                :
                                selectedPayment == 3 ?
                                  <React.Fragment>
                                    <span className='label pl-0'>{t('Phonepe Number transfer to')}</span>
                                    <div>{SAPhonepe || '-'}</div>
                                  </React.Fragment>
                                  :
                                  selectedPayment == 4 ?
                                    <React.Fragment>
                                      <span className='label pl-0'>{t('Googlepay Number transfer to')}</span>
                                      <div>{SAGooglePay || '-'}</div>
                                    </React.Fragment>
                                    : ''
                            }
                          </FormGroup>
                        </Col>

                    }

                  </Row>

                  <Row>

                    <Col sm={6} xs={12}>
                      <FormGroup className='input-border-bottom pb-3' >
                        {
                          selectedPayment == 2 ?
                            <React.Fragment>
                              <span className='label'> {t('Your Paytm Number')} <span className={'nt-n'}>({t("Please mention the number from which you have deposited")})</span></span>
                              <Input
                                type="text"
                                id='paytm'
                                name='paytm'
                                required
                                value={paytm || ''}
                                autoComplete="off"
                                onChange={this.handleChange}
                                invalid={Utils.isInvalid("mobile", paytm)}
                                valid={Utils.isValid("mobile", paytm)}
                              />
                            </React.Fragment>
                            :
                            selectedPayment == 3 ?
                              <React.Fragment>
                                <span className='label'>{t('Your Phonepe Number')}  <span className={'nt-n'}>({t("Please mention the number from which you have deposited")})</span></span>
                                <Input
                                  type="text"
                                  id='phonePe'
                                  name='phonePe'
                                  required
                                  value={phonePe || ''}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                  invalid={Utils.isInvalid("mobile", phonePe)}
                                  valid={Utils.isValid("mobile", phonePe)}
                                />
                              </React.Fragment>
                              :
                              selectedPayment == 4 ?
                                <React.Fragment>
                                  <span className='label'>{t('Your Gpay Number')}  <span className={'nt-n'}>({t("Please mention the number from which you have deposited")})</span></span>
                                  <Input
                                    type="text"
                                    id='googlePay'
                                    name='googlePay'
                                    required
                                    value={googlePay || ''}
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                    invalid={Utils.isInvalid("mobile", googlePay)}
                                    valid={Utils.isValid("mobile", googlePay)}
                                  />
                                </React.Fragment>
                                : ''


                        }


                      </FormGroup>
                    </Col>

                  </Row>


                  <Row>

                    <Col md={6} xs={12}>
                      <FormGroup className='input-border-bottom'>
                        <span className='label pl-0'>{t('Reference/Transaction Remark')}</span>
                        <Input
                          type="text"
                          id='reference'
                          name='reference'
                          value={reference}
                          autoComplete="off"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup className='input-border-bottom'>
                        <span className='label pl-0'>{t('Enter ref. ID/ Transaction ID')}</span>
                        <Input
                          type="text"
                          id='transaction_id'
                          name='transaction_id'
                          value={transaction_id}
                          autoComplete="off"
                          onChange={this.handleChange}
                          required
                          invalid={Utils.isInvalid("reqiured", transaction_id)}
                          valid={Utils.isValid("reqiured", transaction_id)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>{t('Enter amount deposited')}</span>
                        <Input type="number"
                          id='Wallet'
                          name='Wallet'
                          required
                          value={Wallet}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", Wallet)}
                          valid={Utils.isValid("reqiured", Wallet)}
                          min={min_deposit_amount}
                          max={max_deposit_amount}
                        />
                      </FormGroup>
                      <ul className="list-widthdraw-amount mb-3">
                        {
                          deposit_amt_const.map((amt, i) => {
                            return <li key={i} onClick={() => this.amtSelectHandler(amt)}>{amt}</li>
                          })
                        }
                      </ul>

                    </Col>

                    <Col sm={6} xs={12}>


                      {
                        attachment == '' ?

                          <div className="upload-doc">
                            <label className="upload-image">
                              {t('upload image')}
                              <input
                                id="myInput"
                                type="file"
                                accept="image/*"
                                ref={(ref) => this.upload = ref}
                                style={{ display: 'none' }}
                                onChange={(e) => this.onDrop(e)}
                              />
                            </label>
                          </div>
                          : <div className={'attachment_preview'}>
                            <img src={attachment} alt={'img'} />
                            <i className={'cancel_img'} onClick={this.removeImage}>x</i>
                          </div>
                      }
                    </Col>
                  </Row>

                  <Row >

                    <Col sm={6} xs={12}>

                    </Col>
                  </Row>


                  <Row className={'pt-5 pb-3'}>
                    <Col xs={12}>

                      <FormGroup className='m-0 text-center'>
                        <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round btn-web px-5'>{t('Deposit')}</Button>
                      </FormGroup>
                    </Col>

                  </Row>
                </Form>
              </div>
            </Col>
          </Row>

          {VideoModalOpen && <VideoModal {...SignupVideoModalProps} />}

        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(Deposit);
