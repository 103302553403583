import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, Input } from 'reactstrap';
import { Auth } from 'Service';
import { _, Utils, Http, API } from 'Helpers';


class EditStakeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      stackValuesOdds: JSON.parse(localStorage.getItem('currentUser')).odds_stack,
      stackValuesFancy: JSON.parse(localStorage.getItem('currentUser')).fancy_stack,
      stackValuesCasino: JSON.parse(localStorage.getItem('currentUser')).casino_stack,
      currentUser: Auth.currentUserValue
    }
  }
  componentDidMount() {
  }
  handleChange = idx => evt => {
    let { stackValuesOdds } = this.state;
    let value = evt.target.value
    const re = /^\d*\.?(?:\d{1,2})?$/;
    stackValuesOdds[idx] = (value === '' || re.test(value)) ? value : stackValuesOdds[idx] 
    //console.log(stackValuesOdds[idx],"stackValuesOdds[idx]stackValuesOdds[idx]")
    this.setState({ stackValuesOdds }, () => {
      this.validateForm()
    });
  };  

  handleChangeFancy = idx => evt => {
    let { stackValuesFancy } = this.state;
    let value = evt.target.value
    const re = /^\d*\.?(?:\d{1,2})?$/;
    stackValuesFancy[idx] = (value === '' || re.test(value)) ? value : stackValuesFancy[idx] 
    //console.log(stackValuesFancy[idx],"stackValuesFancy[idx]stackValuesFancy[idx]")
    this.setState({ stackValuesFancy }, () => {
      this.validateForm()
    });
  };
  
  handleChangeCasino = idx => evt => {
    let { stackValuesCasino } = this.state;
    let value = evt.target.value
    const re = /^\d*\.?(?:\d{1,2})?$/;
    stackValuesCasino[idx] = (value === '' || re.test(value)) ? value : stackValuesCasino[idx] 
    this.setState({ stackValuesCasino }, () => {
      this.validateForm()
    });
  };


  validateForm() {
//    const {} = this.state;
    this.setState({
      formValid:
       true

    });
  }



  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true })


    const {
      currentUser,
      stackValuesOdds,
      stackValuesFancy,
      stackValuesCasino
    } = this.state;

    let param = {
      user_guid: currentUser.user_guid,
      odds_stack:stackValuesOdds,
      fancy_stack:stackValuesFancy,
      casino_stack:stackValuesCasino,
    }

    Http.post(API.UPDATE_STAKE, param).then(response => {
      Utils.notify(response.message);
      
      let tempUser = Auth.currentUserValue;
      tempUser.fancy_stack = stackValuesFancy
      tempUser.odds_stack = stackValuesOdds
      tempUser.casino_stack = stackValuesCasino
      localStorage.setItem('currentUser', JSON.stringify(tempUser));
      this.setState({ posting: false }, this.props.toggle)


    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  render() {
    const { isOpen, toggle, BetslipOddsStack } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal sm',
    }
    const {
      formValid,
      posting,
      stackValuesOdds,
      stackValuesFancy,
      stackValuesCasino
    } = this.state

    return (
      <Modal {...ModalProps}>
        <ModalHeader>
          Edit Stake
      {/* <i className="close-btn icon-cancel" onClick={toggle} /> */}
        </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <h4 className="head-sm">Exchange/Sportsbook</h4>
              </Col>
              <Col xs={12}>
                <div className="multi-inputs">
                  {
                    _.map(BetslipOddsStack, (item, key) =>
                      <Input {...{ key }}
                        name={"stackValuesOdds" + [key]}
                        value={stackValuesOdds[key]}
                        autoComplete="off"
                        onChange={this.handleChange(key)}
                      />
                    )
                  }
                </div>
              </Col>
              <Col xs={12}>
                <h4 className="head-sm">Fancy</h4>
              </Col>
              <Col xs={12}>
                <div className="multi-inputs">
                  {
                    _.map(stackValuesFancy, (item, key) =>
                      <Input {...{ key }}
                        name={"stackValuesOdds" + [key]}
                        value={stackValuesFancy[key]}
                        autoComplete="off"
                        onChange={this.handleChangeFancy(key)}
                      />
                    )
                  }
                </div>
              </Col>
              <Col xs={12}>
                <h4 className="head-sm">Casino</h4>
              </Col>
              <Col xs={12}>
                <div className="multi-inputs">
                  {
                    _.map(stackValuesCasino, (item, key) =>
                      <Input {...{ key }}
                        name={"stackValuesCasino" + [key]}
                        value={stackValuesCasino[key]}
                        autoComplete="off"
                        onChange={this.handleChangeCasino(key)}
                      />
                    )
                  }
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="default" size='sm' onClick={toggle}>Cancel</Button>
            <Button color="primary" size='sm' onClick={this.onSubmit} disabled={!formValid || posting}>Save</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

EditStakeModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}
export default EditStakeModal;


