import { BehaviorSubject } from 'rxjs';
import { API, AuthHelp, Http } from 'Helpers';
import { CONSTANT } from 'Constants';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

function login(username, password, location, isp) {
  return new Promise((resolve, reject) => {
    let param = {
      username, 
      password,
      device_type: CONSTANT.DEVICE_TYPE,
      device_id: CONSTANT.DEVICE_ID,
      location,
      isp
    }
    Http.post(API.LOGIN, param).then(response => {
      localStorage.setItem('firstlogin', response.data.user_profile.first_time_login);
      AuthHelp.setAuth(response.data.Sessionkey)
      localStorage.setItem('currentUser', JSON.stringify(response.data.user_profile));
      currentUserSubject.next(response.data.user_profile);
      return resolve(response);
    }).catch(error => {
      return reject(error);
    });
  })
}

// function login(username, password, location) {
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({ 
//       username, 
//       password,
//       device_type: CONSTANT.DEVICE_TYPE,
//       device_id: CONSTANT.DEVICE_ID,
//       location
//     })
//   };
//   return fetch(API.LOGIN, requestOptions)
//     .then(status)
//     .then(user => {
//       console.log(user)
//       // store user details and jwt token in local storage to keep user logged in between page refreshes
//       AuthHelp.setAuth(user.data.Sessionkey)
//       localStorage.setItem('currentUser', JSON.stringify(user.data.user_profile));
//       currentUserSubject.next(user.data.user_profile);
//       return user;
//     }).catch(error => {
//       console.log(error)
//       Utils.notify(error.message != '' ? error.message :  error.global_error , "error", 2500);
//     });
// }

function logout() {
  // remove user from local storage to log user out
    let param = {
      Sessionkey: AuthHelp.getAuth()
    }
    Http.post(API.LOGOUT, param)
    .then(response => {
      localStorage.removeItem('currentUser');
      AuthHelp.removeAuth()
      currentUserSubject.next(null);
      window.location.href = '/';
      // this.props.history.push('/')
      // window.location.reload()
      
    }).catch(error => {
      localStorage.removeItem('currentUser');
      AuthHelp.removeAuth()
      window.location.href = '/';
    });
}

function updateBalance(balance, exposure) {
  let user = {...currentUserSubject.value, balance, exposure}
  localStorage.setItem('currentUser', JSON.stringify(user));
  currentUserSubject.next(user);
}
function updateSetting(sport_settings) {
  let user = {...currentUserSubject.value, sport_settings}
  localStorage.setItem('currentUser', JSON.stringify(user));
  currentUserSubject.next(user);
}

export default {
  login,
  logout,
  updateBalance,
  updateSetting,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value }
}