import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { Utils, Http, API } from 'Helpers';
import { Auth } from 'Service';

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      password: '',
      confirm_password: '',
      currentUser: Auth.currentUserValue
    }
  }
  componentDidMount(){
   }
  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, () => {
      this.validateForm()
    });
  };

  comparePassword() {
    const { confirm_password, password } = this.state;
    return confirm_password !== '' && confirm_password !== password
  }
  validateForm() {
    const {
       
      password,
      confirm_password,
       
    } = this.state;
    this.setState({
      formValid:
        password !==''
        && confirm_password !== ''
        && Utils.isInvalid("Password", password) === false
         && this.comparePassword() === false
    });
  }


  
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true })
    

    const {
      password,
      confirm_password,
      currentUser
    } = this.state;

    let param = {
      user_guid:currentUser.user_guid,
      password,
      confirm_password,
    }

    Http.post(API.UPDATE_PASSWORD, param).then(response => {
      Utils.notify(response.message);
      this.setState({ posting: false }, this.props.toggle)


    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  render() {
    const { isOpen, toggle} = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal',
      backdrop:  'static' ,
    }
    const {
      formValid,
      posting,
      password,
      confirm_password,
    } = this.state
 
    return (
      <Modal {...ModalProps}>
    <ModalHeader>
      Change Password
      <i className="close-btn icon-cancel" onClick={toggle} />
    </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Password</Label>
                  <Input type="password"  
                    name="password"
                    value={password}  
                    autocomplete="off"  
                    onChange={this.handleChange}
                    invalid={Utils.isInvalid("Password", password) }        
                    />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <Input type="password"   
                    name="confirm_password"
                    value={confirm_password}
                    autocomplete="off"                      
                    onChange={this.handleChange}
                    invalid={(Utils.isInvalid("Password", confirm_password))}
       
                  />
                </FormGroup>
              </Col>
            </Row>
            
          </ModalBody>
          <ModalFooter>
            <Button color="primary" size='sm' onClick={this.onSubmit} disabled={!formValid || posting}>Change</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

ChangePasswordModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}
export default ChangePasswordModal;

