const Images = {
  Logo               : require('../../Assets/img/logo.svg'),
  LOGO_SM            : require('../../Assets/img/logo-sm.svg'),
  DRAWER_LOGO        : require('../../Assets/img/drawer-logo.png'),
  THUMB              : require('../../Assets/img/thumb.png'),
  // THUMB              : require('../../Assets/img/thumb.jpg'),
  THUMB_WIDE         : require('../../Assets/img/thumb-wide.jpg'),
  THUMB_VERTICAL     : require('../../Assets/img/thumb-vertical.png'),
  MGA                : require('../../Assets/img/mga.png'),
  GAMBLING_COMMISSION: require('../../Assets/img/gambling-commission.png'),
  NET_BANKING        : require('../../Assets/img/net-banking.png'),
  UPI                : require('../../Assets/img/upi.png'),
  PAYTM              : require('../../Assets/img/paytm.png'),
  GOOGLE_PAY         : require('../../Assets/img/google-pay.png'),
  PHONE_PE           : require('../../Assets/img/phone-pe.png'),
  LOGO_HOME          : require('../../Assets/img/logo_home.png'),
  BANNER_RIGHT       : require('../../Assets/img/banner-right.png'),
  BANNER_GAME        : require('../../Assets/img/banner-game.png'),
  IPL                : require('../../Assets/img/ipl.svg'),
  ASIA               : require('../../Assets/img/asia.svg'),
  BBL                : require('../../Assets/img/bbl.svg'),
  ICC                : require('../../Assets/img/icc.svg'),
  ASH                : require('../../Assets/img/ash.svg'),
  PREMIER_LEAGUE     : require('../../Assets/img/premier-league.svg'),
  UEFA               : require('../../Assets/img/uefa.svg'),
  WIMBLEDON          : require('../../Assets/img/Wimbledon.svg'),
  WTA                : require('../../Assets/img/WTA.svg'),
  PASS               : require('../../Assets/img/pass.svg'),
  CASINO             : require('../../Assets/img/casino.jpg'),
  LOGO_WHITE         : require('../../Assets/img/logo-white.png'),
  BANK_TRANSFER      : require('../../Assets/img/bank_transfer.svg'),
  PAYTM_LOGO         : require('../../Assets/img/Paytm_Logo.svg'),
  PHONEPE_LOGO       : require('../../Assets/img/Phonepe.svg'),
  GPAY               : require('../../Assets/img/gpay.png'),
  GPAY_WHITE         : require('../../Assets/img/google-pay_w.png'),
  PHONEPAY_WHITE     : require('../../Assets/img/phone pe_w.png'),
  PAYTM_WHITE        : require('../../Assets/img/paytm_w.png'),
  BANK_WHITE         : require('../../Assets/img/bank_transfer_w.png'),


  STOP_WATCH         : require('../../Assets/img/clock.png'),
  ODDS_BANNER        : require('../../Assets/img/matchBg.png'),
  APP_STORE          :require('../../Assets/img/appstore.png'),
  PLAY_STORE         :require('../../Assets/img/playstore.png'),
  ANDROID            :require('../../Assets/img/android.png'),
  UNAUTH            :require('../../Assets/img/unauth.png'),
  ENGLAND_LOGO      :require('../../Assets/img/england-logo.png'),
  VECTOR_IMG        :require('../../Assets/img/Vector.png'),
  BACK_IMG        :require('../../Assets/img/back-Img.png'),
  MASK_GROUP_IMG :require('../../Assets/img/bet-slip-img.png'),
  HEART_ICON :require('../../Assets/img/heart-img.png'),
}

export default Images
