import React, { Component } from 'react';
import Helmet from 'react-helmet';

class TitleComponent extends Component {
  render() {
    const { title } = this.props;
    const AppName = process.env.REACT_APP_WEBSITE_NAME
    const defaultTitle = process.env.REACT_APP_WEBSITE_TITLE;
    return (
      <Helmet>
        <title>{title ? (title +' | '+ AppName) : defaultTitle}</title>
      </Helmet>
    )
  }
}

export default TitleComponent;