/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Images, SocketEvent, CONSTANT } from 'Constants';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Row, Col, Button, DropdownItem } from 'reactstrap';
import { Auth } from 'Service';
import Drawer from 'react-motion-drawer';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { ChangePasswordModal } from 'Modals';
import { connect } from "react-redux";
import {
  loginOpen,
  UpdateProfile,
  SlipToggle,
  OpenBet,
} from "../../Redux/Reducers";
import { Buzzer, Socket, _, Http, API, Utils, AuthHelp } from "Helpers";
import { CurrentDate } from "Components";


import { Role } from 'Helpers'
import config from "config";

var globalThis = null;

const NotificationListRow = ({ notificationItem }) => {
  return (
    <div className={"list-card"}>
      <p
        dangerouslySetInnerHTML={{
          __html: globalThis.renderTagMessage(notificationItem) || "--",
        }}
      ></p>
    </div>
  );
};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: null,
      NotificationList: [],
      isDrawerOpen: false,
      is_mute: Buzzer.IsMute(),
      Offset: CONSTANT.CURRENT_OFFSET,
      unreadCount: 0,
      FilValue: "",
      ChangePasswordModalShow: false,
      LangValue: { id: 'en', name: 'Eng' },
      LangOptions: [
        { id: 'en', name: 'English' },
        { id: 'hi', name: 'हिन्दी' },
        { id: 'ben', name: 'বাংলা' }
      ],
      downloadPop: false,
      userAgentType: ""
    };

  }

  // LIST API
  getNotificationList = () => {
    const { Offset } = this.state;
    let param = {
      page_size: CONSTANT.ITEMS_PERPAGE_SM,
      page_no: 1,
    };

    this.setState({ posting: true });
    Http.post(API.GET_NOTIFICATION, param)
      .then((response) => {
        this.setState({
          posting: false,
          NotificationList: response.data,
        });
      })
      .catch((error) => {
        this.setState({ posting: false });
      });
  };
  getNotificationCount = () => {
    let param = {};

    this.setState({ posting: true });
    Http.post(API.GET_UNREAD_NOTIFICATION, param)
      .then((response) => {
        this.setState({
          posting: false,
          unreadCount: response.data,
        });
      })
      .catch((error) => {
        this.setState({ posting: false });
      });
  };

  getBalanace = () => {
    let param = {};
    Http.post(API.GET_BAL, param)
      .then((response) => {
        Auth.updateBalance(response.data.balance, response.data.exposure);
        this.setState({ currentUserObj: response.data });
      })
      .catch((error) => {
        this.setState({ posting: false });
      });
  };

  toggleDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };
  onChangeDrawer = (e = false) => {
    let LockScreen = "lock-screen";
    this.setState({ isDrawerOpen: e }, () => {
      if (e) {
        document.body.classList.add(LockScreen);
      } else {
        document.body.classList.remove(LockScreen);
      }
    });
  };

  logout = () => {
    Auth.logout();
    // this.props.history.push('/')
    this.props.UpdateProfile();
  };

  MuteToggle = () => {
    this.setState({ is_mute: !this.state.is_mute }, () => {
      Buzzer.Mute(this.state.is_mute);
    });
  };

  GotoNotification = () => {
    this.props.history.push("/notification");
    this.getNotificationCount();
  };

  sockeListener = () => {
    const currentUser = Auth.currentUserValue;
    const _this = this;
    Socket.on(SocketEvent.BALANCE_UPDATED, function (res) {
      console.log("he;;", res);
      if (currentUser && currentUser.user_guid == res.user_guid) {
        Auth.updateBalance(res.balance, res.exposure);
        _this.props.UpdateProfile();
      }
    });
    Socket.on(SocketEvent.SPORTS_SETTING_UPDATED, function (res) {
      if (currentUser && currentUser.user_guid == res.user_guid) {
        Auth.updateSetting(res.setting_data);
        _this.props.UpdateProfile();
      }
    });
  };

  removeSockeListener = () => {
    Socket.off(SocketEvent.BALANCE_UPDATED);
    Socket.off(SocketEvent.SPORTS_SETTING_UPDATED);
  };

  renderTagMessage = (rowData) => {
    var msg = rowData.message || "{{message}}";
    var content = rowData.content;

    // console.log(msg, 'msg', content)
    _.map(Object.keys(content), (key, idx) => {
      msg = msg.replace(
        "{{" + key + "}}",
        '<p class="highlighted-text">' + content[key] + "</p>"
      );
    });
    return msg;
  };

  modalShow = (name) => {
    this.setState({
      [name]: true
    })
  }

  modalHide = (name) => {
    this.setState({
      ChangePasswordModalShow: false,
    })
  }


  setSavedLang = () => {
    let getLang = Utils.getCookie('Isocial_lang');
    if (!getLang) return;

    let getObj = this.state.LangOptions.find(itm => itm.id == getLang);
    if (!getObj) return;

    this.setState({ LangValue: getObj })

  }


  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //   return "iOS";
    // }
    return "unknown";
  }

  GotoLink = () => {

    if (this.state.userAgentType === 'Android') {
      if (config.is_live) {
        // window.open("https://Isocial-master.s3.ap-south-1.amazonaws.com/app/Isocial-app.apk", "_blank");
        window.location.href = "https://Isocial-master.s3.ap-south-1.amazonaws.com/app/Isocial-app.apk";
      } else {
        window.open("https://drive.google.com/u/0/uc?id=1XB5xHMIoVeufKPk0V_e6-VerDrmqMBBC&export=download", "_blank");
      }

    }

    if (this.state.userAgentType === 'iOS') {
      window.open("https://apps.apple.com/in/app", "_blank");
    }

  }


  componentDidMount() {

    var userAgent = this.getMobileOperatingSystem();
    this.setState({ userAgentType: userAgent });
    if (userAgent !== "unknown") {
      this.setState({ downloadPop: true })
    }

    this.setSavedLang();

    if (
      localStorage.getItem("OddSelectiontype") == "" ||
      _.isNull(localStorage.getItem("OddSelectiontype"))
    ) {
      this.setState({
        FilValue: [{ id: "decimal", name: "Decimal" }],
      });
      localStorage.setItem("OddSelectiontype", "decimal");
    } else {
      this.setState({
        FilValue: [
          {
            id: localStorage.getItem("OddSelectiontype"),
            name: this.props.t(localStorage.getItem("OddSelectiontype")),
          },
        ],
      });
    }

    globalThis = this;
    const currentUser = Auth.currentUserValue;
    this.sockeListener();
    if (currentUser) {
      this.getNotificationList()
      this.getNotificationCount()
      if (currentUser.first_time_login == 0 && localStorage.getItem('firstlogin') == 0) {
        localStorage.setItem('firstlogin', 1);
        this.modalShow('ChangePasswordModalShow', currentUser)
      }

      // setInterval(function() {
      //   globalThis.getBalanace()
      // }, 10000);
    }
  }

  componentWillUnmount() {
    this.removeSockeListener();
    this.setState = () => {
      return;
    };
  }

  gotoPath = (pathname = "/") => {
    this.props.history.push(pathname);
    this.onChangeDrawer();
  };


  handleLanguageChange = (val) => {
    const { i18n } = this.props;
    i18n.changeLanguage(val.id);
    Utils.setCookie('Isocial_lang', val.id, 365)
    this.setState({ LangValue: val })
    if (AuthHelp.getAuth()) {
      localStorage.setItem('lang_posting', JSON.stringify(true));
    }
    window.location.reload(false);
  }
  handleFilterChange = (FilValue) => {
    this.setState(
      {
        FilValue,
      },
      () => {
        Utils.changeOdds(FilValue.id);
      }
    );
  };

  render() {
    // const currentUser = Auth.currentUserValue;
    const {
      OpenBet,
      SlipToggle,
      loginOpen,
      currentUser,
      location,
      t,
    } = this.props;
    const {
      isDrawerOpen,
      is_mute,
      NotificationList,
      unreadCount,
      FilValue,
      ChangePasswordModalShow,
      LangValue,
      LangOptions,
      userAgentType,
      downloadPop
    } = this.state;


    const DrawerProps = {
      zIndex: 1030,
      noTouchOpen: true,
      handleWidth: 5,
      overlayColor: "rgba(0,0,0,0.78)",
      drawerStyle: {
        boxShadow:
          "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px",
      },
    };
    const options = [
      { id: "decimal", name: "Decimal" },
      { id: "fractional", name: "Fractional" },
      { id: "american", name: "American" },
    ];

    const optionsHi = [
      { id: "decimal", name: "दशमलव" },
      { id: "fractional", name: "आंशिक" },
      { id: "american", name: "अमेरिकन" },
    ];

    const optionsBen = [
      { id: "decimal", name: "দশমিক" },
      { id: "fractional", name: "ভগ্নাংশ" },
      { id: "american", name: "মার্কিন" },
    ];


    const ChangePasswordModalProps = {
      isOpen: ChangePasswordModalShow,
      toggle: this.modalHide,
    }
    // const options = [
    //   { id: 'decimal', name: 'Decimal' },
    //   { id: 'decimal', name: 'Decimal' },
    //   { id: 'decimal', name: 'Decimal' }
    // ]
    // const LangOptions = [
    //   { id: 'en', name: 'Eng' },
    //   { id: 'hi', name: 'हिन्दी' },
    //   { id: 'ben', name: 'বাংলা' }
    // ]

    const BudgetComp = () => {
      const currentUser = Auth.currentUserValue;
      return currentUser ? (
        <div className="budget">
          <span className="balance">
            <span className="lbl">Pts</span> {_.round(currentUser.balance, 2)}
          </span>
          {!_.isUndefined(currentUser.exposure) && (
            <span className="exposure">
              <span className="lbl">Exposure </span>
              {_.round(currentUser.exposure, 2) == 0 ? "" : "-"}
              {_.round(currentUser.exposure, 2)}
            </span>
          )}
        </div>
      ) : (
          ""
        );
    };
    const GlobalNav = () => {
      return (
        <nav className="global-nav">
          <NavLink to="/exchange">{t("Exchange")}</NavLink>
          <NavLink to="/sportsbook">{t("Bet365")}</NavLink>
          <NavLink to="/live-cards">{t("Live Cards")}</NavLink>
          {<NavLink to="/live-casino">{t("Live Casino")}</NavLink>}
          {/* <NavLink to="/casino">{t("Casino")}</NavLink> */}
        </nav>
      );
    };

    return (
      <Fragment>
        <Drawer
          open={isDrawerOpen}
          className="custom-drawer"
          {...DrawerProps}
          onChange={this.onChangeDrawer}
          aria-drawer="true"
        >
          <div
            className={
              "drawer-header " + (currentUser ? "drawer-header-inner" : "")
            }
          >
            {currentUser ? (
              <div className="drawer-header-body">
                <i className="logo">
                  <img src={Images.Logo} alt="" />
                </i>
                <div className="user-profile-data">
                  {/* <div className="figure">
                    <img src={Images.THUMB} alt="" />
                  </div> */}
                  <h4>
                    {currentUser.name !== "" ? (
                      <span title={currentUser.name}>{currentUser.name}</span>
                    ) : (
                        currentUser.username
                      )}
                  </h4>
                </div>
                <div className="user-budget">
                  <span className="balance">
                    <span className="lbl">{t("Pts")}</span>{" "}
                    {_.round(currentUser.balance, 2)}
                  </span>
                  {!_.isUndefined(currentUser.exposure) && (
                    <span className="exposure">
                      <span className="lbl">{t("Exposure")} </span>
                      {_.round(currentUser.exposure, 2) == 0 ? "" : "-"}
                      {_.round(currentUser.exposure, 2)}
                    </span>
                  )}
                </div>
              </div>
            ) : (
                <i className="logo">
                  <img src={Images.Logo} alt="" />
                </i>
              )}
            <i className="icon-cancel" onClick={() => this.toggleDrawer()} />

            {/*             
            <Row className="dropdown-wrap gutters-10px">
              <Col>
                <Select
                  className='react-select outline'
                  classNamePrefix="react-select"
                  defaultValue={options[0]}
                  isSearchable={false}
                  options={options}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                // menuIsOpen={true}
                />
              </Col>
              <Col>
                <Select
                  className='react-select outline'
                  classNamePrefix="react-select"
                  defaultValue={LangOptions[0]}
                  isSearchable={false}
                  options={LangOptions}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                // menuIsOpen={true}
                />
              </Col>
            </Row>
             */}
          </div>
          <div className={"drawer-body " + (currentUser ? "lg" : "")}>
            <div className={'d-flex'}>
              <div className="odds-format-select">
                <Select
                  className='react-select'
                  classNamePrefix="react-select"
                  // defaultValue={LangOptions[0]}
                  value={LangValue ? LangValue : LangOptions[0]}
                  isSearchable={false}
                  options={LangOptions}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  onChange={this.handleLanguageChange}
                // menuIsOpen={true}
                />
              </div>
              <div className="odds-format-select">
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  value={FilValue}
                  isClearable={false}
                  isSearchable={false}
                  options={Utils.geti18nextLng() == 'ben' ? optionsBen : Utils.geti18nextLng() == 'hi' ? optionsHi : Utils.geti18nextLng() == 'en' ? options : ""}
                  onChange={this.handleFilterChange}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                />
              </div>
            </div>

            {currentUser && (
              <Fragment>
                <a
                  className="nav-item"
                  onClick={() => this.gotoPath("/bet-history")}
                >
                  {t("Bets History")}
                </a>
                <a
                  className="nav-item"
                  onClick={() => this.gotoPath("/profit-loss")}
                >
                  {t("Profit & Loss")}
                </a>
                <a
                  className="nav-item"
                  onClick={() => this.gotoPath("/account-statement")}
                >
                  {t("Account Statement")}
                </a>

                {
                  currentUser != null && currentUser.role == Role.AgentPunter ?
                    <React.Fragment>
                      <a
                        className="nav-item"
                        onClick={() => this.gotoPath("/deposit")}
                      >
                        {t("Deposit")}
                      </a>
                      <a
                        className="nav-item"
                        onClick={() => this.gotoPath("/withdraw")}
                      >
                        {t("Withdraw")}
                      </a>
                      <a
                        className="nav-item"
                        onClick={() => this.gotoPath("/account-information")}
                      >
                        {t("Account Information")}
                      </a>
                    </React.Fragment>
                    : ''
                }
                <a
                  className="nav-item"
                  onClick={() => this.gotoPath("/change-password")}
                >
                  {t("Change Password")}
                </a>
                <a
                  className="nav-item"
                  onClick={() => this.gotoPath("/notification")}
                >
                  {t("Notification")}
                </a>
              </Fragment>
            )}

            <a
              className="nav-item"
              onClick={() => this.gotoPath("/responsible-gambling")}
            >
              {t("Responsible Gaming")}
            </a>
            <a
              className="nav-item"
              onClick={() => this.gotoPath("/terms-and-conditions")}
            >
              {t("Term & Conditions")}
            </a>
            {currentUser ? (
              <a
                className="nav-item danger-color"
                onClick={() => [this.logout(), this.onChangeDrawer()]}
              >
                {t("Logout")}
              </a>
            ) : (
                <React.Fragment>
                  <a
                    className="nav-item"
                    onClick={() => [this.onChangeDrawer(false), loginOpen()]}
                  >
                    {t("Login")}
                  </a>

                  <Link
                    className="nav-item"
                    onClick={() => [this.onChangeDrawer(false)]}
                    to={'/signup'}
                  >
                    {t("Sign up")}
                  </Link>
                </React.Fragment>
              )}
          </div>
        </Drawer>

        <header className="app-toolbar" id="AppToolbar">
          <div className="max-width-container">
            <div className="mobile-visible">
              {!currentUser ? (
                <React.Fragment>

                  <div className="open-bet-btn login" onClick={() => loginOpen()}>
                    {t("Login")}
                  </div>
                  <Link to={'signup'}>
                    <div className="open-bet-btn login">
                      {t("Sign up")}
                    </div>
                  </Link>
                </React.Fragment>
              ) : (
                  <div
                    className="open-bet-btn"
                    onClick={() => OpenBet({ type: true, bool: true })}
                  >
                    {t("Open Bets")}
                  </div>
                )}

              <i
                className="menu-tigger icon-menu"
                onClick={() => this.toggleDrawer()}
              />
              <Link className="site-logo" to="/">
                <img src={Images.LOGO_SM} alt="" />
              </Link>
              <div className="mobile-header-overflow">
                <BudgetComp />
                <CurrentDate />
              </div>

              <GlobalNav />
            </div>
            <div className="mobile-hidden desk-nav">
              <Link className="site-logo" to="/">
                <img src={Images.Logo} alt="" />
              </Link>
              <GlobalNav />
              <CurrentDate />

              {currentUser ? (
                <Fragment>
                  <a className="logout" onClick={() => this.logout()}>
                    <i className="icon-logout" />
                  </a>
                  <div className="notification-dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="logout">
                        {unreadCount > 0 && (
                          <span className="notification-badge"></span>
                        )}
                        <i
                          className="icon-bell"
                          onClick={() => this.getNotificationList()}
                        />
                      </DropdownToggle>
                      <DropdownMenu right>
                        {NotificationList.length > 0 ? (
                          <div>
                            <ul className="notification-dropdwn-list">
                              {_.map(NotificationList, (item, idx) => {
                                return (
                                  <li key={idx}>
                                    <span>
                                      {Utils.dateTimeFormat(
                                        item.added_date,
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                    <div>
                                      <NotificationListRow
                                        notificationItem={item}
                                        notificationKey={idx}
                                      />
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                            {NotificationList.length > 9 && (
                              <a
                                className="view-notification"
                                onClick={() => this.GotoNotification()}
                              >
                                View All
                              </a>
                            )}
                          </div>
                        ) : (
                            <p className="empty-notification">
                              Yay! Your notification bucket is empty{" "}
                            </p>
                          )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>

                  <span className="avtarsap" />

                  <div className="avtar-dropdown">
                    <div className="user-content">
                      <UncontrolledDropdown>
                        <DropdownToggle tag="div" className="name">
                          {currentUser.name !== "" ? (
                            <span title={currentUser.name}>
                              {currentUser.name}
                            </span>
                          ) : (
                              currentUser.username
                            )}
                          <i className="icon-down" />
                        </DropdownToggle>

                        <DropdownMenu right>
                          <DropdownItem>
                            <Link className="block" to="/bet-history">
                              {t("Bets History")}
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link className="block" to="/profit-loss">
                              {t("Profit & Loss")}
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link className="block" to="/account-statement">
                              {t("Account Statement")}
                            </Link>
                          </DropdownItem>

                          {
                            currentUser != null && currentUser.role == Role.AgentPunter ?
                              <React.Fragment>

                                <DropdownItem>
                                  <Link className="block" to="/deposit">
                                    {t("Deposit")}
                                  </Link>
                                </DropdownItem>
                                <DropdownItem>
                                  <Link className="block" to="/withdraw">
                                    {t("Withdraw")}
                                  </Link>
                                </DropdownItem>
                                <DropdownItem>
                                  <Link className="block" to="/account-information">
                                    {t("Account Information")}
                                  </Link>
                                </DropdownItem>

                              </React.Fragment>
                              : ''
                          }

                          <DropdownItem>
                            <Link className="block" to="/change-password">
                              {t("Change Password")}
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link className="block" to="/notification">
                              {t("Notification")}
                            </Link>
                          </DropdownItem>
                          {/* <div className='dropdown-menu-nav'>
                              <i {...{ className: `icn ${is_mute ? 'icon-mute' : 'icon-unmute'}` }} onClick={() => this.MuteToggle()} />
                            </div> */}
                          {/* <DropdownItem>
                          </DropdownItem> */}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <BudgetComp />
                    </div>
                    {/* <div className="figure">
                        <img src={Images.THUMB} alt="" />
                      </div> */}
                  </div>
                  <div className="odds-format-select lang-sel">
                    <Select
                      className='react-select'
                      classNamePrefix="react-select"
                      // defaultValue={LangOptions[0]}
                      value={LangValue ? LangValue : LangOptions[0]}
                      isSearchable={false}
                      options={LangOptions}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      onChange={this.handleLanguageChange}
                    // menuIsOpen={true}
                    />
                  </div>
                  <div className="odds-format-select">
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      value={FilValue}
                      isClearable={false}
                      isSearchable={false}
                      options={
                        Utils.geti18nextLng() == 'ben' ? optionsBen
                          : Utils.geti18nextLng() == 'hi' ? optionsHi
                            : Utils.geti18nextLng() == 'en' ? options
                              : ""
                      }
                      onChange={this.handleFilterChange}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                    />
                  </div>
                </Fragment>
              ) : (
                  <Fragment>
                    <Button
                      size="sm"
                      color="secondary"
                      className="login-btn"
                      onClick={() => loginOpen()}
                    >
                      {t("Login")}
                    </Button>
                    <Link to={'signup'}>

                      <Button
                        size="sm"
                        color="secondary"
                        className="login-btn"
                      >
                        {t("Sign up")}
                      </Button>
                    </Link>

                    <div className="odds-format-select lang-sel">
                      <Select
                        className='react-select'
                        classNamePrefix="react-select"
                        // defaultValue={LangOptions[0]}
                        value={LangValue ? LangValue : LangOptions[0]}
                        isSearchable={false}
                        options={LangOptions}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        onChange={this.handleLanguageChange}
                      // menuIsOpen={true}
                      />
                    </div>
                    <div className="odds-format-select">
                      <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        value={FilValue}
                        isClearable={false}
                        isSearchable={false}
                        options={Utils.geti18nextLng() == 'ben' ? optionsBen : Utils.geti18nextLng() == 'hi' ? optionsHi : Utils.geti18nextLng() == 'en' ? options : ""}
                        onChange={this.handleFilterChange}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </div>


                  </Fragment>
                )}


            </div>
          </div>
        </header>
        {ChangePasswordModalShow && <ChangePasswordModal {...ChangePasswordModalProps} />}


        {
          (downloadPop && userAgentType != "unknown" && !window.ReactNativeWebView) &&
          <div className={`download-pop`}>
            {/* ${config.is_live ? 'd-none':''} */}
            <div className={'box-area'}>
              <img src={Images.LOGO_SM} alt="" />
              <h3>Want the best experience?</h3>
              <i className="close-btn icon-cancel" onClick={() => this.setState({ downloadPop: false })} />
              <div className={'plystore-btn-area'}>
                {
                  userAgentType == "Android" ?
                    <a onClick={this.GotoLink}>
                      <img src={Images.ANDROID} alt={'Android'} />
                    </a> :
                    userAgentType == "iOS" ?
                      <a onClick={this.GotoLink}>
                        <img src={Images.APP_STORE} alt={'iOS'} />
                      </a> :
                      ""
                }

              </div>
            </div>
          </div>
        }


      </Fragment>
    );
  }
}

const DefaultHeaderTrans = withTranslation()(DefaultHeader);

function mapStateToProps(state) {
  const { login, app } = state;
  return {
    isOpen: login.isOpen,
    currentUser: app.currentUser,
  };
}
const mapDispatchToProps = (dispatch) => ({
  loginOpen: () => dispatch(loginOpen()),
  UpdateProfile: () => dispatch(UpdateProfile()),
  SlipToggle: () => dispatch(SlipToggle()),
  OpenBet: (option) => dispatch(OpenBet(option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeaderTrans);
