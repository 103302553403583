import React, { Component, Fragment } from 'react';
import { API, Http, _ } from 'Helpers';
import { SiteContainer } from 'Components';

class Bet365Poc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: true,
      EventData: {},
    }
  }

  getEvent = () => {
    const { match } = this.props
    let param = {
      event_id: match.params.event_id
    }
    Http.post(API.BET365_EVENT, param).then(response => {
      console.log(response)
      let EventDetails = { }
      
      if(!_.isEmpty(response.data.event_detail)) {
        let _ev = response.data.event_detail[0]
        EventDetails = { ..._ev, participants: JSON.parse(_ev.participants), league_json: JSON.parse(_ev.league_json)}
      }
      this.setState({
        posting: false,
        EventData: response.data.events,
        EventDetails: EventDetails,
      })
    }).catch(error => {

    });
  }
  
  componentDidMount() {
    this.getEvent()
    setInterval(() => {
      this.getEvent()
    }, 300)
  }
  render() {

    const {
      EventDetails,
      EventData,
      posting
    } = this.state

    return (
      <SiteContainer {...this.props}>
        {
          posting ?
            <div className="text-center">Loading...</div>
            :
            <div className="poc-wrapper">
              {
                !_.isEmpty(EventDetails) &&
                <Fragment>
                  <h4>{`${EventDetails.participants.home.name} vs ${EventDetails.participants.away.name}`}</h4>
                </Fragment>
              }
              {
                !_.isEmpty(EventData) &&

                _.map(EventData, (item, key) => {
                  return (
                    <Fragment {...{ key }}>
                      {
                        item.has_lines == 1 ?
                          <div {...{ className: `odd-market-collapse active` }}>
                            <div className="heading">{item.market_name}</div>
                            <div className="odd-market-body">
                              {
                                !_.isEmpty(item.runners_odds) &&
                                <div {...{ className: `odd-market-row head` }}>
                                  {
                                    _.map(_.keys(item.runners_odds), (o, key) => {
                                      return (
                                        <div {...{ className: `odd-market-line`, key, style: {order: o == 'Lines' ? 0 : (key + 1)} }}>{o}</div>
                                      )
                                    })
                                  }
                                </div>
                              }
                              <div className="odd-market-row">
                                {
                                  _.map(item.runners_odds, (object, key) => {
                                    return (
                                      <div {...{ className: `odd-market-line`, key, style: {order: key == 'Lines' ? 0 : 1} }}>
                                        {
                                          _.map(object, (item, key) => {
                                            return (
                                              item.odds ?
                                              <div className="odd-market-line text-center" {...{key}}>{item.odds}</div>
                                              :
                                              <div className="odd-market-line text-center" {...{key}}>{item.name || item.header}</div>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                              {
                                _.isEmpty(item.runners_odds) &&
                                <div className="odd-market-row">
                                  <div className="odd-market-line text-center">Odds Not available</div>
                                </div>
                              }
                            </div>
                          </div>
                          :
                          <div {...{ className: `odd-market-collapse active` }} >
                            <div className="heading">{item.market_name}</div>
                            <div className="odd-market-body">
                              {
                                _.map(item.runners_odds, (obj, key) => {
                                  return (
                                    <div className="cell" {...{ key }}>
                                      <div className='text-cell'>{obj.name}</div>
                                      <div className='odd-market-last'>
                                        <a className="odd-market-btn">{obj.odds}</a>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                              {
                                _.isEmpty(item.runners_odds) &&
                                <div className="odd-market-row">
                                  <div className="odd-market-line text-center">Odds Not available</div>
                                </div>
                              }
                            </div>
                          </div>
                      }
                    </Fragment>
                  )
                })

              }

            </div>
        }
      </SiteContainer>
    );
  }
}

export default Bet365Poc;
